import {FadeInView} from '@/components/basic/animations';
import DetailNavTitle from '@/components/business/detail-nav-title';
import React, {useRef, useState} from 'react';
import Wallet from '@/common-pages/wallet';
import CommonScratch from '@/common-pages/scratch';
import {NavigatorScreenProps} from '@/types';
import {View} from 'react-native';
import theme from '@/style';
import WebView from 'react-native-webview';
import {goBack} from '@/utils';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';

const Scratch = (props: NavigatorScreenProps) => {
  const {i18n} = useTranslation();
  const scratchRef = useRef<{
    webViewRef: React.RefObject<WebView<{}>>;
  }>(null);
  const [canGoBack, setCanGoBack] = useState(false);
  const handleCanGoBack = (_canGoBack: boolean) => {
    setCanGoBack(_canGoBack);
  };
  const handleBack = () => {
    if (globalStore.isWeb) {
      window.history.back();
    } else {
      if (canGoBack) {
        scratchRef.current?.webViewRef.current?.goBack();
      } else {
        goBack();
      }
    }
  };
  return (
    <FadeInView>
      <DetailNavTitle
        hideServer
        hideAmount
        title={i18n.t('home.scratch.title')}
        rightNode={<Wallet theme="dark" />}
        onBack={handleBack}
      />
      <View style={[theme.flex.flex1]}>
        <CommonScratch
          {...props}
          ref={scratchRef}
          onCanGoBack={handleCanGoBack}
        />
      </View>
    </FadeInView>
  );
};

export default Scratch;
