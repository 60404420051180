import React, {useEffect, useRef, useState} from 'react';
import {Animated, View} from 'react-native';
import parabolaService from './parabola.service';
import theme from '@/style';
import {ParabolaStartOffset, ParabolaStartProps, Position} from './types';

/** 二维系数(A) */
const coefficientA = 0.002;

const ParabolaProvider = () => {
  const [render, setRender] = useState<React.ReactElement>(
    <View
      style={[
        {
          width: theme.paddingSize.l,
          height: theme.paddingSize.l,
        },
        theme.borderRadius.xl,
        theme.background.primary,
      ]}
    />,
  );
  const [isMoving, setIsMoving] = useState(false);
  const isMovingRef = useRef(false);
  const currentPosition = useRef(new Animated.ValueXY({x: 0, y: 0})).current;
  const [renderLeft, setRenderLeft] = useState(0);
  const [renderTop, setRenderTop] = useState(0);
  const getPosition = (rely: View, position?: Position) => {
    return new Promise<ParabolaStartOffset>((res, rej) => {
      if (!rely) {
        rej('元素错误');
      }
      rely.measure((_, __, width, height, left, top) => {
        const {xl, xm, xr, yt, ym, yb} = {
          xl: left,
          xm: left + width / 2,
          xr: left + width,
          yt: top,
          ym: top + height / 2,
          yb: top + height,
        };
        switch (position) {
          case 'top':
            return res([xm, yt]);
          case 'bottom':
            return res([xm, yb]);
          case 'left':
            return res([xl, ym]);
          case 'right':
            return res([xr, ym]);
          case 'topLeft':
            return res([xl, yt]);
          case 'topRight':
            return res([xr, yt]);
          case 'bottomLeft':
            return res([xl, yb]);
          case 'bottomRight':
            return res([xr, yb]);
        }
        return res([xm, ym]);
      });
    });
  };
  const start = (res: ParabolaStartProps) => {
    if (!res.startWith || !res.endWith) {
      return;
    }
    Promise.all([getPosition(res.startWith), getPosition(res.endWith)])
      .then(([s, e]) => {
        // eslint-disable-next-line no-self-compare
        if (s.some(v => v !== v) && e.some(v => v !== v)) {
          console.error('始终位置获取失败：', s, e);
          return;
        }
        if (res.render) {
          setRender(res.render);
        }
        setRenderLeft(s[0]);
        setRenderTop(s[1]);
        clear();
        requestAnimationFrame(() => {
          setIsMoving(true);
          isMovingRef.current = true;
          move(s, e, res.afterStop);
        });
      })
      .catch(e => {
        console.error(e);
      });
  };
  const clear = () => {
    setIsMoving(false);
    isMovingRef.current = false;
  };
  const move = (
    startOffset: ParabolaStartOffset,
    endOffset: ParabolaStartOffset,
    afterStop?: () => void,
  ) => {
    /** 坐标 */
    const coordTarget = {
      x: -(startOffset[0] - endOffset[0]),
      y: -(startOffset[1] - endOffset[1]),
    };
    /** 根据距离比例得出一维系数(B) */
    const coefficientB = coordTarget.x
      ? (coordTarget.y - coefficientA * coordTarget.x * coordTarget.x) /
        coordTarget.x
      : 1;
    currentPosition.setValue({
      x: startOffset[0],
      y: startOffset[1],
    });
    /** 当前的x轴偏移量 */
    let startx = 0;
    /** 判断是向左还是向右 */
    let rate = coordTarget.x > 0 ? 1 : -1;
    const step = () => {
      if (!isMovingRef.current) {
        return;
      }
      var tangent = 2 * coefficientA * startx + coefficientB;
      startx = startx + rate * Math.sqrt(166.7 / (tangent * tangent + 1));
      // 限制范围
      if (
        (rate === 1 && startx > coordTarget.x) ||
        (rate === -1 && startx < coordTarget.x)
      ) {
        startx = coordTarget.x;
      }
      const x = startx;
      const y = coefficientA * x * x + coefficientB * x;
      if (!x || !y) {
        return console.warn(
          'The starting position and the ending position are coaxial',
        );
      }
      currentPosition.setValue({x, y});
      if (startx !== coordTarget.x) {
        requestAnimationFrame(step);
      } else {
        afterStop?.();
        clear();
      }
    };
    requestAnimationFrame(step);
  };
  useEffect(() => {
    const sub = parabolaService.startSubject.subscribe(res => {
      const canStart = res.beforeMove?.() ?? true;
      if (!canStart) {
        return;
      }
      start(res);
    });
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <View
      style={[
        theme.position.abs,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          left: 0,
          top: 0,
          zIndex: 99,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        },
      ]}>
      {isMoving && (
        <Animated.View
          style={[
            theme.position.abs,
            {
              transform: currentPosition.getTranslateTransform(),
              left: renderLeft || 0,
              top: renderTop || 0,
            },
          ]}>
          {render}
        </Animated.View>
      )}
    </View>
  );
};

export default ParabolaProvider;
