import {SVGProps} from '@/types';
import React from 'react';

const Analyze = ({color = '#31373D', width = 20, height = 20}: SVGProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_19409_5855)">
        <path
          d="M1.66602 18.334H18.3327"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.66602 10.834L4.99935 11.6673V15.834H1.66602V10.834Z"
          fill={color}
        />
        <path
          d="M8.33398 10.0007L11.6673 8.33398V15.834H8.33398V10.0007Z"
          fill={color}
        />
        <path d="M15 6.66667L18.3333 5V15.8333H15V6.66667Z" fill={color} />
        <path
          d="M1.66602 7.49935L4.99935 8.33268L18.3327 1.66602H14.166"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_19409_5855">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Analyze;
