import {Animated} from 'react-native';
import Ball, {BallProps} from './ball';
import React from 'react';
import theme from '@/style';
import {useResponsiveDimensions} from '@/utils';

const BetsBall = ({ball}: {ball: BallProps}) => {
  const {width} = useResponsiveDimensions();
  const transAnim = React.useRef(new Animated.Value(width)).current;
  const rotateAnim = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    Animated.parallel([trans(), rotate()]).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trans = () =>
    Animated.timing(transAnim, {
      toValue: 0,
      duration: 1500,
      useNativeDriver: true,
    });

  const rotate = () =>
    Animated.timing(rotateAnim, {
      toValue: 2,
      duration: 1500,
      useNativeDriver: true,
    });

  return (
    <Animated.View
      style={[
        {
          transform: [
            {
              translateX: transAnim,
            },
            {
              rotate: rotateAnim.interpolate({
                inputRange: [0, 1, 2],
                outputRange: ['0deg', '-360deg', '-720deg'],
              }),
            },
          ],
        },
      ]}>
      <Ball
        digit={ball.digit}
        color={ball.color}
        ballSize={48}
        fontSize={ball.onlyColor ? 12 : 20}
        fontColor={
          ball.onlyColor ? theme.basicColor.white : theme.basicColor.dark
        }
        onlyColor={ball.onlyColor}
      />
    </Animated.View>
  );
};

export default BetsBall;
