import {StyleSheet, View} from 'react-native';
import React, {useState, useMemo} from 'react';
import Text from '@basicComponents/text';

import theme from '@/style';
import {useResponsiveDimensions} from '@/utils';

interface CountDownProps {
  /** 倒计时，单位：秒 */
  remain?: number;
  /** 倒计时结束后重新计时的时间，单位：秒 */
  resetTime: number;
  calc?: boolean;
  serverTime?: number;
  currentTime?: number;
  dotColor?: string;
}

const CountDown: React.FC<CountDownProps> = ({
  resetTime = 0,
  calc,
  remain = -1,
  serverTime = 0,
  dotColor,
}) => {
  const remainRef = React.useRef<number>(0);
  const [current, setCurrent] = useState(0);
  const timer = React.useRef<NodeJS.Timeout>();

  const startTime = () => {
    timer.current = setInterval(() => {
      if (remainRef.current === 0) {
        if (resetTime > 0) {
          remainRef.current = resetTime;
        } else {
          clearInterval(timer.current);
          remainRef.current = 0;
          setCurrent(remainRef.current);
          return;
        }
      }
      remainRef.current -= 1;
      setCurrent(remainRef.current);
    }, 1000);
  };

  React.useEffect(() => {
    if (remain > 0 || resetTime > 0) {
      remainRef.current = remain === 0 ? resetTime : remain;
      startTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remain, resetTime]);

  React.useEffect(() => {
    return () => timer.current && clearInterval(timer.current);
  }, []);

  const remainValue = useMemo(() => {
    const hour = Math.floor(current / 3600);
    const minutes = Math.floor(current / 60) % 60;
    const seconds = current % 60;
    return {
      hour: (hour + '').padStart(2, '0'),
      minutes: (minutes + '').padStart(2, '0'),
      seconds: (seconds + '').padStart(2, '0'),
    };
  }, [current]);

  // useEffect(() => {
  //   if (!serverTime) {
  //     setRemainTime(0);
  //     setRemainTime(0);
  //   }
  //   const timer = setInterval(() => {
  //     if (remainRef.current > 0) {
  //       remainRef.current--;
  //       setRemainTime(remainRef.current);
  //     } else if (resetTime) {
  //       remainRef.current = resetTime;
  //       setRemainTime(remainRef.current);
  //     } else {
  //       clearInterval(timer);
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [resetTime, serverTime]);

  // useEffect(() => {
  //   setRemainTime(remain);
  //   remainRef.current = remain;
  // }, [remain]);

  const {width} = useResponsiveDimensions();
  const _calc = width / 375;
  const numberSize = theme.iconSize.s * (calc ? _calc : 1);
  const styles = StyleSheet.create({
    blackSquare: {
      backgroundColor: theme.basicColor.dark,
      borderRadius: 2,
      width: numberSize,
      height: numberSize,
    },
    marginHorizonalXXXS: {
      marginHorizontal: 2,
    },
  });
  return (
    <View style={[theme.flex.row, theme.flex.centerByCol]}>
      <View style={[theme.flex.center, styles.blackSquare]}>
        <Text
          calc={calc}
          fontSize={9}
          fontFamily="fontDinBold"
          style={[theme.font.white]}>
          {serverTime ? remainValue.hour : '--'}
        </Text>
      </View>
      <Text
        calc={calc}
        fontSize={9}
        fontFamily="fontDinBold"
        style={[
          {
            color: dotColor || theme.basicColor.white,
          },
          styles.marginHorizonalXXXS,
        ]}>
        :
      </Text>
      <View style={[theme.flex.center, styles.blackSquare]}>
        <Text
          calc={calc}
          fontSize={9}
          fontFamily="fontDinBold"
          style={[theme.font.white]}>
          {serverTime ? remainValue.minutes : '--'}
        </Text>
      </View>
      <Text
        calc={calc}
        fontSize={9}
        fontFamily="fontDinBold"
        style={[
          {
            color: dotColor || theme.basicColor.white,
          },
          styles.marginHorizonalXXXS,
        ]}>
        :
      </Text>
      <View style={[theme.flex.center, styles.blackSquare]}>
        <Text
          calc={calc}
          fontSize={9}
          fontFamily="fontDinBold"
          style={[theme.font.white]}>
          {serverTime ? remainValue.seconds : '--'}
        </Text>
      </View>
    </View>
  );
};

export default CountDown;
