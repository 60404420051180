import {SVGProps} from '@/types';
import React from 'react';

const Question = ({color = '#31373D', width = 20, height = 20}: SVGProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="8.75" fill={color} />
      <path
        d="M8.73011 11.3409C8.49148 9.55682 10.9119 9.15909 10.9119 8.05682C10.9119 7.36364 10.3665 6.97727 9.46875 6.97727C8.77557 6.97727 8.20739 7.29545 7.65057 7.875L6.71875 7.02273C7.44602 6.18182 8.44602 5.625 9.66193 5.625C11.3097 5.625 12.5142 6.39773 12.5142 7.90909C12.5142 9.56818 10.0256 9.77273 10.2074 11.3409H8.73011ZM9.48011 14.375C8.8892 14.375 8.46875 13.9545 8.46875 13.3636C8.46875 12.7727 8.90057 12.3636 9.48011 12.3636C10.0483 12.3636 10.4801 12.7727 10.4801 13.3636C10.4801 13.9545 10.0483 14.375 9.48011 14.375Z"
        fill="white"
      />
    </svg>
  );
};

export default Question;
