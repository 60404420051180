import theme from '@/style';
import {SVGProps} from '@/types';
import React from 'react';

const HomeSvg = ({color, width, height}: SVGProps) => (
  <svg
    width={width || theme.iconSize.l}
    height={height || theme.iconSize.l}
    viewBox="0 0 24 24"
    fill={color || theme.basicColor.primary}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M20.6727 10.1045L12.4909 3.23178C12.1636 2.90451 11.6727 2.90451 11.5091 3.23178L3.32727 10.1045C3.16364 10.2681 3 10.5954 3 10.759V20.0863C3 20.5772 3.32727 20.9045 3.81818 20.9045H20.1818C20.6727 20.9045 21 20.5772 21 20.0863V10.759C21 10.5954 20.8364 10.2681 20.6727 10.1045ZM14.2909 19.4318H9.70909V15.6681C9.70909 14.359 10.6909 13.3772 12 13.3772C13.3091 13.3772 14.2909 14.359 14.2909 15.6681V19.4318Z" />
    <path
      d="M12.0932 3.62953L12.1104 3.64679L12.1291 3.66249L20.2863 10.5145C20.2968 10.5266 20.3112 10.5448 20.3281 10.5697C20.3516 10.604 20.3747 10.6435 20.3946 10.6833C20.4149 10.7238 20.4284 10.7579 20.4359 10.7813C20.4365 10.7832 20.437 10.785 20.4375 10.7866V20.0863C20.4375 20.2039 20.4007 20.2582 20.3773 20.2817C20.3538 20.3052 20.2994 20.342 20.1818 20.342H3.81818C3.70055 20.342 3.64624 20.3052 3.62275 20.2817C3.59925 20.2582 3.5625 20.2039 3.5625 20.0863V10.7866C3.56298 10.785 3.56353 10.7832 3.56415 10.7813C3.57158 10.7579 3.58514 10.7238 3.60539 10.6833C3.62532 10.6435 3.64843 10.604 3.67185 10.5697C3.68879 10.5448 3.70319 10.5266 3.7137 10.5145L11.8709 3.66249L11.9601 3.58755L11.9761 3.55551C12.0068 3.56485 12.0495 3.58585 12.0932 3.62953ZM20.4406 10.7983C20.4406 10.7983 20.4404 10.7977 20.4402 10.7965L20.4406 10.7983ZM3.55942 10.7983C3.5594 10.7983 3.55951 10.7978 3.55979 10.7965L3.55942 10.7983ZM14.2909 19.9943H14.8534V19.4318V15.6681C14.8534 14.0484 13.6198 12.8147 12 12.8147C10.3802 12.8147 9.14659 14.0484 9.14659 15.6681V19.4318V19.9943H9.70909H14.2909Z"
      stroke="white"
      strokeOpacity="0.15"
      strokeWidth="1.125"
    />
  </svg>
);

export default HomeSvg;
