import theme from '@/style';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import {useTranslation} from 'react-i18next';
import CodeItem from '../../components/code-item';
import {SattaOrderDigitItem} from '../../satta-service';
import MoreBtn from '@/components/business/order/more-btn';

const HEADER = [
  {
    label: 'number',
    style: {
      flex: 1,
    },
  },
  {
    label: 'payment',
    style: {
      ...theme.flex.centerByCol,
      flex: 1,
    },
  },
  {
    label: 'result',
    style: {
      flex: 1,
      ...theme.flex.alignEnd,
    },
  },
];

const whenNumToHide = 5;

export interface CodeListProps {
  list: SattaOrderDigitItem[];
  isOpen?: boolean;
}

const CodeList = ({list, isOpen}: CodeListProps) => {
  const {i18n} = useTranslation();
  const [showMore, setShowMore] = React.useState(false);

  const digitList = React.useMemo(() => {
    if (list.length <= whenNumToHide) {
      return list;
    } else {
      return showMore ? list : list.slice(0, whenNumToHide);
    }
  }, [showMore, list]);

  return (
    <View style={[theme.margin.lrl]}>
      <View style={[theme.flex.row, styles.header, theme.flex.between]}>
        {HEADER.map((item, _i) => (
          <View key={_i} style={item.style}>
            <Text>
              {i18n.t('bets-detail.label.' + item.label).toUpperCase()}
            </Text>
          </View>
        ))}
      </View>
      {digitList.map((item, _i) => (
        <CodeItem isOpen={isOpen} key={_i} index={_i} {...item} />
      ))}
      {list.length > whenNumToHide && !showMore && (
        <MoreBtn onPress={() => setShowMore(true)} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#E9ECF5',
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
});

export default React.memo(CodeList);
