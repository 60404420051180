import theme from '@/style';
import {SVGProps} from '@/types';
import React from 'react';

const BallResultBottom = ({width = 73, height = 12}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 73 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50017 12H65.5002C68.223 12 70.6281 10.6398 72.073 8.56176L67.2744 0H6.96757L0.389294 8.80277C0.154849 9.1165 0.0294269 9.46188 -0.00341801 9.8061C1.4306 11.1659 3.36798 12 5.50017 12Z"
      fill="url(#paint0_linear_5567_17812)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5567_17812"
        x1="35.6368"
        y1="0"
        x2="35.6368"
        y2="12"
        gradientUnits="userSpaceOnUse">
        <stop stopColor={theme.backgroundColor.diceResultBg} />
        <stop offset="1" stopColor={theme.backgroundColor.diceResultBgLight} />
      </linearGradient>
    </defs>
  </svg>
);

export default BallResultBottom;
