import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React, {useEffect, useRef} from 'react';
import {View, Image} from 'react-native';
import Text from '@basicComponents/text';
import Tag from '@basicComponents/tag';
import {NoticeMap} from '../home.type';
import {useLuckySpinModal} from '@/common-pages/luckyspin/luckyspin.hooks';
import {useLotteryModal} from '../lottery.hooks';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {postGetFreeLottery} from '../home.service';
import {goTo, toAgentApply, useResponsiveDimensions} from '@/utils';
import theme from '@style';
import {postSpinConfig} from '@/common-pages/luckyspin/luckyspin.service';
import {StyleSheet} from 'react-native';
import AgentLottie from './lottie/agent/agent';
import RebateLottie from './lottie/rebate/rebate';
import SpinLottie from './lottie/spin/spin';
import FreeLotteryLottie from './lottie/free-lottery/free-lottery';

const HomeGoldArea = ({
  noticeMap,
  onNotice,
}: {
  noticeMap: NoticeMap;
  onNotice?: () => void;
}) => {
  const {width} = useResponsiveDimensions();
  const styles = React.useMemo(
    () =>
      StyleSheet.create({
        vipNavsItem: {
          height: (69 * width) / 375,
          alignItems: 'center',
          position: 'relative',
        },
        vipNavsItemImg: {
          width: (52 * width) / 375,
          height: (52 * width) / 375,
        },
        vipNavsItemTag: {
          position: 'absolute',
          top: 5,
          right: 5,
        },
      }),
    [width],
  );
  const getNavTag = React.useCallback(
    (num?: number) =>
      num && num !== 0 ? (
        <Tag
          style={styles.vipNavsItemTag}
          content={num < 0 ? '!' : num > 0 ? num : ''}
        />
      ) : null,
    [styles.vipNavsItemTag],
  );

  const {i18n} = useTranslation();
  const doNotice = () => {
    onNotice?.();
  };
  const {renderModal: renderSpin, show: spinShow} = useLuckySpinModal({
    onNotice: doNotice,
  });
  const {renderModal: renderLottery, show: lotteryShow} = useLotteryModal(
    () => {
      globalStore.globalTotal.next({
        type: 'success',
        message: i18n.t('home.tip.copied'),
      });
    },
  );
  const login = useRef<boolean>(false);
  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      login.current = !!token;
      globalStore.asyncGetItem('luckyspinBackground').then(luckyspinBg => {
        if (luckyspinBg == null || luckyspinBg === '') {
          postSpinConfig(!!token).then(data => {
            Image.prefetch(data?.image);
            if (data?.image != null && data.image !== '') {
              globalStore.asyncSetItem('luckyspinBackground', data?.image);
            }
          });
        } else {
          Image.prefetch(luckyspinBg);
        }
      });
    });
    const sub2 = globalStore.doNotices.subscribe(() => {
      doNotice();
    });
    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLotteryClick = async () => {
    if (!login.current) {
      goTo('Login');
      return;
    }
    globalStore.globalLoading.next(true);
    const lotteryInfo = await postGetFreeLottery();
    Image.getSize(lotteryInfo.imgUrl, (imgWidth, imgHeight) => {
      lotteryShow({
        current: lotteryInfo.num,
        total: lotteryInfo.maxNum,
        backgroundUrl: lotteryInfo.imgUrl,
        shareUrl: lotteryInfo.shareUrl,
        toolTipContent: lotteryInfo.contentStr,
        imgWidth,
        imgHeight,
      });
      globalStore.globalLoading.next(false);
    });
  };

  return (
    <View
      style={[
        theme.flex.row,
        theme.margin.btml,
        theme.flex.between,
        theme.padding.lrl,
      ]}>
      <NativeTouchableOpacity
        style={[theme.margin.lefts]}
        activeOpacity={0.8}
        onPress={() => {
          if (!globalStore.token) {
            goTo('Login');
            return;
          }
          if (globalStore.userInfo?.isAgent === 1) {
            goTo('ProxyHome');
          } else {
            toAgentApply();
          }
        }}>
        <View style={[styles.vipNavsItem, theme.flex.col, theme.flex.center]}>
          <View style={[theme.flex.flex1]}>
            <AgentLottie style={styles.vipNavsItemImg} />
          </View>
          <Text
            fontFamily="fontInterBold"
            fontSize={11}
            second
            textAlign="center"
            calc
            fontWeight="500">
            {i18n.t('home.label.agent')}
          </Text>
        </View>
      </NativeTouchableOpacity>
      <NativeTouchableOpacity
        activeOpacity={0.8}
        onPress={() => {
          if (!login.current) {
            goTo('Login');
            return;
          }
          goTo('Rebate');
        }}>
        <View
          style={[
            styles.vipNavsItem,
            theme.flex.col,
            theme.flex.center,
            theme.position.rel,
          ]}>
          <View style={[theme.flex.flex1]}>
            <RebateLottie style={styles.vipNavsItemImg} />
          </View>
          <Text
            fontFamily="fontInterBold"
            fontSize={11}
            second
            textAlign="center"
            calc
            fontWeight="500">
            {i18n.t('home.label.rebate')}
          </Text>
          {getNavTag(noticeMap.REBATE)}
        </View>
      </NativeTouchableOpacity>
      <NativeTouchableOpacity activeOpacity={0.8} onPress={spinShow}>
        <View style={[styles.vipNavsItem, theme.flex.col, theme.flex.center]}>
          <View style={[theme.flex.flex1]}>
            <SpinLottie style={styles.vipNavsItemImg} />
          </View>
          <Text
            fontFamily="fontInterBold"
            fontSize={11}
            second
            textAlign="center"
            calc
            fontWeight="500">
            {i18n.t('home.label.lucky-spin')}
          </Text>
          {getNavTag(noticeMap.LUCKY_SPIN)}
        </View>
      </NativeTouchableOpacity>
      <NativeTouchableOpacity activeOpacity={0.8} onPress={handleLotteryClick}>
        <View style={[styles.vipNavsItem, theme.flex.col, theme.flex.center]}>
          <View style={[theme.flex.flex1]}>
            <FreeLotteryLottie style={styles.vipNavsItemImg} />
          </View>
          <Text
            second
            textAlign="center"
            fontWeight="500"
            fontSize={11}
            calc
            fontFamily="fontInterBold">
            {i18n.t('home.label.free-lottery')}
          </Text>
          {getNavTag(noticeMap.FREE_LOTTERY)}
        </View>
      </NativeTouchableOpacity>
      {renderSpin}
      {renderLottery}
    </View>
  );
};

export default HomeGoldArea;
