import React from 'react';
import {View} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import {COLOR_MAP} from './constant';

export interface CarItemProps {
  index?: number;
  cars: string;
  roundNum: string;
}

const CarItem = ({roundNum = '', cars = '', index = 0}: CarItemProps) => {
  const backgroundColor = React.useMemo(() => {
    return index % 2 === 0 ? '#fff' : '#F8F9FF';
  }, [index]);

  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.centerByCol,
        // eslint-disable-next-line react-native/no-inline-styles
        {height: 40, backgroundColor},
      ]}>
      <View style={[theme.flex.flex1, theme.padding.lrl]}>
        <Text size="medium">{roundNum}</Text>
      </View>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.padding.lrl,
          {gap: theme.paddingSize.xxs / 2},
        ]}>
        {cars.split(',').map((item, _i) => (
          <View
            key={_i}
            style={[
              theme.flex.center,
              theme.borderRadius.xs,
              {
                width: theme.iconSize.s,
                height: theme.iconSize.s,
                backgroundColor: COLOR_MAP[item],
              },
            ]}>
            <Text fontFamily="fontDinBold" color={theme.basicColor.white}>
              {item}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export default CarItem;
