import React, {ReactNode, useMemo} from 'react';
import {FC} from 'react';
import {useScreenSize} from '../hooks/size.hooks';
import Text from '@/components/basic/text';
import './recharge-tip.css';
interface RechargeTipProps {
  payMethodBonus?: number;
  payMethodScale?: number;
  payCurrentScale?: number;
  children?: ReactNode;
  style?: React.CSSProperties;
  animateType?: '90' | '95';
}

const RechargeTip: FC<RechargeTipProps> = ({
  payCurrentScale: payCurrentScale,
  payMethodBonus,
  payMethodScale: payMethodScale,
  children,
  style = {},
  animateType = '90',
}) => {
  const {calcActualSize} = useScreenSize();
  const payStr = useMemo(() => {
    const payMethodStr =
      payMethodScale || payMethodBonus
        ? payMethodScale
          ? `${payMethodScale * 100}%`
          : payMethodBonus
        : '';
    const payCurrentStr = payCurrentScale ? `${payCurrentScale * 100}%` : '';
    if (payMethodStr && payCurrentStr) {
      return `${payMethodStr}+${payCurrentStr}`;
    }
    if (payMethodStr) {
      return `+${payMethodStr}`;
    }
    if (payCurrentStr) {
      return `+${payCurrentStr}`;
    }
    return '';
  }, [payCurrentScale, payMethodBonus, payMethodScale]);
  return payStr || children ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        right: 0,
        top: -calcActualSize(8),
        background: '#DC0000',
        padding: `${calcActualSize(2)}px ${calcActualSize(6)}px`,
        borderRadius: `${calcActualSize(8)}px ${calcActualSize(
          12,
        )}px ${calcActualSize(12)}px 0`,
        zIndex: 1,
        ...style,
      }}
      className={`recharge-tip-${animateType}`}>
      <Text calc color={'#FFF29C'} fontSize={10} fontWeight="700">
        {children ? children : payStr}
      </Text>
      <div
        style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          width: 0,
          height: 0,
          borderTop: `${calcActualSize(4)}px solid #DC0000`,
          borderRight: `${calcActualSize(4)}px solid transparent`,
        }}
      />
    </div>
  ) : (
    <></>
  );
};

export default RechargeTip;
