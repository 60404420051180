import lottie, {AnimationItem} from 'lottie-web';
import React from 'react';
import {Modal, View, StyleSheet} from 'react-native';
import theme from '@/style';
import Price from '../price';
import animationjson from '@components/assets/lottie/gold-coin.json';
import {useResponsiveDimensions} from '@/utils';

const GoldWin = ({
  visible,
  onClose,
  amount = 0,
}: {
  visible?: boolean;
  amount?: number;
  onClose?: () => void;
}) => {
  const viewRef = React.useRef(null);
  const animationRef = React.useRef<AnimationItem>();
  const {width} = useResponsiveDimensions();
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (visible) {
      animationRef.current = lottie.loadAnimation({
        container: viewRef.current!,
        renderer: 'svg',
        loop: false,
        animationData: animationjson,
      });
      animationRef.current.addEventListener('complete', () => {
        onClose && onClose();
        setShow(false);
      });
      setTimeout(() => {
        setShow(true);
      }, 400);
      setTimeout(() => {
        setShow(false);
      }, 2950);
      return () => {
        animationRef.current?.destroy();
      };
    } else {
      animationRef.current?.destroy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return (
    <Modal visible={visible} animationType="fade" transparent>
      <View style={styles.container}>
        <View ref={viewRef} style={{width, height: width}} />
        <View
          style={[
            styles.amountContainer,
            {
              width,
              height: width,
            },
          ]}>
          <View
            style={[
              styles.amountInner,
              theme.flex.center,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                opacity: show ? 1 : 0,
              },
            ]}>
            <Price
              price={amount}
              textProps={{
                fontSize: 24,
                color: theme.basicColor.white,
              }}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  amount: {
    fontSize: 20,
  },
  amountContainer: {
    position: 'absolute',
  },
  amountInner: {
    position: 'absolute',
    bottom: '29%',
    right: 0,
    left: 0,
  },
});

export default GoldWin;
