import globalStore from '@/services/global.state';
import {getRacecarChip, getRacecarLastOrder} from '../result-service';
import {RaceBuyRecords} from '../result.type';
import React from 'react';
import ResultPanel from '../components/result-panel/result-panel';
import {View} from 'react-native';
import dayjs from 'dayjs';
import RacecarResult from './result';
import {useTranslation} from 'react-i18next';
import Text from '@/components/basic/text';
import theme from '@/style';
import CarNumber from './car-number';
import {goTo} from '@/utils';

const RacecarDraw = ({
  cycleTabKey,
  drawLabel,
}: {
  cycleTabKey?: string;
  drawLabel?: string;
}) => {
  const [racecarOrder, setRacecarOrder] = React.useState<RaceBuyRecords>();
  const {t} = useTranslation();
  const getLastOrder = async (tabKey = '') => {
    try {
      const res = await getRacecarChip();
      if (res) {
        const order = await getRacecarLastOrder(tabKey, res.player);
        if (order.records) {
          setRacecarOrder(order.records[0] || {});
        }
      }
    } finally {
    }
  };

  React.useEffect(() => {
    if (cycleTabKey && globalStore.token) {
      getLastOrder(cycleTabKey);
    }
  }, [cycleTabKey]);

  return racecarOrder?.roundNum ? (
    <>
      <ResultPanel
        onMore={() => {
          goTo('Bets', {tag: 'Quick Race'});
        }}
        betInfo={
          <>
            <Text size="medium" fontFamily="fontInterBold" main>
              {drawLabel} {'\n'}
            </Text>
            <Text size="small" fontFamily="fontInterBold" main>
              {racecarOrder.roundNum}
            </Text>
          </>
        }
        winAmount={racecarOrder?.profit}
        betTime={
          racecarOrder?.timestamp
            ? dayjs(racecarOrder.timestamp * 1000).format('DD-MM-YYYY hh:mm A')
            : '-'
        }>
        <View style={[theme.padding.lrl, theme.padding.tops]}>
          <Text textAlign="center">{t('game-page.label.myBets')}</Text>
          <View
            style={[
              theme.flex.row,
              theme.flex.center,
              theme.padding.topl,
              theme.padding.btmxxs,
              {gap: theme.paddingSize.l},
            ]}>
            <CarNumber num={racecarOrder.item} type="square" />
          </View>
        </View>
      </ResultPanel>
      <RacecarResult
        roundNum={racecarOrder?.roundNum}
        num={+racecarOrder?.result[0]}
      />
    </>
  ) : (
    <View />
  );
};

export default RacecarDraw;
