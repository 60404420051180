import React from 'react';
import {useTranslation} from 'react-i18next';
import ResultPanel from '../components/result-panel/result-panel';
import Text from '@/components/basic/text';
import theme from '@/style';
import {View} from 'react-native';
import globalStore from '@/services/global.state';
import dayjs from 'dayjs';
import {sumMap, sumSuffixMap} from '@/common-pages/dice/constant';
import DiceItem from '@/common-pages/dice/component/dice-item';
import Ball from '@/common-pages/dice/component/ball';
import {formatDate, goTo} from '@/utils';
import DrawResult from './result';
import {getDice} from '@/common-pages/bets/bets.service';
import {BasicObject} from '@/types';
const DiceDraw = ({configId}: {configId: number}) => {
  const {t} = useTranslation();
  const [order, setOrder] = React.useState<BasicObject>({});

  const getResult = async () => {
    try {
      const res = await getDice({
        pageNo: 1,
        pageSize: 1,
        orderStatus: '4',
        yearMonth: formatDate(new Date(), 'yyyyMM'),
      });
      if (res.content) {
        setOrder(res.content[0]);
      }
    } finally {
    }
  };

  React.useEffect(() => {
    if (configId && globalStore.token) {
      getResult();
    }
  }, [configId]);

  const bets = React.useMemo(() => {
    if (!order.selectType) {
      return null;
    }
    if (sumMap[order.selectType]) {
      return <DiceItem diceSize={40} diceNum={sumMap[order.selectType]} />;
    }
    const [diceType, diceNum] = order.selectType.split('_');
    if (diceType === 'sum') {
      return (
        <Ball
          ballSize={40}
          num={sumSuffixMap[diceNum] || 0}
          fontSize={22}
          isNum
          // type={item?.number?.toLowerCase()}
        />
      );
    }
    if (diceType === 'single') {
      return <DiceItem diceSize={40} diceNum={sumSuffixMap[diceNum] || 0} />;
    }
    if (diceType === 'double') {
      return (
        <>
          <DiceItem diceSize={40} diceNum={sumSuffixMap[diceNum] || 0} />
          <DiceItem diceSize={40} diceNum={sumSuffixMap[diceNum] || 0} />
        </>
      );
    }
    if (diceType === 'leopard') {
      return (
        <>
          <DiceItem diceSize={40} diceNum={sumSuffixMap[diceNum] || 0} />
          <DiceItem diceSize={40} diceNum={sumSuffixMap[diceNum] || 0} />
          <DiceItem diceSize={40} diceNum={sumSuffixMap[diceNum] || 0} />
        </>
      );
    }
  }, [order.selectType]);

  // const renderBets = (item: BasicObject) => {
  //   if (!item.type) {
  //     return null;
  //   }
  //   if (sumMap[item.type]) {
  //     return <DiceItem diceSize={40} diceNum={sumMap[order.type]} />;
  //   }
  //   const [diceType, diceNum] = item.type.split('_');
  //   if (diceType === 'sum') {
  //     return (
  //       <Ball
  //         ballSize={40}
  //         num={sumSuffixMap[diceNum] || 0}
  //         fontSize={22}
  //         isNum
  //         // type={item?.number?.toLowerCase()}
  //       />
  //     );
  //   }
  //   if (diceType === 'single') {
  //     return <DiceItem diceSize={40} diceNum={sumSuffixMap[diceNum] || 0} />;
  //   }
  //   if (diceType === 'double') {
  //     return (
  //       <>
  //         <DiceItem diceSize={40} diceNum={sumSuffixMap[diceNum] || 0} />
  //         <DiceItem diceSize={40} diceNum={sumSuffixMap[diceNum] || 0} />
  //       </>
  //     );
  //   }
  //   if (diceType === 'leopard') {
  //     return (
  //       <>
  //         <DiceItem diceSize={40} diceNum={sumSuffixMap[diceNum] || 0} />
  //         <DiceItem diceSize={40} diceNum={sumSuffixMap[diceNum] || 0} />
  //         <DiceItem diceSize={40} diceNum={sumSuffixMap[diceNum] || 0} />
  //       </>
  //     );
  //   }
  // };

  const renderItem = (info: BasicObject, key?: number) => {
    if (info.playType === 'Sum' && isNaN(Number(info.number))) {
      return (
        <Ball
          isNum={false}
          type={info.number.toLowerCase()}
          ballSize={40}
          key={key}
        />
      );
    }
    if (info.playType === 'Sum' && !isNaN(Number(info.number))) {
      return <Ball num={+info.number} ballSize={40} fontSize={22} key={key} />;
    }
    if (info.playType === 'Single') {
      return <DiceItem diceSize={40} diceNum={info.number} key={key} />;
    }
    if (info.playType === 'Pair') {
      return (
        <View style={[theme.flex.row]} key={key}>
          {info.number.split('').map((pie: string, _ii: number) => (
            <DiceItem diceSize={40} diceNum={pie} key={_ii} />
          ))}
        </View>
      );
    }
    if (info.playType === 'Triple') {
      return (
        <View style={[theme.flex.row]} key={key}>
          {info.number.split('').map((pie: string, _ii: number) => (
            <DiceItem diceSize={40} diceNum={pie} key={_ii} />
          ))}
        </View>
      );
    }
    return null;
  };

  return order.issNo ? (
    <>
      <ResultPanel
        onMore={() => goTo('Bets', {tag: 'Dice'})}
        betInfo={
          <>
            <Text size="medium" fontFamily="fontInterBold" main>
              {order.cycle} Minutes {'\n'}
            </Text>
            <Text size="small" fontFamily="fontInterBold" main>
              {order.issNo}
            </Text>
          </>
        }
        winAmount={order.totalAwardAmount}
        betTime={dayjs(order.createTime).format('DD-MM-YYYY hh:mm A')}>
        <View style={[theme.padding.lrl, theme.padding.tops]}>
          <Text textAlign="center">{t('game-page.label.myBets')}</Text>
          <View
            style={[
              theme.padding.tbl,
              theme.flex.center,
              theme.flex.row,
              theme.flex.wrap,
              {gap: theme.paddingSize.s},
            ]}>
            {order.typeList.map((item: BasicObject, _i: number) =>
              renderItem(item, _i),
            )}
            {bets}
          </View>
        </View>
      </ResultPanel>
      <DrawResult />
    </>
  ) : (
    <View />
  );
};

export default DiceDraw;
