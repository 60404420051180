import React, {useEffect, useState} from 'react';
import RenderHtml from 'react-native-render-html';
import {useInnerStyle} from './promotion.hooks';
import {FadeInView} from '@basicComponents/animations';
import DetailNavTitle from '@businessComponents/detail-nav-title';
import {goBack} from '@utils';
import {PromotionListItem} from './promotion.service';
import {ScrollView, View} from 'react-native';
import theme from '@/style';
import Text from '@basicComponents/text';
import Button from '@basicComponents/button';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {goToUrl} from '@/common-pages/game-navigate';

const PromotionDetail = () => {
  const {i18n} = useTranslation();
  const [item, setItem] = useState<PromotionListItem>();
  const {
    size: {screenHeight, screenWidth, designWidth},
    itemStyle,
    listStyle,
    detailStyle,
  } = useInnerStyle();

  useEffect(() => {
    globalStore.asyncGetItem('promotion-detail').then(_item => {
      if (_item) {
        setItem(JSON.parse(_item) as PromotionListItem);
      }
    });
  }, []);
  const source = {
    html: item?.activityContent || '',
  };
  const htmlStyle = {
    fontSize: theme.fontSize.m,
    lineHeight: theme.fontSize.m * 1.4,
    fontFamily: 'Inter',
    color: theme.fontColor.accent,
  };
  const tagsStyles = {
    p: {
      marginTop: 0,
    },
    img: {
      width: screenWidth - (theme.paddingSize.xxl + theme.paddingSize.l) * 2,
    },
  };

  return (
    <FadeInView style={[{height: screenHeight}, theme.background.lightGrey]}>
      <DetailNavTitle
        title={i18n.t('promotion.title')}
        hideAmount
        hideServer
        onBack={goBack}
      />
      <ScrollView
        style={[
          theme.flex.flex1,
          theme.background.white,
          theme.margin.lrl,
          theme.margin.tbxxl,
          theme.padding.xxl,
          theme.borderRadius.m,
        ]}
        contentContainerStyle={[listStyle.list]}>
        <View style={[theme.flex.center]}>
          <Text
            fontSize={theme.fontSize.xl}
            main
            blod
            style={[theme.margin.btmxxs, detailStyle.title]}>
            {item?.activityTitle}
          </Text>
        </View>
        <RenderHtml
          source={source}
          baseStyle={htmlStyle}
          tagsStyles={tagsStyles}
        />
        {item?.activityUrl && (
          <View style={[theme.fill.fillW, theme.flex.center]}>
            <Button
              titleBold
              buttonStyle={itemStyle.button}
              width={(screenWidth * 96) / designWidth}
              onPress={() => {
                goToUrl(item.activityUrl, item.activityTitle);
              }}>
              <Text
                blod
                fontSize={theme.fontSize.s}
                color={theme.basicColor.white}>
                {i18n.t('promotion.join')}
              </Text>
            </Button>
          </View>
        )}
      </ScrollView>
    </FadeInView>
  );
};

export default PromotionDetail;
