import DetailWebView, {GameWebView} from './common-pages/webview';
import Login, {SetPassword, SingUp} from './common-pages/login';
import {BasicObject, NavigatorScreenProps} from './types';
import Result from './common-pages/result';
import PaidSuccess from './common-pages/paid-success';
import Recharge from './common-pages/recharge';
import Withdraw from './common-pages/withdraw';
import Vip from './common-pages/vip/vip';
import Rebate from './common-pages/rebate';
import Bets from './common-pages/bets';
import BetsDetail from './common-pages/bets-detail';
import AddBank from './common-pages/add-bank';
import Transactions from './common-pages/transaction';
import Transfer from './common-pages/transfer';
import NotificationPage from './common-pages/notification';
import NotificationDetail from './common-pages/notification/notification-detail';
import Profile from './common-pages/profile';
import {
  RechargeRecords,
  WithdrawRecords,
  TransferRecords,
} from './common-pages/records';
import {
  ProxyRules,
  ProxyCommissionDetail,
  ProxyCommissionDetailUser,
  ProxyTeamReport,
  ProxyTeamReportSearch,
  ProxyNewUser,
} from './common-pages/proxy';
import Invitation, {
  InvitationApply,
  InvitationRecord,
  InvitationRule,
} from './common-pages/invitation';
import ColorGame from './common-pages/color';
import DiceGame from './common-pages/dice/dice';
import MixLottery from './common-pages/mix-lottery/mix-lottery';
import MixLotteryDetail from './common-pages/result-detail/sate-lottery/mix-lottery-detail';
import BetBy from './pages/betby/index';

type Route = {
  name: string;
  component: (props: NavigatorScreenProps & BasicObject) => React.JSX.Element;
  link: string;
  headerShown?: boolean;
};

const commonRoutes: Route[] = [
  {
    name: 'SingUp',
    component: SingUp,
    link: 'sing-up',
  },
  {
    name: 'Login',
    component: Login,
    link: 'login',
  },
  {
    name: 'SetPassword',
    component: SetPassword,
    link: 'set-password',
  },
  {
    name: 'Result',
    component: Result,
    link: 'result',
  },
  {
    name: 'Recharge',
    component: Recharge,
    link: 'recharge',
  },
  {
    name: 'Withdraw',
    component: Withdraw,
    link: 'withdraw',
  },
  {
    name: 'AddBank',
    component: AddBank,
    link: 'add-bank',
  },
  {
    name: 'Vip',
    component: Vip,
    link: 'vip',
  },
  {
    name: 'PaidSuccess',
    component: PaidSuccess,
    link: 'paid-success',
  },
  {
    name: 'Rebate',
    component: Rebate,
    link: 'rebate',
  },
  {
    name: 'Bets',
    component: Bets,
    link: 'bets',
  },
  {
    name: 'BetsDetail',
    component: BetsDetail,
    link: 'bets-detail',
  },
  {
    name: 'Transactions',
    component: Transactions,
    link: 'transactions',
  },
  {
    name: 'Transfer',
    component: Transfer,
    link: 'transfer',
  },
  {
    name: 'RechargeRecords',
    component: RechargeRecords,
    link: 'recharge-records',
  },
  {
    name: 'WithdrawRecords',
    component: WithdrawRecords,
    link: 'withdraw-records',
  },
  {
    name: 'TransferRecords',
    component: TransferRecords,
    link: 'transfer-records',
  },
  {
    name: 'GameWebView',
    component: GameWebView,
    link: 'game-webview',
  },
  {
    name: 'WebView',
    component: DetailWebView,
    link: 'webview',
  },
  {
    name: 'Notification',
    component: NotificationPage,
    link: 'notification',
  },
  {
    name: 'NotificationDetail',
    component: NotificationDetail,
    link: 'notification-detail',
  },
  {
    name: 'ProxyRules',
    component: ProxyRules,
    link: 'proxy-rules',
  },
  {
    name: 'ProxyCommissionDetail',
    component: ProxyCommissionDetail,
    link: 'proxy-commission-detail',
  },
  {
    name: 'ProxyCommissionDetailUser',
    component: ProxyCommissionDetailUser,
    link: 'proxy-commission-detail-user',
  },
  {
    name: 'ProxyTeamReport',
    component: ProxyTeamReport,
    link: 'proxy-team-report',
  },
  {
    name: 'ProxyTeamReportSearch',
    component: ProxyTeamReportSearch,
    link: 'proxy-team-report-search',
  },
  {
    name: 'ProxyNewUser',
    component: ProxyNewUser,
    link: 'proxy-new-user',
  },
  {
    name: 'Invitation',
    component: Invitation,
    link: 'invitation',
  },
  {
    name: 'InvitationApply',
    component: InvitationApply,
    link: 'invitation-apply',
  },
  {
    name: 'InvitationRecord',
    component: InvitationRecord,
    link: 'invitation-record',
  },
  {
    name: 'InvitationRule',
    component: InvitationRule,
    link: 'invitation-rule',
  },
  {name: 'Dice', component: DiceGame, link: 'dice'},
  {
    name: 'Color',
    component: ColorGame,
    link: 'color',
  },
  {
    name: 'Profile',
    component: Profile,
    link: 'profile',
  },
  {name: 'MixLottery', component: MixLottery, link: 'mix-lottery'},
  {
    name: 'MixLotteryDetail',
    component: MixLotteryDetail,
    link: 'mix-lottery-detail',
  },
];

const customerRoutes: Route[] = [
  // 这里放各个盘需要的额外路由配置
  // 为了不破坏顶部的引用结构,请使用require的方式引入
  {
    name: 'CasinoDetail',
    component: require('./common-pages/casino').default,
    link: 'casino-detail',
  },
  {
    name: 'Referral',
    component: require('./pages/referral').default,
    link: 'referral',
  },
  {
    name: 'Wallet',
    component: require('./common-pages/wallet/wallet-page').default,
    link: 'wallet',
  },
  {
    name: 'ProxyHome',
    component: require('./common-pages/proxy').default,
    link: 'proxy-home',
  },
  {
    name: 'Scratch',
    component: require('./pages/scratch').default,
    link: 'scratch',
  },
  {
    name: 'PromotionDetail',
    component: require('./common-pages/promotion/promotion-detail').default,
    link: 'promotion-detail',
  },
  {
    name: 'Sports',
    component: require('./common-pages/sports').default,
    link: 'sports',
  },
  {
    name: 'GameSatta',
    component: require('./common-pages/satta/game-satta').default,
    link: 'game-satta',
  },
  {
    name: 'Digit',
    component: require('./common-pages/pick-digit/pick-digit').default,
    link: 'digit',
  },
  {
    name: 'KeralaResultDetail',
    component: require('./common-pages/result-detail/kerala/kerala').default,
    link: 'kerala-result-detail',
  },
  {
    name: 'ScratchResultDetail',
    component: require('./common-pages/result-detail/scratch/scratch').default,
    link: 'scratch-result-detail',
  },
  {
    name: 'BetBy',
    component: BetBy,
    link: 'BetBy',
  },
];

const routes: Route[] = [...commonRoutes, ...customerRoutes];

export default routes;
