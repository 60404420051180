import {ImageBackground} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import {DICES} from '../constant';
import theme from '@/style';

export interface BallProps {
  ballSize?: number;
  isNum?: boolean;
  num?: number;
  fontSize?: number;
  type?: string;
  disabled?: boolean;
}

const Ball = ({
  ballSize,
  isNum = true,
  num = 3,
  fontSize = 28,
  type = '',
  disabled = false,
}: BallProps) => {
  const textColor = React.useMemo(() => {
    return disabled ? '#666' : num % 2 === 0 ? '#00701C' : '#CE0006';
  }, [num, disabled]);

  const bg = React.useMemo(() => {
    if (isNum) {
      return num % 2 === 0
        ? DICES[disabled ? 'greenBall-disabled' : 'greenBall']
        : DICES[disabled ? 'redBall-disabled' : 'redBall'];
    } else {
      return DICES[disabled ? `${type}-disabled` : type];
    }
  }, [isNum, num, type, disabled]);

  return (
    <ImageBackground
      source={bg}
      style={[
        theme.flex.center,
        {
          width: ballSize,
          height: ballSize,
        },
      ]}>
      {isNum && (
        <Text color={textColor} fontSize={fontSize} fontFamily="fontDinBold">
          {num}
        </Text>
      )}
    </ImageBackground>
  );
};

export default React.memo(Ball);
