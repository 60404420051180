import {FlatList} from 'react-native';
import CartItem from './cart-item';
import React from 'react';
import theme from '@/style';
import {CartListItem} from '../mix-lottery-service';

const LotteryCart = ({
  carList = [],
  onDelItem,
  changeAmount,
}: {
  carList?: CartListItem[];
  onDelItem?: (i: number) => void;
  changeAmount?: (amount: string, i: number) => void;
}) => {
  return (
    <FlatList
      contentContainerStyle={[theme.padding.lrl, theme.padding.btml]}
      data={carList}
      renderItem={({item, index}) => (
        <CartItem
          {...item}
          onDel={() => onDelItem?.(index)}
          changeAmount={v => changeAmount?.(v, index)}
        />
      )}
      keyExtractor={(item, _ii) => `${_ii}`}
    />
  );
};

export default LotteryCart;
