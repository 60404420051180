import Video from './video';

type Source =
  | {
      uri?: string | undefined;
      headers?: {[key: string]: string} | undefined;
      type?: string | undefined;
    }
  | number;

type VideoProps = {
  onLoad?: () => void;
  onEnd?: () => void;
  audioOnly?: boolean;
  paused?: boolean;
  source: Source;
  src: string;
};

export default Video;

export type {VideoProps};
