import {SVGProps} from '@/types';
import React from 'react';

const TipIcon = ({color = '#9FA5AC', width = 20, height = 20}: SVGProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_19402_71082)">
        <path
          d="M9.99998 0.473877C8.74897 0.474049 7.51024 0.720625 6.35452 1.19953C5.1988 1.67843 4.14872 2.38028 3.26424 3.265C1.47796 5.05177 0.474628 7.47497 0.474976 10.0015C0.475323 12.528 1.47932 14.951 3.2661 16.7372C5.05287 18.5235 7.47607 19.5269 10.0026 19.5265C12.5291 19.5262 14.9521 18.5222 16.7383 16.7354C18.5246 14.9486 19.528 12.5254 19.5276 9.99888C19.5273 7.47235 18.5233 5.04943 16.7365 3.26314C14.9497 1.47686 12.5265 0.473529 9.99998 0.473877ZM9.11182 5.03571C9.21701 4.90088 9.35215 4.7924 9.50654 4.71886C9.66092 4.64532 9.83032 4.60875 10.0013 4.61203C10.3631 4.61203 10.6579 4.7515 10.8908 5.02913C11.1224 5.30809 11.2381 5.65547 11.2381 6.07518C11.2381 6.43574 10.7552 9.08703 10.5934 11.0147H9.43026C9.28945 9.08703 8.76445 6.43571 8.76445 6.07518C8.75242 5.69833 8.87563 5.32961 9.11182 5.03571ZM10.8763 14.987C10.7685 15.1134 10.6345 15.2148 10.4837 15.2844C10.3329 15.3539 10.1687 15.39 10.0026 15.39C9.83653 15.39 9.6724 15.3539 9.52156 15.2844C9.37073 15.2148 9.23678 15.1134 9.12894 14.987C8.88587 14.724 8.75468 14.3767 8.76314 14.0186C8.76314 13.641 8.88418 13.3147 9.12894 13.041C9.23546 12.9126 9.36897 12.8092 9.51998 12.7383C9.67098 12.6673 9.83576 12.6305 10.0026 12.6305C10.3395 12.6305 10.6316 12.7673 10.8763 13.041C11.121 13.3147 11.2421 13.641 11.2421 14.0186C11.2493 14.3765 11.1183 14.7233 10.8763 14.987Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_19402_71082">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TipIcon;
