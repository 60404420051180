import {FlatList} from 'react-native';
import ListHeader from './list-header';
import React from 'react';
import ListItem from './list-item';
import theme from '@/style';
import {SattaOrderCreateDataContent} from '../satta-service';
export interface SattaCartListProps {
  cartList?: SattaOrderCreateDataContent[];
  onItemClear?: (item: SattaOrderCreateDataContent) => void;
}

const SattaCartList = ({cartList = [], onItemClear}: SattaCartListProps) => {
  return (
    <>
      <FlatList
        style={[theme.margin.topl]}
        contentContainerStyle={[theme.padding.lrl]}
        ListHeaderComponent={<ListHeader />}
        data={cartList}
        keyExtractor={(item, _i) => `${_i}`}
        renderItem={({item, index}) => (
          <ListItem
            index={index}
            info={item}
            onItemClear={() => {
              onItemClear?.(item);
            }}
          />
        )}
      />
    </>
  );
};

export default SattaCartList;
