export default {
  v: '5.6.3',
  fr: 25,
  ip: 0,
  op: 40,
  w: 156,
  h: 156,
  nm: '合成 1',
  ddd: 0,
  assets: [
    {
      id: 'image_0',
      w: 78,
      h: 30,
      u: '/assets/lottie/home/agent/',
      p: '____.png',
      e: 0,
    },
    {
      id: 'image_1',
      w: 75,
      h: 30,
      u: '/assets/lottie/home/agent/',
      p: '_____0.png',
      e: 0,
    },
    {
      id: 'image_2',
      w: 21,
      h: 21,
      u: '/assets/lottie/home/agent/',
      p: '__2.png',
      e: 0,
    },
    {
      id: 'image_3',
      w: 42,
      h: 42,
      u: '/assets/lottie/home/agent/',
      p: '__1.png',
      e: 0,
    },
    {
      id: 'image_4',
      w: 108,
      h: 109,
      u: '/assets/lottie/home/agent/',
      p: '__.png',
      e: 0,
    },
    {
      id: 'image_5',
      w: 156,
      h: 156,
      u: '/assets/lottie/home/agent/',
      p: '___1.png',
      e: 0,
    },
    {
      id: 'image_6',
      w: 156,
      h: 156,
      u: '/assets/lottie/home/agent/',
      p: '___2.png',
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: '消息橘色.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              t: 5,
              s: [36.75, 86, 0],
              to: [0.667, -1.208, 0],
              ti: [-0.667, 1.208, 0],
            },
            {t: 11, s: [40.75, 78.75, 0]},
          ],
          ix: 2,
          x: "var $bm_rt;\nvar enable, amp, freq, decay, n, t, v;\ntry {\n    $bm_rt = enable = effect('Excite - \\u4f4d\\u7f6e')('Pseudo/BNCA2506f0b33-0001');\n    if (enable == 0) {\n        $bm_rt = value;\n    } else {\n        amp = $bm_div(effect('Excite - \\u4f4d\\u7f6e')('Pseudo/BNCA2506f0b33-0003'), 2.5);\n        freq = $bm_div(effect('Excite - \\u4f4d\\u7f6e')('Pseudo/BNCA2506f0b33-0004'), 20);\n        decay = $bm_div(effect('Excite - \\u4f4d\\u7f6e')('Pseudo/BNCA2506f0b33-0005'), 20);\n        n = 0, 0 < numKeys && (n = nearestKey(time).index, key(n).time > time && n--), t = 0 === n ? 0 : $bm_sub(time, key(n).time), $bm_rt = 0 < n ? (v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10))), $bm_sum(value, $bm_div($bm_mul($bm_mul($bm_div(v, 100), amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))))) : value;\n    }\n} catch (err) {\n    $bm_rt = value = value;\n}",
        },
        a: {a: 0, k: [39, 15, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: 'Excite - 位置',
          np: 8,
          mn: 'Pseudo/BNCA2506f0b33',
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: '启用',
              mn: 'Pseudo/BNCA2506f0b33-0001',
              ix: 1,
              v: {a: 0, k: 1, ix: 1},
            },
            {ty: 6, nm: '属性', mn: 'Pseudo/BNCA2506f0b33-0002', ix: 2, v: 0},
            {
              ty: 0,
              nm: '回弹幅度',
              mn: 'Pseudo/BNCA2506f0b33-0003',
              ix: 3,
              v: {
                a: 0,
                k: 20,
                ix: 3,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {
              ty: 0,
              nm: '强度',
              mn: 'Pseudo/BNCA2506f0b33-0004',
              ix: 4,
              v: {
                a: 0,
                k: 40,
                ix: 4,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {
              ty: 0,
              nm: '摩擦力',
              mn: 'Pseudo/BNCA2506f0b33-0005',
              ix: 5,
              v: {
                a: 0,
                k: 40,
                ix: 5,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {ty: 6, nm: '', mn: 'Pseudo/BNCA2506f0b33-0006', ix: 6, v: 0},
          ],
        },
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: '消息紫色.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              t: 0,
              s: [103.75, 62.5, 0],
              to: [0.792, -1.167, 0],
              ti: [-0.792, 1.167, 0],
            },
            {t: 6, s: [108.5, 55.5, 0]},
          ],
          ix: 2,
          x: "var $bm_rt;\nvar enable, amp, freq, decay, n, t, v;\ntry {\n    $bm_rt = enable = effect('Excite - \\u4f4d\\u7f6e')('Pseudo/BNCA2506f0b33-0001');\n    if (enable == 0) {\n        $bm_rt = value;\n    } else {\n        amp = $bm_div(effect('Excite - \\u4f4d\\u7f6e')('Pseudo/BNCA2506f0b33-0003'), 2.5);\n        freq = $bm_div(effect('Excite - \\u4f4d\\u7f6e')('Pseudo/BNCA2506f0b33-0004'), 20);\n        decay = $bm_div(effect('Excite - \\u4f4d\\u7f6e')('Pseudo/BNCA2506f0b33-0005'), 20);\n        n = 0, 0 < numKeys && (n = nearestKey(time).index, key(n).time > time && n--), t = 0 === n ? 0 : $bm_sub(time, key(n).time), $bm_rt = 0 < n ? (v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10))), $bm_sum(value, $bm_div($bm_mul($bm_mul($bm_div(v, 100), amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))))) : value;\n    }\n} catch (err) {\n    $bm_rt = value = value;\n}",
        },
        a: {a: 0, k: [37.5, 15, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: 'Excite - 位置',
          np: 8,
          mn: 'Pseudo/BNCA2506f0b33',
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: '启用',
              mn: 'Pseudo/BNCA2506f0b33-0001',
              ix: 1,
              v: {a: 0, k: 1, ix: 1},
            },
            {ty: 6, nm: '属性', mn: 'Pseudo/BNCA2506f0b33-0002', ix: 2, v: 0},
            {
              ty: 0,
              nm: '回弹幅度',
              mn: 'Pseudo/BNCA2506f0b33-0003',
              ix: 3,
              v: {
                a: 0,
                k: 20,
                ix: 3,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {
              ty: 0,
              nm: '强度',
              mn: 'Pseudo/BNCA2506f0b33-0004',
              ix: 4,
              v: {
                a: 0,
                k: 40,
                ix: 4,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {
              ty: 0,
              nm: '摩擦力',
              mn: 'Pseudo/BNCA2506f0b33-0005',
              ix: 5,
              v: {
                a: 0,
                k: 40,
                ix: 5,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {ty: 6, nm: '', mn: 'Pseudo/BNCA2506f0b33-0006', ix: 6, v: 0},
          ],
        },
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: '表情2.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.667, y: 1},
              o: {x: 0.333, y: 0},
              t: 2,
              s: [121.5, 102.375, 0],
              to: [0.083, 2.229, 0],
              ti: [-0.083, -1.255, 0],
            },
            {
              i: {x: 0.667, y: 0.412},
              o: {x: 0.333, y: 0},
              t: 8.478,
              s: [122, 115.75, 0],
              to: [0.083, 1.255, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.667, y: 1},
              o: {x: 0.333, y: 0.467},
              t: 14.5,
              s: [122, 109.902, 0],
              to: [0, 0, 0],
              ti: [0, -1.344, 0],
            },
            {
              i: {x: 0.667, y: 1},
              o: {x: 0.333, y: 0},
              t: 19.046,
              s: [122, 115.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.667, y: 1},
              o: {x: 0.333, y: 0},
              t: 23.591,
              s: [122, 112.625, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {t: 27, s: [122, 115.75, 0]},
          ],
          ix: 2,
        },
        a: {a: 0, k: [10.5, 10.5, 0], ix: 1},
        s: {
          a: 1,
          k: [
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 2.535,
              s: [100, 100, 100],
            },
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 6.205,
              s: [100, 78.846, 100],
            },
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 9.012,
              s: [100, 100, 100],
            },
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 14.5,
              s: [100, 100, 100],
            },
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 16.998,
              s: [100, 85.577, 100],
            },
            {t: 19.0458984375, s: [100, 100, 100]},
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: '表情1.png',
      cl: 'png',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.667, y: 1},
              o: {x: 0.333, y: 0},
              t: 0,
              s: [49.5, 47.5, 0],
              to: [0.083, 2.792, 0],
              ti: [-0.083, -1.167, 0],
            },
            {
              i: {x: 0.667, y: 1},
              o: {x: 0.333, y: 0},
              t: 6.478,
              s: [50, 64.25, 0],
              to: [0.083, 1.167, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.667, y: 1},
              o: {x: 0.333, y: 0},
              t: 12.955,
              s: [50, 54.5, 0],
              to: [0, 0, 0],
              ti: [0, -1.625, 0],
            },
            {
              i: {x: 0.667, y: 1},
              o: {x: 0.333, y: 0},
              t: 17.271,
              s: [50, 64.25, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.667, y: 1},
              o: {x: 0.333, y: 0},
              t: 21.591,
              s: [50, 57.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {t: 25, s: [50, 64.25, 0]},
          ],
          ix: 2,
        },
        a: {a: 0, k: [21, 42, 0], ix: 1},
        s: {
          a: 1,
          k: [
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 2.808,
              s: [100, 100, 100],
            },
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 6.478,
              s: [100, 78.846, 100],
            },
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 9.284,
              s: [100, 100, 100],
            },
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 15.113,
              s: [100, 100, 100],
            },
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 17.271,
              s: [100, 85.577, 100],
            },
            {t: 19.474609375, s: [100, 100, 100]},
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: '手机.png',
      cl: 'png',
      refId: 'image_4',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [78, 78, 0], ix: 2},
        a: {a: 0, k: [54, 54.5, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: '投影.png',
      cl: 'png',
      refId: 'image_5',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [78, 78, 0], ix: 2},
        a: {a: 0, k: [78, 78, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: '底色.png',
      cl: 'png',
      refId: 'image_6',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [78, 78, 0], ix: 2},
        a: {a: 0, k: [78, 78, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
