import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import DigitBall from './digit-ball';
import theme from '@/style';
import AnalyzeLabel from './analyze-label';
import {COLORS, COLORS_LIGHT} from '../constant';

export interface HotColdProps {
  hot?: number[];
  cold?: number[];
  type: 'A' | 'B' | 'C' | 'D' | 'UNUSED';
}

const HotCold = ({hot = [], cold = [], type = 'A'}: HotColdProps) => {
  return (
    <View style={[styles.container, theme.padding.l, theme.flex.centerByCol]}>
      <Text color={theme.basicColor.dark} size="medium" fontWeight="500">
        Hot/Cold
      </Text>
      <View style={[theme.margin.tops, {gap: theme.paddingSize.xxs}]}>
        <View style={[theme.flex.row, {gap: theme.paddingSize.xxs}]}>
          {hot.map((item, _i) => (
            <DigitBall
              fontWeight="500"
              fontSize="default"
              size={22}
              key={_i}
              digit={item + ''}
              type={type}
            />
          ))}
        </View>
        <View style={[theme.flex.row, {gap: theme.paddingSize.xxs}]}>
          {cold.map((item, _i) => (
            <DigitBall
              size={22}
              fontSize="default"
              fontWeight="500"
              key={_i}
              digit={item + ''}
              type={type}
              bg={COLORS_LIGHT[type]}
            />
          ))}
        </View>
      </View>
      <View
        style={[
          theme.margin.topl,
          theme.flex.row,
          theme.flex.centerByCol,
          {gap: theme.paddingSize.xs},
        ]}>
        <AnalyzeLabel label="Hot" backgroundColor={COLORS[type]} />
        <AnalyzeLabel label="Cold" backgroundColor={COLORS_LIGHT[type]} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 160,
    backgroundColor: '#EFF1F7',
    borderRadius: 8,
  },
});

export default HotCold;
