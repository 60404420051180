import theme from '@/style';
import {View, StyleSheet} from 'react-native';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import Dustbin from '@/components/svgs/basic/dustbin';
import React from 'react';
import Text from '@/components/basic/text';
import Price from '@/components/business/price/price';
import {SattaOrderCreateDataContent} from '../satta-service';

export interface ListItemProps {
  index: number;
  info: SattaOrderCreateDataContent;
  onItemClear?: () => void;
}

const ListItem = ({index = 0, info, onItemClear}: ListItemProps) => {
  return (
    <View
      style={[
        theme.flex.row,
        index % 2 === 0 ? styles.even : styles.odd,
        theme.padding.l,
      ]}>
      <View style={[theme.flex.flex1, theme.flex.center]}>
        <Text size="medium">{info.digits}</Text>
      </View>
      <View style={[theme.flex.flex1, theme.flex.center]}>
        <Price
          price={info.amount}
          textProps={{size: 'medium', fontFamily: 'fontInter', blod: false}}
        />
      </View>
      <View style={[theme.flex.flex1, theme.flex.center]}>
        <Text size="medium">{info.type}</Text>
      </View>
      <NativeTouchableOpacity
        style={[theme.flex.flex1, theme.flex.center]}
        onPress={onItemClear}>
        <Dustbin />
      </NativeTouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  odd: {
    backgroundColor: '#F8F9FF',
  },
  even: {
    backgroundColor: theme.basicColor.white,
  },
});

export default ListItem;
