import theme from '@style';
import React, {ReactNode} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

export interface CardBaseProps {
  paddingHorizontal?: number;
  paddingVertical?: number;
  backgroundColor?: string;
  radius?: number;
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
}

const CardBase: React.FC<CardBaseProps> = props => {
  const {
    paddingHorizontal = 0,
    paddingVertical = 0,
    backgroundColor = 'transparent',
    radius = 0,
    children,
    style,
  } = props;
  return (
    <View
      style={[
        theme.flex.col,
        theme.overflow.hidden,
        {
          paddingHorizontal,
          paddingVertical,
          backgroundColor,
          borderRadius: radius,
        },
        style,
      ]}>
      {children}
    </View>
  );
};

CardBase.displayName = 'Card';

export default CardBase;
