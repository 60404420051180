import theme from '@/style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React from 'react';
import Text from '@/components/basic/text';
import {Shadow} from 'react-native-shadow-2';
import {CART_ACTION_COLOR, CART_ACTION_SHADOW_COLOR} from '../constant';
import {View} from 'react-native';
export interface CartActionButtonProps {
  value?: string;
  onPress?: () => void;
  disabled?: boolean;
}

const CartActionButton = ({
  value = '20',
  onPress,
  disabled = false,
}: CartActionButtonProps) => {
  return (
    <Shadow
      containerStyle={[theme.flex.flex1, theme.overflow.visible]}
      style={[theme.fill.fillW]}
      startColor={CART_ACTION_SHADOW_COLOR[value]}
      endColor={CART_ACTION_SHADOW_COLOR[value]}
      offset={[0, 3]}
      distance={0}>
      <View
        style={[
          theme.background.primary,
          theme.borderRadius.xs,
          theme.overflow.hidden,
          {
            backgroundColor: CART_ACTION_COLOR[value],
          },
        ]}>
        <NativeTouchableOpacity
          onPress={onPress}
          style={[
            theme.flex.center,
            theme.padding.tbs,
            theme.background.primary,
            {backgroundColor: CART_ACTION_COLOR[value]},
            // eslint-disable-next-line react-native/no-inline-styles
            disabled && {opacity: 0.3},
          ]}>
          <Text size="medium" fontWeight="500" color={theme.basicColor.white}>
            {value !== 'MAX' ? '+' : ''}
            {value}
          </Text>
        </NativeTouchableOpacity>
      </View>
    </Shadow>
  );
};

export default CartActionButton;
