/* eslint-disable react-native/no-inline-styles */
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {View} from 'react-native';

const TableData = [
  {
    tl: 'BIG',
    tc: 'Total points between 11 to 17 excluding Triples',
    tr: '1:1.9',
  },
  {
    tl: 'SMALL',
    tc: 'Total points between 4 to 10 excluding Triples',
    tr: '1:1.9',
  },
  {
    tl: 'Odd',
    tc: 'Sum of points (Odd number)',
    tr: '1:1.9',
  },
  {
    tl: 'Even',
    tc: 'Sum of points (Even number)',
    tr: '1:1.9',
  },
  {
    tl: '4 or 17',
    tc: 'Total points of 4 or 17',
    tr: '1:50',
  },
  {
    tl: '5 or 16',
    tc: 'Total points of 5 or 16',
    tr: '1:18',
  },
  {
    tl: '6 or 15',
    tc: 'Total points of 6 or 15',
    tr: '1:14',
  },
  {
    tl: '7 or 14',
    tc: 'Total points of 7 or 14',
    tr: '1:12',
  },
  {
    tl: '8 or 13',
    tc: 'Total points of 8 or 13',
    tr: '1:8',
  },
  {
    tl: '9/10/11/12',
    tc: 'Total points of 9 or 10 or 11 or 12',
    tr: '1:6',
  },
  {
    tl: 'Specific Triple',
    tc: 'Specific combination of 3 Dice with identical Value',
    tr: '1:150',
  },
  {
    tl: 'Any Triple',
    tc: 'Any combination of 3 Dice with identical Value.',
    tr: '1:24',
  },
  {
    tl: 'Specific Double',
    tc: 'Specific combination of 2 Dice with identical Value',
    tr: '1:8',
  },
  {
    tl: 'Double Dices',
    tc: 'Pair of Dice with specific combination',
    tr: '1:5',
  },
  {
    tl: 'Single Dice',
    tc: '1 Dice with specific value drawn',
    tr: '1:1.9',
  },
];

const DiceRule = () => {
  return (
    <>
      <Text
        fontFamily="fontInterBold"
        size="medium"
        style={[theme.margin.btml]}>
        Dice Game Description
      </Text>
      <Text>
        Dice Game is a game played with dice to guess the dice points or the
        total number of points sum up by the one or more dice. There are many
        ways to play and players are allowed to place multiple bets together.
      </Text>
      <Text fontFamily="fontInterBold" size="medium" style={[theme.margin.tbl]}>
        Big / Small
      </Text>
      <Text>
        In a 3 dice game, the point values for 'BIG' and 'SMALL' are as follows:
      </Text>
      <Text>
        <Text primary fontFamily="fontInterBold">
          Big{' '}
        </Text>
        = 11, 12, 13, 14, 15, 16 and 17
      </Text>
      <Text>
        <Text primary fontFamily="fontInterBold">
          Small{' '}
        </Text>
        = 4, 5, 6, 7, 8, 9 and 10
      </Text>
      <Text>
        The players who bet on{' '}
        <Text primary fontFamily="fontInterBold">
          'BIG'
        </Text>{' '}
        and{' '}
        <Text primary fontFamily="fontInterBold">
          'SMALL'
        </Text>{' '}
        will win if points match these numb er after Dice draw.
      </Text>
      <Text fontFamily="fontInterBold" size="medium" style={[theme.margin.tbl]}>
        Odd / Even
      </Text>

      <Text>
        The sum of points of 3 Dice allows players to bet on{' '}
        <Text primary fontFamily="fontInterBold">
          odd{' '}
        </Text>
        or{' '}
        <Text primary fontFamily="fontInterBold">
          Even
        </Text>{' '}
        numbers.
      </Text>
      <Text>
        In a 3 dice game, the point values for{' '}
        <Text primary fontFamily="fontInterBold">
          {' '}
          Odd{' '}
        </Text>{' '}
        and{' '}
        <Text primary fontFamily="fontInterBold">
          Even
        </Text>
        or are as follows:
      </Text>
      <Text>
        <Text primary fontFamily="fontInterBold">
          Odd{' '}
        </Text>
        = 5, 7, 9, 11, 13, 15 and 17
      </Text>
      <Text>
        <Text primary fontFamily="fontInterBold">
          Even{' '}
        </Text>
        = 4, 6, 8, 10, 12, 14 and 16
      </Text>
      <Text>
        <Text primary fontFamily="fontInterBold">
          Except specific triple :{' '}
        </Text>
        3=111, 6=222, 9=333, 12=444, 15=555, 18=666
      </Text>
      <Text fontFamily="fontInterBold" size="medium" style={[theme.margin.tbl]}>
        Sum of Points
      </Text>
      <Text>
        The sum of points allows players to bet on the specific total of points
        drawn from the 3 Dice. This does not include triples.
      </Text>
      <Text>Bet on 4,5,6,7,8,9,10,11,12,13,14,15,16,17</Text>
      <Text>
        <Text primary fontFamily="fontInterBold">
          Except specific triple :{' '}
        </Text>
        3=111, 6=222, 9=333, 12=444, 15=555, 18=666
      </Text>
      <Text fontFamily="fontInterBold" size="medium" style={[theme.margin.tbl]}>
        Single Dice
      </Text>
      <Text>
        Single Dice is the prediction of the dice point that will be drawn any 1
        out of 3 dice.
      </Text>
      <Text>Bet on 1,2,3,4,5,6</Text>
      <Text fontFamily="fontInterBold" size="medium" style={[theme.margin.tbl]}>
        Double Dice
      </Text>
      <Text>
        Players can place 1 or more bets on any of the 15 different Double Dice
        Combinations available. Betting results are based on any of the 2 dice
        out of 3 dice results.
      </Text>
      <Text>
        Bet on 2 dice numbers: 12,13,14,15,16,23,24,25,26,34,35,36,45,46,56
      </Text>
      <Text fontFamily="fontInterBold" size="medium" style={[theme.margin.tbl]}>
        Specific Double
      </Text>
      <Text>
        Specific Double allows players to bet on a specific point pair drawn on
        any of the 2 Dice out of 3.
      </Text>
      <Text>Bet on 11,22,33,44,55,66</Text>
      <Text fontFamily="fontInterBold" size="medium" style={[theme.margin.tbl]}>
        Any Triple
      </Text>
      <Text>
        Any Triple allows players to place bets for any of the 6 combinations of
        triples.
      </Text>
      <Text>Bet on all triple:(111,222,333,444,555,666)</Text>
      <Text fontFamily="fontInterBold" size="medium" style={[theme.margin.tbl]}>
        Specific Triple
      </Text>
      <Text>
        Specific Triple allows players to bet on specific triple combinations
        that will be drawn from the Dice.
      </Text>
      <Text>
        Bet separately specific triple {'\n'}
        =111/222/333/444/555/666
      </Text>
      <Text fontFamily="fontInterBold" size="medium" style={[theme.margin.tbl]}>
        Game Odds
      </Text>
      <View style={[theme.flex.row]}>
        <View style={[{width: 85}]}>
          <Text fontFamily="fontInterBold">Type</Text>
        </View>
        <View style={[theme.flex.flex1]}>
          <Text fontFamily="fontInterBold">Description</Text>
        </View>
        <View style={[{width: 40}]}>
          <Text fontFamily="fontInterBold">Odds</Text>
        </View>
      </View>
      <View>
        {TableData.map((item, _i) => (
          <View style={[theme.flex.row, theme.margin.tops]} key={_i}>
            <View style={[{width: 85}]}>
              <Text>{item.tl}</Text>
            </View>
            <View style={[theme.flex.flex1]}>
              <Text>{item.tc}</Text>
            </View>
            <View style={[{width: 40}]}>
              <Text>{item.tr}</Text>
            </View>
          </View>
        ))}
      </View>
      <Text fontFamily="fontInterBold" size="medium" style={[theme.margin.tbl]}>
        DISCLAIMER
      </Text>
      <Text style={[{marginBottom: 30}]}>
        This game is provided "as is" without any express or implied warranties.
        In no event shall the author be liable for any damages resulting from
        the use of this game. The user assumes all responsibility for any and
        all risks associated with using this game. No guarantee of accuracy is
        provided with the use of this game. This game is intended for users over
        the age of 18 only.
      </Text>
    </>
  );
};

export default DiceRule;
