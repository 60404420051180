const red = require('@components/assets/imgs/game/color/color-red.webp');
const green = require('@components/assets/imgs/game/color/color-green.webp');
const greenV = require('@components/assets/imgs/game/color/color-green-violet.webp');
const redV = require('@components/assets/imgs/game/color/color-red-violet.webp');
const violet = require('@components/assets/imgs/game/color/color-violet.webp');
const colorRed = require('@components/assets/imgs/game/color/red.webp');
const colorRedDisabled = require('@components/assets/imgs/game/color/red-disabled.webp');
const colorGreen = require('@components/assets/imgs/game/color/green.webp');
const colorGreenDisabled = require('@components/assets/imgs/game/color/green-disabled.webp');
const colorRedV = require('@components/assets/imgs/game/color/red-violet.webp');
const colorRedVDisabled = require('@components/assets/imgs/game/color/red-violet-disabled.webp');
const colorGreenV = require('@components/assets/imgs/game/color/green-violet.webp');
const colorGreenVDisabled = require('@components/assets/imgs/game/color/green-violet-disabled.webp');
const colorNone = require('@components/assets/imgs/game/color/color-none.webp');

const ALL_BALLS = [
  {
    digit: '0',
    color: 'r,v',
  },
  {
    digit: '1',
    color: 'g',
  },
  {
    digit: '2',
    color: 'r',
  },
  {
    digit: '3',
    color: 'g',
  },
  {
    digit: '4',
    color: 'r',
  },
  {
    digit: '5',
    color: 'g,v',
  },
  {
    digit: '6',
    color: 'r',
  },
  {
    digit: '7',
    color: 'g',
  },
  {
    digit: '8',
    color: 'r',
  },
  {
    digit: '9',
    color: 'g',
  },
];

const BALLS = {
  red,
  green,
  greenV,
  redV,
  violet,
  colorRed,
  colorGreen,
  colorRedV,
  colorGreenV,
  colorRedDisabled,
  colorGreenDisabled,
  colorRedVDisabled,
  colorGreenVDisabled,
  colorNone,
};

export {BALLS, ALL_BALLS};
