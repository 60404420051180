import theme from '@style';
import LazyImage from '@basicComponents/image';
import React, {useMemo} from 'react';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {BasicObject} from '@types';
import globalStore from '@/services/global.state';
import {goCS} from '@/utils';
import {StyleSheet} from 'react-native';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

const RightMenusService = () => {
  const {calcActualSize} = useScreenSize();
  const wrapSize = useMemo(() => calcActualSize(52), [calcActualSize]);
  const innerSize = useMemo(() => calcActualSize(46), [calcActualSize]);
  const serviceStyle = StyleSheet.create({
    service: {
      width: wrapSize,
      height: wrapSize,
      borderRadius: wrapSize,
    },
  });

  const iconShadow = useMemo(() => {
    let shadow = {} as BasicObject;
    if (globalStore.isWeb) {
      shadow = {
        ...shadow,
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
      };
    } else {
      shadow = {
        ...shadow,
        shadowColor: 'rgba(0, 0, 0, 0.25)',
        shadowOffset: {width: 0, height: 4},
        shadowOpacity: 1,
        shadowRadius: 4,
        elevation: 12,
      };
    }
    return shadow;
  }, []);

  return (
    <NativeTouchableOpacity
      style={[
        theme.background.primary,
        serviceStyle.service,
        theme.flex.center,
        iconShadow,
      ]}
      onPress={goCS}>
      <LazyImage
        width={innerSize}
        height={innerSize}
        radius={innerSize}
        imageUrl={require('@components/assets/icons/service.webp')}
      />
    </NativeTouchableOpacity>
  );
};

export default RightMenusService;
