import theme from '@/style';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import React, {ReactNode} from 'react';
import {useResponsiveDimensions} from '@/utils';

const GAP = 8;
const PADDING = 12;

export interface DiceContainerProps {
  children?: ReactNode;
  itemNumber?: number;
  disabled?: boolean;
  onPress?: () => void;
}

const DiceContainer = ({
  itemNumber = 4,
  children,
  disabled = false,
  onPress = () => {},
}: DiceContainerProps) => {
  const {width} = useResponsiveDimensions();
  const itemWidth = (width - PADDING * 2 - GAP * (itemNumber - 1)) / itemNumber;

  return (
    <NativeTouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[
        {width: itemWidth},
        theme.padding.tbs,
        theme.background.white,
        theme.flex.center,
        theme.borderRadius.m,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          elevation: 4,
        },
      ]}>
      {children}
    </NativeTouchableOpacity>
  );
};

export default React.memo(DiceContainer);
