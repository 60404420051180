import LazyImage from '@/components/basic/image';
import React from 'react';
import {View} from 'react-native';
import dayjs from 'dayjs';
import Text from '@/components/basic/text';
import theme from '@/style';
import DatePicker from '@/components/basic/date-picker';
const calendar = require('@/assets/icons/calendar.webp');
const triangle = require('@/assets/icons/triangle.webp');
export interface SelectDateType {
  value?: Date;
  onChange?: (v: Date) => void;
}

const SelectDate = (props: SelectDateType) => {
  const {value, onChange} = props;
  const [showDate, setShowDate] = React.useState(false);
  return (
    <DatePicker
      titleRender={
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            theme.background.white,
            theme.borderRadius.m,
            theme.padding.l,
            theme.margin.btml,
            theme.margin.lrl,
          ]}>
          <View
            style={[
              theme.flex.between,
              theme.flex.row,
              theme.flex.centerByCol,
              theme.flex.flex1,
            ]}>
            <LazyImage
              imageUrl={calendar}
              width={16}
              height={16}
              occupancy="transparent"
            />
            <Text
              blod
              size="medium"
              style={[theme.margin.rightl, theme.font.main]}>
              {dayjs(value).format('YYYY-MM')}
            </Text>
          </View>
          <LazyImage
            imageUrl={triangle}
            width={14}
            height={14}
            occupancy="transparent"
          />
        </View>
      }
      open={showDate}
      setOpen={setShowDate}
      type="month"
      value={value}
      onValueChange={onChange}
    />
  );
};

export default SelectDate;
