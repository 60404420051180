export const packageId = 8 as number;
export const packageInfo = 'com.resulta.checka';
export const defaultChannel = 'Samsung';
export const disabledPhoneCode = true;
export const apiSendPhoneCode = false;
export const defaultPhoneCode = '91';
export const homePage = 'Index';
export const defaultApkPath = 'bhau';
export const defaultApkName = 'bhau.apk';
export const defaultApkPrefix = 'bhau-';
export const gameNavigateVersion = 1;
