import {
  View,
  StyleSheet,
  TextInput,
  TextInputProps,
  ScrollView,
} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
// import TextInput, {
//   TextInputProps,
// } from '@/components/basic/input-field/text-input';
import React from 'react';
import theme from '@/style';
import globalStore from '@/services/global.state';

export type BettingType =
  | 'Ank'
  | 'Jodi'
  | 'SP'
  | 'DP'
  | 'TP'
  | 'Half Sangam'
  | 'Full Sangam';

export interface DigitInputProps {
  digitList: number[];
  hasSelect?: boolean;
  bettingType: BettingType;
  digits?: number;
}

const DigitInput = (props: TextInputProps & DigitInputProps) => {
  const inputRef = React.useRef<TextInput>(null);

  const [pageX, setPageX] = React.useState(0);
  // const [pageY, setPageY] = React.useState(0);
  const [show, setShow] = React.useState(false);
  const {
    value,
    onChangeText,
    digitList,
    digits,
    bettingType,
    placeholder,
    ...otherProps
  } = props;

  const allCanInputStrList = React.useMemo(() => {
    return digitList.map(x => `${x}`.padStart(3, '0'));
  }, [digitList]);

  const resultDigits = React.useMemo(() => {
    if (bettingType === 'Ank') {
      return 1;
    }
    if (bettingType === 'Jodi') {
      return 2;
    }
    if (['SP', 'DP', 'TP'].find(x => x === bettingType)) {
      return 3;
    }
    return digits || 1;
  }, [digits, bettingType]);

  const allCanInputSumList = React.useMemo(() => {
    const set = new Set(
      allCanInputStrList.map(
        str => str.split('').reduce((s, x) => s + +x, 0) + '',
      ),
    );
    const list = [...set].filter(item => item.length === 1);
    return list;
  }, [allCanInputStrList]);

  // 列举所有当前过滤后可以输入的列表
  const filteredList = React.useMemo(() => {
    if (resultDigits === 1) {
      return allCanInputSumList.filter(item => item.startsWith(value || ''));
    }
    if (resultDigits === 2) {
      const secondList = [];
      for (let i = 0; i < allCanInputSumList.length; i++) {
        for (let j = 0; j < allCanInputSumList.length; j++) {
          secondList.push(`${allCanInputSumList[i]}${allCanInputSumList[j]}`);
        }
      }
      return secondList.filter(item => item.startsWith(value || ''));
    }
    // 三位数的情况，依据bettingType来进行过滤
    // SP 三位数都不相同
    if (bettingType === 'SP') {
      return allCanInputStrList.filter(item => {
        const set = new Set(item.split(''));
        return set.size === 3 && item.startsWith(value || '');
      });
    }
    // DP 三位数的相邻两个相同，但不能三个都相同
    if (bettingType === 'DP') {
      return allCanInputStrList.filter(item => {
        return (
          (item[0] === item[1] || item[1] === item[2]) &&
          item[2] !== item[0] &&
          item.startsWith(value || '')
        );
      });
    }
    // TP 三位数都相同
    if (bettingType === 'TP') {
      return allCanInputStrList.filter(item => {
        return (
          item[0] === item[1] &&
          item[1] === item[2] &&
          item.startsWith(value || '')
        );
      });
    }
    // Sangam系列的情况，直接筛选即可
    return allCanInputStrList.filter(item => item.startsWith(value || ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, bettingType, resultDigits, digitList]);

  // 下拉列表
  const dropDownList = React.useMemo(() => {
    // 只有一位数或者未填值，不出现列表
    if (!value || resultDigits === 1) {
      return [];
    }
    return filteredList;
  }, [value, resultDigits, filteredList]);

  const handleChange = (_value: string) => {
    // 正常判断，如果不在选取范围，返回原值
    const resultList = filteredList.filter(item => item.startsWith(_value));
    if (!resultList.length) {
      onChangeText?.(value || '');
      return;
    }
    // 在非删除的情况下，如果只有一个选项，则自动填入
    if (resultList.length === 1 && (!value || value.length < _value.length)) {
      onChangeText?.(resultList[0]);
      return;
    }
    onChangeText?.(_value);
  };

  return (
    <>
      <Shadow
        style={[theme.borderRadius.xs]}
        distance={4}
        startColor={theme.backgroundColor.sattaDigitShadow}>
        <View style={[theme.borderRadius.xs]}>
          <TextInput
            multiline
            textAlign="center"
            numberOfLines={1}
            keyboardType="numeric"
            style={[
              styles.inputContainer,
              globalStore.isWeb && styles.webInput,
            ]}
            onFocus={() => {
              inputRef.current?.measure((x, y, width, height, _pageX) => {
                setPageX(_pageX - 12);
              });
              setShow(true);
            }}
            onBlur={() => {
              setTimeout(() => {
                setShow(false);
              }, 200);
            }}
            placeholder={show ? '' : placeholder}
            ref={inputRef}
            value={value}
            onChangeText={handleChange}
            placeholderTextColor={'#9FA5AC'}
            {...otherProps}
          />
        </View>
      </Shadow>
      {show && dropDownList.length > 0 && (
        <View style={[styles.selectContainer, {left: pageX}]}>
          <ScrollView nestedScrollEnabled keyboardShouldPersistTaps="always">
            {dropDownList.map((item, _i) => (
              <NativeTouchableOpacity
                key={_i}
                onPress={() => {
                  onChangeText?.(item);
                  setTimeout(() => {
                    inputRef.current?.blur();
                  }, 0);
                }}
                style={[theme.flex.center, styles.item]}>
                <Text size="medium" fontFamily="fontInterBold">
                  {item}
                </Text>
              </NativeTouchableOpacity>
            ))}
          </ScrollView>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    borderWidth: 1,
    width: 160,
    height: 40,
    backgroundColor: theme.basicColor.white,
    borderColor: '#D8E0EA',
    borderRadius: 4,
    textAlign: 'center',
  },
  webInput: {
    lineHeight: 38,
    outlineWidth: '0',
  },
  item: {
    height: 36,
  },
  selectContainer: {
    backgroundColor: '#fff',
    left: 0,
    position: 'absolute',
    borderRadius: 4,
    width: 160,
    elevation: 10,
    top: 44,
    maxHeight: 200,
    zIndex: 100,
  },
});

export default DigitInput;
