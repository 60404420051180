import React from 'react';
import {Image} from 'react-native';
import {DICES} from '../constant';

export interface DiceItemProps {
  diceSize?: number;
  diceNum?: number | string;
  disabled?: boolean;
}

const DiceItem = ({
  diceSize = 10,
  diceNum = 1,
  disabled = false,
}: DiceItemProps) => {
  return (
    <Image
      source={DICES[disabled ? `${diceNum}-disabled` : diceNum]}
      style={{width: diceSize, height: diceSize}}
    />
  );
};

export default DiceItem;
