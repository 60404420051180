import {View} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import {useTranslation} from 'react-i18next';

export interface TableHeaderProps {
  headers: string[];
}

const TableHeader = ({headers = []}: TableHeaderProps) => {
  const {i18n} = useTranslation();
  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.centerByCol,
        theme.flex.between,
        theme.padding.lrl,
        theme.padding.tbs,
        theme.margin.lrl,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          backgroundColor: '#E9ECF5',
        },
      ]}>
      {headers.map((str, _i) => (
        <View
          key={_i}
          style={[
            _i > 0 && theme.flex.center,
            // eslint-disable-next-line react-native/no-inline-styles
            _i === 0 && {width: 92},
            // eslint-disable-next-line react-native/no-inline-styles
            _i === 2 && {width: 80},
            _i === 1 && theme.flex.flex1,
          ]}>
          <Text color={theme.fontColor.second}>
            {i18n.t(`game-page.label.${str}`).toUpperCase()}
          </Text>
        </View>
      ))}
    </View>
  );
};

export default TableHeader;
