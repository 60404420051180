import {View, StyleSheet} from 'react-native';

import Text from '@/components/basic/text';
import React from 'react';
import {useResponsiveDimensions} from '@/utils';

const Tag = ({text = '', isWin}: {text: string; isWin?: boolean}) => {
  const {width} = useResponsiveDimensions();
  const tagWidth = (width - 48 - 16) / 3;
  return (
    <View
      style={[
        styles.container,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          width: tagWidth,
          backgroundColor: isWin ? '#fff' : '#F6F7FB',
          borderColor: isWin ? '#F15802' : '#F6F7FB',
        },
      ]}>
      <Text
        color={isWin ? '#F15802' : '#31373D'}
        size="medium"
        fontFamily="fontInterBold">
        {text}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 36,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
  },
});

export default Tag;
