import theme from '@/style';
import {useResponsiveDimensions} from '@/utils';
import React from 'react';
import {View, StyleSheet, Animated} from 'react-native';

export interface ProgressBarProps {
  total?: number;
  value?: number;
}

const ProgressBar = ({value = 0, total = 1}: ProgressBarProps) => {
  const {width: windowWidth} = useResponsiveDimensions();
  const moveAnim = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    const rate = total ? windowWidth / total : 1;
    Animated.timing(moveAnim, {
      toValue: -(windowWidth - rate * value),
      duration: 900,
      useNativeDriver: true,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <View style={styles.container}>
      <Animated.View
        style={[
          {
            width: windowWidth,
            transform: [
              {
                translateX: moveAnim,
              },
            ],
          },
          styles.inner,
        ]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 6,
    backgroundColor: '#8998A1',
    overflow: 'hidden',
  },
  inner: {
    backgroundColor: theme.basicColor.primary,
    height: '100%',
  },
});

export default React.memo(ProgressBar);
