import theme from '@/style';
import {toPriceStr} from '@/utils';
import {View} from 'react-native';
import Text from '@basicComponents/text';
import React, {useMemo} from 'react';
import {Input} from '@rneui/themed';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useInnerStyle} from './recharge.hooks';
import {BalanceListItem, PayMethod} from './recharge.service';
import BoxShadow from '@/components/basic/shadow';
import LinearGradient from '@/components/basic/linear-gradient';
import {Trans, useTranslation} from 'react-i18next';
import RechargeTip from './recharge-tip';
import {getGiveSentStrs} from './recharge.utils';

export interface RechargeSelectProps {
  min: number;
  max: number;
  balance: string;
  payMethod?: PayMethod;
  extraBounsBalanceIndex?: number;
  onChangeBalance: (balance: string) => void;
  balanceList?: BalanceListItem[];
}

const RechargeSelect: React.FC<RechargeSelectProps> = ({
  balance,
  onChangeBalance,
  balanceList,
  min,
  max,
  payMethod,
  extraBounsBalanceIndex,
}) => {
  const {i18n} = useTranslation();
  const {
    inputStyle,
    compressedInputStyle,
    inputStyles,
    selectStyles,
    size: {calcActualSize},
  } = useInnerStyle();

  const giveSentStr = useMemo(() => {
    if (extraBounsBalanceIndex == null || balanceList == null) {
      return '';
    }
    return getGiveSentStrs(payMethod, balanceList[extraBounsBalanceIndex]);
  }, [balanceList, extraBounsBalanceIndex, payMethod]);

  return (
    <View
      style={[
        theme.flex.col,
        theme.borderRadius.m,
        theme.padding.l,
        theme.background.white,
      ]}>
      <View
        style={[
          theme.flex.col,
          {
            gap: calcActualSize(theme.paddingSize.s),
            marginBottom: calcActualSize(theme.paddingSize.s),
          },
        ]}>
        <View
          style={[
            theme.flex.col,
            theme.background.lightGrey,
            selectStyles.inputWrap,
            theme.borderRadius.xs,
            theme.position.rel,
          ]}>
          <Input
            containerStyle={[
              theme.padding.lrm,
              !giveSentStr
                ? inputStyles.container
                : inputStyles.compressedContainer,
            ]}
            inputContainerStyle={inputStyles.inputContainer}
            style={!giveSentStr ? inputStyle : compressedInputStyle}
            errorStyle={inputStyles.error}
            keyboardType="numeric"
            inputMode="numeric"
            value={balance}
            onChangeText={value => {
              if (value && !value.startsWith('0')) {
                if (/^[0-9]+$/.test(value)) {
                  if (max > 0) {
                    if (Number(value) <= max) {
                      onChangeBalance(value);
                    }
                  } else {
                    if (Number(value) <= 50000) {
                      onChangeBalance(value);
                    }
                  }
                } else {
                  onChangeBalance(balance || '');
                }
              } else {
                onChangeBalance('');
              }
            }}
            placeholder={i18n.t('recharge-page.label.enter')}
          />
          {!!giveSentStr && (
            <Text
              style={[
                theme.position.abs,
                {
                  bottom: calcActualSize(8),
                  left: calcActualSize(12),
                },
              ]}
              calc
              color={theme.backgroundColor.second}
              fontSize={10}
              fontWeight="700">
              {i18n.t('recharge.tip.extra', {bonus: giveSentStr})}
            </Text>
          )}
        </View>
        <View>
          <Text calc accent>
            <Trans
              i18nKey="recharge-page.label.enterText"
              components={{
                mark: <Text calc primary />,
              }}>
              {i18n.t('recharge-page.label.enterText')}
            </Trans>
          </Text>
        </View>
        <View style={[theme.flex.row]}>
          <Text accent fontSize={theme.fontSize.m}>
            {i18n.t('recharge-page.label.min')}
          </Text>
          <Text
            style={[theme.margin.leftxxs]}
            second
            blod
            fontSize={theme.fontSize.m}>
            {min ? toPriceStr(min, {fixed: 0, thousands: true}) : '--'}
          </Text>
          <Text
            style={[theme.margin.leftxxl]}
            accent
            fontSize={theme.fontSize.m}>
            {i18n.t('recharge-page.label.max')}
          </Text>
          <Text
            style={[theme.margin.leftxxs]}
            second
            blod
            fontSize={theme.fontSize.m}>
            {max ? toPriceStr(max, {fixed: 0, thousands: true}) : '--'}
          </Text>
        </View>
      </View>
      <View
        style={[
          {
            paddingTop: calcActualSize(theme.paddingSize.s),
            gap: calcActualSize(theme.paddingSize.s),
          },
          theme.flex.row,
          theme.flex.wrap,
        ]}>
        {balanceList?.map((bl, index) => (
          <NativeTouchableOpacity
            key={index}
            style={[selectStyles.item, theme.flex.col, theme.position.rel]}
            onPress={() => onChangeBalance(bl.balance + '')}>
            {bl.ratio ? (
              <RechargeTip>{`${i18n.t('recharge.label.extra')} +${
                bl.ratio
              }%`}</RechargeTip>
            ) : null}
            {bl.balance + '' !== balance ? (
              <BoxShadow
                shadowStyle={{
                  radius: theme.borderRadiusSize.xs,
                  out: {x: 0, y: 2, blur: 0, color: '#C3C8DC'},
                }}>
                <LinearGradient
                  start={{x: 0, y: 0}}
                  end={{x: 0, y: 1}}
                  colors={['#F2F4F9', '#F3F5FB']}
                  style={[
                    theme.flex.center,
                    theme.borderRadius.xs,
                    selectStyles.item,
                  ]}>
                  <Text main blod fontSize={theme.fontSize.m}>
                    {toPriceStr(bl.balance, {
                      fixed: 0,
                      showCurrency: false,
                      thousands: true,
                    })}
                  </Text>
                </LinearGradient>
              </BoxShadow>
            ) : (
              <View
                style={[
                  theme.flex.center,
                  selectStyles.item,
                  theme.background.primary,
                  theme.borderRadius.xs,
                ]}>
                <Text
                  color={theme.basicColor.white}
                  blod
                  fontSize={theme.fontSize.m}>
                  {toPriceStr(bl.balance, {
                    fixed: 0,
                    showCurrency: false,
                    thousands: true,
                  })}
                </Text>
              </View>
            )}
          </NativeTouchableOpacity>
        ))}
      </View>
    </View>
  );
};

export default RechargeSelect;
