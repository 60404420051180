import {toPriceStr} from '@/utils';
import {BalanceListItem, PayMethod} from './recharge.service';
import globalStore from '@/services/global.state';
import i18n from '@/i18n';

export function getChannelGiveStr(payMethod?: PayMethod, single = true) {
  const {giveWay, giveFixedAmount, ratio} = payMethod || {};
  if (giveWay != null) {
    if (giveWay === 0) {
      return ratio
        ? `${single ? `${i18n.t('recharge.label.extra')} +` : ''}${ratio}%`
        : '';
    }
    if (giveWay === 1) {
      return giveFixedAmount
        ? `${single ? `${i18n.t('recharge.label.extra')} +` : ''}${
            globalStore.currency
          }${giveFixedAmount}`
        : '';
    }
    return '';
  }
  return '';
}

export function getGiveSentStrs(
  payMethod?: PayMethod,
  balanceItem?: BalanceListItem,
) {
  const payMethodStr = getChannelGiveStr(payMethod, false);
  const balanceItemStr = balanceItem?.ratio ? `${balanceItem.ratio}%` : '';
  const totalGiveSentStr = [payMethodStr, balanceItemStr]
    .filter(item => item)
    .join('+');
  return totalGiveSentStr;
}

export function getTotalSentPriceStr(
  balance: string,
  payMethod?: PayMethod,
  balanceItem?: BalanceListItem,
) {
  if (!balance || isNaN(Number(balance)) || Number(balance) <= 0) {
    return '';
  }
  const balanceNum = Number(balance);
  const payMethodResult =
    payMethod?.giveWay != null
      ? payMethod.giveWay === 1
        ? payMethod.giveFixedAmount || 0
        : payMethod.giveWay === 0
        ? ((payMethod.ratio || 0) * balanceNum) / 100
        : 0
      : 0;
  const payBalanceResult = ((balanceItem?.ratio || 0) / 100) * balanceNum;
  return payMethodResult + payBalanceResult <= 0
    ? ''
    : '+' + toPriceStr(payMethodResult + payBalanceResult, {fixed: 2});
}
