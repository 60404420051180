import {View} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import {COLOR_MAP} from '../../constant';
import {DiceResultItem} from '../../dice-service';

const AnalyzeNum = ({list = []}: {list?: DiceResultItem[]}) => {
  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.wrap,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          gap: 2,
        },
      ]}>
      {list.map((item, _i) => (
        <AnalyzeNumItem
          bigOrSmall={item.bigOrSmall}
          result={item.result}
          totalCount={item.totalCount}
          key={_i}
        />
      ))}
    </View>
  );
};

interface AnalyzeNumItemProps {
  totalCount?: number;
  result?: string;
  bigOrSmall: number;
}

const AnalyzeNumItem = ({
  totalCount = 0,
  bigOrSmall = 1,
  result = '',
}: AnalyzeNumItemProps) => {
  const color = React.useMemo(() => {
    return COLOR_MAP[bigOrSmall];
  }, [bigOrSmall]);

  return (
    <View
      style={[
        theme.padding.xxs,
        theme.borderRadius.xxs,
        theme.flex.center,
        // eslint-disable-next-line react-native/no-inline-styles
        {backgroundColor: '#F6F7FB', width: 28},
      ]}>
      <Text color={color} fontSize={10}>
        {totalCount}
      </Text>
      <Text fontSize={10} accent style={[theme.margin.topxxs]}>
        {result ? result.split(',').join('') : '-'}
      </Text>
    </View>
  );
};

export default AnalyzeNum;
