import {
  DigitResultItem,
  getDigitResultList,
} from '@/common-pages/pick-digit/digit-service';
import ResultItem from '@/common-pages/pick-digit/digit-tab/digit-result/result-item';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import React from 'react';
import {SectionList} from 'react-native';
import TableHeder from './table-header';
import dayjs from 'dayjs';
import useInfiniteScroll from '@/common-pages/hooks/load-more.hooks';
import DigitDraw from './draw';
import {packageId} from '@/config';

export const digitTypeList = [
  {
    label: 'ALL',
    value: 0,
  },
  {
    value: 1,
    label: 'Dear Lottery',
  },
  {
    value: 2,
    label: 'Kerala Lottery',
  },
  {
    value: 3,
    label: 'Lucwin',
  },
  {
    value: 4,
    label: 'KuberLottery',
  },
  {
    value: 5,
    label: 'Chennailotto',
  },
  {
    value: 6,
    label: 'Skywin',
  },
  packageId === 3
    ? {
        value: 8,
        label: 'Bhutan',
      }
    : {
        value: 7,
        label: 'GoldenJackpot',
      },
];

const DigitResult = ({
  isActive,
  lotteryType,
  digitTypes = [],
}: {
  isActive?: boolean;
  lotteryType?: string;
  digitTypes?: {label: string; value: number}[];
}) => {
  const [loading, setLoading] = React.useState(true);
  const [list, setList] = React.useState<DigitResultItem[]>([]);
  const {onEndReachedCalledDuringMomentum} = useInfiniteScroll(
    'result-digit-normal',
  );
  const currentPage = React.useRef(1);
  const hasMores = React.useRef(true);

  const getList = async (page = 1, loadMore = false) => {
    try {
      if (!loadMore) {
        setLoading(true);
      }
      const searchId = digitTypes?.find(item => item.label === lotteryType);
      const res = (await getDigitResultList(page, searchId?.value || 0)) || [];
      if (res.length === 0 && res[0].drawResultList.length < 10) {
        hasMores.current = false;
      }
      const target = res.map(item => {
        return {...item, data: item.drawResultList};
      });
      setList(loadMore ? list?.concat(target) : target);
    } finally {
      setLoading(false);
      onEndReachedCalledDuringMomentum.current = true;
    }
  };

  React.useEffect(() => {
    if (isActive) {
      currentPage.current = 1;
      hasMores.current = true;
      getList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, lotteryType]);

  const onLoadMore = async () => {
    if (hasMores.current && !onEndReachedCalledDuringMomentum.current) {
      currentPage.current += 1;
      await getList(currentPage.current, true);
    }
  };

  return (
    <Spin
      loading={loading}
      style={[theme.flex.flex1, theme.background.lightGrey]}>
      <SectionList
        ListHeaderComponent={!loading && isActive ? <DigitDraw /> : null}
        id="result-digit-normal"
        keyExtractor={(item, index) => `${index}`}
        scrollEventThrottle={16}
        onEndReachedThreshold={0.3}
        onMomentumScrollBegin={() => {
          onEndReachedCalledDuringMomentum.current = false;
        }}
        onEndReached={onLoadMore}
        contentContainerStyle={[theme.padding.btml]}
        sections={list}
        renderSectionHeader={({section: {drawDate}}) => (
          <TableHeder drawTime={dayjs(drawDate).format('DD-MM-YYYY')} />
        )}
        renderItem={({item, index}) => (
          <ResultItem
            emptyType="-"
            index={index}
            time={item.drawTime}
            result={item.drawResult}
            issueNum={item.pickName}
          />
        )}
      />
    </Spin>
  );
};

export default DigitResult;
