import Text from '@/components/basic/text';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  View,
  Image,
  StyleSheet,
  TouchableOpacity,
  Animated,
} from 'react-native';
import theme from '@/style';
import {downIcon} from '../rebate/rebate.variables';
import {LotteryMode, LotteryModeData, checkType} from './mix-lottery-service';
import BetTab from './component/bet-tab';
import {DEFAULT_TAB} from './constant';

const LotteryRule = ({modeInfos = []}: {modeInfos?: LotteryModeData[]}) => {
  const {t} = useTranslation();
  const [showMore, setShowMore] = React.useState(false);
  const [odds, setOdds] = React.useState(1);
  const rotateAnim = React.useRef(new Animated.Value(0)).current;
  const [mode, setMode] = React.useState<`${LotteryMode}`>(
    `${DEFAULT_TAB[0].modeID}`,
  );
  const rotate = (status: boolean) => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(rotateAnim, {
      toValue: status ? 1 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start();
  };

  React.useEffect(() => {
    // setCurrentTypeMap(checkType(parseInt(currentTab + "", 10)));
    const _odds = modeInfos.find(
      info => info.modeID + '' === mode + '',
    )!.itemData;
    if (!_odds) {
      return;
    }
    if (Array.isArray(_odds)) {
      setOdds(_odds[0].odds);
    } else {
      setOdds(_odds.odds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <>
      <Text
        fontFamily="fontInterBold"
        size="large"
        color={theme.basicColor.dark}>
        {t('mix-lotto.label.name')}
      </Text>
      <View style={[{gap: theme.paddingSize.m}, theme.margin.topl]}>
        <Text size="medium" fontWeight="500">
          {t('mix-lotto.desc.rule.desc1')}
        </Text>
        <Text size="medium" fontWeight="500">
          {t('mix-lotto.desc.rule.desc2')}
        </Text>
        <Text size="medium" fontWeight="500">
          {t('mix-lotto.desc.rule.desc3')}
        </Text>
      </View>
      <View style={[theme.margin.topl]}>
        <TouchableOpacity
          onPress={() => {
            rotate(!showMore);
            setShowMore(!showMore);
          }}
          activeOpacity={0.8}
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            theme.flex.between,
            theme.padding.tbxxs,
          ]}>
          <Text
            fontFamily="fontInterBold"
            size="large"
            color={theme.basicColor.dark}>
            {t('game-page.label.howToPlay')}
          </Text>
          <Animated.View
            style={[
              {
                transform: [
                  {
                    rotate: rotateAnim.interpolate({
                      inputRange: [0, 1],
                      outputRange: ['-90deg', '0deg'],
                    }),
                  },
                ],
              },
            ]}>
            <Image style={styles.iconSize} source={downIcon} />
          </Animated.View>
        </TouchableOpacity>
        {showMore && (
          <View style={[theme.margin.topl]}>
            <Text size="medium" fontWeight="500">
              {t('mix-lotto.desc.rule.howToPlay1')}
            </Text>
            <Text size="medium" fontWeight="500">
              {t('mix-lotto.desc.rule.howToPlay2')}
            </Text>
            <Text size="medium" fontWeight="500">
              {t('mix-lotto.desc.rule.howToPlay3')}
            </Text>
            <Text size="medium" fontWeight="500">
              {t('mix-lotto.desc.rule.howToPlay4')}
            </Text>
            <Text size="medium" fontWeight="500">
              {t('mix-lotto.desc.rule.howToPlay5')}
            </Text>
          </View>
        )}
      </View>
      <BetTab
        style={[{backgroundColor: '#E9ECF5'}, theme.margin.topl]}
        onChange={setMode}
        selectedKey={mode}
        tabList={(modeInfos || DEFAULT_TAB).map(v => ({
          label: LotteryMode[v.modeID],
          key: `${v.modeID}`,
        }))}
      />
      <View style={[theme.padding.l, styles.desc, theme.margin.btmxxl]}>
        {Number(mode) === LotteryMode['Two Side'] ? (
          [
            {key: 'BS', betValue: 'Small', result: '654321'},
            {key: 'OE', betValue: 'Odd', result: '654321'},
          ].map((v, _i) => (
            <View key={v.key}>
              <Text
                fontFamily="fontInterBold"
                size="large"
                color={theme.basicColor.dark}>
                {t('mix-lotto.label.rule.towSide.' + v.key + 'Title')}
              </Text>
              <View style={[theme.margin.tbl]}>
                <Text size="medium" fontWeight="500">
                  {t('mix-lotto.desc.rule.towSide.' + v.key + 'Title')}
                </Text>
                <Text size="medium" fontWeight="500">
                  {t(_i === 0 ? 'mix-lotto.label.Big' : 'mix-lotto.label.Odd')}:
                  {_i === 0 ? '5,6,7,8,9' : '1,3,5,7,9'}
                </Text>
                <Text size="medium" fontWeight="500">
                  {t(
                    _i === 0 ? 'mix-lotto.label.Small' : 'mix-lotto.label.Even',
                  )}
                  : {_i === 0 ? '0,1,2,3,4' : '0,2,4,6,8'}
                </Text>
              </View>
              <View
                style={[
                  theme.flex.row,
                  theme.padding.tbs,
                  theme.padding.lrl,
                  theme.flex.between,
                  {backgroundColor: '#E9ECF5'},
                ]}>
                <Text size="medium" fontWeight="500">
                  {t('mix-lotto.label.BETTYPE').toUpperCase()}
                </Text>
                <Text size="medium" fontWeight="500">
                  {t(
                    _i === 0 ? 'mix-lotto.label.Big' : 'mix-lotto.label.Odd',
                  ).toUpperCase()}
                </Text>
                <Text size="medium" fontWeight="500">
                  {t(
                    _i === 0 ? 'mix-lotto.label.Small' : 'mix-lotto.label.Even',
                  ).toUpperCase()}
                </Text>
              </View>
              <View
                style={[
                  theme.flex.row,
                  theme.padding.l,
                  theme.flex.between,
                  theme.background.white,
                  theme.margin.btml,
                ]}>
                <Text size="medium" fontWeight="500">
                  {t('game-page.label.Odds')}
                </Text>
                <Text size="medium" fontWeight="500">
                  {odds}
                </Text>
                <Text size="medium" fontWeight="500">
                  {odds}
                </Text>
              </View>
              <BetRuleDesc
                betType={LotteryMode[+mode]}
                odds={odds}
                betValue={v.betValue}
                result={v.result}
              />
            </View>
          ))
        ) : Number(mode) === LotteryMode.FishPrawnCrab ? (
          <>
            <Text
              fontFamily="fontInterBold"
              size="large"
              color={theme.basicColor.dark}>
              {t('mix-lotto.label.rule.singleTitle')}
            </Text>
            <View style={[theme.margin.topl]}>
              <Text>{t('mix-lotto.desc.rule.FPCTitle')}</Text>
              <Text>{t('mix-lotto.label.Fish')}: 1,2,3</Text>
              <Text>{t('mix-lotto.label.Prawn')}: 4,5,6</Text>
              <Text>{t('mix-lotto.label.Crab')}: 7,8,9</Text>
            </View>
            <View
              style={[
                theme.flex.row,
                theme.padding.tbs,
                theme.padding.lrl,
                theme.flex.between,
                {backgroundColor: '#E9ECF5'},
              ]}>
              <Text size="medium" fontWeight="500">
                {t('mix-lotto.label.BETTYPE').toUpperCase()}
              </Text>
              <Text size="medium" fontWeight="500">
                {t('mix-lotto.label.FISH').toUpperCase()}
              </Text>
              <Text size="medium" fontWeight="500">
                {t('mix-lotto.label.PRAWN').toUpperCase()}
              </Text>
              <Text size="medium" fontWeight="500">
                {t('mix-lotto.label.CRAB').toUpperCase()}
              </Text>
            </View>
            <View
              style={[
                theme.flex.row,
                theme.padding.l,
                theme.flex.between,
                theme.background.white,
                theme.margin.btml,
              ]}>
              <Text size="medium" fontWeight="500">
                {t('game-page.label.Odds')}
              </Text>
              <Text size="medium" fontWeight="500">
                {odds}
              </Text>
              <Text size="medium" fontWeight="500">
                {odds}
              </Text>
              <Text size="medium" fontWeight="500">
                {odds}
              </Text>
            </View>
            {[
              {key: 'Fish', result: '654321', value: '1/2/3'},
              {key: 'Prawn', result: '654324', value: '4/5/6'},
              {key: 'Crab', result: '654327', value: '7/8/9'},
            ].map((v, i) => (
              <View key={v.key}>
                <Text
                  fontFamily="fontInterBold"
                  size="large"
                  color={theme.basicColor.dark}>
                  {i + 1}.{' '}
                  {t('mix-lotto.label.rule.singleSubTitle', {
                    value: t('mix-lotto.label.' + v.key),
                  })}
                </Text>
                <BetRuleDesc
                  betType={LotteryMode[+mode]}
                  odds={odds}
                  betValue={t('mix-lotto.label.' + v.key)}
                  result={v.result}
                />
              </View>
            ))}
          </>
        ) : Number(mode) === LotteryMode['1Digit'] ? (
          <>
            <Text
              fontFamily="fontInterBold"
              size="large"
              color={theme.basicColor.dark}>
              {t('mix-lotto.label.rule.mixTitle', {num: 2})}
            </Text>
            <Text
              fontFamily="fontInterBold"
              size="large"
              color={theme.basicColor.dark}>
              {t('game-page.label.Odds')}: {odds}
            </Text>
            <BetRuleDesc
              odds={odds}
              betType={LotteryMode[+mode]}
              betValue={'1'}
              result="654321"
              resultLen={2}
            />
          </>
        ) : (
          <>
            <Text
              fontFamily="fontInterBold"
              size="large"
              color={theme.basicColor.dark}>
              {t('mix-lotto.label.rule.mixTitle', {
                num: checkType(Number(mode)).isx2
                  ? 2
                  : checkType(Number(mode)).isx3
                  ? 3
                  : 4,
              })}{' '}
              ({t('game-page.label.Odds')}: {odds})
            </Text>
            <Text size="medium" fontWeight="500">
              {t(
                'mix-lotto.desc.rule.' +
                  (checkType(Number(mode)).isD ? 'mixD' : 'mixX'),
                {
                  num: checkType(Number(mode)).isx2
                    ? '12'
                    : checkType(Number(mode)).isx3
                    ? '123'
                    : '1234',
                },
              )}
            </Text>
            <BetRuleDesc
              betType={LotteryMode[+mode]}
              odds={odds}
              betValue={
                checkType(Number(mode)).isx2
                  ? '12'
                  : checkType(Number(mode)).isx3
                  ? '123'
                  : '1234'
              }
              resultLen={
                checkType(Number(mode)).isx2
                  ? 2
                  : checkType(Number(mode)).isx3
                  ? 3
                  : 4
              }
              result={
                checkType(Number(mode)).isD
                  ? checkType(Number(mode)).isx2
                    ? '654312'
                    : checkType(Number(mode)).isx3
                    ? '654123'
                    : '651234'
                  : checkType(Number(mode)).isx2
                  ? '654321'
                  : checkType(Number(mode)).isx3
                  ? '654321'
                  : '654321'
              }
            />
          </>
        )}
      </View>
    </>
  );
};

const BetRuleDesc = ({
  odds,
  betValue,
  result,
  resultLen = 1,
  betType = '11',
}: {
  odds: number;
  betValue: string;
  result: string;
  resultLen?: number;
  betType?: string;
}) => {
  const {t} = useTranslation();
  return (
    <View>
      <Text size="medium" fontWeight="500">
        {t('mix-lotto.label.rule.exapmle')}
      </Text>
      <View>
        <Text size="medium" fontFamily="fontInterBold">
          {t('mix-lotto.label.rule.betOn')}:{' '}
          <Text size="medium" fontWeight="500">
            {t('mix-lotto.desc.rule.betOn', {betValue, betType})}
          </Text>
        </Text>
      </View>
      <View>
        <Text size="medium" fontFamily="fontInterBold">
          {t('game-page.label.drawResult')}:{' '}
          <Text size="medium" fontWeight="500">
            {' '}
            {t('mix-lotto.desc.rule.drawResult', {result})}
          </Text>
        </Text>
      </View>
      <View>
        <Text size="medium" fontFamily="fontInterBold">
          {t('game-page.label.betResult')}:
          <Text size="medium" fontWeight="500">
            {t('mix-lotto.desc.rule.betResult', {
              result: result.slice(-resultLen),
              betValue,
              betType,
            })}
          </Text>
        </Text>
      </View>
      <View>
        <Text size="medium" fontFamily="fontInterBold">
          {t('game-page.label.wager')}:
          <Text size="medium" fontWeight="500">
            100
          </Text>
        </Text>
      </View>
      <View>
        <Text size="medium" fontFamily="fontInterBold">
          {t('game-page.label.payout')}:
          <Text size="medium" fontWeight="500">
            100 * {odds} = {100 * odds}
          </Text>
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  iconSize: {
    width: 14,
    height: 14,
  },
  desc: {
    backgroundColor: '#F5F5F5',
  },
});

export default LotteryRule;
