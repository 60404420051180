import React from 'react';
import PrizeTab from '../component/prize-tab';
import AnalyzeItem from './analyze-item';
import {ModeDataTabItem} from '../mix-lottery-service';
import {StyleSheet} from 'react-native';
const LotteryAnalyze = ({
  analyze,
  tabs,
}: {
  analyze: string[][];
  tabs: ModeDataTabItem[];
}) => {
  const tabOptions = React.useMemo(() => {
    if (!tabs || !tabs.length) {
      return [];
    }
    return tabs
      .reduce(
        (a, b) =>
          a.concat(...['A', 'B', 'C', 'D'].map((v, _i) => `${v}-${b.name}`)),
        [] as string[],
      )
      .map((item, _i) => ({
        name: item,
        id: _i + 1,
      }));
  }, [tabs]);

  const [currentIndex, setCurrentIndex] = React.useState(1);

  return (
    <>
      <PrizeTab
        style={styles.tabStyle}
        tabs={tabOptions}
        selectPrize={currentIndex}
        onChange={setCurrentIndex}
      />
      <AnalyzeItem
        analyze={analyze}
        tabs={tabs}
        selectIndex={currentIndex}
        tabOptions={tabOptions}
      />
    </>
  );
};

const styles = StyleSheet.create({
  tabStyle: {
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderColor: '#DDDDDD',
  },
});

export default React.memo(LotteryAnalyze);
