import {
  LotteryMode,
  LotteryModeData,
  LotteryModeName,
} from './mix-lottery-service';

export const defaultDetial: LotteryModeData = {
  modeName: 'Two Side',
  modeID: LotteryMode['Two Side'],
  tabs: [
    {id: 1, name: '1st Prize'},
    {id: 2, name: '2nd Prize'},
  ],
  itemData: new Array(4).fill('').map((v, i) => ({
    itemID: i + 1,
    odds: 1.98,
    max: 300,
    min: 100,
    def: 200,
  })),
};

export const DefaultTypeTab = [
  {id: 1, name: '1st Prize'},
  {id: 2, name: '2nd Prize'},
];

export const DefaultTab = [
  'Two Side',
  '4D',
  '3D',
  '2D',
  '4X',
  '3X',
  '2X',
  '1 Digit',
  'FishPrawnCrab',
].map(modeName => ({
  modeName,
  modeID: LotteryMode[modeName as LotteryModeName],
}));
