/* eslint-disable react-native/no-inline-styles */
import {View, StyleSheet} from 'react-native';
import TimesItem from '@/components/business/games/times-list/times-item';
import React from 'react';
import {PickGamesContent} from './digit-service';
import theme from '@/style';

const DigitQuickSelect = ({
  list = [],
  onChangeTime = () => {},
  activeIndex = 0,
}: {
  list?: PickGamesContent[];
  onChangeTime?: (i: number) => void;
  activeIndex?: number;
}) => {
  return (
    <View
      style={[
        theme.flex.row,
        theme.padding.tops,
        theme.padding.lrl,
        {gap: theme.paddingSize.s},
      ]}>
      {list.map((item, index) => (
        <TimesItem
          label={item.timeName}
          key={index}
          onTimePress={() => {
            onChangeTime(index);
          }}
          active={index === activeIndex}
          itemStyle={[styles.noBorder, theme.flex.row, {height: 40, gap: 4}]}
          innerStyle={styles.noBorder}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  noBorder: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
});

export default React.memo(DigitQuickSelect);
