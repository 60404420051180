/* eslint-disable @typescript-eslint/no-unused-vars */
import TabHeader from '@/components/business/games/tab-header';
import React from 'react';

const DigitTab = ({
  type,
  pickThreeId = 0,
  refreshCode = 0,
  setIndex = () => {},
  index = 0,
  orderNum,
}: {
  type?: string;
  pickThreeId?: number;
  refreshCode?: number;
  index?: number;
  orderNum?: number;
  setIndex?: (v: number) => void;
}) => {
  const tablist = [
    {
      title: 'result',
      key: 'result',
    },
    {
      title: 'order',
      key: 'order',
    },
  ];

  return (
    <TabHeader
      orderNum={orderNum}
      orderKey="order"
      routes={tablist}
      onTabPress={setIndex}
      index={index || 0}
      itemEqual
    />
  );
};

export default React.memo(DigitTab);
