import baseVariable from './base.variable';
import * as baseStyle from '@components/style/base.style';
import * as paddingStyle from '@components/style/padding.style';
import useCustomerTheme from './customer.theme';

const theme = {
  ...baseVariable,
  ...baseStyle,
  ...paddingStyle,
};

export {useCustomerTheme};

export default theme;
