import React from 'react';
import TabHeader from '@/components/business/games/tab-header';

const tablist = [
  {
    title: 'result',
    key: 'result',
  },
  {
    title: 'winners',
    key: 'winners',
  },
  {
    title: 'order',
    key: 'order',
  },
];

const SattaTab = ({
  activeKey = 0,
  onTabChange,
  orderNum,
}: {
  activeKey: number;
  orderNum?: number;
  onTabChange: (i: number) => void;
}) => {
  return (
    <TabHeader
      orderNum={orderNum}
      orderKey="order"
      routes={tablist}
      onTabPress={onTabChange}
      index={activeKey}
      itemEqual
    />
  );
};

export default React.memo(SattaTab);
