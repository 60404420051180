import React from 'react';
import ResultTab from '../components/result-tab/result-tab';
// import {useTranslation} from 'react-i18next';
import {RaceCarGroupItem, RaceCarRecord} from '../result.type';
import {getRacecarGroupList, getRacecarHistory} from '../result-service';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import {ScrollView, View} from 'react-native';
import CarItem from './result-item';
import Page from '@/components/business/games/page';
import Text from '@/components/basic/text';
import RacecarDraw from './draw';
const pageSize = 10;

const Racecar = ({isActive}: {isActive?: boolean}) => {
  const [loading, setLoading] = React.useState(true);
  const [groupList, setGroupList] = React.useState<RaceCarGroupItem[]>([]);
  const [cycleTabKey, setCycleTabKey] = React.useState<string>('');
  const [list, setList] = React.useState<RaceCarRecord[]>([]);
  const [total, setTotal] = React.useState(1);
  const [initPage, setInitPage] = React.useState(1);
  React.useEffect(() => {
    if (isActive) {
      setLoading(true);
      getRacecarGroupList()
        .then(group => {
          setGroupList(group);
          setCycleTabKey(group[0].groupName);
        })
        .finally(() => setLoading(false));
    }
  }, [isActive]);
  const getHistory = async (pageNo = 1, groupName = '') => {
    try {
      setLoading(true);
      const res = await getRacecarHistory({
        pageNo,
        groupName,
        size: pageSize,
      });
      if (res) {
        setList(res.records || []);
        setTotal(res.totalPage * pageSize);
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (cycleTabKey && isActive) {
      setInitPage(1);
      getHistory(1, cycleTabKey);
    }
  }, [cycleTabKey, isActive]);

  const drawLabel = React.useMemo(() => {
    const res = groupList.find(item => item.groupName === cycleTabKey);
    if (res) {
      return `${res.text.split('min')[0]}Minutes`;
    }
    return '';
  }, [cycleTabKey, groupList]);

  return (
    <Spin loading={loading} style={[theme.flex.flex1]}>
      <ScrollView stickyHeaderIndices={[1]}>
        {isActive && !loading ? (
          <RacecarDraw cycleTabKey={cycleTabKey} drawLabel={drawLabel} />
        ) : (
          <View />
        )}
        <ResultTab
          selectKey={cycleTabKey}
          onSelectKey={setCycleTabKey}
          tabs={groupList.map(item => {
            return {
              label: item.text.split('min')[0],
              key: item.groupName,
            };
          })}
        />
        <View style={[theme.padding.lrl]}>
          <View
            style={[
              theme.flex.row,
              theme.flex.between,
              theme.padding.lrl,
              theme.padding.tbs,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                backgroundColor: '#E9ECF5',
              },
            ]}>
            <View>
              <Text>Bet ID</Text>
            </View>
            <View>
              <Text>Result</Text>
            </View>
          </View>
          {list.map((item, _i) => (
            <CarItem
              key={_i}
              cars={item.cars}
              roundNum={item.roundNum}
              index={_i}
            />
          ))}
        </View>
        {total > 0 && (
          <Page
            page={initPage}
            total={total}
            pageSize={pageSize}
            onPageChange={p => {
              if (cycleTabKey) {
                setInitPage(p);
                getHistory(p, cycleTabKey);
              }
            }}
          />
        )}
      </ScrollView>
    </Spin>
  );
};

export default Racecar;
