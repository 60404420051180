import LinearGradient from '@/components/basic/linear-gradient';
import React from 'react';
import SattaItemButton from './components/satta-item-button';
import theme from '@/style';
import {SattaConfig} from './satta-service';
import {View} from 'react-native';

const SattaBetSelect = ({
  selectBetsKey = 0,
  betsTabList = [],
  onItemPress,
}: {
  selectBetsKey: number;
  betsTabList?: SattaConfig[];
  onItemPress?: (i: number) => void;
}) => {
  const firstRowConfig = React.useMemo(() => {
    return betsTabList.filter(conf => conf.row === 1);
  }, [betsTabList]);

  const secondRowConfig = React.useMemo(() => {
    return betsTabList.filter(conf => conf.row === 2);
  }, [betsTabList]);

  return (
    <LinearGradient colors={['#CFDAE0', '#E7EFF4']} style={[theme.padding.l]}>
      <View style={[theme.flex.row, {gap: theme.paddingSize.s}]}>
        {firstRowConfig.map((item, _i) => (
          <SattaItemButton
            onPress={() => {
              if (item.key !== undefined) {
                onItemPress?.(item.key);
              }
            }}
            style={[theme.flex.flex1]}
            key={_i}
            title={item.label}
            isSelected={selectBetsKey === item.key}
          />
        ))}
      </View>
      {secondRowConfig.length > 0 && (
        <View
          style={[
            theme.flex.row,
            {gap: theme.paddingSize.s},
            theme.margin.tops,
          ]}>
          {secondRowConfig.map((item, _i) => (
            <SattaItemButton
              onPress={() => {
                if (item.key !== undefined) {
                  onItemPress?.(item.key);
                }
              }}
              key={_i}
              style={
                // eslint-disable-next-line react-native/no-inline-styles
                {minWidth: 110}
              }
              title={item.label}
              isSelected={selectBetsKey === item.key}
            />
          ))}
        </View>
      )}
    </LinearGradient>
  );
};

export default SattaBetSelect;
