import {View, StyleSheet, Image} from 'react-native';
import Text from '@/components/basic/text';
import Ball from '../../component/ball';
import React from 'react';
import theme from '@/style';
import DiceItem from '../../component/dice-item';
import {useTranslation} from 'react-i18next';

export interface DrawResultProps {
  issNo?: string;
  totalCount?: number;
  result?: string;
  status: 0 | 1 | 2;
  bigOrSmall?: number;
  oddOrEven?: number;
}

const DrawResult = ({
  issNo,
  totalCount,
  status,
  bigOrSmall = 0,
  oddOrEven = 0,
  result = '',
}: DrawResultProps) => {
  const {i18n} = useTranslation();
  const leftNode = React.useMemo(() => {
    if (status === 2) {
      return (
        <View
          style={[
            theme.flex.row,
            theme.fill.fill,
            theme.flex.centerByCol,
            theme.flex.around,
            theme.padding.lrs,
          ]}>
          {['?', '?', '?'].map((item, _i) => (
            <Text
              fontSize={20}
              key={_i}
              fontFamily="fontInterBold"
              color={theme.basicColor.dark}>
              {item}
            </Text>
          ))}
        </View>
      );
    } else {
      const resultArr = result.split(',');
      return (
        <View
          style={[
            theme.flex.row,
            theme.fill.fill,
            theme.flex.centerByCol,
            theme.flex.around,
            theme.padding.lrs,
          ]}>
          {resultArr.map((item, _i) => (
            <DiceItem diceSize={32} key={_i} diceNum={Number(item)} />
          ))}
        </View>
      );
    }
  }, [status, result]);

  return (
    <View>
      <Text size="medium" fontFamily="fontInterBold">
        {issNo} {i18n.t('game-page.label.drawResult')}:
      </Text>
      <View style={[theme.flex.row, theme.flex.between, theme.margin.topl]}>
        <View>
          <Text fontSize={9} second fontFamily="fontInterBold">
            {i18n.t('game-page.label.result')}
          </Text>
          <View style={styles.left}>
            <Image
              resizeMode="stretch"
              style={[theme.position.abs, theme.fill.fill]}
              source={require('@components/assets/imgs/game/dice/result-bg.webp')}
            />
            {leftNode}
          </View>
        </View>
        <View style={styles.line} />
        <View style={[theme.flex.center]}>
          <Text fontSize={9} second fontFamily="fontInterBold">
            {i18n.t('game-page.label.totalPoint')}
          </Text>
          <View style={styles.center}>
            <Image
              resizeMode="stretch"
              style={[theme.position.abs, theme.fill.fill]}
              source={require('@components/assets/imgs/game/dice/result-bg.webp')}
            />
            {status === 2 ? (
              <Text
                fontSize={20}
                fontFamily="fontInterBold"
                color={theme.basicColor.dark}>
                ?
              </Text>
            ) : (
              <Ball ballSize={32} num={totalCount} isNum fontSize={18} />
            )}
          </View>
        </View>
        <View style={styles.line} />
        <View style={[theme.flex.center]}>
          <Text fontSize={9} second fontFamily="fontInterBold">
            {i18n.t('game-page.label.draw')}
          </Text>
          {status === 2 ? (
            <View style={[styles.right, theme.flex.around]}>
              <Image
                resizeMode="stretch"
                style={[theme.position.abs, theme.fill.fill, styles.bg]}
                source={require('@components/assets/imgs/game/dice/result-bg.webp')}
              />
              <Text
                fontSize={20}
                fontFamily="fontInterBold"
                color={theme.basicColor.dark}>
                ?
              </Text>
              <Text
                fontSize={20}
                fontFamily="fontInterBold"
                color={theme.basicColor.dark}>
                ?
              </Text>
            </View>
          ) : (
            <View style={styles.right}>
              <Image
                resizeMode="stretch"
                style={[theme.position.abs, theme.fill.fill, styles.bg]}
                source={require('@components/assets/imgs/game/dice/result-bg.webp')}
              />
              {bigOrSmall > 0 && (
                <Ball
                  ballSize={32}
                  type={bigOrSmall === 2 ? 'small' : 'big'}
                  isNum={false}
                />
              )}
              {oddOrEven > 0 && (
                <Ball
                  ballSize={32}
                  type={oddOrEven === 1 ? 'odd' : 'even'}
                  isNum={false}
                />
              )}
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  left: {
    height: 48,
    width: 128,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    marginTop: 4,
  },
  center: {
    height: 48,
    width: 48,
    overflow: 'hidden',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 4,
  },
  line: {
    width: 1,
    backgroundColor: '#D8E0EA',
  },
  right: {
    height: 48,
    width: 88,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4,
    paddingHorizontal: 8,
  },
  bg: {
    width: 88,
    left: 0,
  },
});

export default DrawResult;
