import {FadeInView} from '@/components/basic/animations';
import React, {useState} from 'react';
import HomeLotteryDice from './lottery-dice';
import HomeLotteryCar from './lottery-car';
import HomeLotteryDigit from './lottery-digit';
import HomeLotteryKerala from './lottery-kerala';
import HomeLotteryMix from './lottery-mix';
import HomeLotteryColor from './lottery-color';
import HomeLotterySatta from './lottery-satta';
import homeStore from '../../home.refresh.service';
import {CarListItem} from '../../home.type';
import {LotteryData, getCarGameList, getHomeGameList} from '../../home.service';

const HomeLottery = () => {
  const [games, setGames] = useState<LotteryData>();
  const [carList, setCarList] = useState<CarListItem[]>(Array(0));
  // const [current, setCurrent] = useState(0);
  // const timer = React.useRef<NodeJS.Timeout>();
  // const currentTime = React.useRef(0);

  // React.useEffect(() => {
  //   startTime();
  //   return () => timer.current && clearInterval(timer.current);
  // }, []);

  // const startTime = () => {
  //   timer.current = setInterval(() => {
  //     currentTime.current += 1;
  //     setCurrent(currentTime.current);
  //   }, 1000);
  // };

  const getCarList = () => {
    getCarGameList()
      .then(res => {
        if (res) {
          setCarList(res.content);
        }
      })
      .finally(() => {
        homeStore.refreshEnd.next();
      });
  };
  const getGameList = () => {
    getHomeGameList()
      .then(res => {
        if (res) {
          setGames(res);
        }
      })
      .finally(() => {
        homeStore.refreshEnd.next();
      });
  };
  React.useEffect(() => {
    getCarList();
    getGameList();
    const sub = homeStore.refresh.subscribe(() => {
      getCarList();
      getGameList();
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return (
    <FadeInView
      style={[
        // eslint-disable-next-line react-native/no-inline-styles
        {
          height: 'auto',
        },
      ]}>
      {games ? (
        <>
          <HomeLotteryDice
            serverTime={games.currentTimestamp}
            list={games.diceResList}
          />
          <HomeLotteryColor
            serverTime={games.currentTimestamp}
            list={games.redResList}
          />
          <HomeLotteryDigit
            serverTime={games.currentTimestamp}
            list={games.pickResList}
          />
          <HomeLotteryMix
            serverTime={games.currentTimestamp}
            list={games.stateLotteryList}
          />

          {carList.length > 0 && (
            <HomeLotteryCar
              list={carList}
              serverTime={games.currentTimestamp}
            />
          )}
          <HomeLotteryKerala
            serverTime={games.currentTimestamp}
            list={games.keralaResList}
          />
          <HomeLotterySatta
            serverTime={games.currentTimestamp}
            list={games.matkaResList}
          />
        </>
      ) : (
        <></>
      )}
    </FadeInView>
  );
};

export default HomeLottery;
