import {View, StyleSheet, ScrollView} from 'react-native';

import LinearGradient from '@/components/basic/linear-gradient';
import React from 'react';
import Text from '@/components/basic/text';
import theme from '@/style';
import {Image} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import BetsListItem from './bets-list-item';
interface betsInfoType {
  type: string;
  code: string;
  digit: number;
  digitType: string;
}
const MyBets = ({betsList = []}: {betsList?: betsInfoType[]}) => {
  return (
    <View style={[styles.container]}>
      <Shadow
        style={[theme.fill.fillW]}
        distance={2}
        startColor={'rgba(255, 255, 255, 0.25)'}
        offset={[-1, 2]}>
        <LinearGradient colors={['#4B5961', '#80929C']} style={[styles.inner]}>
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.flex.between,
              theme.padding.lrl,
              theme.margin.tops,
            ]}>
            <Image
              style={styles.dot}
              resizeMode="stretch"
              source={require('@components/assets/imgs/game/dice/bets-dot.webp')}
            />
            <Text
              textAlign="center"
              size="medium"
              fontFamily="fontDinBold"
              color={'#A8C3D2'}>
              MY BETS
            </Text>
            <Image
              resizeMode="stretch"
              style={styles.dot}
              source={require('@components/assets/imgs/game/dice/bets-dot.webp')}
            />
          </View>
          <View style={styles.bottom}>
            <Image
              style={[theme.fill.fill]}
              resizeMode="stretch"
              source={require('@components/assets/imgs/game/dice/bets-bottom.webp')}
            />
          </View>
          <View
            style={[theme.padding.lrl, theme.padding.tops, theme.flex.flex1]}>
            <ScrollView
              showsHorizontalScrollIndicator={false}
              horizontal
              // eslint-disable-next-line react-native/no-inline-styles
              contentContainerStyle={[{columnGap: 8}]}>
              {betsList.map((item, _i) => (
                <BetsListItem key={_i} {...item} />
              ))}
            </ScrollView>
          </View>
        </LinearGradient>
      </Shadow>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
    paddingHorizontal: 12,
  },
  inner: {
    height: 65,
    borderRadius: 8,
  },
  dot: {
    width: 107,
    height: 8,
  },
  bottom: {
    position: 'absolute',
    bottom: 0,
    left: 6,
    height: 20,
    right: 6,
  },
});

export default React.memo(MyBets);
