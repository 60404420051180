import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import SattaPanel from './satta-panel';

const DrawResult = ({
  pannel,
  drawDate,
}: {
  pannel: string;
  drawDate?: string;
}) => {
  const {i18n} = useTranslation();

  return (
    <View style={[theme.flex.centerByCol]}>
      <View style={[theme.flex.row, theme.flex.centerByCol, theme.margin.btms]}>
        <Text accent size="medium" style={[theme.font.medium]}>
          {drawDate}
        </Text>
        <Text
          second
          fontFamily="fontInterBold"
          size="medium"
          style={[theme.margin.lefts]}>
          {i18n.t('game-page.label.drawResult')}:
        </Text>
      </View>
      <SattaPanel pannel={pannel} />
    </View>
  );
};

export default DrawResult;
