import Spin from '@/components/basic/spin';
import theme from '@/style';
import ListItem from './list-item';
import React from 'react';
import {FlatList} from 'react-native';
import {getKeralaTicketList} from '../result-service';
import {KeralaTicket} from '../result.type';
import useInfiniteScroll from '@/common-pages/hooks/load-more.hooks';

export interface KeralaResultProps {
  lotteryType?: string;
  isActive?: boolean;
}

const pageSize = 10;

const KeralaResult = ({lotteryType = '', isActive}: KeralaResultProps) => {
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState<KeralaTicket[]>([]);
  const {onEndReachedCalledDuringMomentum} = useInfiniteScroll('result-kerala');
  const currentPage = React.useRef(1);
  const hasMores = React.useRef(true);

  const onLoadMore = async () => {
    if (hasMores.current && !onEndReachedCalledDuringMomentum.current) {
      currentPage.current += 1;
      await getList(currentPage.current, true);
    }
  };

  const getList = async (page = 1, loadMore = false) => {
    try {
      if (!loadMore) {
        setLoading(true);
      }
      const res = await getKeralaTicketList({
        pageNo: page,
        pageSize,
        lotteryType: lotteryType === 'ALL' ? '' : lotteryType,
      });
      if (res && res.length < pageSize) {
        hasMores.current = false;
      }
      setList(loadMore ? list?.concat(res) : res);
    } finally {
      setLoading(false);
      onEndReachedCalledDuringMomentum.current = true;
    }
  };

  React.useEffect(() => {
    if (isActive) {
      currentPage.current = 1;
      getList(currentPage.current);
      hasMores.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryType, isActive]);

  return (
    <Spin style={[theme.flex.flex1]} loading={loading}>
      <FlatList
        id="result-kerala"
        scrollEventThrottle={16}
        onEndReachedThreshold={0.1}
        onMomentumScrollBegin={() => {
          onEndReachedCalledDuringMomentum.current = false;
        }}
        onEndReached={onLoadMore}
        contentContainerStyle={[
          theme.padding.l,
          {gap: theme.paddingSize.l, paddingBottom: theme.paddingSize.l * 2},
        ]}
        keyExtractor={item => item.issueNo}
        data={list}
        renderItem={({item}) => <ListItem {...item} />}
      />
    </Spin>
  );
};

export default KeralaResult;
