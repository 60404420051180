import theme from '@/style';
import React from 'react';
import {FlatList, ListRenderItemInfo, View} from 'react-native';
import {useInnerStyle} from './vip.hooks';
import Text from '@/components/basic/text';
import {VipRenderType} from '@/components/business/vip';
import LazyImage from '@basicComponents/image';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {
  currentPointIcon,
  firstPointIcon,
  luckyspinIcon,
  moneyIcon,
  nextPointIcon,
  reachPointIcon,
  unreachPointIcon,
} from './vip.variable';
import {toPriceStr} from '@/utils';
import {IVipConfigItem, IVipItem} from '@/services/global.service';
import LinearGradient from '@basicComponents/linear-gradient';
import {useTranslation} from 'react-i18next';

export interface VipTableListProps {
  cards: VipRenderType[];
  vipList: IVipItem[];
  vipConfigList: IVipConfigItem[];
  onCheck?: (index: number) => void;
  checkIndex?: number;
  currentLevel: number;
}

const VipTableList: React.FC<VipTableListProps> = ({
  cards,
  vipList,
  onCheck,
  checkIndex,
  vipConfigList,
  currentLevel,
}) => {
  const {tableStyle} = useInnerStyle();
  const {i18n} = useTranslation();
  const renderVipTableItem = ({item, index}: ListRenderItemInfo<IVipItem>) => {
    return (
      <NativeTouchableOpacity onPress={() => onCheck?.(index)}>
        <View style={[tableStyle.item, theme.flex.row]}>
          <View
            style={[
              tableStyle.points,
              theme.flex.row,
              theme.flex.start,
              theme.flex.centerByCol,
            ]}>
            <LazyImage
              width={18}
              height={18}
              radius={9}
              occupancy="#0000"
              imageUrl={
                item.level === 0 && item.level < currentLevel
                  ? firstPointIcon
                  : item.level < currentLevel
                  ? reachPointIcon
                  : item.level === currentLevel
                  ? currentPointIcon
                  : item.level === currentLevel + 1
                  ? nextPointIcon
                  : unreachPointIcon
              }
            />
          </View>
          <View
            style={[
              theme.flex.center,
              tableStyle.level,
              tableStyle.itemLevel,
              index === 0 ? tableStyle.itemLevelFirst : null,
              index === vipList.length - 1 ? tableStyle.itemLevelLast : null,
              index === checkIndex ? tableStyle.itemLevelLight : null,
            ]}>
            {cards[index].smallFn(25)}
          </View>
          <View
            style={[
              theme.flex.center,
              theme.flex.flex1,
              tableStyle.itemReward,
              theme.padding.lrm,
              index === checkIndex ? tableStyle.itemRewardLight : null,
            ]}>
            <View
              style={[
                theme.flex.between,
                theme.fill.fillW,
                theme.padding.lrm,
                theme.flex.row,
              ]}>
              <View
                style={[
                  theme.flex.col,
                  theme.flex.centerByCol,
                  tableStyle.itemRewardItem,
                ]}>
                <View style={[theme.flex.row, theme.flex.centerByCol]}>
                  <LazyImage
                    imageUrl={moneyIcon}
                    occupancy="#0000"
                    width={theme.iconSize.l}
                    height={theme.iconSize.l}
                  />
                  <Text
                    style={[theme.margin.leftxxs]}
                    fontSize={theme.fontSize.s}
                    blod
                    accent>
                    {i18n.t('vip.table.bouns')}
                  </Text>
                </View>
                <Text blod fontSize={theme.fontSize.l}>
                  {toPriceStr(vipConfigList[index]?.amount || 0, {
                    fixed: 0,
                    thousands: true,
                  })}
                </Text>
              </View>
              <View
                style={[
                  theme.flex.col,
                  theme.flex.centerByCol,
                  tableStyle.itemRewardItem,
                ]}>
                <View style={[theme.flex.row, theme.flex.centerByCol]}>
                  <LazyImage
                    imageUrl={luckyspinIcon}
                    occupancy="#0000"
                    width={theme.iconSize.l}
                    height={theme.iconSize.l}
                  />
                  <Text
                    style={[theme.margin.leftxxs]}
                    fontSize={theme.fontSize.s}
                    blod
                    accent>
                    {i18n.t('vip.table.spin')}
                  </Text>
                </View>
                <Text blod fontSize={theme.fontSize.l}>
                  ×{vipConfigList[index]?.spin}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </NativeTouchableOpacity>
    );
  };

  return (
    <View style={[theme.flex.flex1, theme.margin.topl, theme.padding.lrl]}>
      <LinearGradient
        start={{x: 0, y: 0}}
        end={{x: 0, y: 1}}
        colors={[theme.basicColor.white, theme.backgroundColor.lightGrey]}
        style={[tableStyle.header, theme.flex.row]}>
        <View style={[tableStyle.points]} />
        <View style={[theme.flex.center, tableStyle.level]}>
          <Text second fontSize={theme.fontSize.m}>
            {i18n.t('vip.table.level')}
          </Text>
        </View>
        <View style={[theme.flex.center, theme.flex.flex1]}>
          <Text second fontSize={theme.fontSize.m}>
            {i18n.t('vip.table.reward')}
          </Text>
        </View>
      </LinearGradient>
      <FlatList
        data={vipList}
        renderItem={renderVipTableItem}
        ItemSeparatorComponent={ItemSeperator}
      />
    </View>
  );
};

const ItemSeperator = () => {
  const {tableSeperatorStyle} = useInnerStyle();

  return (
    <View style={[tableSeperatorStyle.container, theme.fill.fillW]}>
      <View style={[tableSeperatorStyle.line]} />
    </View>
  );
};

export default VipTableList;
