import OrderCard from '@/components/business/order/order-card';
import theme from '@/style';
import React from 'react';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import NoData from '@/components/basic/error-pages/no-data';
import Spin from '@/components/basic/spin';
import Clipboard from '@react-native-clipboard/clipboard';
import globalStore from '@/services/global.state';
import {goTo} from '@/utils';
import {View} from 'react-native';
import PageNumber from '@/components/business/games/page';
import DrawResult from '../../components/draw-result';
import CodeList from './code-list';
import {SattaOrderItem, getOrderListSatta} from '../../satta-service';

const pageSize = 3;

function getResultNum(
  openResultNum?: string | null,
  closeResultNum?: string | null,
) {
  let openNum, closeNum;
  if (openResultNum) {
    openNum = openResultNum.split('').reduce((s, y) => (s + +y) % 10, 0);
  } else {
    openNum = '*';
  }
  if (closeResultNum) {
    closeNum = closeResultNum.split('').reduce((s, y) => (s + +y) % 10, 0);
  } else {
    closeNum = '*';
  }
  return {openNum, closeNum};
}

const OrderList = ({refreshCode = 0}: {refreshCode?: number}) => {
  const [list, setList] = React.useState<SattaOrderItem[]>([]);
  const [total, setTotal] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const {i18n} = useTranslation();
  const [initPage, setInitPage] = React.useState(1);

  React.useEffect(() => {
    if (!globalStore.token) {
      goTo('Login');
      setFirstLoad(false);
      return;
    }
    getList();
  }, []);

  const getList = async (page: number = 1) => {
    try {
      setLoading(true);
      const res = await getOrderListSatta(page, pageSize, 3);
      if (res.content) {
        setList(res.content);
        setTotal(res.totalSize);
      }
    } finally {
      setFirstLoad(false);
      setLoading(false);
    }
  };

  const onCopy = (id: string) => {
    Clipboard.setString(id);
    globalStore.globalSucessTotal(i18n.t('share.copy-success'));
  };

  React.useEffect(() => {
    if (refreshCode && !firstLoad) {
      if (!globalStore.token) {
        return;
      }
      getList();
      setInitPage(1);
    }
  }, [refreshCode, firstLoad]);

  const pannelDigits = (data: SattaOrderItem) => {
    return `${data!.matkaResultVo!.openNum || '***'}-${
      getResultNum(data!.matkaResultVo!.openNum, data!.matkaResultVo!.closeNum)
        .openNum
    }${
      getResultNum(data!.matkaResultVo!.openNum, data!.matkaResultVo!.closeNum)
        .closeNum
    }-${data!.matkaResultVo!.closeNum || '***'}`;
  };

  return (
    <Spin
      loading={firstLoad || loading}
      style={[
        // eslint-disable-next-line react-native/no-inline-styles
        {minHeight: 300},
        theme.flex.flex1,
        total <= 3 && theme.padding.btml,
      ]}>
      {!firstLoad && !loading && list && list.length === 0 ? <NoData /> : null}
      {list.map((item, _i) => (
        <View key={_i}>
          <OrderCard
            id={item.orderGroup}
            gameName={item.gameName}
            payment={item.totalAmount}
            awardAmount={item.totalAmount}
            hideDraw={false}
            drawTime={item.drawDate}
            betsTime={dayjs(item.createTime).format('DD-MM-YYYY hh:mm:ss A')}
            onCopy={() => onCopy(item.orderGroup)}
            result={
              <DrawResult
                pannel={pannelDigits(item)}
                drawDate={item.drawDate}
              />
            }
            status={item.openStatus === 1 ? 2 : item.wonAmount > 0 ? 1 : 0}
          />
          <CodeList list={item.userDigits} isOpen={item.openStatus !== 1} />
        </View>
      ))}
      {total > pageSize ? (
        <PageNumber
          total={total}
          page={initPage}
          pageSize={pageSize}
          onPageChange={p => {
            setInitPage(p);
            getList(p).then();
          }}
        />
      ) : null}
    </Spin>
  );
};

export default OrderList;
