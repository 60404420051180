import React, {useEffect, useRef} from 'react';
import {VideoProps} from '.';

const Video = ({onLoad, onEnd, src, paused}: VideoProps) => {
  const videoRef = useRef<HTMLAudioElement>(null);
  useEffect(() => {
    if (paused) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
  }, [paused]);
  return (
    <audio
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        height: 0,
        width: 0,
      }}
      autoPlay={false}
      ref={videoRef}
      onLoad={onLoad}
      onEnded={onEnd}
      src={src}
    />
  );
};

export default Video;
