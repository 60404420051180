import {View, StyleSheet, Image} from 'react-native';
import Text from '@/components/basic/text';
import {useTranslation} from 'react-i18next';
import React from 'react';
import theme from '@/style';
import {toPriceStr} from '@/utils';
const rightIcon = require('@components/assets/icons/me-list-item/right-icon.webp');

export interface ScratchResultProps {
  orderList: {
    cardId: string;
    status: number;
    winAmount: number;
  }[];
}

const ScratchResult = ({orderList = []}: ScratchResultProps) => {
  const {i18n} = useTranslation();

  return (
    <View style={[styles.container]}>
      <View>
        <Text accent fontWeight="500" textAlign="center">
          {i18n.t('game-page.label.myBets')}
        </Text>
      </View>
      <View style={styles.line} />
      <View>
        {orderList.slice(0, 4).map((item, _i) => {
          return (
            <View
              key={_i}
              style={[
                theme.flex.row,
                theme.flex.between,
                theme.flex.centerByCol,
                {gap: theme.paddingSize.l},
              ]}>
              <Text accent fontFamily="fontInterBold">
                {i18n.t('label.id')}:{item.cardId.toUpperCase()}
              </Text>

              {item.status === 2 ? (
                <Text
                  color={theme.basicColor.dark}
                  size="medium"
                  fontFamily="fontInterBold">
                  {i18n.t('game-page.label.unused')}
                </Text>
              ) : (
                <View
                  style={[
                    theme.flex.row,
                    theme.flex.centerByCol,
                    {gap: theme.paddingSize.xxs},
                  ]}>
                  <Text fontWeight="500">{i18n.t('label.won')}</Text>
                  <Text fontFamily="fontDinBold" size="medium">
                    {toPriceStr(item.winAmount)}
                  </Text>
                </View>
              )}
            </View>
          );
        })}
      </View>
      {orderList.length > 4 && (
        <View
          style={[
            theme.margin.topl,
            theme.flex.row,
            theme.flex.between,
            theme.flex.centerByCol,
            {height: theme.paddingSize.xxl * 2},
          ]}>
          <Text fontWeight="500">View All</Text>
          <Image
            source={rightIcon}
            style={
              // eslint-disable-next-line react-native/no-inline-styles
              [{width: 14, height: 14}]
            }
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F8F9FF',
    padding: 12,
    marginTop: 12,
  },
  line: {
    height: 1,
    backgroundColor: '#D8E0EA',
    marginVertical: 12,
  },
});

export default ScratchResult;
