import theme from '@/style';
import DrawStatus from './draw-status';
import {BasicObject} from '@/types';

const DATA_MAP = {
  1: {
    text: 'won',
    bg: theme.backgroundColor.wonLinearGradient,
    color: theme.backgroundColor.orange,
  },
  0: {
    text: 'noWin',
    bg: theme.backgroundColor.noWinLinearGradient,
    color: theme.fontColor.accent,
  },
  2: {
    text: 'toBe',
    bg: theme.backgroundColor.toDrawnLinearGradient,
    color: theme.backgroundColor.blue,
  },
  3: {
    text: 'settling',
    bg: theme.backgroundColor.wonLinearGradient,
    color: theme.backgroundColor.orange,
  },
  4: {
    text: 'settled',
    bg: theme.backgroundColor.wonLinearGradient,
    color: theme.backgroundColor.orange,
  },
} as BasicObject;

const BETS_DATA_MAP = {
  1: {
    text: 'drawn',
    bg: theme.backgroundColor.wonLinearGradient,
    color: theme.fontColor.second,
  },
  0: {
    text: 'toBe',
    bg: theme.backgroundColor.toDrawnLinearGradient,
    color: theme.backgroundColor.blue,
  },
  3: {
    text: 'settling',
    bg: theme.backgroundColor.wonLinearGradient,
    color: theme.backgroundColor.orange,
  },
  4: {
    text: 'settled',
    bg: theme.backgroundColor.wonLinearGradient,
    color: theme.backgroundColor.orange,
  },
} as BasicObject;

export {DrawStatus, DATA_MAP, BETS_DATA_MAP};
