import {Animated, View} from 'react-native';
import {SINGLE, SUF_TYPE, SUM_POINTS} from '../constant';
import React from 'react';
import DiceItem from './dice-item';
import Ball from './ball';
import theme from '@/style';

interface PrevResultItemProps {
  type: 'DICE' | 'SUM' | 'TYPE';
  result?: string | number;
}

const PrevResultItem = ({type = 'DICE', result}: PrevResultItemProps) => {
  const transAnim = React.useRef(new Animated.Value(40)).current;

  React.useEffect(() => {
    if (result) {
      Animated.sequence([reset(), startAnim(result)]).start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const startAnim = (num: string | number | null) => {
    let toValue = 40;
    if (type === 'DICE' && num) {
      toValue = (-num + 1) * 40;
    } else if (type === 'SUM' && num) {
      toValue = (-num + 3) * 40;
    } else {
      const index = SUF_TYPE.findIndex(item => item === num);
      toValue = index >= 0 ? index * -40 : 40;
    }
    return Animated.timing(transAnim, {
      toValue,
      duration: 1000,
      // easing: Easing.l,
      useNativeDriver: true,
    });
  };

  const reset = () => {
    return Animated.timing(transAnim, {
      toValue: 40,
      duration: 0,
      useNativeDriver: true,
    });
  };

  return (
    <Animated.View
      style={[
        {
          transform: [
            {
              translateY: transAnim,
            },
          ],
        },
      ]}>
      {type === 'DICE' &&
        SINGLE.map((item, _i) => (
          // eslint-disable-next-line react-native/no-inline-styles
          <View key={item} style={[{height: 40}, theme.flex.centerByRow]}>
            <DiceItem diceSize={24} diceNum={_i + 1} />
          </View>
        ))}
      {type === 'SUM' &&
        SUM_POINTS.map((item, _i) => (
          // eslint-disable-next-line react-native/no-inline-styles
          <View key={item} style={[{height: 40}, theme.flex.centerByRow]}>
            <Ball fontSize={14} ballSize={24} isNum num={_i + 3} />
          </View>
        ))}
      {type === 'TYPE' &&
        SUF_TYPE.map((item, _i) => (
          // eslint-disable-next-line react-native/no-inline-styles
          <View key={_i} style={[{height: 40}, theme.flex.centerByRow]}>
            <Ball type={item} isNum={false} ballSize={24} fontSize={14} />
          </View>
        ))}
    </Animated.View>
  );
};

export default React.memo(PrevResultItem);
