export default {
  v: '5.6.3',
  fr: 25,
  ip: 0,
  op: 40,
  w: 156,
  h: 156,
  nm: '合成 1',
  ddd: 0,
  assets: [
    {
      id: 'image_0',
      w: 32,
      h: 31,
      u: '/assets/lottie/home/rebate/',
      p: '___.png',
      e: 0,
    },
    {
      id: 'image_1',
      w: 78,
      h: 79,
      u: '/assets/lottie/home/rebate/',
      p: '____0.png',
      e: 0,
    },
    {
      id: 'image_2',
      w: 92,
      h: 55,
      u: '/assets/lottie/home/rebate/',
      p: '__.png',
      e: 0,
    },
    {
      id: 'image_3',
      w: 67,
      h: 93,
      u: '/assets/lottie/home/rebate/',
      p: '_.png',
      e: 0,
    },
    {
      id: 'image_4',
      w: 90,
      h: 108,
      u: '/assets/lottie/home/rebate/',
      p: '__1.png',
      e: 0,
    },
    {
      id: 'image_5',
      w: 92,
      h: 84,
      u: '/assets/lottie/home/rebate/',
      p: '__2_.png',
      e: 0,
    },
    {
      id: 'image_6',
      w: 157,
      h: 157,
      u: '/assets/lottie/home/rebate/',
      p: '__3_.png',
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: '小金币.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [33.25, 96, 0], ix: 2},
        a: {a: 0, k: [16, 15.5, 0], ix: 1},
        s: {
          a: 1,
          k: [
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 18,
              s: [100, 100, 100],
            },
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 23,
              s: [120, 120, 100],
            },
            {t: 26, s: [100, 100, 100]},
          ],
          ix: 6,
          x: "var $bm_rt;\nvar enable, amp, freq, decay, n, t, v;\ntry {\n    $bm_rt = enable = effect('Excite - \\u7f29\\u653e')('Pseudo/BNCA2506f0b33-0001');\n    if (enable == 0) {\n        $bm_rt = value;\n    } else {\n        amp = $bm_div(effect('Excite - \\u7f29\\u653e')('Pseudo/BNCA2506f0b33-0003'), 2.5);\n        freq = $bm_div(effect('Excite - \\u7f29\\u653e')('Pseudo/BNCA2506f0b33-0004'), 20);\n        decay = $bm_div(effect('Excite - \\u7f29\\u653e')('Pseudo/BNCA2506f0b33-0005'), 20);\n        n = 0, 0 < numKeys && (n = nearestKey(time).index, key(n).time > time && n--), t = 0 === n ? 0 : $bm_sub(time, key(n).time), $bm_rt = 0 < n ? (v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10))), $bm_sum(value, $bm_div($bm_mul($bm_mul($bm_div(v, 100), amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))))) : value;\n    }\n} catch (err) {\n    $bm_rt = value = value;\n}",
        },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: 'Excite - 缩放',
          np: 8,
          mn: 'Pseudo/BNCA2506f0b33',
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: '启用',
              mn: 'Pseudo/BNCA2506f0b33-0001',
              ix: 1,
              v: {a: 0, k: 1, ix: 1},
            },
            {
              ty: 6,
              nm: '属性',
              mn: 'Pseudo/BNCA2506f0b33-0002',
              ix: 2,
              v: 0,
            },
            {
              ty: 0,
              nm: '回弹幅度',
              mn: 'Pseudo/BNCA2506f0b33-0003',
              ix: 3,
              v: {
                a: 0,
                k: 20,
                ix: 3,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {
              ty: 0,
              nm: '强度',
              mn: 'Pseudo/BNCA2506f0b33-0004',
              ix: 4,
              v: {
                a: 0,
                k: 40,
                ix: 4,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {
              ty: 0,
              nm: '摩擦力',
              mn: 'Pseudo/BNCA2506f0b33-0005',
              ix: 5,
              v: {
                a: 0,
                k: 40,
                ix: 5,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {
              ty: 6,
              nm: '',
              mn: 'Pseudo/BNCA2506f0b33-0006',
              ix: 6,
              v: 0,
            },
          ],
        },
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: '形状图层 2',
      td: 1,
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [78, 78, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {a: 0, k: [47.046, 47.046], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              nm: '椭圆路径 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: {a: 0, k: [1, 1, 1, 1], ix: 4},
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              bm: 0,
              nm: '填充 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [37.653, -2.744], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [106.62, 106.62], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: '变换',
            },
          ],
          nm: '椭圆 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: '形状图层 1',
      tt: 1,
      sr: 1,
      ks: {
        o: {a: 0, k: 80, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {
          a: 0,
          k: [109.25, 100.25, 0],
          ix: 2,
          x: "var $bm_rt;\nvar enable, amp, freq, decay, n, t, v;\ntry {\n    $bm_rt = enable = effect('Excite - \\u4f4d\\u7f6e')('Pseudo/BNCA2506f0b33-0001');\n    if (enable == 0) {\n        $bm_rt = value;\n    } else {\n        amp = $bm_div(effect('Excite - \\u4f4d\\u7f6e')('Pseudo/BNCA2506f0b33-0003'), 2.5);\n        freq = $bm_div(effect('Excite - \\u4f4d\\u7f6e')('Pseudo/BNCA2506f0b33-0004'), 20);\n        decay = $bm_div(effect('Excite - \\u4f4d\\u7f6e')('Pseudo/BNCA2506f0b33-0005'), 20);\n        n = 0, 0 < numKeys && (n = nearestKey(time).index, key(n).time > time && n--), t = 0 === n ? 0 : $bm_sub(time, key(n).time), $bm_rt = 0 < n ? (v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10))), $bm_sum(value, $bm_div($bm_mul($bm_mul($bm_div(v, 100), amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))))) : value;\n    }\n} catch (err) {\n    $bm_rt = value = value;\n}",
        },
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: 'Excite - 位置',
          np: 8,
          mn: 'Pseudo/BNCA2506f0b33',
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: '启用',
              mn: 'Pseudo/BNCA2506f0b33-0001',
              ix: 1,
              v: {a: 0, k: 1, ix: 1},
            },
            {
              ty: 6,
              nm: '属性',
              mn: 'Pseudo/BNCA2506f0b33-0002',
              ix: 2,
              v: 0,
            },
            {
              ty: 0,
              nm: '回弹幅度',
              mn: 'Pseudo/BNCA2506f0b33-0003',
              ix: 3,
              v: {
                a: 0,
                k: 20,
                ix: 3,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {
              ty: 0,
              nm: '强度',
              mn: 'Pseudo/BNCA2506f0b33-0004',
              ix: 4,
              v: {
                a: 0,
                k: 40,
                ix: 4,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {
              ty: 0,
              nm: '摩擦力',
              mn: 'Pseudo/BNCA2506f0b33-0005',
              ix: 5,
              v: {
                a: 0,
                k: 40,
                ix: 5,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {
              ty: 6,
              nm: '',
              mn: 'Pseudo/BNCA2506f0b33-0006',
              ix: 6,
              v: 0,
            },
          ],
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [51.25, -33],
                    [2.75, 15.5],
                    [10.25, 21.75],
                    [59.5, -26.25],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: '路径 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {a: 0, k: [1, 1, 1, 1], ix: 4},
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              bm: 0,
              nm: '填充 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [1, -0.032], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: '变换',
            },
          ],
          nm: '形状 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: '大金币.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [116.5, 74.75, 0], ix: 2},
        a: {a: 0, k: [42.5, 33, 0], ix: 1},
        s: {
          a: 1,
          k: [
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 15,
              s: [100, 100, 100],
            },
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833]},
              o: {x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167]},
              t: 20,
              s: [60, 60, 100],
            },
            {t: 23, s: [100, 100, 100]},
          ],
          ix: 6,
          x: "var $bm_rt;\nvar enable, amp, freq, decay, n, t, v;\ntry {\n    $bm_rt = enable = effect('Excite - \\u7f29\\u653e')('Pseudo/BNCA2506f0b33-0001');\n    if (enable == 0) {\n        $bm_rt = value;\n    } else {\n        amp = $bm_div(effect('Excite - \\u7f29\\u653e')('Pseudo/BNCA2506f0b33-0003'), 2.5);\n        freq = $bm_div(effect('Excite - \\u7f29\\u653e')('Pseudo/BNCA2506f0b33-0004'), 20);\n        decay = $bm_div(effect('Excite - \\u7f29\\u653e')('Pseudo/BNCA2506f0b33-0005'), 20);\n        n = 0, 0 < numKeys && (n = nearestKey(time).index, key(n).time > time && n--), t = 0 === n ? 0 : $bm_sub(time, key(n).time), $bm_rt = 0 < n ? (v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10))), $bm_sum(value, $bm_div($bm_mul($bm_mul($bm_div(v, 100), amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))))) : value;\n    }\n} catch (err) {\n    $bm_rt = value = value;\n}",
        },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: 'Excite - 缩放',
          np: 8,
          mn: 'Pseudo/BNCA2506f0b33',
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: '启用',
              mn: 'Pseudo/BNCA2506f0b33-0001',
              ix: 1,
              v: {a: 0, k: 1, ix: 1},
            },
            {
              ty: 6,
              nm: '属性',
              mn: 'Pseudo/BNCA2506f0b33-0002',
              ix: 2,
              v: 0,
            },
            {
              ty: 0,
              nm: '回弹幅度',
              mn: 'Pseudo/BNCA2506f0b33-0003',
              ix: 3,
              v: {
                a: 0,
                k: 20,
                ix: 3,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {
              ty: 0,
              nm: '强度',
              mn: 'Pseudo/BNCA2506f0b33-0004',
              ix: 4,
              v: {
                a: 0,
                k: 40,
                ix: 4,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {
              ty: 0,
              nm: '摩擦力',
              mn: 'Pseudo/BNCA2506f0b33-0005',
              ix: 5,
              v: {
                a: 0,
                k: 40,
                ix: 5,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
            {
              ty: 6,
              nm: '',
              mn: 'Pseudo/BNCA2506f0b33-0006',
              ix: 6,
              v: 0,
            },
          ],
        },
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: '外侧.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [75.5, 101.25, 0], ix: 2},
        a: {a: 0, k: [46, 27.5, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: '大.png',
      cl: 'png',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {
          a: 1,
          k: [
            {
              i: {x: [0.667], y: [1]},
              o: {x: [0.333], y: [0]},
              t: 0,
              s: [0],
            },
            {
              i: {x: [0.667], y: [1]},
              o: {x: [0.333], y: [0]},
              t: 5,
              s: [-5],
            },
            {
              i: {x: [0.833], y: [1]},
              o: {x: [0.333], y: [0]},
              t: 15,
              s: [5],
            },
            {t: 20, s: [0]},
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.667, y: 1},
              o: {x: 0.333, y: 0},
              t: 0,
              s: [87.075, 112.294, 0],
              to: [0, -1.5, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 1},
              o: {x: 0.333, y: 0},
              t: 10,
              s: [87.075, 103.294, 0],
              to: [0, 0, 0],
              ti: [0, -1.5, 0],
            },
            {t: 20, s: [87.075, 112.294, 0]},
          ],
          ix: 2,
        },
        a: {a: 0, k: [33.5, 93, 0], ix: 1},
        s: {
          a: 1,
          k: [
            {
              i: {x: [0.667, 0.667, 0.667], y: [1, 1, 1]},
              o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
              t: 0,
              s: [100, 100, 100],
            },
            {
              i: {x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1.16]},
              o: {x: [0.333, 0.333, 0.333], y: [0, 0, 0]},
              t: 10,
              s: [105, 105, 100],
            },
            {t: 20, s: [100, 100, 100]},
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: '小.png',
      cl: 'png',
      refId: 'image_4',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.667, y: 1},
              o: {x: 0.333, y: 0},
              t: 7,
              s: [65.5, 129, 0],
              to: [0, -2.042, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 1},
              o: {x: 0.333, y: 0},
              t: 10,
              s: [65.5, 116.75, 0],
              to: [0, 0, 0],
              ti: [0, -2.042, 0],
            },
            {t: 22, s: [65.5, 129, 0]},
          ],
          ix: 2,
        },
        a: {a: 0, k: [45, 108, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: '内侧.png',
      cl: 'png',
      refId: 'image_5',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [75.5, 86.75, 0], ix: 2},
        a: {a: 0, k: [46, 42, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: '背景.png',
      cl: 'png',
      refId: 'image_6',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [78, 78, 0], ix: 2},
        a: {a: 0, k: [78.5, 78.5, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
