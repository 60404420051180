import theme from '@/style';
import {TabView} from '@rneui/themed';
import React from 'react';
import Racecar from './racecar/racecar';
import ScratchResult from './scratch/scratch';
import KeralaResult from './kerala/kerala';
import DigitResult from './digit/digit';
import ColorResult from './color/color';
import DiceResult from './dice/dice';
import MatkaResult from './matka/matka';
import TabBar from '../transaction/components/transaction-tabBar';
import {View} from 'react-native';
import {packageId} from '@/config';
import {MatkaTypeItem} from './result.type';
import QuickDigitResult from './quick-digit/quick-digit';
import StateLottery from './state-lottery/state-lottery';

const ROUTERS = [
  {
    type: 'scratch',
    typeName: 'Scratch Off',
    hide: [8],
  },
  {
    type: 'kerala',
    typeName: 'Kerala',
    hide: [5, 6],
  },
  {
    type: 'digit',
    typeName: '3 Digits',
    hide: [5, 6],
  },
  {
    type: 'quickDigit',
    typeName: 'Quick 3 Digits',
    hide: [5, 6],
  },
  {
    type: 'color',
    typeName: 'Color',
    hide: [],
  },
  {
    type: 'dice',
    typeName: 'Dice',
    hide: [],
  },
  {
    type: 'matka',
    typeName: 'Matka',
    hide: [5, 6],
  },
  {
    type: 'racecar',
    typeName: 'Quick Race',
    hide: [],
  },
  {
    type: 'state-lottery',
    typeName: 'State Lottery',
    hide: [],
  },
];

const Lottery = ({
  currentIndex = 0,
  onChange,
  selectType,
  matkaType = [],
  digitTypes,
}: {
  currentIndex?: number;
  selectType?: string;
  onChange: (v: number) => void;
  matkaType?: MatkaTypeItem[];
  digitTypes?: {label: string; value: number}[];
}) => {
  const targetRoutes = React.useMemo(() => {
    return ROUTERS.filter(item => !item.hide.includes(packageId));
  }, []);
  return (
    <>
      <View style={[theme.padding.tbs, theme.background.white]}>
        <TabBar
          value={currentIndex}
          routers={targetRoutes}
          onChange={onChange}
        />
      </View>
      <TabView value={currentIndex} onChange={onChange}>
        <TabView.Item style={[theme.fill.fillW]}>
          <ScratchResult isActive={currentIndex === 0} />
        </TabView.Item>
        {packageId !== 5 && packageId !== 6 && (
          <TabView.Item style={[theme.fill.fillW]}>
            <KeralaResult
              isActive={currentIndex === 1}
              lotteryType={selectType}
            />
          </TabView.Item>
        )}
        {packageId !== 5 && packageId !== 6 && (
          <TabView.Item style={[theme.fill.fillW]}>
            <DigitResult
              digitTypes={digitTypes}
              isActive={currentIndex === 2}
              lotteryType={selectType}
            />
          </TabView.Item>
        )}
        {packageId !== 5 && packageId !== 6 && (
          <TabView.Item style={[theme.fill.fillW]}>
            <QuickDigitResult isActive={currentIndex === 3} />
          </TabView.Item>
        )}
        <TabView.Item style={[theme.fill.fillW]}>
          <ColorResult
            isActive={
              currentIndex === (packageId !== 5 && packageId !== 6 ? 4 : 1)
            }
          />
        </TabView.Item>
        <TabView.Item style={[theme.fill.fillW]}>
          <DiceResult
            isActive={
              currentIndex === (packageId !== 5 && packageId !== 6 ? 5 : 2)
            }
          />
        </TabView.Item>
        {packageId !== 5 && packageId !== 6 && (
          <TabView.Item style={[theme.fill.fillW]}>
            <MatkaResult
              isActive={currentIndex === 6}
              matkaType={matkaType}
              lotteryType={selectType}
            />
          </TabView.Item>
        )}
        <TabView.Item style={[theme.fill.fillW]}>
          <Racecar
            isActive={
              currentIndex === (packageId !== 5 && packageId !== 6 ? 7 : 3)
            }
          />
        </TabView.Item>
        <TabView.Item style={[theme.fill.fillW]}>
          <StateLottery
            isActive={
              currentIndex === (packageId !== 5 && packageId !== 6 ? 8 : 4)
            }
          />
        </TabView.Item>
      </TabView>
    </>
  );
};

export default Lottery;
