import {View, StyleSheet} from 'react-native';
import LinearGradient from '@/components/basic/linear-gradient';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import NativeTouchableOpacity from '@/components/basic/touchable-opacity/naitve-touchable-opacity';
import {TimeRemaining} from '@/components/business/games';
import ColorBallResult from './color-ball-result';
import {Svg, Line} from 'react-native-svg';
import {useTranslation} from 'react-i18next';
interface ColorTimeProps {
  issueNumber?: string;
  prevIssueNum?: string;
  digit?: string;
  cycle?: number;
  showRule?: () => void;
  countdownTime?: number;
  onTimeEnd?: () => void;
  onDisabled?: () => void;
  isSameRoute?: boolean;
  stopBetSec?: number;
  timeVersion?: number;
  onTimeChange?: (v: number) => void;
}

const ColorTime = ({
  cycle,
  issueNumber,
  prevIssueNum,
  countdownTime,
  showRule,
  digit = '',
  stopBetSec,
  onTimeEnd,
  onDisabled,
  isSameRoute = true,
  timeVersion = 0,
  onTimeChange,
}: ColorTimeProps) => {
  const {i18n} = useTranslation();
  return (
    <LinearGradient colors={theme.backgroundColor.remainingLinearGradient}>
      <View
        style={[
          theme.padding.xxl,
          theme.flex.row,
          theme.flex.between,
          theme.flex.centerByCol,
        ]}>
        <View style={[theme.flex.row]}>
          <View style={[theme.flex.between]}>
            <View style={[]}>
              <Text
                fontFamily="fontDinBold"
                size="medium"
                color={theme.fontColor.second}>
                {cycle || '-'}min
              </Text>
              <Text fontFamily="fontDinBold" color={theme.fontColor.second}>
                {prevIssueNum || '-'}
              </Text>
            </View>
            <NativeTouchableOpacity
              onPress={() => showRule && showRule()}
              style={[
                theme.borderRadius.xs,
                styles.plyBtn,
                theme.flex.center,
                theme.padding.tbxxs,
                theme.padding.lrs,
                theme.margin.tops,
              ]}>
              <Text fontFamily="fontInter">
                {i18n.t('game-page.label.howToPlay')}
              </Text>
            </NativeTouchableOpacity>
          </View>
          <ColorBallResult digit={digit} />
        </View>
        <View style={[theme.flex.flex1, theme.flex.centerByCol]}>
          <Svg height={72} width={1}>
            <Line
              x1="0"
              y1="0"
              x2="0"
              y2="72"
              stroke="#897AA1"
              strokeWidth="2"
              strokeDasharray={'5,5'}
            />
          </Svg>
        </View>
        <TimeRemaining
          stopBetSec={stopBetSec}
          onDisabled={onDisabled}
          onTimeEnd={onTimeEnd}
          isSame={isSameRoute}
          timeVersion={timeVersion}
          remainingTime={countdownTime}
          tip={issueNumber}
          onTimeChange={onTimeChange}
        />
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  plyBtn: {
    width: 105,
    backgroundColor: theme.backgroundColor.ruleBtn,
  },
});

export default React.memo(ColorTime);
