import {View, TouchableOpacity, StyleSheet, Image} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import {useTranslation} from 'react-i18next';

export interface OpenCloseTabProps {
  disabled?: boolean;
  activeKey: 'open' | 'close';
  onItemPress: (i: 'open' | 'close') => void;
}

const OpenCloseTab = ({
  activeKey,
  onItemPress,
  disabled = false,
}: OpenCloseTabProps) => {
  const {t} = useTranslation();

  return (
    <View style={[theme.flex.row, {gap: theme.paddingSize.s}]}>
      <TouchableOpacity
        onPress={() => onItemPress('open')}
        activeOpacity={0.8}
        disabled={disabled}
        style={[styles.tabItem, activeKey === 'open' && styles.tabItemSelect]}>
        {activeKey === 'open' && (
          <Image
            resizeMode="stretch"
            source={require('@components/assets/imgs/game/satta/item-select-bg.webp')}
            style={[theme.position.abs, theme.fill.fill]}
          />
        )}
        <Text>{t('satta.betting.open')}</Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => onItemPress('close')}
        activeOpacity={0.8}
        style={[styles.tabItem, activeKey === 'close' && styles.tabItemSelect]}>
        {activeKey === 'close' && (
          <Image
            resizeMode="stretch"
            source={require('@components/assets/imgs/game/satta/item-select-bg.webp')}
            style={[theme.position.abs, theme.fill.fill]}
          />
        )}
        <Text>{t('satta.betting.close')}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  tabItem: {
    width: 72,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.basicColor.white,
    borderRadius: 4,
    height: 30,
    overflow: 'hidden',
  },
  tabItemSelect: {
    height: 34,
    marginTop: 2,
    borderTopWidth: 2,
    borderTopColor: theme.basicColor.primary,
    backgroundColor: 'transparent',
  },
});

export default OpenCloseTab;
