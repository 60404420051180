import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {View} from 'react-native';
import DigitBall from './digit-ball';
import {INDEX_TO_O, RESULT_INDEX} from '../constant';
import {useTranslation} from 'react-i18next';

export interface DrawResultProps {
  wonCode?: string[];
  name?: string;
}

const DrawResult = ({wonCode = [], name = ''}: DrawResultProps) => {
  const {i18n} = useTranslation();
  return (
    <View style={[theme.flex.centerByCol]}>
      <View style={[theme.flex.center]}>
        <Text
          size="medium"
          fontFamily="fontInterBold"
          color={theme.basicColor.dark}>
          {name}
        </Text>
        <Text
          size="medium"
          style={[theme.margin.tops]}
          fontFamily="fontInterBold">
          {i18n.t('bets-detail.label.drawRes')}:
        </Text>
      </View>
      <View style={[theme.margin.tops, {gap: theme.paddingSize.s}]}>
        {wonCode.map((item, i) => (
          <View style={[theme.flex.row, theme.flex.between]} key={i}>
            <Text fontFamily="fontInterBold">{INDEX_TO_O[i + 1]}-prize：</Text>
            <View
              style={[
                theme.flex.row,
                theme.margin.leftxxs,
                {gap: theme.paddingSize.xxs},
              ]}>
              {item.split('').map((pie, _i) => (
                <DigitBall
                  key={_i}
                  disabled={
                    RESULT_INDEX.slice(-item.split('').length)[_i] === 'UNUSED'
                  }
                  digit={pie}
                  type={RESULT_INDEX.slice(-item.split('').length)[_i]}
                />
              ))}
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default DrawResult;
