import React from 'react';
import {View} from 'react-native';
// import Result from './result/result';
// import Winner from './winner/winner';
// import Analyze from './analyze/analyze';
// import OrderBetsList from './order/order';
import {useResponsiveDimensions} from '@/utils';
import DigitResult from './digit-result/result';
import OrderList from './digit-order/order-list';

const DigitTabView = ({
  refreshCode,
  pickThreeId,
  index,
  type = 'normal',
}: {
  refreshCode: number;
  pickThreeId?: number;
  index: number;
  type?: string;
}) => {
  const {height: screenHeight} = useResponsiveDimensions();
  return (
    <View
      style={[
        {
          minHeight: screenHeight - 100,
        },
      ]}>
      <View
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {height: index === 0 ? '100%' : 0, overflow: 'hidden'},
        ]}>
        {index === 0 ? (
          <DigitResult
            type={type}
            refreshCode={refreshCode}
            pickThreeId={pickThreeId}
            isActive={index === 0}
          />
        ) : null}
      </View>
      <View
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {height: index === 1 ? '100%' : 0, overflow: 'hidden'},
        ]}>
        {index === 1 ? (
          <OrderList
            type={type}
            refreshCode={refreshCode}
            pickThreeId={pickThreeId}
            isActive={index === 1}
          />
        ) : null}
      </View>
    </View>
  );
};

export default DigitTabView;
