import React from 'react';
import ResultPanel from '../components/result-panel/result-panel';
import {IGameOrder} from '../result.type';
import {getIGameLastOrder} from '../result-service';
import {View, Image} from 'react-native';
import {useTranslation} from 'react-i18next';
import Text from '@/components/basic/text';
import globalStore from '@/services/global.state';
import theme from '@/style';
import LazyImage from '@/components/basic/image';
import {goTo, toPriceStr} from '@/utils';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {downIcon} from '@/common-pages/rebate/rebate.variables';

const ScratchDraw = () => {
  const [scratchOrder, setScratchOrder] = React.useState<IGameOrder>();
  const {t} = useTranslation();

  const getResult = async () => {
    try {
      const res = await getIGameLastOrder(1);
      setScratchOrder(res);
    } finally {
    }
  };

  React.useEffect(() => {
    if (globalStore.token) {
      getResult();
    }
  }, []);

  return scratchOrder?.orderId ? (
    <ResultPanel
      onMore={() => goTo('Bets', {tag: 'Scratch off'})}
      betTime={scratchOrder.orderTime}
      isWin={scratchOrder.orderWinAmount > 0}
      winAmount={scratchOrder.orderWinAmount}>
      <View style={[theme.padding.lrl, theme.padding.tops]}>
        <Text textAlign="center">{t('game-page.label.myBets')}</Text>
        <View style={[theme.flex.row, theme.padding.tbs]}>
          <LazyImage
            width={48}
            height={48}
            radius={6}
            imageUrl={scratchOrder.gameIcon}
            occupancy="transparent"
          />
          <View
            style={[
              theme.flex.flex1,
              theme.flex.row,
              theme.flex.centerByCol,
              theme.flex.between,
              theme.margin.leftl,
            ]}>
            <View>
              <Text fontFamily="fontInterBold" size="medium" main>
                {scratchOrder.gameName}
              </Text>
              <Text style={[theme.margin.topxxs]} fontFamily="fontDinBold" main>
                {toPriceStr(scratchOrder.orderPrice)}x{scratchOrder.orderNumber}
              </Text>
            </View>
            <View style={[theme.flex.alignEnd]}>
              <Text fontFamily="fontInterBold" size="medium">
                Won
              </Text>
              <Text
                color={scratchOrder.orderWinAmount > 0 ? '#F15802' : '#000'}
                fontFamily="fontInterBold"
                size="medium">
                {toPriceStr(scratchOrder.orderWinAmount)}
              </Text>
            </View>
          </View>
        </View>
        <View style={[{gap: theme.paddingSize.xxs / 2}]}>
          {scratchOrder.orderList?.slice(0, 3).map((item, _i) => (
            <View
              key={_i}
              style={[
                theme.flex.row,
                theme.flex.between,
                theme.padding.s,
                theme.borderRadius.m,
                // eslint-disable-next-line react-native/no-inline-styles
                {backgroundColor: '#EFF1F7'},
              ]}>
              <Text fontFamily="fontInterBold" accent>
                ID:{item.cardId}
              </Text>
              <View style={[theme.flex.row, theme.flex.centerByCol]}>
                {item.status !== 2 ? <Text>Won</Text> : null}
                <Text
                  style={[theme.margin.leftxxs]}
                  color={item.winAmount > 0 ? '#F15802' : '#000'}
                  size="medium"
                  fontFamily="fontDinBold">
                  {item.status === 2 ? 'Unused' : toPriceStr(item.winAmount)}
                </Text>
              </View>
            </View>
          ))}
        </View>
        {scratchOrder.orderList && scratchOrder.orderList?.length > 3 && (
          <NativeTouchableOpacity
            onPress={() => {
              goTo('ScratchResultDetail', {
                scratchOrder: JSON.stringify(scratchOrder),
              });
            }}
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.flex.between,
              theme.padding.tbs,
            ]}>
            <Text>View all</Text>
            <View
              style={[
                theme.margin.leftxxs,
                {
                  transform: [
                    {
                      rotate: '-90deg',
                    },
                  ],
                },
              ]}>
              <Image source={downIcon} style={[theme.icon.xs]} />
            </View>
          </NativeTouchableOpacity>
        )}
      </View>
    </ResultPanel>
  ) : null;
};

export default ScratchDraw;
