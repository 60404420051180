import React from 'react';
import {showCenterModal, closeModal} from '@/components/basic/modal/manager';
import {
  VersionUpdateModal,
  checkVersion,
} from '@/common-pages/hooks/versionmodal.hooks';
import globalStore from '@/services/global.state';

export function checkUpdate(showToast?: boolean) {
  checkVersion()
    .then(res => {
      if (res && res.downUrl) {
        const id = showCenterModal(
          <VersionUpdateModal
            info={res}
            onClose={() => {
              closeModal(id);
            }}
          />,
          {},
        );
      } else {
        if (showToast) {
          globalStore.globalTotal.next({
            type: 'success',
            message: 'This is the latest version',
          });
        }
      }
    })
    .catch(e => {
      console.log(e);
    });
}
