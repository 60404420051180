import {
  DiceHomeItem,
  DiceResultItem,
  getDiceHomeList,
  pageDiceResult,
} from '@/common-pages/dice/dice-service';
import React from 'react';
import ResultTab from '../components/result-tab/result-tab';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import {ScrollView, View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import ResultItem from '@/common-pages/dice/dice-tab/result/result-item';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import Page from '@/components/business/games/page';
import DiceDraw from './draw';
const pageSize = 10;

const DiceResult = ({isActive}: {isActive?: boolean}) => {
  const [times, setTimes] = React.useState<DiceHomeItem[]>([]);
  const [configId, setConfigId] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [list, setList] = React.useState<DiceResultItem[]>([]);
  const [initPage, setInitPage] = React.useState(1);
  const getTimes = async () => {
    try {
      const res = await getDiceHomeList();
      setTimes(res);
      if (res) {
        setConfigId(`${res[0].configId}`);
      }
    } finally {
    }
  };

  const getList = async (id: number, page: number = 1) => {
    try {
      setLoading(true);
      const res = await pageDiceResult({
        configId: id,
        pageNo: page,
        pageSize,
      });
      if (res) {
        setTotal(res.totalSize);
        setList(res.content);
      }
    } finally {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  React.useEffect(() => {
    if (isActive) {
      getTimes();
    }
  }, [isActive]);

  React.useEffect(() => {
    if (configId && isActive) {
      setInitPage(1);
      getList(+configId);
    }
  }, [configId, isActive]);

  const {calcActualSize} = useScreenSize();

  return (
    <Spin loading={loading} style={[theme.flex.flex1]}>
      <ScrollView stickyHeaderIndices={[1]}>
        {!firstLoad && configId && isActive ? (
          <DiceDraw configId={+configId} />
        ) : (
          <View />
        )}
        <ResultTab
          selectKey={configId}
          onSelectKey={setConfigId}
          tabs={times.map(item => {
            return {
              label: `${item.cycle}`,
              key: `${item.configId}`,
            };
          })}
        />
        <View style={[styles.tableHeader]}>
          <View style={[theme.flex.flex1, theme.flex.row, theme.flex.center]}>
            <View style={[{width: calcActualSize(64)}]}>
              <Text
                textAlign="center"
                // eslint-disable-next-line react-native/no-inline-styles
                style={[{fontWeight: '500'}]}>
                Issue
              </Text>
            </View>
            <View style={[theme.flex.flex1, theme.flex.center]}>
              <Text
                // eslint-disable-next-line react-native/no-inline-styles
                style={[{fontWeight: '500'}]}>
                Result
              </Text>
            </View>
          </View>
          <View style={[theme.flex.flex1, theme.flex.row]}>
            <View>
              <Text
                // eslint-disable-next-line react-native/no-inline-styles
                style={[{fontWeight: '500'}]}>
                Sum
              </Text>
            </View>
            <View style={[theme.flex.flex1, theme.flex.center]}>
              <Text
                // eslint-disable-next-line react-native/no-inline-styles
                style={[{fontWeight: '500'}]}>
                Value
              </Text>
            </View>
            <View style={[theme.padding.rightl]}>
              <Text
                // eslint-disable-next-line react-native/no-inline-styles
                style={[{fontWeight: '500'}]}>
                Number
              </Text>
            </View>
          </View>
        </View>
        {list.map((item, _i) => (
          <ResultItem key={_i} index={_i} {...item} countHasBg />
        ))}
        {total > 0 ? (
          <Page
            page={initPage}
            total={total}
            pageSize={pageSize}
            onPageChange={p => {
              if (configId) {
                setInitPage(p);
                getList(+configId, p).then();
              }
            }}
          />
        ) : null}
      </ScrollView>
    </Spin>
  );
};

const styles = StyleSheet.create({
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#E9ECF5',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginHorizontal: 12,
    paddingVertical: 8,
    columnGap: 12,
  },
});

export default DiceResult;
