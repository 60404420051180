import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  headerHeight: {
    height: 72,
  },
  vipWrap: {
    width: 52,
    height: 20,
  },
  commissionWrap: {
    height: 183,
    marginTop: 12,
  },
  topWrap: {
    height: 61,
  },
  wrapBg: {
    backgroundColor: 'rgba(255, 255, 255, 0.24)',
  },
  imgBg: {
    width: '100%',
    height: 84,
  },
  codeWrap: {
    width: '68%',
    height: '100%',
  },
  gap: {
    width: '4%',
  },
  copyWrap: {
    width: '28%',
    height: '100%',
  },
  subHeight: {
    height: 145,
    marginTop: 12,
  },
  paddingtbms: {
    paddingTop: 2,
    paddingBottom: 2,
  },
});
