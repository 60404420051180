import OrderListItemStatus from '@/components/business/order/order-list-item-status';
import Price from '@/components/business/price/price';
import theme from '@/style';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import {SattaOrderDigitItem} from '../satta-service';
import Text from '@/components/basic/text';
import {useTranslation} from 'react-i18next';
export interface CodeItemProps {
  index?: number;
  isOpen?: boolean;
}

const CodeItem = ({
  index = 0,
  points,
  digitsWonAmount = 0,
  digits,
  isWin,
  gameType,
}: CodeItemProps & SattaOrderDigitItem) => {
  const backgroundColor = React.useMemo(() => {
    return {
      backgroundColor: index % 2 === 0 ? '#FFF' : '#F8F9FF',
    };
  }, [index]);

  const {t} = useTranslation();
  const matkaType = [
    '',
    `${t('satta.betting.ank')} ${t('satta.betting.open')}`,
    `${t('satta.betting.ank')} ${t('satta.betting.close')}`,
    t('satta.betting.jodi'),
    `${t('satta.betting.sp')} ${t('satta.betting.open')}`,
    `${t('satta.betting.sp')} ${t('satta.betting.close')}`,
    `${t('satta.betting.dp')} ${t('satta.betting.open')}`,
    `${t('satta.betting.dp')} ${t('satta.betting.close')}`,
    `${t('satta.betting.tp')} ${t('satta.betting.open')}`,
    `${t('satta.betting.tp')} ${t('satta.betting.close')}`,
    `${t('satta.betting.half')} ${t('satta.betting.open')}`,
    `${t('satta.betting.half')} ${t('satta.betting.close')}`,
    t('satta.betting.full'),
  ];

  return (
    <View
      style={[
        backgroundColor,
        theme.flex.row,
        theme.flex.between,
        styles.itemAction,
        theme.padding.lrl,
        theme.flex.centerByCol,
      ]}>
      <View style={[theme.flex.flex1]}>
        <Text size="medium" style={[theme.font.medium]}>
          {matkaType[gameType]}
        </Text>
        <Text size="medium" fontFamily="fontInterBold">
          {digits}
        </Text>
      </View>
      <View style={[theme.flex.flex1, theme.flex.center]}>
        <Price
          textProps={{
            size: 'medium',
          }}
          price={points}
        />
      </View>
      <View style={[theme.flex.flex1]}>
        <OrderListItemStatus
          hasDetail={false}
          status={isWin === undefined ? 2 : digitsWonAmount > 0 ? 1 : 0}
          awardAmount={digitsWonAmount}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  itemAction: {
    height: 48,
  },
});

export default React.memo(CodeItem);
