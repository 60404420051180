import React from 'react';
import theme from '@/style';

export default ({
  width,
  height,
  stroke = theme.basicColor.primary,
}: {
  width: number;
  height: number;
  stroke?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 14 15"
    fill="none">
    <path
      d="M6 10.3333L8.66667 7.66667L6 5"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
