import {View} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import DigitBall from '../../components/digit-ball';
import {useTranslation} from 'react-i18next';

const ResultHeader = ({isQuick = false}: {isQuick?: boolean}) => {
  const {i18n} = useTranslation();
  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.centerByCol,
        theme.flex.between,
        theme.padding.lrl,
        theme.padding.tbs,
        theme.margin.lrl,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          backgroundColor: '#E9ECF5',
        },
      ]}>
      <View style={[theme.flex.flex1]}>
        <Text>
          {isQuick
            ? i18n.t('bets-detail.label.issue')
            : i18n.t('bets-detail.label.name')}
        </Text>
      </View>
      <View style={[theme.flex.flex1]}>
        <Text textAlign="center">{i18n.t('bets-detail.label.time')}</Text>
      </View>
      <View style={[theme.flex.row, {gap: theme.paddingSize.xxs}]}>
        {['A', 'B', 'C'].map((item, _i) => (
          <DigitBall ballSize={24} digit={item} color={item} key={_i} />
        ))}
      </View>
    </View>
  );
};

export default ResultHeader;
