import theme from '@/style';
import React from 'react';
import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native';
import TimesItem from './times-item';
interface TimesProps {
  configId?: number | null;
  minutes?: {cycle: number; id: number}[];
  onTimePress?: (id?: number) => void;
  itemStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
}

const Times = ({
  configId,
  minutes = [],
  onTimePress = () => {},
  itemStyle = {},
  style = {},
}: TimesProps) => {
  return (
    <View
      style={[
        theme.margin.s,
        theme.flex.row,
        styles.container,
        {gap: theme.paddingSize.s},
        style,
      ]}>
      {minutes.map((item, _index) => (
        <TimesItem
          key={_index}
          label={`${item.cycle}min`}
          active={configId === item.id}
          timeInfo={item}
          onTimePress={onTimePress}
          itemStyle={itemStyle}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 59,
  },
});

export default React.memo(Times);
