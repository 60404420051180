import {View, StyleSheet, Image} from 'react-native';
import Text from '@/components/basic/text';
import {MatkaResultItem} from '../result.type';
import dayjs from 'dayjs';
import React from 'react';
import theme from '@/style';
import LazyImage from '@/components/basic/image';
import SattaPanel from '@/common-pages/satta/components/satta-panel';
import {calcResultNum} from '@/common-pages/satta/satta-service';
import {useTranslation} from 'react-i18next';
import Cards from './cards';

const ListItem = ({
  lotteryName,
  issueNo,
  createTime,
  backImgWeb,
  openNum,
  closeNum,
  openTime,
  closeTime,
  ...props
}: MatkaResultItem) => {
  const {t} = useTranslation();

  const panel = React.useMemo(() => {
    const openSumNum = calcResultNum(openNum);
    const closeSumNum = calcResultNum(closeNum);
    return `${
      openNum && openNum.length ? openNum : '***'
    }-${openSumNum}${closeSumNum}-${
      closeNum && closeNum.length ? closeNum : '***'
    }`;
  }, [openNum, closeNum]);

  const list = React.useMemo(() => {
    return [
      {
        title: t('result-page.matka.ank'),
        tip: t('result-page.matka.open'),
        result: props.openAnk,
      },
      {
        title: t('result-page.matka.ank'),
        tip: t('result-page.matka.close'),
        result: props.closeAnk,
      },
      {
        title: t('result-page.matka.jodi'),
        tip: '',
        result: props.jodi,
      },
      {
        title: t('result-page.matka.sp'),
        tip: t('result-page.matka.open'),
        result: props.openSp,
      },
      {
        title: t('result-page.matka.sp'),
        tip: t('result-page.matka.close'),
        result: props.closeSp,
      },
      {
        title: t('result-page.matka.dp'),
        tip: t('result-page.matka.open'),
        result: props.openDp,
      },
      {
        title: t('result-page.matka.dp'),
        tip: t('result-page.matka.close'),
        result: props.closeDp,
      },
      {
        title: t('result-page.matka.tp'),
        tip: t('result-page.matka.open'),
        result: props.openTp,
      },
      {
        title: t('result-page.matka.tp'),
        tip: t('result-page.matka.close'),
        result: props.closeTp,
      },
      {
        title: t('result-page.matka.hsa'),
        tip: t('result-page.matka.open'),
        result: props.hsaNum,
      },
      {
        title: t('result-page.matka.hsb'),
        tip: t('result-page.matka.open'),
        result: props.hsbNum,
      },
      {
        title: t('result-page.matka.fs'),
        tip: '',
        result: props.fullSangam,
      },
    ];
  }, [props, t]);

  return (
    <View
      style={[
        theme.borderRadius.m,
        theme.background.white,
        theme.padding.lrxxl,
        theme.overflow.hidden,
        {paddingBottom: theme.paddingSize.l * 2},
      ]}>
      <View style={[styles.cardBg]}>
        <LazyImage imageUrl={backImgWeb} width={'100%'} height={'100%'} />
      </View>
      <View style={[theme.flex.row, theme.flex.between, theme.margin.topl]}>
        <Text color={'#fff'} main size="large" fontFamily="fontDinBold">
          {lotteryName}
        </Text>
        <View style={[theme.flex.alignEnd]}>
          <Text
            color={'#fff'}
            style={[
              // eslint-disable-next-line react-native/no-inline-styles
              {
                fontWeight: '500',
              },
            ]}>
            NO.{issueNo}
          </Text>
          <Text
            color={'#fff'}
            style={[
              // eslint-disable-next-line react-native/no-inline-styles
              {
                fontWeight: '500',
                marginTop: 4,
              },
            ]}>
            {dayjs(createTime).format('DD-MM-YYYY')}
          </Text>
        </View>
      </View>
      <View style={[theme.flex.row, theme.flex.center, theme.margin.tops]}>
        <SattaPanel pannel={panel} />
      </View>
      <View
        style={[
          theme.flex.row,
          theme.flex.between,
          theme.padding.tbl,
          theme.margin.tops,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            borderTopWidth: 1,
            borderTopColor: '#FFFFFF80',
          },
        ]}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Image
            source={require('@assets/icons/home/matka-open.webp')}
            style={[styles.icon]}
          />
          <Text secAccent>{t('home.matka.open').toUpperCase()}</Text>
          <Text main style={[theme.margin.leftxxs]}>
            {openTime}
          </Text>
        </View>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Image
            source={require('@assets/icons/home/matka-close.webp')}
            style={[styles.icon]}
          />
          <Text secAccent>{t('home.matka.close').toUpperCase()}</Text>
          <Text main style={[theme.margin.leftxxs]}>
            {closeTime}
          </Text>
        </View>
      </View>
      <View
        style={[theme.flex.row, theme.flex.wrap, {gap: theme.paddingSize.s}]}>
        {list.map((item, _i) => (
          <Cards
            key={_i}
            title={item.title}
            tip={item.tip}
            digit={item.result}
            closed={!item.result || item.result === '-'}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardBg: {
    position: 'absolute',
    height: 168,
    left: 0,
    top: 0,
    right: 0,
  },
  icon: {
    width: 14,
    height: 14,
  },
});

export default ListItem;
