import React from 'react';
import {DiceListItem} from '../../home.type';
import {useTranslation} from 'react-i18next';
import {goTo} from '@/utils';
import HomeLotteryImgTemplate from './lottery-img-template';

interface DiceProps {
  list: DiceListItem[];
  serverTime?: number;
  currentTime?: number;
}
const HomeLotteryDice = ({list = [], serverTime, currentTime}: DiceProps) => {
  const i18n = useTranslation();
  return (
    <HomeLotteryImgTemplate
      list={list}
      game={'dice'}
      serverTime={serverTime}
      currentTime={currentTime}
      titleColor="rgba(201, 228, 209, 0.50)"
      title={i18n.t('home.dice.title')}
      onlineUser={9999}
      onPress={item => {
        item &&
          goTo('Dice', {
            configId: (item as DiceListItem)!.configId,
            cycle: (item as DiceListItem)!.cycle,
          });
      }}
      bgColor="#306AC7"
      odds={150}
    />
  );
};

export default React.memo(HomeLotteryDice);
