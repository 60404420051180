import {StatusBar, StyleSheet} from 'react-native';
import {paddingSize} from './base.variable';

export const padding = StyleSheet.create({
  topBar: {
    paddingTop: StatusBar.currentHeight || 0,
  },
  zorro: {
    paddingLeft: paddingSize.zorro,
    paddingRight: paddingSize.zorro,
    paddingTop: paddingSize.zorro,
    paddingBottom: paddingSize.zorro,
  },
  /** 整体xxs(4)边距 */
  xxs: {
    padding: paddingSize.xxs,
  },
  /** 左xxs(4)边距 */
  leftxxs: {
    paddingLeft: paddingSize.xxs,
  },
  /** 顶xxs(4)边距 */
  topxxs: {
    paddingTop: paddingSize.xxs,
  },
  /** 右xxs(4)边距 */
  rightxxs: {
    paddingRight: paddingSize.xxs,
  },
  /** 底xxs(4)边距 */
  btmxxs: {
    paddingBottom: paddingSize.xxs,
  },
  /** 上下xxs(4)边距 */
  tbxxs: {
    paddingTop: paddingSize.xxs,
    paddingBottom: paddingSize.xxs,
  },
  /** 左右xxs(4)边距 */
  lrxxs: {
    paddingLeft: paddingSize.xxs,
    paddingRight: paddingSize.xxs,
  },

  /** 整体xs(6)边距 */
  xs: {
    padding: paddingSize.xs,
  },
  /** 左xs(6)边距 */
  leftxs: {
    paddingLeft: paddingSize.xs,
  },
  /** 顶xs(6)边距 */
  topxs: {
    paddingTop: paddingSize.xs,
  },
  /** 右xs(6)边距 */
  rightxs: {
    paddingRight: paddingSize.xs,
  },
  /** 底xs(6)边距 */
  btmxs: {
    paddingBottom: paddingSize.xs,
  },
  /** 上下xs(6)边距 */
  tbxs: {
    paddingTop: paddingSize.xs,
    paddingBottom: paddingSize.xs,
  },
  /** 左右xs(6)边距 */
  lrxs: {
    paddingLeft: paddingSize.xs,
    paddingRight: paddingSize.xs,
  },

  /** 整体s(8)边距 */
  s: {
    padding: paddingSize.s,
  },
  /** 左s(8)边距 */
  lefts: {
    paddingLeft: paddingSize.s,
  },
  /** 顶s(8)边距 */
  tops: {
    paddingTop: paddingSize.s,
  },
  /** 右s(8)边距 */
  rights: {
    paddingRight: paddingSize.s,
  },
  /** 底s(8)边距 */
  btms: {
    paddingBottom: paddingSize.s,
  },
  /** 上下s(8)边距 */
  tbs: {
    paddingTop: paddingSize.s,
    paddingBottom: paddingSize.s,
  },
  /** 左右s(8)边距 */
  lrs: {
    paddingLeft: paddingSize.s,
    paddingRight: paddingSize.s,
  },

  /** 整体m(10)边距 */
  m: {
    padding: paddingSize.m,
  },
  /** 左m(10)边距 */
  leftm: {
    paddingLeft: paddingSize.m,
  },
  /** 顶m(10)边距 */
  topm: {
    paddingTop: paddingSize.m,
  },
  /** 右m(10)边距 */
  rightm: {
    paddingRight: paddingSize.m,
  },
  /** 底m(10)边距 */
  btmm: {
    paddingBottom: paddingSize.m,
  },
  /** 上下m(10)边距 */
  tbm: {
    paddingTop: paddingSize.m,
    paddingBottom: paddingSize.m,
  },
  /** 左右m(10)边距 */
  lrm: {
    paddingLeft: paddingSize.m,
    paddingRight: paddingSize.m,
  },

  /** 整体l(12)边距 */
  l: {
    padding: paddingSize.l,
  },
  /** 左l(12)边距 */
  leftl: {
    paddingLeft: paddingSize.l,
  },
  /** 顶l(12)边距 */
  topl: {
    paddingTop: paddingSize.l,
  },
  /** 右l(12)边距 */
  rightl: {
    paddingRight: paddingSize.l,
  },
  /** 底l(12)边距 */
  btml: {
    paddingBottom: paddingSize.l,
  },
  /** 上下l(12)边距 */
  tbl: {
    paddingTop: paddingSize.l,
    paddingBottom: paddingSize.l,
  },
  /** 左右l(12)边距 */
  lrl: {
    paddingLeft: paddingSize.l,
    paddingRight: paddingSize.l,
  },

  /** 整体xl(14)边距 */
  xl: {
    padding: paddingSize.xl,
  },
  /** 左xl(14)边距 */
  leftxl: {
    paddingLeft: paddingSize.xl,
  },
  /** 顶xl(14)边距 */
  topxl: {
    paddingTop: paddingSize.xl,
  },
  /** 右xl(14)边距 */
  rightxl: {
    paddingRight: paddingSize.xl,
  },
  /** 底xl(14)边距 */
  btmxl: {
    paddingBottom: paddingSize.xl,
  },
  /** 上下xl(14)边距 */
  tbxl: {
    paddingTop: paddingSize.xl,
    paddingBottom: paddingSize.xl,
  },
  /** 左右xl(14)边距 */
  lrxl: {
    paddingLeft: paddingSize.xl,
    paddingRight: paddingSize.xl,
  },

  /** 整体xxl(16)边距 */
  xxl: {
    padding: paddingSize.xxl,
  },
  /** 左xxl(16)边距 */
  leftxxl: {
    paddingLeft: paddingSize.xxl,
  },
  /** 顶xxl(16)边距 */
  topxxl: {
    paddingTop: paddingSize.xxl,
  },
  /** 右xxl(16)边距 */
  rightxxl: {
    paddingRight: paddingSize.xxl,
  },
  /** 底xxl(16)边距 */
  btmxxl: {
    paddingBottom: paddingSize.xxl,
  },
  /** 上下xxl(16)边距 */
  tbxxl: {
    paddingTop: paddingSize.xxl,
    paddingBottom: paddingSize.xxl,
  },
  /** 左右xxl(16)边距 */
  lrxxl: {
    paddingLeft: paddingSize.xxl,
    paddingRight: paddingSize.xxl,
  },
});

export const margin = StyleSheet.create({
  /** 整体xxs(4)边距 */
  xxs: {
    margin: paddingSize.xxs,
  },
  /** 左xxs(4)边距 */
  leftxxs: {
    marginLeft: paddingSize.xxs,
  },
  /** 顶xxs(4)边距 */
  topxxs: {
    marginTop: paddingSize.xxs,
  },
  /** 右xxs(4)边距 */
  rightxxs: {
    marginRight: paddingSize.xxs,
  },
  /** 底xxs(4)边距 */
  btmxxs: {
    marginBottom: paddingSize.xxs,
  },
  /** 上下xxs(4)边距 */
  tbxxs: {
    marginTop: paddingSize.xxs,
    marginBottom: paddingSize.xxs,
  },
  /** 左右xxs(4)边距 */
  lrxxs: {
    marginLeft: paddingSize.xxs,
    marginRight: paddingSize.xxs,
  },

  /** 整体xs(6)边距 */
  xs: {
    margin: paddingSize.xs,
  },
  /** 左xs(6)边距 */
  leftxs: {
    marginLeft: paddingSize.xs,
  },
  /** 顶xs(6)边距 */
  topxs: {
    marginTop: paddingSize.xs,
  },
  /** 右xs(6)边距 */
  rightxs: {
    marginRight: paddingSize.xs,
  },
  /** 底xs(6)边距 */
  btmxs: {
    marginBottom: paddingSize.xs,
  },
  /** 上下xs(6)边距 */
  tbxs: {
    marginTop: paddingSize.xs,
    marginBottom: paddingSize.xs,
  },
  /** 左右xs(6)边距 */
  lrxs: {
    marginLeft: paddingSize.xs,
    marginRight: paddingSize.xs,
  },

  /** 整体s(8)边距 */
  s: {
    margin: paddingSize.s,
  },
  /** 左s(8)边距 */
  lefts: {
    marginLeft: paddingSize.s,
  },
  /** 顶s(8)边距 */
  tops: {
    marginTop: paddingSize.s,
  },
  /** 右s(8)边距 */
  rights: {
    marginRight: paddingSize.s,
  },
  /** 底s(8)边距 */
  btms: {
    marginBottom: paddingSize.s,
  },
  /** 上下s(8)边距 */
  tbs: {
    marginTop: paddingSize.s,
    marginBottom: paddingSize.s,
  },
  /** 左右s(8)边距 */
  lrs: {
    marginLeft: paddingSize.s,
    marginRight: paddingSize.s,
  },

  /** 整体m(10)边距 */
  m: {
    margin: paddingSize.m,
  },
  /** 左m(10)边距 */
  leftm: {
    marginLeft: paddingSize.m,
  },
  /** 顶m(10)边距 */
  topm: {
    marginTop: paddingSize.m,
  },
  /** 右m(10)边距 */
  rightm: {
    marginRight: paddingSize.m,
  },
  /** 底m(10)边距 */
  btmm: {
    marginBottom: paddingSize.m,
  },
  /** 上下m(10)边距 */
  tbm: {
    marginTop: paddingSize.m,
    marginBottom: paddingSize.m,
  },
  /** 左右m(10)边距 */
  lrm: {
    marginLeft: paddingSize.m,
    marginRight: paddingSize.m,
  },

  /** 整体l(12)边距 */
  l: {
    margin: paddingSize.l,
  },
  /** 左l(12)边距 */
  leftl: {
    marginLeft: paddingSize.l,
  },
  /** 顶l(12)边距 */
  topl: {
    marginTop: paddingSize.l,
  },
  /** 右l(12)边距 */
  rightl: {
    marginRight: paddingSize.l,
  },
  /** 底l(12)边距 */
  btml: {
    marginBottom: paddingSize.l,
  },
  /** 上下l(12)边距 */
  tbl: {
    marginTop: paddingSize.l,
    marginBottom: paddingSize.l,
  },
  /** 左右l(12)边距 */
  lrl: {
    marginLeft: paddingSize.l,
    marginRight: paddingSize.l,
  },

  /** 整体xl(14)边距 */
  xl: {
    margin: paddingSize.xl,
  },
  /** 左xl(14)边距 */
  leftxl: {
    marginLeft: paddingSize.xl,
  },
  /** 顶xl(14)边距 */
  topxl: {
    marginTop: paddingSize.xl,
  },
  /** 右xl(14)边距 */
  rightxl: {
    marginRight: paddingSize.xl,
  },
  /** 底xl(14)边距 */
  btmxl: {
    marginBottom: paddingSize.xl,
  },
  /** 上下xl(14)边距 */
  tbxl: {
    marginTop: paddingSize.xl,
    marginBottom: paddingSize.xl,
  },
  /** 左右xl(14)边距 */
  lrxl: {
    marginLeft: paddingSize.xl,
    marginRight: paddingSize.xl,
  },

  /** 整体xxl(16)边距 */
  xxl: {
    margin: paddingSize.xxl,
  },
  /** 左xxl(16)边距 */
  leftxxl: {
    marginLeft: paddingSize.xxl,
  },
  /** 顶xxl(16)边距 */
  topxxl: {
    marginTop: paddingSize.xxl,
  },
  /** 右xxl(16)边距 */
  rightxxl: {
    marginRight: paddingSize.xxl,
  },
  /** 底xxl(16)边距 */
  btmxxl: {
    marginBottom: paddingSize.xxl,
  },
  /** 上下xxl(16)边距 */
  tbxxl: {
    marginTop: paddingSize.xxl,
    marginBottom: paddingSize.xxl,
  },
  /** 左右xxl(16)边距 */
  lrxxl: {
    marginLeft: paddingSize.xxl,
    marginRight: paddingSize.xxl,
  },
});
