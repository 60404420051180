import {FadeInView} from '@/components/basic/animations';
import theme from '@/style';
import React, {useEffect, useRef, useState} from 'react';
import {ScrollView, View} from 'react-native';
import {useInnerStyle} from './wallet.hooks';
import {LazyImageBackground} from '@/components/basic/image';
import {walletBackground} from './wallet.variable';
import globalStore from '@/services/global.state';
import WalletTotalWallet from './wallet-total-wallet';
import WalletList from './wallet-list';
import WalletTransfer from './wallet-transfer';
import {WalletAmountListItem, getWalletAmountList} from './wallet.service';
import {goTo, goBack} from '@/utils';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {useRoute} from '@react-navigation/native';
import {BasicObject} from '@/types';

const WalletPage = () => {
  const {
    size: {topBgWidth, topBgHeight},
    walletStyle,
  } = useInnerStyle();
  const [login, setLogin] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const allAmount = useRef<number>();
  const [thirdAmount, setThirdAmount] = useState(0);
  const [mainAmount, setMainAmount] = useState(0);
  const [walletList, setWalletList] = useState<WalletAmountListItem[]>([]);
  const refreshWallet = (loading = true) => {
    loading && globalStore.globalLoading.next(true);
    if (login) {
      globalStore.updateAmount.next({});
    }
    getWalletAmountList()
      .then(info => {
        const _amount = info.totalCreditAmount;
        if (allAmount.current == null) {
          allAmount.current = _amount;
        }
        setThirdAmount(_amount);
        setMainAmount(allAmount.current - _amount);
        setWalletList(info.gamesList);
      })
      .finally(() => {
        globalStore.globalLoading.next(false);
      });
  };
  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setLogin(!!token);
      if (token) {
        refreshWallet();
      }
    });
    const moneySub = globalStore.amountChanged.subscribe(res => {
      setAmount(res.current);
    });

    return () => {
      sub.unsubscribe();
      moneySub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toRecharge = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    // 跳转recharge
    goTo('Recharge');
  };

  const route = useRoute();

  return (
    <FadeInView style={[walletStyle.page, theme.position.rel]}>
      <DetailNavTitle
        title={'My wallet'}
        hideServer={true}
        hideAmount={true}
        onBack={
          (route.params as BasicObject)?.hideBack ? undefined : () => goBack()
        }
      />

      <ScrollView style={[theme.flex.flex1]}>
        <LazyImageBackground
          style={[theme.position.abs, walletStyle.topArea]}
          imageUrl={walletBackground}
          width={topBgWidth}
          height={topBgHeight}
        />
        <View style={[walletStyle.container, theme.padding.lrl]}>
          <WalletTotalWallet
            login={login}
            amount={amount}
            onRecharge={toRecharge}
          />
          <WalletTransfer
            login={login}
            mainAmount={mainAmount}
            thirdAmount={thirdAmount}
            onRecharge={toRecharge}
            onRefreshTransfer={refreshWallet}
          />
          <WalletList walletList={walletList} />
        </View>
      </ScrollView>
    </FadeInView>
  );
};

export default WalletPage;
