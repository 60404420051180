import {SVGProps} from '@/types';
import React from 'react';

const FilterIcon = ({color = '#31373D', width = 14, height = 14}: SVGProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15007_35460)">
        <path
          d="M8.06582 6.54454L9.87649 4.63867C9.98416 4.52505 10.1326 4.45886 10.289 4.45466C10.4455 4.45046 10.5973 4.50859 10.7109 4.61627C10.8245 4.72395 10.8907 4.87235 10.8949 5.02883C10.8991 5.18531 10.841 5.33705 10.7333 5.45067L9.08409 7.18667L9.24649 6.77974V12.824C9.2455 13.1271 9.1246 13.4175 8.91019 13.6317C8.69579 13.8459 8.40531 13.9666 8.10222 13.9673C7.94887 13.9675 7.79707 13.9366 7.65593 13.8766C7.51479 13.8166 7.38723 13.7288 7.28089 13.6183L5.35542 11.6293C5.14885 11.4162 5.03337 11.131 5.03342 10.8341V7.05694L1.01075 3.1752C0.750302 2.91504 0.573083 2.58331 0.501622 2.22218C0.43016 1.86105 0.467683 1.48682 0.60942 1.14707C0.750459 0.806933 0.989322 0.516336 1.29572 0.312123C1.60213 0.10791 1.96227 -0.000720686 2.33049 3.59833e-06H11.5789C12.3349 3.59833e-06 13.0106 0.451737 13.299 1.14987C13.4395 1.48751 13.477 1.85917 13.4067 2.21806C13.3365 2.57696 13.1617 2.90705 12.9042 3.1668L12.0866 4.02734C12.0333 4.08353 11.9694 4.12868 11.8987 4.16019C11.8279 4.19171 11.7516 4.20898 11.6742 4.21102C11.5968 4.21305 11.5197 4.19982 11.4474 4.17207C11.375 4.14431 11.3089 4.10259 11.2527 4.04927C11.1965 3.99595 11.1513 3.93209 11.1198 3.86133C11.0883 3.79056 11.071 3.71428 11.069 3.63685C11.067 3.55941 11.0802 3.48233 11.108 3.41C11.1357 3.33768 11.1774 3.27153 11.2308 3.21534C11.5481 2.87934 11.5481 2.87934 12.0596 2.3436C12.1561 2.24891 12.2221 2.12753 12.2492 1.99503C12.2763 1.86254 12.2632 1.72497 12.2115 1.59999C12.1599 1.47502 12.072 1.36832 11.9593 1.29361C11.8466 1.21889 11.7141 1.17957 11.5789 1.18067H2.32955C2.19485 1.18033 2.0631 1.22009 1.95107 1.29489C1.83904 1.36968 1.75181 1.47613 1.70049 1.60067C1.64852 1.723 1.634 1.85798 1.65876 1.98856C1.68351 2.11914 1.74643 2.23945 1.83955 2.33427L6.21689 6.55574V10.8183L8.06675 12.7325V6.54454H8.06582Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_15007_35460">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FilterIcon;
