import {Animated, View, StyleSheet} from 'react-native';
import {ALL_BALLS} from './constant';
import Ball from './components/ball';
import React from 'react';
import theme from '@/style';
import BallResultBottom from '@/components/svgs/basic/ball-result-bottom';
import Video from '@/components/basic/video';
const ColorBallResult = ({digit = ''}: {digit: string}) => {
  const transAnim = React.useRef(new Animated.Value(72)).current;
  const [paused, setPaused] = React.useState(true);

  React.useEffect(() => {
    if (digit) {
      setPaused(false);
      Animated.sequence([reset(), startAnim(digit)]).start(() => {
        setPaused(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [digit]);

  const startAnim = (num: string) => {
    return Animated.timing(transAnim, {
      toValue: digit === '0' ? -10 * 72 : -num * 72,
      duration: 1000,
      // easing: Easing.l,
      useNativeDriver: true,
    });
  };

  const reset = () => {
    return Animated.timing(transAnim, {
      toValue: 72,
      duration: 0,
      // easing: Easing.l,
      useNativeDriver: true,
    });
  };

  return (
    <View
      style={[
        theme.margin.lefts,
        theme.flex.centerByCol,
        theme.overflow.hidden,
        theme.borderRadius.m,
        styles.container,
      ]}>
      {/* <Image
        resizeMode="contain"
        source={require('@components/assets/imgs/game/color/result-bottom.webp')}
      /> */}
      <View style={styles.bottom}>
        <BallResultBottom width={73} height={12} />
      </View>
      <Animated.View
        style={[
          {
            transform: [
              {
                translateY: transAnim,
              },
            ],
          },
        ]}>
        {ALL_BALLS.concat([
          {
            digit: '0',
            color: 'r,v',
          },
        ]).map((item, index) => {
          return (
            <View key={index} style={[theme.padding.tbm]}>
              <Ball
                onlyColor={false}
                digit={item.digit}
                color={item.color}
                ballSize={52}
                fontSize={20}
              />
            </View>
          );
        })}
      </Animated.View>
      <Video
        audioOnly
        paused={paused}
        source={require('@components/assets/music/rolling.mp3')}
        src="/assets/mp3/rolling.mp3"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // backgroundColor: '#B098D8',
    backgroundColor: theme.backgroundColor.diceResultBg,
    width: 76,
    height: 72,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.50)',
  },
  bottom: {
    position: 'absolute',
    bottom: 0,
    left: 1,
    right: 2,
  },
});

export default React.memo(ColorBallResult);
