import {View, StyleSheet} from 'react-native';

import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {COLOR_MAP} from '../../constant';
import {useTranslation} from 'react-i18next';

export interface BigSmallProps {
  big?: number;
  small?: number;
}

const BigSmall = ({big = 0, small = 0}: BigSmallProps) => {
  const {i18n} = useTranslation();
  return (
    <View
      style={[
        theme.background.white,
        theme.padding.l,
        theme.margin.topl,
        // eslint-disable-next-line react-native/no-inline-styles
        {borderTopColor: theme.backgroundColor.grey, borderTopWidth: 1},
      ]}>
      <View
        style={[theme.flex.row, theme.flex.centerByCol, theme.flex.between]}>
        <Text color={COLOR_MAP[2]} size="medium" fontFamily="fontInterBold">
          {i18n.t('game-page.label.small').toUpperCase()}
        </Text>
        <Text color={COLOR_MAP[1]} size="medium" fontFamily="fontInterBold">
          {i18n.t('game-page.label.big').toUpperCase()}
        </Text>
      </View>
      <View
        style={[
          styles.bar,
          theme.flex.row,
          theme.margin.topxxs,
          // eslint-disable-next-line react-native/no-inline-styles
          {backgroundColor: '#CED2D6'},
        ]}>
        <View
          style={[
            {
              backgroundColor: COLOR_MAP[2],
              width: `${small}%`,
            },
            theme.flex.center,
          ]}>
          <Text color={theme.basicColor.white} fontFamily="fontInterBold">
            {small}%
          </Text>
        </View>
        {big || small ? (
          <View style={[theme.flex.flex1, {backgroundColor: COLOR_MAP[0]}]} />
        ) : null}
        <View
          style={[
            {
              backgroundColor: COLOR_MAP[1],
              width: `${big}%`,
            },
            theme.flex.center,
          ]}>
          <Text color={theme.basicColor.white} fontFamily="fontInterBold">
            {big}%
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  bar: {
    height: 16,
    borderRadius: 16,
    overflow: 'hidden',
  },
});

export default BigSmall;
