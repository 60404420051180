import {View} from 'react-native';
import {getSattaConfig} from './satta-service';
import React from 'react';
import {BasicObject} from '@/types';
import Text from '@/components/basic/text';
import theme from '@/style';
const SattaRule = ({id}: {id?: number}) => {
  const [rule, setRule] = React.useState<BasicObject>({});

  React.useEffect(() => {
    if (id) {
      getSattaConfig(id + '').then(res => {
        setRule(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={[{paddingHorizontal: theme.paddingSize.m * 2}]}>
      {Object.keys(rule).map((key, _ii) => (
        <Text
          key={_ii}
          size="medium"
          style={[theme.font.medium, theme.margin.btml]}>
          {key} {rule[key]}
        </Text>
      ))}
    </View>
  );
};

export default SattaRule;
