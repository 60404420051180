import {Subject} from 'rxjs';

class HomeStore {
  refresh = new Subject<void>();
  refreshEnd = new Subject<void>();

  changeTab = new Subject<0 | 1 | 2 | 3>();
}

const homeStore = new HomeStore();

export default homeStore;
