import {FadeInView} from '@/components/basic/animations';
import React from 'react';
import {getDistinctFieldValues, getHomeGames} from '../home.service';
import globalStore from '@/services/global.state';
import {Image, View} from 'react-native';
import Text from '@/components/basic/text';
import {HomeGameListItem} from '../home.type';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import {NoMoreData} from '@/components/basic/default-page';
import LazyImage from '@/components/basic/image';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import HomeFloorHeader from './home-floor-header';
import {useTranslation} from 'react-i18next';
import {toGame} from '@/common-pages/game-navigate';
import homeStore from '../home.refresh.service';
import Picker from '@/components/basic/picker';

const HomeLive = () => {
  const [distinctFieldValues, setDistinctFieldValues] = React.useState<
    string[]
  >(['All']);
  const [currentDistinct, setCurrentDistinct] = React.useState('All');
  const [loading, setLoading] = React.useState(false);
  const currentDistinctRef = React.useRef('');
  const [sourceValues, setSourceValues] = React.useState<
    {label: string; value: string}[]
  >([{label: 'All', value: 'All'}]);
  const [currendSource, setCurrentSource] = React.useState('All');
  const currentSourceRef = React.useRef('');
  const initLive = () => {
    const distinctList =
      (globalStore.getItem('HomeLiveDistinctFieldValues') as string[]) || [];
    if (distinctList.length) {
      setDistinctFieldValues(distinctList);
      setCurrentDistinct(distinctList[0]);
    }
    const sourceList =
      (globalStore.getItem('HomeLiveSourceValues') as {
        label: string;
        value: string;
      }[]) || [];
    if (sourceList.length) {
      setSourceValues(sourceList);
      setCurrentSource(sourceList[0].value);
    }
    getDistinctFieldValues('game_type', '6')
      .then(res => {
        if (res.join() !== distinctFieldValues.join()) {
          globalStore.setItem('HomeLiveDistinctFieldValues', res);
          res.unshift('All');
          setDistinctFieldValues(res);
          setCurrentDistinct(res[0]);
        }
      })
      .catch(() => {
        setDistinctFieldValues([]);
      });
    getDistinctFieldValues('source', '6')
      .then(res => {
        if (res.join() !== sourceValues.map(v => v.value).join()) {
          const list = res.map(v => ({label: v, value: v}));
          globalStore.setItem('HomeLiveSourceValues', list);
          list.unshift({label: 'All', value: 'All'});
          setSourceValues(list);
          setCurrentSource(list[0].value);
        }
      })
      .catch(() => {
        setDistinctFieldValues([]);
      });
  };
  React.useEffect(() => {
    initLive();
    const sub = homeStore.refresh.subscribe(() => {
      getLiveList();
    });
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const pageNo = React.useRef(1);
  const total = React.useRef(0);
  const noMore = React.useRef(false);
  const [gameList, setGameList] = React.useState<(HomeGameListItem | null)[]>(
    Array(9).fill(null),
  );
  const getLiveList = (loadMore = false) => {
    setLoading(true);
    if (loadMore) {
      ++pageNo.current;
    } else {
      pageNo.current = 1;
    }
    getHomeGames({
      categoryId: 6,
      pageNo: pageNo.current,
      pageSize: 30,
      gameType:
        currentDistinctRef.current === 'All'
          ? undefined
          : currentDistinctRef.current,
      source:
        currentSourceRef.current === 'All'
          ? undefined
          : currentSourceRef.current,
    })
      .then(res => {
        setLoading(false);
        total.current = res.totalSize;
        noMore.current = res.totalPages <= pageNo.current;
        if (loadMore) {
          setGameList([...gameList, ...res.content]);
        } else {
          setGameList(res.content);
        }
      })
      .finally(() => {
        setLoading(false);
        !loadMore && homeStore.refreshEnd.next();
      });
  };
  React.useEffect(() => {
    currentDistinctRef.current = currentDistinct;
    currentSourceRef.current = currendSource;
    currentDistinct && currendSource && getLiveList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDistinct, currendSource]);
  const {screenWidth} = useScreenSize();
  const itemWidth =
    (screenWidth - theme.paddingSize.l * 2 - theme.paddingSize.xs * 2) / 3;
  const i18n = useTranslation();
  const [pickerOpen, setPickerOpen] = React.useState(false);
  return (
    <FadeInView
      style={[
        // eslint-disable-next-line react-native/no-inline-styles
        {
          height: 'auto',
        },
        theme.padding.btml,
      ]}>
      <HomeFloorHeader
        color="rgba(201, 228, 209, 0.50)"
        title={i18n.t('home.tab.liveCasino')}
        showRightDom
        rightDom={
          sourceValues.length > 1 ? (
            <Picker
              list={sourceValues}
              value={currendSource}
              onValueChange={value => {
                setCurrentSource(value);
                setPickerOpen(false);
              }}
              title={i18n.t('home.label.provedores')}
              open={pickerOpen}
              setOpen={setPickerOpen}
              titleRender={
                <View
                  style={[
                    theme.flex.centerByCol,
                    theme.flex.row,
                    theme.background.white,
                    theme.borderRadius.xs,
                    theme.border.greyAccent,
                    theme.flex.between,
                    theme.padding.tbxxs,
                    theme.padding.lrs,
                    // eslint-disable-next-line react-native/no-inline-styles
                    {
                      minWidth: 80,
                    },
                  ]}>
                  <Text calc>{currendSource}</Text>
                  <Image
                    source={require('@assets/icons/down.webp')}
                    style={[theme.icon.xs]}
                  />
                </View>
              }
            />
          ) : (
            <></>
          )
        }
      />
      {distinctFieldValues?.length > 0 && (
        <View
          style={[
            theme.flex.row,
            theme.padding.leftl,
            theme.padding.rightxs,
            theme.flex.wrap,
          ]}>
          {distinctFieldValues.map((type, index) => {
            return (
              <NativeTouchableOpacity
                onPress={() => {
                  setCurrentDistinct(type);
                }}
                style={[
                  theme.margin.rights,
                  theme.margin.btms,
                  theme.background.white,
                  theme.borderRadius.xs,
                  theme.padding.tbxxs,
                  theme.padding.lrs,
                  currentDistinct === type && [theme.background.main],
                ]}
                key={index}>
                <Text
                  calc
                  color={
                    currentDistinct === type
                      ? theme.basicColor.white
                      : theme.fontColor.main
                  }>
                  {type}
                </Text>
              </NativeTouchableOpacity>
            );
          })}
        </View>
      )}
      {gameList?.length ? (
        <>
          <View
            style={[
              theme.flex.row,
              theme.flex.wrap,
              theme.padding.leftl,
              theme.padding.rightxs,
            ]}>
            <View />
            {gameList.map((game, index) => {
              return (
                <NativeTouchableOpacity
                  onPress={() => game && toGame(game)}
                  style={[
                    theme.borderRadius.m,
                    theme.overflow.hidden,
                    theme.margin.rightxs,
                    theme.margin.btms,
                    {
                      width: itemWidth,
                      maxWidth: itemWidth,
                    },
                  ]}
                  key={index}>
                  {game ? (
                    <LazyImage
                      imageUrl={game.gamePic}
                      resizeMode="cover"
                      width={itemWidth}
                      height={itemWidth}
                    />
                  ) : (
                    <View
                      style={[
                        {height: itemWidth, width: itemWidth},
                        theme.background.palegrey,
                      ]}
                    />
                  )}
                  <View
                    style={[
                      theme.background.white,
                      theme.padding.tbxxs,
                      theme.padding.lrs,
                    ]}>
                    <Text ellipsizeMode="middle" main numberOfLines={1} calc>
                      {game?.name || '--'}
                    </Text>
                    <View style={[theme.flex.row, theme.flex.centerByCol]}>
                      <Text fontSize={10} second calc>
                        {game?.provider || '--'}
                      </Text>
                    </View>
                  </View>
                </NativeTouchableOpacity>
              );
            })}
          </View>
          <View
            style={[theme.margin.topxs, theme.margin.btms, theme.flex.center]}>
            <Text accent calc>
              {gameList.length} / {total.current}
            </Text>
          </View>
          {gameList.length < total.current ? (
            <View style={[theme.flex.row]}>
              <View style={[theme.flex.flex1]} />
              <NativeTouchableOpacity
                onPress={() => {
                  !loading && getLiveList(true);
                }}
                style={[
                  theme.background.white,
                  theme.borderRadius.m,
                  theme.flex.flex2,
                  theme.flex.center,
                  theme.padding.l,
                ]}>
                <Text blod calc>
                  {i18n.t('label.loadMore')}
                </Text>
              </NativeTouchableOpacity>
              <View style={[theme.flex.flex1]} />
            </View>
          ) : (
            <NoMoreData />
          )}
        </>
      ) : (
        <NoMoreData />
      )}
    </FadeInView>
  );
};

export default HomeLive;
