import theme from '@/style';
import {View} from 'react-native';
import BetsItem from '../component/bets-item';
import BetsLabel from '../component/bets-label';
import {BETS_BG} from '../constant';
import React from 'react';
import {
  LotteryMode,
  MixBetOpt,
  CartListItem,
  roundTicketHisType,
} from '../mix-lottery-service';
import Text from '@/components/basic/text';
import {padZero} from '@/utils';
// import globalStore from '@/services/global.state';
// import {useTranslation} from 'react-i18next';
export interface BetsTypeItemProps {
  type: 'A' | 'B' | 'C' | 'D';
  options: MixBetOpt[];
  isDigit: boolean;
  isSingle: boolean;
  mode: LotteryMode;
  onBets?: (item: MixBetOpt & {betItem?: number}, view?: View | null) => void;
  cartList: CartListItem[];
  currentPrize: number;
  cartListShadow?: CartListItem[];
  index?: number;
  hasSelected?: roundTicketHisType[];
  disabledAll?: boolean;
}

const DEFAULT = ['A', 'B', 'C', 'D'];
const SIDE_TYPE = ['Odd', 'Even', 'Big', 'Small'];
const F_P_C_TYPE = ['Fish', 'Prawn', 'Crab'];

const BetsDigitItem = ({
  type,
  options = [],
  isDigit = false,
  isSingle = false,
  onBets,
  mode,
  cartList = [],
  currentPrize,
  index = 0,
  cartListShadow = [],
  hasSelected = [],
  disabledAll,
}: BetsTypeItemProps) => {
  // const {t} = useTranslation();
  // const betIsDisabled = (item: MixBetOpt) => {
  //   if (mode === LotteryMode['Two Side']) {
  //     const currentRow = cartList.find(
  //       pie =>
  //         pie.type === type &&
  //         pie.selectPrize === currentPrize &&
  //         pie.modeID === mode,
  //     );
  //     if (currentRow) {
  //       if (currentRow.value === 'Big' || currentRow.value === 'Small') {
  //         return item.value === 'Odd' || item.value === 'Even';
  //       } else {
  //         return item.value === 'Big' || item.value === 'Small';
  //       }
  //     }
  //   }
  //   return false;
  // };

  const getTWSType = (betItem: number) => {
    const TYPE_INDEX = (betItem - 1) % 4;
    const INDEX = Math.trunc((betItem - 1) / 4);
    return {
      type: DEFAULT[INDEX],
      value: SIDE_TYPE[TYPE_INDEX],
    };
  };

  const getSub = (v: string) => {
    if (v === 'Small' || v === 'Big') {
      return SIDE_TYPE.slice(2, 4);
    } else if (v === 'Even' || v === 'Odd') {
      return SIDE_TYPE.slice(0, 2);
    }
    return [];
  };

  const betHasSelect = (item: MixBetOpt, isDisabled: boolean = true) => {
    if (mode === LotteryMode['Two Side']) {
      const hasBuyRow = hasSelected.filter(
        pie =>
          pie.modeID === mode &&
          pie.tabID === currentPrize &&
          getTWSType(pie.betItem).type === type,
      );
      if (hasBuyRow.length > 0) {
        const INDEX_S = hasBuyRow.map(pie => (pie.betItem - 1) % 4);
        const checkV_S = INDEX_S.map(pie => SIDE_TYPE[pie]);
        if (!isDisabled) {
          return checkV_S.includes(item.value);
        } else {
          const diff = checkV_S.length === 2 ? checkV_S : getSub(checkV_S[0]);
          const arrayDifference = SIDE_TYPE.filter(
            pie => !diff.includes(pie),
          ).concat(checkV_S);
          return arrayDifference.includes(item.value);
        }
      }
    }
    if (mode === LotteryMode['1 Digit']) {
      const has = hasSelected.find(
        pie =>
          pie.modeID === mode &&
          pie.tabID === currentPrize &&
          pie.betNo === padZero(item.value, 4),
      );
      return Boolean(has);
    }
    if (mode === LotteryMode.FishPrawnCrab) {
      const has = hasSelected.find(
        pie =>
          pie.modeID === mode &&
          pie.tabID === currentPrize &&
          pie.betItem === F_P_C_TYPE.findIndex(p => p === item.value) + 1,
      );
      return Boolean(has);
    }
    return false;
  };

  const betIsSelected = (item: MixBetOpt) => {
    const isSpecial =
      mode === LotteryMode['Two Side'] ||
      mode === LotteryMode['1 Digit'] ||
      mode === LotteryMode.FishPrawnCrab;
    const target = isSpecial ? cartList : cartListShadow;
    const res = target.find(
      pie =>
        pie.type === type &&
        pie.modeID === mode &&
        pie?.value === item.value &&
        pie.selectPrize === currentPrize,
    );
    return Boolean(res);
  };

  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.centerByCol,
        isSingle
          ? {paddingHorizontal: theme.paddingSize.m * 2}
          : theme.padding.lrl,
        isSingle && {paddingVertical: theme.paddingSize.l * 2},
        {backgroundColor: BETS_BG[type]},
      ]}>
      {!isSingle && <BetsLabel type={type} />}

      <View
        style={[
          theme.flex.flex1,
          !isSingle && theme.margin.tbl,
          !isSingle && {marginLeft: theme.paddingSize.m * 2},
        ]}>
        {!isDigit && (
          <View
            style={[
              theme.flex.row,
              theme.flex.flex1,
              theme.flex.between,
              {gap: theme.paddingSize.s},
            ]}>
            {options.map((item, i) => {
              return (
                <BetsItem
                  // onDisabledPress={() => {
                  //   if (!disabledAll) {
                  //     globalStore.globalWaringTotal(
                  //       t('mix-lotto.tip.numberRepeated'),
                  //     );
                  //   }
                  // }}
                  onItemPress={v => {
                    onBets?.({...item, betItem: index * options.length + i}, v);
                  }}
                  disabled={disabledAll}
                  checked={
                    betIsSelected(item) ||
                    betHasSelect(item, LotteryMode['Two Side'] !== mode)
                  }
                  key={i}
                  isDigit={isDigit}
                  rate={`${item.odds}X`}
                  digit={item.label}
                  type={type}
                />
              );
            })}
          </View>
        )}
        {isSingle && isDigit && (
          <>
            <View
              style={[theme.flex.row, theme.flex.flex1, theme.flex.between]}>
              {options.slice(0, 5).map((pie, _ii) => (
                <View style={[theme.flex.center]} key={_ii}>
                  <BetsItem
                    onItemPress={v => {
                      onBets?.(pie, v);
                    }}
                    disabled={disabledAll || betHasSelect(pie)}
                    checked={betIsSelected(pie) || betHasSelect(pie)}
                    isPrimary
                    isDigit
                    digit={pie.label}
                    type={type}
                  />
                  <Text fontWeight="500" style={[theme.margin.topxxs]}>
                    {pie.odds}X
                  </Text>
                </View>
              ))}
            </View>
            <View
              style={[
                theme.flex.row,
                theme.flex.flex1,
                theme.flex.between,
                theme.margin.tops,
              ]}>
              {options.slice(5).map((pie, _ii) => (
                <View style={[theme.flex.center]} key={_ii}>
                  <BetsItem
                    isPrimary
                    isDigit
                    digit={pie.label}
                    type={type}
                    disabled={disabledAll || betHasSelect(pie)}
                    checked={betIsSelected(pie) || betHasSelect(pie)}
                    onItemPress={v => {
                      onBets?.(pie, v);
                    }}
                  />
                  <Text fontWeight="500" style={[theme.margin.topxxs]}>
                    {pie.odds}X
                  </Text>
                </View>
              ))}
            </View>
          </>
        )}
        {!isSingle && isDigit && (
          <>
            <View
              style={[theme.flex.row, theme.flex.flex1, theme.flex.between]}>
              {options.slice(0, 5).map(pie => (
                <BetsItem
                  checked={betIsSelected(pie)}
                  key={pie.label}
                  isDigit
                  disabled={disabledAll}
                  digit={pie.label}
                  type={type}
                  onItemPress={v => {
                    onBets?.(pie, v);
                  }}
                />
              ))}
            </View>
            <View
              style={[
                theme.flex.row,
                theme.flex.flex1,
                theme.flex.between,
                theme.margin.tops,
              ]}>
              {options.slice(5).map(pie => (
                <BetsItem
                  checked={betIsSelected(pie)}
                  key={pie.label}
                  isDigit
                  disabled={disabledAll}
                  digit={pie.label}
                  type={type}
                  onItemPress={v => {
                    onBets?.(pie, v);
                  }}
                />
              ))}
            </View>
          </>
        )}
      </View>
    </View>
  );
};

export default React.memo(BetsDigitItem);
