import OrderCard from '@/components/business/order/order-card';
import theme from '@/style';
import React from 'react';
import {View} from 'react-native';
import DrawResult from '../../components/draw-result';
import ColorResult from '../../components/color-result';
import PageNumber from '@/components/business/games/page';
import {ColorListItem, getOrderListColor} from '../../color-service';
import dayjs from 'dayjs';
import NoData from '@/components/basic/error-pages/no-data';
import Spin from '@/components/basic/spin';
import Clipboard from '@react-native-clipboard/clipboard';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {goTo} from '@/utils';
const pageSize = 3;

const MyOrder = ({
  isActive,
  refreshCode = 0,
}: {
  isActive?: boolean;
  refreshCode?: number;
}) => {
  const [list, setList] = React.useState<ColorListItem[]>([]);
  const [total, setTotal] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [initPage, setInitPage] = React.useState(1);
  const {i18n} = useTranslation();
  const getOrderList = async (pageNo: number = 1) => {
    try {
      if (!firstLoad) {
        setLoading(true);
      }
      const yearMonth = dayjs().format('YYYYMM');
      const res = await getOrderListColor({
        orderStatus: '3',
        pageNo,
        pageSize,
        yearMonth,
      });
      if (res) {
        setTotal(res.totalSize);
        setList(res.content);
      }
    } finally {
      setFirstLoad(false);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (isActive) {
      if (!globalStore.token) {
        goTo('Login');
        setFirstLoad(false);
        return;
      }
      getOrderList().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  React.useEffect(() => {
    if (refreshCode && isActive) {
      if (!globalStore.token) {
        return;
      }
      getOrderList().then();
      setInitPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCode]);

  const onCopy = (id: string) => {
    Clipboard.setString(id);
    globalStore.globalSucessTotal(i18n.t('share.copy-success'));
  };

  return (
    <Spin loading={firstLoad || loading} style={[theme.flex.flex1]}>
      {!firstLoad && !loading && list && list.length === 0 ? <NoData /> : null}
      {list.map((item, _i) => (
        <View key={_i}>
          <OrderCard
            id={item.groupOrderNo}
            gameName={item.gameName}
            payment={item.totalAmount}
            awardAmount={item.awardAmount}
            drawTime={dayjs(item.openTime).format('DD-MM hh:mm A')}
            betsTime={dayjs(item.createTime).format('DD-MM-YYYY hh:mm:ss A')}
            onCopy={() => onCopy(item.groupOrderNo)}
            result={
              <DrawResult
                onlyColor={false}
                issueNum={item.issueNo}
                digit={item.digitResult}
                color={item.colorResult}
              />
            }
            status={item.openStatus === 1 ? 2 : item.awardAmount > 0 ? 1 : 0}
          />
          <ColorResult
            subsetList={item.subsetList}
            isOpen={item.openStatus !== 1}
          />
        </View>
      ))}
      {total > 3 ? (
        <PageNumber
          page={initPage}
          total={total}
          pageSize={pageSize}
          onPageChange={p => {
            setInitPage(p);
            getOrderList(p).then();
          }}
        />
      ) : null}
    </Spin>
  );
};

export default React.memo(MyOrder);
