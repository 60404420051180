import React from 'react';
import TableHeader from '../../components/table-header';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import Page from '@/components/business/games/page';
import NoData from '@/components/basic/error-pages/no-data';
import ResultItem from './result-item';
import dayjs from 'dayjs';
import {IMatkaResultVo, getMatkaResultList} from '../../satta-service';

const pageSize = 10;

const SattaResult = ({gameId}: {gameId: string}) => {
  const [total, setTotal] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [list, setList] = React.useState<IMatkaResultVo[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getList().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getList = async (page: number = 1) => {
    try {
      setLoading(true);
      const res = await getMatkaResultList(page, pageSize, +gameId);
      if (res.content) {
        setTotal(res.totalSize);
        setList(res.content);
      }
    } finally {
      setFirstLoad(false);
      setLoading(false);
    }
  };

  return (
    <Spin
      loading={firstLoad || loading}
      style={[
        // eslint-disable-next-line react-native/no-inline-styles
        {minHeight: 300},
        theme.flex.flex1,
        theme.background.lightGrey,
        theme.padding.topl,
      ]}>
      <TableHeader headers={['issue', 'numbers', 'time']} />
      {!firstLoad && !loading && list && list.length === 0 ? <NoData /> : null}
      {list.map((item, index) => (
        <ResultItem
          key={index}
          issueNum={item.issueNo}
          index={index}
          digit={`${item.openNum}-${item.jodi}-${item.closeNum}`}
          drawTime={dayjs(item.createTime).format('DD-MM-YYYY')}
        />
      ))}
      {list.length > 0 ? (
        <Page
          total={total}
          pageSize={pageSize}
          onPageChange={p => {
            getList(p).then();
          }}
        />
      ) : null}
    </Spin>
  );
};

export default SattaResult;
