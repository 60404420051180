import React from 'react';
import {View, StyleSheet, Image} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import LazyImage from '@basicComponents/image';
import {WinnerResponse} from '../../color-service';
import Price from '@/components/business/price';
const first = require('@components/assets/imgs/game/color/01.webp');
const second = require('@components/assets/imgs/game/color/02.webp');
const third = require('@components/assets/imgs/game/color/03.webp');
export interface WinnerItemProps {
  index?: number;
  page?: number;
}

const WinnerItem = ({
  index = 0,
  page = 1,
  userAvatar,
  bonus,
  userPhone,
}: WinnerItemProps & WinnerResponse) => {
  const rank = React.useMemo(() => {
    return (page - 1) * 10 + index + 1;
  }, [index, page]);

  const rankIcon = React.useMemo(() => {
    if (rank <= 3) {
      const image = rank === 1 ? first : rank === 2 ? second : third;
      return <Image source={image} style={[styles.rankImage]} />;
    }
    return (
      <View style={[styles.rank, theme.flex.center]}>
        <Text color={'#5F6975'}>{rank}</Text>
      </View>
    );
  }, [rank]);

  return (
    <View
      style={[
        theme.background.white,
        theme.padding.lrl,
        theme.padding.btml,
        index === 0 && theme.padding.topl,
      ]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.between,
          theme.flex.centerByCol,
          styles.itemContainer,
        ]}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          {rankIcon}
          <LazyImage
            radius={12}
            imageUrl={userAvatar}
            width={24}
            height={24}
            occupancy="transparent"
          />
          <Text size="small" style={[theme.margin.leftxxs]}>
            {userPhone}
          </Text>
        </View>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Text fontFamily="fontDinBold">Won </Text>
          <Price price={bonus} textProps={{size: 'large'}} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    height: 40,
  },
  rank: {
    height: 20,
    borderRadius: 4,
    overflow: 'hidden',
    minWidth: 20,
    marginRight: 12,
    backgroundColor: '#E5E5E5',
  },
  rankImage: {
    width: 20,
    height: 20,
    marginRight: 12,
  },
});

export default WinnerItem;
