import theme from '@/style';
import React from 'react';
import {View} from 'react-native';
import Text from '@/components/basic/text';
import CarNumber from './car-number';

const RacecarResult = ({num, roundNum}: {num?: number; roundNum?: string}) => {
  return (
    <View
      style={[
        theme.padding.lrl,
        theme.padding.tbxxl,
        theme.background.white,
        theme.borderRadius.m,
        theme.margin.lrl,
        theme.margin.topl,
      ]}>
      <Text fontFamily="fontInterBold" size="medium">
        {roundNum} Result:
      </Text>
      <View style={[theme.margin.topl, {gap: theme.paddingSize.xxs}]}>
        <Text fontSize={9} fontFamily="fontInterBold" accent>
          RESULT
        </Text>
        <View style={[theme.flex.center, theme.padding.tbl]}>
          <CarNumber num={num} />
        </View>
      </View>
    </View>
  );
};

export default RacecarResult;
