import {View, Image} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import {CAR_MAP, COLOR_MAP} from './constant';
import theme from '@/style';

export interface CarNumberProps {
  num?: number;
  type?: 'car' | 'square';
  squareSize?: number;
  textSize?: number;
}

const CarNumber = ({
  num = 1,
  squareSize = 24,
  type,
  textSize = 14,
}: CarNumberProps) => {
  const carAlphaMap: Record<number, string> = {
    11: 'B',
    12: 'S',
    13: 'O',
    14: 'E',
  };

  return type === 'square' ? (
    <View
      style={[
        theme.flex.center,
        theme.borderRadius.xs,
        {
          width: squareSize,
          height: squareSize,
          backgroundColor: COLOR_MAP[num],
        },
      ]}>
      <Text color={'#fff'} fontFamily="fontInterBold" fontSize={textSize}>
        {num <= 10 ? num : carAlphaMap[num]}
      </Text>
    </View>
  ) : (
    <Image
      source={CAR_MAP[num]}
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        width: 64,
        height: 18,
      }}
    />
  );
};

export default CarNumber;
