import React from 'react';
import {View, ViewStyle, StyleProp} from 'react-native';
import animationjson from './animation-config-web';
import globalStore from '@/services/global.state';

const SpinLottie = ({style}: {style?: StyleProp<ViewStyle>}) => {
  const viewRef = React.useRef(null);
  React.useEffect(() => {
    if (document && viewRef.current) {
      const lottie = require('lottie-web');
      const animation = lottie.loadAnimation({
        container: viewRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationjson,
      });
      const sub = globalStore.amountChanged.subscribe(res => {
        if (res.last !== null) {
          if (res.last < res.current) {
            animation.play();
          }
        }
      });
      return () => {
        animation.destroy();
        sub.unsubscribe();
      };
    }
  }, []);
  return (
    <View style={[style]}>
      <View ref={viewRef} />
    </View>
  );
};

export default SpinLottie;
