import {
  ReactNode,
  Children,
  isValidElement,
  Fragment,
  cloneElement,
} from 'react';
import {SafeAny} from '../types';
export function isNotObjectAndArray(node: ReactNode) {
  return node === null || typeof node !== 'object';
}

export function isReactElement(node: ReactNode) {
  return (
    node != null &&
    typeof node === 'object' &&
    typeof (node as Iterable<ReactNode>)[Symbol.iterator] !== 'function'
  );
}

export function isReactIterator(node: ReactNode) {
  return (
    node != null &&
    typeof node === 'object' &&
    typeof (node as Iterable<ReactNode>)[Symbol.iterator] === 'function'
  );
}

export function cloneThroughFragments(children: ReactNode): React.ReactNode {
  return Children.map(children, c => {
    if (isValidElement(c)) {
      if (c.type === Fragment) {
        return cloneThroughFragments(c.props.children);
      }
      return cloneElement(c, {...c.props, isWhatever: true});
    }
    return c;
  });
}

export const getFirstScrollParent = (node?: SafeAny) => {
  const regex = /(auto|scroll)/;
  const parents = (_node: SafeAny, ps: SafeAny[]): SafeAny[] => {
    if (_node.parentNode === null) {
      return ps;
    }
    return parents(_node.parentNode, ps.concat([_node]));
  };

  const style = (_node: SafeAny, prop: string) =>
    getComputedStyle(_node, null).getPropertyValue(prop);
  const overflow = (_node: SafeAny) =>
    style(_node, 'overflow') +
    style(_node, 'overflow-y') +
    style(_node, 'overflow-x');
  const scroll = (_node: SafeAny) => regex.test(overflow(_node));

  const scrollParent = (_node: SafeAny) => {
    if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
      return;
    }

    const ps = parents(_node.parentNode, []);
    let scrollCount = 0;

    for (let i = ps.length - 1; i >= 0; i--) {
      if (scroll(ps[i])) {
        // return ps[i];
        scrollCount++;
      }
      // rn项目中，这种内部的scroll会包两层overflow，需要第二层的overflow
      if (scrollCount >= 2) {
        return ps[i];
      }
    }

    // 如果找不到任何滚动的父级，直接返回空，这样会以根视口判定是否在内部
    return null;
  };

  return scrollParent(node);
};
