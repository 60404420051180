import Spin from '@/components/basic/spin';
import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import {BasicObject} from '@/types';
import {debounce, goBack} from '@/utils';
import {useRoute} from '@react-navigation/native';
import React from 'react';
import {ScrollView, View} from 'react-native';
import SattaHeader from './satta-header';
import SattaBetSelect from './statta-bet-select';
import SattaTab from './satta-tab/satta-tab';
import SattaResult from './satta-tab/result/satta-result';
import OrderList from './satta-tab/order/order-list';
import SattaInputDigit from './satta-input-digit/satta.input.digit';
import ShopCart from '@/components/business/games/cart/cart';
import SattaCartList from './satta-cart-list/satta-cart-list';
import {
  SattaConfig,
  SattaInfoResponse,
  SattaOrderCreateDataContent,
  buyMatka,
  getSattaInfo,
} from './satta-service';
import {useTranslation} from 'react-i18next';
import Winner from './satta-tab/winner/winner-list';
import globalStore from '@/services/global.state';
import RuleModal from '@/components/business/games/rule';
import SattaRule from './satta-rule';

const GameSatta = () => {
  const {params = {}} = useRoute();
  const {id, lotteryName} = params as BasicObject;
  const [loading, setLoading] = React.useState(false);
  const [ruleVisible, setRuleVisible] = React.useState(false);
  const [detail, setDetail] = React.useState<SattaInfoResponse | null>();
  const {t} = useTranslation();
  const [selectTab, setSelectTab] = React.useState<'open' | 'close'>('open');
  const selectOpen = React.useMemo(() => selectTab === 'open', [selectTab]);
  const [selectBetsKey, setSelectBetsKey] = React.useState<number>(0);
  const [tabActive, setTabActive] = React.useState(0);
  const [openDigit, setOpenDigit] = React.useState('');
  const [closeDigit, setCloseDigit] = React.useState('');
  const [expectedValue, setExpectedValue] = React.useState('');
  const [buyList, setBuyList] = React.useState<SattaOrderCreateDataContent[]>(
    [],
  );
  const [version, setVersion] = React.useState(0);
  const [orderNum, setOrderNum] = React.useState(0);
  const totalOrder = React.useRef(0);
  React.useEffect(() => {
    init().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    try {
      setLoading(true);
      const info = await getSattaInfo(id);
      if (info) {
        setDetail(info);
      }
    } finally {
      setLoading(false);
    }
  };

  const betsTabs = React.useMemo(() => {
    if (!detail) {
      return [];
    }
    const baseConfig: SattaConfig[] = [
      {
        label: t('satta.betting.ank'),
        value: 'Ank',
        digitTitle: t('satta.betting.digitTitle.ank'),
        describe: t('satta.betting.describe.ank'),
        row: 1,
      },
      ...(detail?.isClose === 0
        ? [
            {
              label: t('satta.betting.jodi'),
              value: 'Jodi',
              digitTitle: t('satta.betting.digitTitle.jodi'),
              describe: t('satta.betting.describe.jodi'),
              row: 1,
            },
          ]
        : []),
      {
        label: t('satta.betting.sp'),
        value: 'SP',
        digitTitle: t('satta.betting.digitTitle.sp'),
        describe: t('satta.betting.describe.sp'),
        row: 1,
      },
      {
        label: t('satta.betting.dp'),
        value: 'DP',
        digitTitle: t('satta.betting.digitTitle.dp'),
        describe: t('satta.betting.describe.dp'),
        row: 1,
      },
      {
        label: t('satta.betting.tp'),
        value: 'TP',
        digitTitle: t('satta.betting.digitTitle.tp'),
        describe: t('satta.betting.describe.tp'),
        row: 1,
      },
      ...(detail?.halfStatus === 0 && detail.isClose === 0
        ? [
            {
              label: t('satta.betting.half'),
              value: 'Half Sangam',
              digitTitle: t('satta.betting.digitTitle.half'),
              describe: t('satta.betting.describe.half'),
              row: 2,
            },
          ]
        : []),
      ...(detail?.fullStatus === 0 && detail.isClose === 0
        ? [
            {
              label: t('satta.betting.full'),
              value: 'Full Sangam',
              digitTitle: t('satta.betting.digitTitle.full'),
              describe: t('satta.betting.describe.full'),
              row: 2,
            },
          ]
        : []),
    ];
    baseConfig.map((conf, index) => (conf.key = index));
    return baseConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  React.useEffect(() => {
    if (detail?.isClose === 1 || detail?.isClose === 2) {
      setSelectTab('close');
    }
  }, [detail]);

  const selectBets = React.useMemo(() => {
    if (betsTabs.length > 0) {
      return betsTabs[selectBetsKey];
    }
    return {} as SattaConfig;
  }, [selectBetsKey, betsTabs]);

  const gameType = React.useMemo(() => {
    /**
     * 游戏类型
     * 1、ankOpen 2、ankClose 3、jodi 4、SPOpen 5、SPColse 6、DPOpen
     * 7、DPClose 8、TPOpen 9、TPClose 10、Half SangamOpen 11、Half SangamClose 12、Full Sangam
     */
    if (selectBets.value === 'Ank') {
      return selectOpen ? 1 : 2;
    }
    if (selectBets.value === 'Jodi') {
      return 3;
    }
    if (selectBets.value === 'SP') {
      return selectOpen ? 4 : 5;
    }
    if (selectBets.value === 'DP') {
      return selectOpen ? 6 : 7;
    }
    if (selectBets.value === 'TP') {
      return selectOpen ? 8 : 9;
    }
    if (selectBets.value === 'Half Sangam') {
      return selectOpen ? 10 : 11;
    }
    return 12;
  }, [selectBets, selectOpen]);

  const onChangeExpected = (text: string) => {
    setExpectedValue(text);
  };

  const changeDigit = (status: 'open' | 'close', v: string) => {
    if (status === 'open') {
      setOpenDigit(v);
    } else {
      setCloseDigit(v);
    }
  };

  const digits = React.useMemo(() => {
    if (
      selectBets.value === 'Half Sangam' ||
      selectBets.value === 'Full Sangam'
    ) {
      return `${openDigit}-${closeDigit}`;
    }
    return openDigit || '-';
  }, [closeDigit, openDigit, selectBets]);

  const reverseGametype = React.useCallback((value: number) => {
    return (
      {
        1: 'AnkOpen',
        2: 'AnkClose',
        3: 'Jodi',
        4: 'SPOpen',
        5: 'SPClose',
        6: 'DPOpen',
        7: 'DPClose',
        8: 'TPOpen',
        9: 'TPClose',
        10: 'HSAOpen',
        11: 'HSAClose',
        12: 'FS',
      }[value] || 'AnkOpen'
    );
  }, []);

  const handleAddCart = (amount: string) => {
    setBuyList(list => {
      const copyList = [...list];
      const haveOne = copyList.find(
        item => item.digits === digits && item.gameType === gameType,
      );
      if (haveOne) {
        haveOne.amount += +amount;
        return copyList;
      }
      return [
        ...copyList,
        {
          digits,
          gameType,
          amount: +amount,
          type: reverseGametype(gameType),
        },
      ];
    });
    onClearAll();
  };

  const onDeleteOne = (item: SattaOrderCreateDataContent) => {
    setBuyList(list => list.filter(p => p.digits !== item?.digits));
  };

  const buyData = React.useMemo(() => {
    return buyList.reduce(
      (pre, cur) => {
        pre.price += cur.amount;
        pre.count += 1;
        return pre;
      },
      {
        price: 0,
        count: 0,
      },
    );
  }, [buyList]);

  const onClearAll = () => {
    onChangeExpected?.('');
    changeDigit?.('close', '');
    changeDigit?.('open', '');
  };

  const onPay = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const res = await buyMatka({
        matkaId: (detail?.id || '') + '',
        matkaBaseReqList: buyList.map(p => ({
          ...p,
          digits:
            p.gameType === 11 ? (p.digits + '').replace('-', '') : p.digits,
        })),
      });
      if (res) {
        setBuyList([]);
        onClearAll();
        globalStore.globalSucessTotal(
          t('paidSuccess.label.subTitle'),
          t('paidSuccess.tip.msg'),
        );
        if (tabActive !== 2) {
          totalOrder.current += 1;
          setOrderNum(totalOrder.current);
        }
        globalStore.updateAmount.next({gameType: 'Matka'});
        setVersion(version + 1);
      }
    } finally {
      setLoading(false);
    }
  };

  const onPayCheck = debounce(() => {
    onPay();
  }, 200);

  React.useEffect(() => {
    if (tabActive === 2 && totalOrder.current) {
      totalOrder.current = 0;
      setOrderNum(0);
    }
  }, [tabActive]);

  return (
    <View
      style={[theme.background.lightGrey, theme.flex.flex1, theme.flex.col]}>
      <DetailNavTitle
        title={'Satta Matka'}
        hideServer={true}
        onBack={() => goBack()}
      />
      <Spin loading={loading} style={[theme.flex.flex1]}>
        <View style={[theme.flex.flex1, theme.flex.basis0]}>
          <ScrollView
            stickyHeaderIndices={[3]}
            keyboardShouldPersistTaps="always">
            <SattaHeader
              open={detail?.openDraw}
              close={detail?.closeDraw}
              name={lotteryName}
              issueNum={detail?.id || ''}
              openResultNum={detail?.openResultNum}
              closeResultNum={detail?.closeResultNum}
              backImage={detail?.backImgWeb}
              showRule={() => setRuleVisible(true)}
            />
            <SattaBetSelect
              betsTabList={betsTabs}
              selectBetsKey={selectBetsKey}
              onItemPress={setSelectBetsKey}
            />
            <SattaInputDigit
              digits={digits}
              detail={detail}
              selectOpen={selectOpen}
              selectType={selectBets}
              gameType={gameType}
              selectTab={selectTab}
              onTabItemPress={setSelectTab}
              changeDigit={changeDigit}
              openDigit={openDigit}
              closeDigit={closeDigit}
              expectedValue={expectedValue}
              onChangeExpected={onChangeExpected}
              handleAddCart={handleAddCart}
            />
            <SattaTab
              orderNum={orderNum}
              activeKey={tabActive}
              onTabChange={setTabActive}
            />
            {tabActive === 0 && <SattaResult gameId={id} />}
            {tabActive === 1 && <Winner />}
            {tabActive === 2 && <OrderList refreshCode={version} />}
          </ScrollView>
        </View>
      </Spin>
      <ShopCart
        disable={buyList.length === 0}
        price={buyData.price}
        onClear={() => setBuyList([])}
        title={t('satta.betting.mybids')}
        onConfirm={onPayCheck}
        tip={`${buyData.count} ${t('satta.betting.bids')}`}>
        <SattaCartList cartList={buyList} onItemClear={onDeleteOne} />
      </ShopCart>
      <RuleModal
        title="Rule"
        visible={ruleVisible}
        scrollEnabled={false}
        onClose={() => setRuleVisible(false)}>
        <SattaRule id={detail?.id} />
      </RuleModal>
    </View>
  );
};

export default GameSatta;
