import {Subject} from 'rxjs';
import {ParabolaStartProps} from './types';

class ParabolaService {
  readonly start = (props: ParabolaStartProps) => {
    this.startSubject.next(props);
  };
  readonly startSubject = new Subject<ParabolaStartProps>();
}
const parabolaService = new ParabolaService();
export default parabolaService;
