import {Dimensions} from 'react-native';

const DESIGN_WIDTH = 375;
/**
 * @deprecated
 * 请使用useResponsiveDimensions
 */
export function designToDp(designSize: number) {
  const width = Dimensions.get('window').width;
  return (designSize / DESIGN_WIDTH) * (width > 500 ? 500 : width);
}
