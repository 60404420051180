import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import DiceItem from '@/common-pages/dice/component/dice-item';
import Ball from '@/common-pages/dice/component/ball';
import {useTranslation} from 'react-i18next';
import Won from '../won/won';

interface ListItem {
  amount: number;
  awardAmount: number;
  number: string;
  playType: string;
  type: string;
}

export interface DiceResultProps {
  isOpen?: boolean;
  result: string;
  bigOrSmall: number;
  oddOrEven: number;
  totalCount: number;
  typeList: ListItem[];
}

const DiceResult = ({
  result = '',
  totalCount = 1,
  bigOrSmall,
  isOpen = false,
  oddOrEven,
  typeList = [],
}: DiceResultProps) => {
  const {t} = useTranslation();

  const winList = React.useMemo(() => {
    return typeList.filter(item => item.awardAmount > 0);
  }, [typeList]);

  const sum_type = React.useMemo(() => {
    return typeList.filter(
      item => item.playType === 'Sum' && isNaN(Number(item.number)),
    );
  }, [typeList]);

  const sum_point = React.useMemo(() => {
    return typeList.filter(
      item => item.playType === 'Sum' && !isNaN(Number(item.number)),
    );
  }, [typeList]);

  const single = React.useMemo(() => {
    return typeList.filter(item => item.playType === 'Single');
  }, [typeList]);

  const double = React.useMemo(() => {
    return typeList.filter(item => item.playType === 'Pair');
  }, [typeList]);
  const triple = React.useMemo(() => {
    return typeList.filter(item => item.playType === 'Triple');
  }, [typeList]);

  const renderItem = (info: ListItem, key?: number) => {
    if (info.playType === 'Sum' && isNaN(Number(info.number))) {
      return (
        <Ball
          isNum={false}
          type={info.number.toLowerCase()}
          ballSize={24}
          key={key}
        />
      );
    }
    if (info.playType === 'Sum' && !isNaN(Number(info.number))) {
      return <Ball num={+info.number} ballSize={24} fontSize={14} key={key} />;
    }
    if (info.playType === 'Single') {
      return <DiceItem diceSize={24} diceNum={info.number} key={key} />;
    }
    if (info.playType === 'Pair') {
      return (
        <View style={[theme.flex.row]} key={key}>
          {info.number.split('').map((pie, _ii) => (
            <DiceItem diceSize={24} diceNum={pie} key={_ii} />
          ))}
        </View>
      );
    }
    if (info.playType === 'Triple') {
      return (
        <View style={[theme.flex.row]} key={key}>
          {info.number.split('').map((pie, _ii) => (
            <DiceItem diceSize={24} diceNum={pie} key={_ii} />
          ))}
        </View>
      );
    }
    return null;
  };

  return (
    <View style={[theme.margin.tops, {gap: theme.paddingSize.s}]}>
      {isOpen && (
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            theme.flex.between,
            styles.drawContainer,
          ]}>
          <View style={[theme.flex.flex1]}>
            <Text size="medium" fontFamily="fontInterBold">
              {t('bets-detail.label.drawRes')}：
            </Text>
          </View>
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              {gap: theme.paddingSize.xxs},
            ]}>
            {result.split(',').map((item, _i) => (
              <DiceItem diceSize={32} diceNum={item} key={_i} />
            ))}
            <View style={styles.line} />
            <Ball fontSize={16} ballSize={32} isNum num={totalCount} />
            <View style={styles.line} />
            {bigOrSmall && bigOrSmall > 0 ? (
              <Ball
                isNum={false}
                type={bigOrSmall === 1 ? 'big' : 'small'}
                fontSize={16}
                ballSize={32}
              />
            ) : null}
            {oddOrEven && oddOrEven > 0 ? (
              <Ball
                isNum={false}
                type={oddOrEven === 2 ? 'even' : 'odd'}
                fontSize={16}
                ballSize={32}
              />
            ) : null}
          </View>
        </View>
      )}
      {winList.length > 0 &&
        winList.map((item, _i) => (
          <Won
            key={_i}
            awardAmount={item.awardAmount}
            bet={item.amount}
            result={renderItem(item)}
          />
        ))}
      {sum_type.length > 0 && (
        <View style={[theme.flex.row, styles.item]}>
          <Text fontFamily="fontInterBold" size="medium">
            {t('game-page.label.sumType')}：
          </Text>
          <View style={[theme.flex.row, {gap: theme.paddingSize.s}]}>
            {sum_type.map((item, _i) => renderItem(item, _i))}
          </View>
        </View>
      )}
      {sum_point.length > 0 && (
        <View style={[theme.flex.row, styles.item]}>
          <Text fontFamily="fontInterBold" size="medium">
            {t('game-page.label.sumPoints')}：
          </Text>
          <View style={[theme.flex.row, {gap: theme.paddingSize.s}]}>
            {sum_point.map((item, _i) => renderItem(item, _i))}
          </View>
        </View>
      )}
      {single.length > 0 && (
        <View style={[theme.flex.row, styles.item]}>
          <Text fontFamily="fontInterBold" size="medium">
            {t('game-page.label.single')}：
          </Text>
          <View style={[theme.flex.row, {gap: theme.paddingSize.s}]}>
            {single.map((item, _i) => renderItem(item, _i))}
          </View>
        </View>
      )}
      {double.length > 0 && (
        <View style={[theme.flex.row, styles.item]}>
          <Text fontFamily="fontInterBold" size="medium">
            {t('game-page.label.double')}：
          </Text>
          <View style={[theme.flex.row, {gap: theme.paddingSize.s}]}>
            {double.map((item, _i) => renderItem(item, _i))}
          </View>
        </View>
      )}
      {triple.length > 0 && (
        <View style={[theme.flex.row, styles.item]}>
          <Text fontFamily="fontInterBold" size="medium">
            {t('game-page.label.triple')}：
          </Text>
          <View style={[theme.flex.row, {gap: theme.paddingSize.s}]}>
            {triple.map((item, _i) => renderItem(item, _i))}
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  drawContainer: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: '#F4F4F4',
  },
  line: {
    width: 1,
    height: 32,
    backgroundColor: '#D8E0EA',
  },
  item: {
    backgroundColor: '#F6F7FB',
    paddingHorizontal: 12,
    paddingVertical: 4,
    alignSelf: 'flex-start',
    borderRadius: 20,
    gap: 8,
  },
});

export default DiceResult;
