import NoData from '@/components/basic/error-pages/no-data';
import Spin from '@/components/basic/spin';
import Page from '@/components/business/games/page';
import theme from '@/style';
import React from 'react';
import ResultHeader from './result-header';
import ResultItem from './result-item';
import {
  DigitDrawResultItem,
  getDigitResultList,
  getQuickPickResult,
} from '../../digit-service';

const pageSize = 10;

const DigitResult = ({
  type = '',
  pickThreeId,
  isActive = false,
  refreshCode = 0,
}: {
  type?: string;
  pickThreeId?: number;
  isActive?: boolean;
  refreshCode?: number;
}) => {
  const [total, setTotal] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(false);
  const [list, setList] = React.useState<DigitDrawResultItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [initPage, setInitPage] = React.useState(1);
  const normalList = React.useRef<DigitDrawResultItem[]>([]);

  React.useEffect(() => {
    if (pickThreeId && isActive) {
      if (type === 'normal') {
        getResult();
      } else {
        getQuickResult();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickThreeId, isActive]);

  React.useEffect(() => {
    if (refreshCode > 0 && isActive && pickThreeId) {
      if (type === 'quick') {
        getQuickResult();
        setInitPage(1);
      } else {
        getResult();
        setInitPage(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCode]);

  const getResult = () => {
    if (!firstLoad) {
      setLoading(true);
    }
    getDigitResultList(1, pickThreeId)
      .then(res => {
        if (res[0] && res[0].drawResultList) {
          setList(res[0].drawResultList.slice(0, pageSize));
          setTotal(res[0].drawResultList.length);
          normalList.current = res[0].drawResultList;
        }
      })
      .finally(() => {
        setFirstLoad(false);
        setLoading(false);
      });
  };

  const getQuickResult = async (pageNumber: number = 1) => {
    if (!firstLoad) {
      setLoading(true);
    }
    try {
      if (pickThreeId) {
        const res = await getQuickPickResult({
          pageNo: pageNumber,
          pickThreeId,
          pageSize,
        });
        if (res) {
          setList(res.drawResultList);
          setTotal(res.totalCount);
        }
      }
    } finally {
      setFirstLoad(false);
      setLoading(false);
    }
  };

  const getListByPage = (pageNumber: number = 1) => {
    if (normalList.current.length > pageSize) {
      const current = (pageNumber - 1) * pageSize;
      const next = pageNumber * pageSize;
      setList(normalList.current.slice(current, next));
      // setPage(pageNumber);
    }
  };

  return (
    <Spin
      loading={firstLoad || loading}
      style={[theme.fill.fill, theme.padding.topl]}>
      <ResultHeader isQuick={type !== 'normal'} />
      {!firstLoad && !loading && list && list.length === 0 ? <NoData /> : null}
      {list.map((item, _i) => (
        <ResultItem
          key={_i}
          issueNum={item.pickNo || item.pickName}
          index={_i}
          result={item.drawResult}
          time={item.drawTime}
        />
      ))}
      {total > 0 ? (
        <Page
          page={initPage}
          total={total}
          pageSize={10}
          onPageChange={p => {
            setInitPage(p);
            if (type === 'normal') {
              getListByPage(p);
            } else {
              getQuickResult(p);
            }
          }}
        />
      ) : null}
    </Spin>
  );
};

export default DigitResult;
