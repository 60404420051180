import {useTranslation} from 'react-i18next';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';

const ColorSaleRule = () => {
  const {i18n} = useTranslation();
  return (
    <>
      {new Array(6).fill('p').map((item, _i) => (
        <Text key={_i} size="medium" style={[theme.margin.btml]}>
          {i18n.t(`game-page.sale-rules.${item}${_i + 1}`)}
        </Text>
      ))}
    </>
  );
};

export default ColorSaleRule;
