import React from 'react';
import TableHeader from '../../components/table-header';
import AnalyzeItem from './analyze-item';
import {ColorGetLatestContentResponse, getLatest} from '../../color-service';
import theme from '@/style';
import Page from '@/components/business/games/page';
import NoData from '@/components/basic/error-pages/no-data';
import Spin from '@/components/basic/spin';

const pageSize = 10;

const Analyze = ({
  configId,
  isActive = false,
  refreshCode = 0,
}: {
  configId?: number;
  refreshCode?: number;
  isActive?: boolean;
}) => {
  const [total, setTotal] = React.useState(0);
  const [list, setList] = React.useState<ColorGetLatestContentResponse[]>([]);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [initPage, setInitPage] = React.useState(1);

  const getList = async (id: number, page: number = 1) => {
    try {
      setLoading(true);
      const res = await getLatest({
        configId: id,
        pageNo: page,
        pageSize,
      });
      if (res) {
        setTotal(res.totalSize);
        setList(res.content);
      }
    } finally {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  React.useEffect(() => {
    if (configId && isActive) {
      setInitPage(1);
      getList(configId).then();
    }
  }, [configId, isActive]);

  React.useEffect(() => {
    if (refreshCode && isActive && configId) {
      getList(configId).then();
      setInitPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCode]);

  return (
    <Spin loading={firstLoad || loading} style={[theme.flex.flex1]}>
      <TableHeader headers={['perriod', 'number']} />
      {!firstLoad && !loading && list && list.length === 0 ? <NoData /> : null}
      {list.map((item, index) => (
        <AnalyzeItem
          key={index}
          issueNum={item.issueNo}
          digit={item.digitResult}
          color={item.colorResult}
          index={index}
        />
      ))}
      {total > 0 ? (
        <Page
          page={initPage}
          total={total}
          pageSize={pageSize}
          onPageChange={page => {
            if (configId) {
              setInitPage(page);
              getList(configId, page).then();
            }
          }}
        />
      ) : null}
    </Spin>
  );
};

export default React.memo(Analyze);
