import {
  DigitOrderItem,
  quickGameOrder,
} from '@/common-pages/pick-digit/digit-service';
import React from 'react';
import ResultPanel from '../components/result-panel/result-panel';
import {useTranslation} from 'react-i18next';
import globalStore from '@/services/global.state';
import theme from '@/style';
import Text from '@/components/basic/text';
import {View} from 'react-native';
import dayjs from 'dayjs';
import {goTo, toPriceStr} from '@/utils';
// import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
// import {downIcon} from '@/common-pages/rebate/rebate.variables';
import DigitBall from '@/common-pages/pick-digit/components/digit-ball';
const DigitDraw = ({id}: {id?: number}) => {
  const [order, setOrder] = React.useState<DigitOrderItem>();
  const {t} = useTranslation();

  const getQuickOrder = async (pickThreeId: number) => {
    try {
      const res = await quickGameOrder({
        pickThreeId,
        pageNo: 1,
        pageSize: 1,
        orderStatus: 4,
      });
      if (res?.records) {
        setOrder(res.records[0]);
      }
    } finally {
    }
  };

  React.useEffect(() => {
    if (id && globalStore.token) {
      getQuickOrder(id);
    }
  }, [id]);

  return order?.orderGroup ? (
    <ResultPanel
      winAmount={order.winAmount}
      onMore={() => goTo('Bets', {tag: 'Quick 3D'})}
      betInfo={order?.timeName}
      betTime={dayjs(order?.createTime).format('DD-MM-YYYY hh:mm A')}>
      <View style={[theme.padding.lrl, theme.padding.tops]}>
        <Text textAlign="center">{t('game-page.label.myBets')}</Text>
        <View style={[theme.margin.tops, {gap: theme.paddingSize.xxs / 2}]}>
          {order?.codeLists?.map((item, _i) => {
            const indexArr = item.indexCode.split('=')[0].split('');
            const digitArr = item.indexCode.split('=')[1].split('');
            return (
              <View
                key={_i}
                style={[
                  theme.padding.s,
                  theme.borderRadius.m,
                  theme.flex.between,
                  theme.flex.centerByCol,
                  theme.flex.row,
                  // eslint-disable-next-line react-native/no-inline-styles
                  {backgroundColor: '#EFF1F7'},
                ]}>
                <View
                  style={[
                    theme.flex.row,
                    theme.flex.centerByCol,
                    {gap: theme.paddingSize.xxs},
                  ]}>
                  {indexArr.map((pie, _ii) => (
                    <DigitBall
                      ballSize={24}
                      digit={pie}
                      color={pie}
                      key={_ii}
                    />
                  ))}
                  <Text size="medium" fontFamily="fontInterBold">
                    =
                  </Text>
                  {digitArr.map((pie, _ii) => (
                    <DigitBall
                      ballSize={24}
                      digit={pie}
                      color={indexArr[_ii]}
                      key={_ii}
                    />
                  ))}
                  <View
                    style={[
                      theme.borderRadius.xs,
                      theme.margin.leftxxs,
                      theme.padding.lrxxs,
                      // eslint-disable-next-line react-native/no-inline-styles
                      {backgroundColor: '#F15802', paddingHorizontal: 2},
                    ]}>
                    <Text fontSize={10} color={'#fff'}>
                      x{item.pickCount}
                    </Text>
                  </View>
                </View>
                <View style={[theme.flex.row, theme.flex.centerByCol]}>
                  <Text>{item.winAmount > 0 ? 'Won ' : 'No Win '}</Text>
                  <Text
                    color={item.winAmount ? '#F15802' : '#000'}
                    size="medium"
                    fontFamily="fontDinBold">
                    {toPriceStr(item.winAmount)}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
        {/* {order?.codeLists && order.codeLists?.length > 3 && (
          <NativeTouchableOpacity
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.flex.between,
              theme.padding.tbs,
            ]}>
            <Text>View all</Text>
            <View
              style={[
                theme.margin.leftxxs,
                {
                  transform: [
                    {
                      rotate: '-90deg',
                    },
                  ],
                },
              ]}>
              <Image source={downIcon} style={[theme.icon.xs]} />
            </View>
          </NativeTouchableOpacity>
        )} */}
      </View>
    </ResultPanel>
  ) : null;
};

export default DigitDraw;
