import {View, Image, StyleSheet, FlatList} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {downIcon} from '@/common-pages/rebate/rebate.variables';
import theme from '@/style';
import LinearGradient from '@/components/basic/linear-gradient';
export interface PageNumberProps {
  total?: number;
  pageSize?: number;
  page?: number;
  onPageChange?: (page: number) => void;
}

const PageNumber = ({
  total = 0,
  pageSize = 10,
  onPageChange,
  page = 0,
}: PageNumberProps) => {
  const [prevActive, setPrevActive] = React.useState(false);
  const [nextActive, setNextActive] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const flatListRef = React.useRef<FlatList>(null);
  const lock = React.useRef(true);

  React.useEffect(() => {
    if (!lock.current) {
      onPageChange?.(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  React.useEffect(() => {
    if (page === 1) {
      lock.current = true;
      setCurrentPage(page);
      flatListRef.current?.scrollToOffset({
        offset: 0,
        animated: true,
      });
    }
  }, [page]);

  const totalPage = React.useMemo(() => {
    if (pageSize && total) {
      return Math.ceil(total / pageSize);
    }
    return 1;
  }, [pageSize, total]);

  const canPrev = React.useMemo(() => {
    return currentPage !== 1;
  }, [currentPage]);

  const canNext = React.useMemo(() => {
    return currentPage !== totalPage;
  }, [currentPage, totalPage]);

  const pageData = React.useMemo(() => {
    const arr = [] as number[];
    for (let i = 0; i < totalPage; i++) {
      arr.push(i + 1);
    }
    return arr;
  }, [totalPage]);

  React.useEffect(() => {
    if (totalPage > 3) {
      if (currentPage > 1) {
        flatListRef.current?.scrollToOffset({
          offset: 36 * (currentPage - 2),
          animated: true,
        });
      }
    }
  }, [totalPage, currentPage]);

  return (
    <View
      style={[
        theme.background.white,
        theme.flex.row,
        theme.flex.center,
        theme.margin.topl,
        // eslint-disable-next-line react-native/no-inline-styles
        {gap: 20, height: 56},
      ]}>
      <View>
        <Text size="medium">Total {total}</Text>
      </View>
      <View style={styles.pageContainer}>
        <FlatList
          ref={flatListRef}
          scrollEnabled={false}
          horizontal
          contentContainerStyle={[
            totalPage < 3 && theme.flex.centerByRow,
            totalPage < 3 && theme.flex.flex1,
          ]}
          showsHorizontalScrollIndicator={false}
          data={pageData}
          renderItem={({item}) => (
            <NativeTouchableOpacity
              onPress={() => {
                lock.current = false;
                setCurrentPage(item);
              }}
              style={[
                theme.flex.center,
                theme.margin.rightxxs,
                theme.icon.xxl,
              ]}>
              <Text
                size="medium"
                fontFamily={
                  currentPage === item ? 'fontInterBold' : 'fontInter'
                }
                color={
                  currentPage === item
                    ? theme.basicColor.dark
                    : theme.fontColor.accent
                }>
                {item}
              </Text>
            </NativeTouchableOpacity>
          )}
        />
      </View>
      <View style={[theme.flex.row]}>
        <NativeTouchableOpacity
          disabled={!canPrev}
          style={[theme.margin.rights]}
          activeOpacity={1}
          onPressOut={() => {
            setPrevActive(false);
            lock.current = false;
            const prevPage = currentPage - 1;
            setCurrentPage(prevPage);
          }}
          onPressIn={() => setPrevActive(true)}>
          <LinearGradient
            colors={
              !canPrev
                ? ['rgb(248,250,254)', 'rgb(239,241,245)']
                : prevActive
                ? ['rgb(190,190, 198)', 'rgb(190,190, 198)']
                : ['#F2F6FF', '#DFE3ED']
            }
            style={[styles.btn]}>
            <View
              style={[
                styles.left,
                // eslint-disable-next-line react-native/no-inline-styles
                !canPrev && {
                  opacity: 0.5,
                },
              ]}>
              <Image style={styles.iconSize} source={downIcon} />
            </View>
          </LinearGradient>
        </NativeTouchableOpacity>
        <NativeTouchableOpacity
          disabled={!canNext}
          activeOpacity={1}
          onPressOut={() => {
            setNextActive(false);
            lock.current = false;
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
          }}
          onPressIn={() => {
            setNextActive(true);
          }}>
          <LinearGradient
            colors={
              !canNext
                ? ['rgb(248,250,254)', 'rgb(239,241,245)']
                : nextActive
                ? ['rgb(190,190, 198)', 'rgb(190,190, 198)']
                : ['#F2F6FF', '#DFE3ED']
            }
            style={[styles.btn]}>
            <View
              style={[
                styles.right,
                // eslint-disable-next-line react-native/no-inline-styles
                !canNext && {
                  opacity: 0.5,
                },
              ]}>
              <Image style={styles.iconSize} source={downIcon} />
            </View>
          </LinearGradient>
        </NativeTouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  iconSize: {
    width: 12,
    height: 12,
  },
  left: {
    transform: [
      {
        rotate: '90deg',
      },
    ],
  },
  right: {
    transform: [
      {
        rotate: '-90deg',
      },
    ],
  },
  btn: {
    width: 32,
    height: 32,
    ...theme.flex.center,
    borderRadius: 4,
    borderTopColor: theme.basicColor.white,
    borderTopWidth: 1,
    elevation: 1,
  },
  pageContainer: {
    width: 104,
    flexDirection: 'row',
    gap: 4,
    overflow: 'hidden',
  },
});

export default React.memo(PageNumber);
