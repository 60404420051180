import theme from '@/style';
import React, {useEffect, useRef} from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  NativeScrollEvent,
  NativeSyntheticEvent,
  View,
} from 'react-native';
import {useInnerStyle} from './vip.hooks';
import {
  VipProgressInfo,
  VipRenderType,
  maxVipLevel,
  useVipProgressList,
  vipColors,
} from '@/components/business/vip';
import Text from '@basicComponents/text';
import {toPriceStr} from '@/utils';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {IVipItem, postUserInfo} from '@/services/global.service';
import Button from '@basicComponents/button';
import globalStore from '@/services/global.state';
import {postReceiveBox} from './vip.service';
import {useTranslation} from 'react-i18next';
import VipReceivingBox from './vip-receiving-box';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

export interface VipCardListProps {
  rechargeAmount: number;
  vipInfoList: VipProgressInfo[];
  cards: VipRenderType[];
  vipList: IVipItem[];
  onRecharge?: () => void;
  onCheck?: (index: number) => void;
  onRefresh?: () => void;
  checkIndex?: number;
  currentLevel: number;
}

const VipCardList: React.FC<VipCardListProps> = ({
  vipInfoList,
  cards,
  vipList,
  currentLevel,
  rechargeAmount,
  onRecharge,
  onCheck,
  onRefresh,
  checkIndex,
}) => {
  const {calcActualSize} = useScreenSize();
  const {i18n} = useTranslation();
  const {
    vipStyle,
    cardStyle,
    size: {vipCardWidth},
  } = useInnerStyle();
  const ref = useRef<FlatList>(null);
  const renderProgressList = useVipProgressList(vipInfoList);
  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const width = vipCardWidth + theme.paddingSize.s;
    const x = e.nativeEvent.contentOffset.x;
    const nowIndex = Math.round(x / width);
    onCheck?.(nowIndex);
  };
  const handleReceive = async (item: IVipItem) => {
    if (item.statusReached === 1 && item.rewardReceivingStatus === 1) {
      try {
        globalStore.globalLoading.next(true);
        const userInfo = await postUserInfo();
        await postReceiveBox(userInfo.userId, item.level);
        globalStore.globalSucessTotal(i18n.t('vip.success'));
        globalStore.updateAmount.next({});
        globalStore.doNotices.next();
        onRefresh?.();
      } finally {
        globalStore.globalLoading.next(false);
      }
    }
  };
  useEffect(() => {
    if (vipList.length <= 0) {
      return;
    }
    ref.current?.scrollToIndex({
      index: checkIndex || 0,
    });
  }, [checkIndex, vipList.length]);
  const renderVipCardItem = ({item, index}: ListRenderItemInfo<IVipItem>) => {
    return (
      <View style={[theme.margin.rightm]} key={index}>
        {cards[index].backgroundFn(
          <View style={[theme.position.rel, theme.flex.col]}>
            <View style={[theme.margin.topxxl, theme.padding.leftm]}>
              <View
                style={[
                  theme.margin.leftm,
                  theme.flex.row,
                  theme.flex.centerByCol,
                ]}>
                <Text
                  fontSize={32}
                  fontFamily="fontDin"
                  blod
                  color={vipColors[index]}>
                  V{index}
                </Text>
                {index !== 0 && (
                  <View
                    style={[
                      cardStyle.topTag,
                      theme.padding.lrs,
                      theme.padding.tbxxs,
                      theme.margin.lefts,
                    ]}>
                    <Text fontSize={theme.fontSize.s} accent>
                      {vipList[index - 1].statusReached === 1
                        ? vipList[index - 1].rewardReceivingStatus === 0
                          ? i18n.t('vip.received')
                          : i18n.t('vip.collected')
                        : i18n.t('vip.finished')}
                    </Text>
                  </View>
                )}
              </View>
            </View>
            <View style={[theme.position.abs, cardStyle.sign]}>
              {cards[index].sign('large')}
            </View>
            <View
              style={[
                theme.position.rel,
                theme.margin.tops,
                theme.padding.tbs,
                cardStyle.progressTextContainer,
                theme.flex.row,
              ]}>
              <Text main fontSize={theme.fontSize.m} blod>
                {toPriceStr(rechargeAmount, {fixed: 0, thousands: true})}
              </Text>
              <Text
                accent
                fontSize={theme.fontSize.m}
                blod
                style={[theme.margin.leftxxs]}>
                / {toPriceStr(item.amount, {fixed: 0, thousands: true})}(V
                {Math.min(index + 1, maxVipLevel)})
              </Text>
              {index !== 0 && (
                <View style={[theme.position.abs, cardStyle.box]}>
                  <NativeTouchableOpacity
                    activeOpacity={
                      vipList[index - 1].rewardReceivingStatus === 1 &&
                      vipList[index - 1].statusReached === 1
                        ? 0.8
                        : 1
                    }
                    onPress={() => handleReceive(vipList[index - 1])}>
                    {/* <LazyImage
                      occupancy="#0000"
                      imageUrl={
                        vipList[index - 1].statusReached === 1
                          ? vipList[index - 1].rewardReceivingStatus === 0
                            ? boxReceivedIcon
                            : boxReachedIcon
                          : boxUnReachIcon
                      }
                      width={40}
                      height={40}
                    /> */}
                    <VipReceivingBox
                      statusReached={vipList[index - 1].statusReached}
                      rewardReceivingStatus={
                        vipList[index - 1].rewardReceivingStatus
                      }
                    />
                    <View
                      style={[
                        theme.position.abs,
                        {
                          transform: [
                            {
                              translateX: calcActualSize(
                                vipList[index - 1].statusReached === 1
                                  ? -24.5
                                  : vipList[index - 1].rewardReceivingStatus ===
                                    1
                                  ? -29.5
                                  : -36,
                              ),
                            },
                          ],
                          left: '50%',
                          bottom: -8,
                        },
                      ]}>
                      <LinearGradient
                        style={{
                          paddingHorizontal: 6,
                          paddingVertical: 4,
                          borderWidth:
                            vipList[index - 1].statusReached === 1 &&
                            vipList[index - 1].rewardReceivingStatus === 1
                              ? 1
                              : 0,
                          borderColor: 'rgba(0, 0, 0, 0.20)',
                          borderRadius: 19,
                          height: 19,
                          width: calcActualSize(
                            vipList[index - 1].statusReached === 1
                              ? 49
                              : vipList[index - 1].rewardReceivingStatus === 1
                              ? 59
                              : 72,
                          ),
                        }}
                        colors={
                          vipList[index - 1].statusReached === 1 &&
                          vipList[index - 1].rewardReceivingStatus === 1
                            ? ['#FFB800', '#D05700']
                            : ['#a4a4a4', '#a4a4a4']
                        }>
                        <View
                          style={[
                            theme.flex.flex,
                            theme.flex.row,
                            theme.flex.center,
                            {
                              height: '100%',
                            },
                          ]}>
                          <Text
                            calc
                            color={theme.basicColor.white}
                            fontWeight="700"
                            fontSize={9}>
                            {vipList[index - 1].statusReached === 0
                              ? i18n.t('vip.unReached')
                              : vipList[index - 1].rewardReceivingStatus === 0
                              ? i18n.t('vip.reached')
                              : i18n.t('vip.pending')}
                          </Text>
                        </View>
                      </LinearGradient>
                    </View>
                  </NativeTouchableOpacity>
                </View>
              )}
            </View>
            <View
              style={[theme.padding.lrm, theme.flex.col, theme.margin.tops]}>
              <View style={[theme.flex.row]}>{renderProgressList[index]}</View>
              <View
                style={[
                  theme.flex.row,
                  theme.flex.between,
                  theme.flex.centerByCol,
                ]}>
                <View
                  style={[
                    theme.flex.row,
                    theme.flex.centerByCol,
                    theme.margin.lrm,
                    cardStyle.notice,
                  ]}>
                  <Text
                    numberOfLines={2}
                    main
                    style={[
                      cardStyle.opacity,
                      item.level >= currentLevel ? cardStyle.text : [],
                    ]}
                    fontSize={theme.fontSize.xs}>
                    {i18n.t('vip.recharge')}{' '}
                    <Text blod>
                      {toPriceStr(item.diff, {fixed: 0, thousands: true})}
                    </Text>{' '}
                    {i18n.t('vip.move')} VIP
                    {Math.min(item.level + 1, maxVipLevel)}
                  </Text>
                </View>
                {item.level >= currentLevel && (
                  <Button
                    type="linear-primary"
                    size="xsmall"
                    titleBold
                    title={i18n.t('vip.recharge')}
                    style={[theme.margin.rightm]}
                    onPress={onRecharge}
                  />
                )}
              </View>
            </View>
          </View>,
          vipCardWidth,
        )}
      </View>
    );
  };
  return (
    <FlatList
      ref={ref}
      style={[vipStyle.cardList]}
      showsHorizontalScrollIndicator={false}
      onScroll={handleScroll}
      contentContainerStyle={[vipStyle.cardListContent, theme.flex.alignEnd]}
      data={vipList}
      initialScrollIndex={currentLevel}
      pagingEnabled
      snapToAlignment="center"
      renderItem={renderVipCardItem}
      getItemLayout={(data, index) => ({
        length: vipCardWidth + theme.paddingSize.s,
        offset: (vipCardWidth + theme.paddingSize.s) * index,
        index,
      })}
      horizontal
    />
  );
};

export default VipCardList;
