import {
  TouchableOpacity,
  StyleSheet,
  Image,
  View,
  ViewStyle,
  StyleProp,
} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';

export interface SattaItemButtonProps {
  title?: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  isSelected?: boolean;
}

const SattaItemButton = ({
  title = '',
  isSelected = false,
  style = {},
  onPress,
}: SattaItemButtonProps) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={0.8}
      style={[styles.button, isSelected && styles.buttonSelect, style]}>
      {isSelected && (
        <View
          style={[
            theme.position.abs,
            // theme.borderRadius.m,
            theme.overflow.hidden,
            // eslint-disable-next-line react-native/no-inline-styles
            {left: 0, right: 0, top: 0, bottom: 0},
          ]}>
          <Image
            resizeMode="stretch"
            source={require('@components/assets/imgs/game/satta/button-bg.webp')}
            style={[theme.fill.fill]}
          />
        </View>
      )}
      <Text
        fontFamily="fontInterBold"
        size="medium"
        style={[
          {
            color: isSelected
              ? theme.basicColor.primary
              : theme.basicColor.dark,
          },
        ]}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    height: 40,
    backgroundColor: '#fff',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 8,
    elevation: 6,
    minWidth: 64,
  },
  buttonSelect: {
    elevation: 0,
  },
});

export default SattaItemButton;
