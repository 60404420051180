import TipIcon from '@/components/svgs/basic/tip';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {LotteryMode} from '../mix-lottery-service';
import {MAIN_KEY} from '../constant';
import Text from '@/components/basic/text';
import theme from '@/style';

const LotteryTip = ({mode}: {mode?: LotteryMode}) => {
  const {t} = useTranslation();
  const Two = [
    [
      {title: 'Big', value: MAIN_KEY.Big},
      {title: 'Small', value: MAIN_KEY.Small},
    ],
    [
      {title: 'Odd', value: MAIN_KEY.Odd},
      {title: 'Even', value: MAIN_KEY.Even},
    ],
  ];

  const F_P_C = [
    {
      label: 'Fish：',
      value: MAIN_KEY.Fish,
    },
    {
      label: 'Prawn：',
      value: MAIN_KEY.Prawn,
    },
    {
      label: 'Crab：',
      value: MAIN_KEY.Crab,
    },
  ];

  return (
    <View
      style={[
        theme.padding.tbs,
        theme.padding.lrl,
        theme.flex.row,
        theme.flex.centerByCol,
      ]}>
      <TipIcon />
      <View style={[theme.margin.lefts, theme.flex.flex1]}>
        {mode === LotteryMode['Two Side'] &&
          Two.map((item, _i) => (
            <View key={_i} style={[theme.flex.row]}>
              {item.map(({title, value}, _ii) => (
                <View key={title}>
                  <Text size="medium" fontFamily="fontInterBold">
                    {t(`mix-lotto.label.${title}`)}:{' '}
                    <Text size="medium"> {value}</Text>
                    {_ii === 0 && <Text size="medium"> | </Text>}
                  </Text>
                </View>
              ))}
            </View>
          ))}

        {mode === LotteryMode.FishPrawnCrab && (
          <View style={[theme.flex.row]}>
            {F_P_C.map((item, _i) => (
              <View style={[theme.flex.row, theme.flex.centerByCol]} key={_i}>
                <Text fontFamily="fontInterBold" size="medium">
                  {item.label}
                </Text>
                <Text size="medium" fontWeight="500">
                  {item.value}
                </Text>
                {_i !== F_P_C.length - 1 && (
                  <Text size="medium" fontWeight="500">
                    {' '}
                    ｜{' '}
                  </Text>
                )}
              </View>
            ))}
          </View>
        )}
        {mode === LotteryMode['1 Digit'] && (
          <Text fontWeight="500">{t('mix-lotto.tip.1digitModeTip')}</Text>
        )}
        {mode !== LotteryMode['1 Digit'] &&
          mode !== LotteryMode.FishPrawnCrab &&
          mode !== LotteryMode['Two Side'] && (
            <Text fontWeight="500">{t('mix-lotto.tip.numberModeTip')}</Text>
          )}
      </View>
    </View>
  );
};

export default React.memo(LotteryTip);
