import theme from '@/style';
import {View, StyleSheet} from 'react-native';
import Button from '@/components/basic/button';
import Text from '@/components/basic/text';
import React, {ReactNode} from 'react';
import {ButtonType} from '@/components/basic/button/button';
import {useTranslation} from 'react-i18next';
import {useResponsiveDimensions} from '@/utils';
import globalStore from '@/services/global.state';

export interface RechargeButtonProps {
  disabled?: boolean;
  type?: ButtonType;
  text?: string;
  onRecharge?: () => void;
  receiveTip?: ReactNode;
}

const RechargeButton: React.FC<RechargeButtonProps> = ({
  type,
  onRecharge,
  disabled,
  text,
  receiveTip,
}) => {
  const {i18n} = useTranslation();
  const {width} = useResponsiveDimensions();
  const designWidth = 375;
  const rechargeButtonWidth = (335 * width) / designWidth;
  const rechargeStyle = StyleSheet.create({
    button: {
      width: rechargeButtonWidth,
      height: 48,
    },
    buttonWrap: {
      paddingTop: theme.paddingSize.l,
      paddingHorizontal: theme.paddingSize.l,
      paddingBottom: globalStore.isAndroid
        ? theme.paddingSize.l * 3
        : theme.paddingSize.l,
    },
  });

  return (
    <View
      style={[
        theme.flex.center,
        theme.fill.fillW,
        rechargeStyle.buttonWrap,
        theme.background.white,
      ]}>
      <div style={{position: 'relative'}}>
        {receiveTip}
        <Button
          size="large"
          type={type}
          disabled={disabled}
          width={rechargeButtonWidth}
          buttonStyle={[rechargeStyle.button]}
          onPress={onRecharge}>
          <Text fontSize={theme.fontSize.m} blod color={theme.basicColor.white}>
            {text || i18n.t('label.recharge')}
          </Text>
        </Button>
      </div>
    </View>
  );
};

export default RechargeButton;
