import ProxyHome from './proxy-home';
import ProxyRules from './proxy-rules';
import ProxyCommissionDetail from './proxy-commission-detail';
import ProxyCommissionDetailUser from './proxy-commission-detail-user';
import ProxyTeamReport from './proxy-team-report';
import ProxyTeamReportSearch from './proxy-team-report-search';
import ProxyNewUser from './proxy-new-user';
// import ProxyNewSubordinates from './p';

export default ProxyHome;

export {
  ProxyRules,
  ProxyCommissionDetail,
  ProxyCommissionDetailUser,
  ProxyTeamReport,
  ProxyNewUser,
  ProxyTeamReportSearch,
  // ProxyNewSubordinates,
};
