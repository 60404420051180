import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {View, Image, StyleSheet} from 'react-native';
import React, {
  LegacyRef,
  ReactNode,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Text from '@/components/basic/text';
import Price from '../../price';
import {Shadow} from 'react-native-shadow-2';
const emptyCart = require('@components/assets/imgs/game/empty-cart.webp');
const fullCart = require('@components/assets/imgs/game/full-cart.webp');

import theme from '@/style';
import Button from '@/components/basic/button';
import CartModal, {CartModalRef} from './cart-modal';
import {useConfirm} from '@/components/basic/modal';
import {useTranslation} from 'react-i18next';

export interface ShopCartProps {
  price: number;
  buttonText?: string;
  title: string | ReactNode;
  tip?: string;
  onConfirm?: () => void;
  onClear?: () => void;
  disable?: boolean;
  children?: ReactNode;
  endRef?: LegacyRef<View>;
  buttonType?: 'primary' | 'linear-primary' | 'linear-primary-reversal';
}

export type ShopCarRef = {
  closeModal: () => void;
};

const ShopCart = forwardRef(
  (
    {
      price = 0,
      tip = '',
      buttonText = 'Pay Now',
      disable = false,
      onConfirm,
      title,
      onClear,
      endRef,
      children,
      buttonType = 'linear-primary',
    }: ShopCartProps,
    ref,
  ) => {
    const {t} = useTranslation();
    const [visible, setVisible] = React.useState(false);
    const modalRef = React.useRef<CartModalRef>();
    const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm(
      t('label.del'),
    );
    useImperativeHandle(ref, () => ({
      closeModal: () => {
        modalRef.current?.closeModal?.();
      },
    }));

    const onClearPress = () => {
      confirmShow(t('label.del'), t('game-page.tip.tip-clear'), () =>
        onClear?.(),
      );
    };

    return (
      <>
        {visible && (
          <CartModal
            isEmpty={disable}
            ref={modalRef}
            onClose={() => setVisible(false)}
            onClear={onClearPress}
            headerTitle={title}>
            {children}
          </CartModal>
        )}
        <View
          style={[
            // eslint-disable-next-line react-native/no-inline-styles
            {zIndex: 9999},
          ]}>
          <Shadow style={[theme.fill.fillW]}>
            <View
              style={[
                theme.background.white,
                theme.padding.lrxxl,
                theme.flex.row,
                theme.flex.between,
                styles.container,
                theme.flex.centerByCol,
              ]}>
              <NativeTouchableOpacity
                onPress={() => {
                  if (!visible) {
                    setVisible(true);
                  } else {
                    modalRef.current?.closeModal?.();
                  }
                }}
                style={[theme.flex.row, theme.flex.centerByCol]}>
                <View ref={endRef} collapsable={false}>
                  <Image
                    source={price > 0 ? fullCart : emptyCart}
                    style={[theme.icon.xxl]}
                  />
                </View>

                <View style={[theme.margin.lefts]}>
                  <Price
                    price={price}
                    textProps={{
                      fontFamily: 'fontInterBold',
                      fontSize: 20,
                      color: theme.basicColor.dark,
                    }}
                  />
                  <Text style={[theme.font.medium]} accent>
                    {tip}
                  </Text>
                </View>
              </NativeTouchableOpacity>
              <Button
                onPress={() => onConfirm?.()}
                disabled={disable}
                title={buttonText}
                type={buttonType}
                titleFontFamily="fontInterBold"
              />
            </View>
          </Shadow>
        </View>
        {renderConfirmModal}
      </>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    zIndex: 9999,
    height: 64,
  },
});

export default React.memo(ShopCart);
