import {View, StyleSheet, ScrollView} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import HotCold from '../component/hot-cold';
import DigitAnalyze from '../component/digit-analyze';
import {ModeDataTabItem} from '../mix-lottery-service';
import DigitBall from '../component/digit-ball';
import {
  COLORS,
  COLORS_BORDER,
  COLORS_LIGHT,
  COLORS_LIGHT_BORDER,
} from '../constant';
import {groupArrayByLength} from '@/utils';
import {BasicObject} from '@/types';

const AnalyzeItem = ({
  analyze,
  tabOptions,
  selectIndex = 1,
}: {
  analyze: string[][];
  tabs: ModeDataTabItem[];
  tabOptions: ModeDataTabItem[];
  selectIndex: number;
}) => {
  const [currentType, setCurrentType] = React.useState(0);

  const currentChar = React.useMemo(() => {
    return tabOptions[selectIndex - 1].name[0] as
      | 'A'
      | 'B'
      | 'C'
      | 'D'
      | 'UNUSED';
  }, [selectIndex, tabOptions]);

  const getNumArr = (from: string[][], _i: number) => {
    const currentPrize = from
      .map(item => item[_i <= 4 ? 0 : 1])
      .map(item => item.slice(-4)) as string[];
    const _index = _i % 4 === 0 ? 3 : (_i % 4) - 1;
    return currentPrize.map(item => item[_index]);
  };

  const currentData = React.useMemo<string[][]>(() => {
    if (analyze.length > 0) {
      const numArr = getNumArr(analyze, selectIndex);
      return groupArrayByLength(numArr);
    }
    return [];
  }, [selectIndex, analyze]);

  const defaultRate = React.useMemo(() => {
    const rate = {
      B: 0,
      O: 0,
      Hot: [0, 0, 0],
      Cold: [0, 0, 0],
    };
    const numArr = getNumArr(analyze, selectIndex);
    const total = numArr.length;
    if (total > 0) {
      const big = numArr.filter(item => +item > 4).length;
      const odd = numArr.filter(item => +item % 2 === 1).length;
      rate.B = big / total;
      rate.O = odd / total;
      const frequencyMap = {} as BasicObject;
      // 计算每个数字的出现次数
      numArr.forEach(num => {
        frequencyMap[num] = (frequencyMap[num] || 0) + 1;
      });
      // 将频率对象转换为数组形式
      const frequencyArray = Object.entries(frequencyMap);

      // 按照出现次数对数组进行排序
      frequencyArray.sort((a, b) => b[1] - a[1]);

      // 找出出现次数最多的三个数字
      const most = frequencyArray
        .slice(0, 3)
        .map(entry => parseInt(entry[0], 10));

      // 找出出现次数最少的三个数字
      const least = frequencyArray
        .slice(-3)
        .map(entry => parseInt(entry[0], 10));
      rate.Hot = most;
      rate.Cold = least;
    }
    return rate;
  }, [selectIndex, analyze]);

  return (
    <>
      <SelectTab selected={currentType} onChange={setCurrentType} />
      <View style={[theme.background.white, theme.padding.btml]}>
        {analyze.length > 0 && (
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={[theme.padding.leftl, theme.padding.tops]}>
            {[
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19,
            ].map(rowi => {
              return (
                <View key={rowi}>
                  {[0, 1, 2, 3, 4].map(coli => {
                    const currentNumArr = currentData[rowi] || [];
                    const value = currentNumArr[coli] || '';
                    const type = value
                      ? currentType === 0
                        ? +value > 4
                          ? 'B'
                          : 'S'
                        : +value % 2
                        ? 'O'
                        : 'E'
                      : '';
                    return (
                      <View style={[styles.box, theme.flex.center]} key={coli}>
                        {type ? (
                          <DigitBall
                            fontWeight="500"
                            hasBorder={coli === 0 && rowi === 0}
                            borderColor={
                              type === 'B' || type === 'O'
                                ? COLORS_BORDER[currentChar]
                                : COLORS_LIGHT_BORDER[currentChar]
                            }
                            digit={type}
                            fontSize="default"
                            type={'A'}
                            bg={
                              type === 'B' || type === 'O'
                                ? COLORS[currentChar]
                                : COLORS_LIGHT[currentChar]
                            }
                          />
                        ) : null}
                      </View>
                    );
                  })}
                </View>
              );
            })}
          </ScrollView>
        )}
        <ScrollView
          style={[theme.margin.topl]}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={[
            {gap: theme.paddingSize.m},
            theme.padding.leftl,
          ]}>
          <DigitAnalyze title="B/S" type={currentChar} rate={defaultRate.B} />
          <DigitAnalyze title="O/E" type={currentChar} rate={defaultRate.O} />
          <HotCold
            type={currentChar}
            hot={defaultRate.Hot}
            cold={defaultRate.Cold}
          />
        </ScrollView>
      </View>
    </>
  );
};

const TYPE_LIST = ['B/S', 'O/E'];

const SelectTab = ({
  selected = 0,
  onChange,
}: {
  selected?: number;
  onChange?: (i: number) => void;
}) => {
  return (
    <View
      style={[
        theme.flex.row,
        {paddingHorizontal: theme.paddingSize.l * 2},
        theme.padding.btml,
        theme.padding.tops,
        theme.flex.between,
        styles.tabContainer,
      ]}>
      {TYPE_LIST.map((item, i) => {
        const isActive = selected === i;
        return (
          <NativeTouchableOpacity
            key={i}
            style={[styles.typeItem]}
            onPress={() => onChange?.(i)}>
            <Text
              color={isActive ? theme.basicColor.dark : theme.fontColor.accent}
              textAlign="center"
              fontWeight="500"
              size="medium">
              {item}
            </Text>
            {isActive && <View style={styles.arrow} />}
          </NativeTouchableOpacity>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  tabContainer: {
    backgroundColor: '#E9ECF5',
  },
  typeItem: {
    width: 80,
    ...theme.flex.center,
  },
  arrow: {
    position: 'absolute',
    bottom: -12,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderLeftWidth: 6,
    borderRightWidth: 6,
    borderBottomWidth: 8,
    borderTopColor: 'transparent', //下箭头颜色
    borderLeftColor: 'transparent', //右箭头颜色
    borderBottomColor: '#000', //上箭头颜色
    borderRightColor: 'transparent', //左箭头颜色
  },
  box: {
    width: 26,
    height: 26,
    // backgroundColor: 'red',
    borderColor: '#D8E0EA',
    borderWidth: 0.5,
  },
});

export default AnalyzeItem;
