import {Modal, View, StyleSheet} from 'react-native';
import lottie, {AnimationItem} from 'lottie-web';
import React from 'react';
import animationjson from '@components/assets/lottie/cut-down.json';
import {useResponsiveDimensions} from '@/utils';

const CutDown = ({
  visible = false,
  onClose,
}: {
  visible?: boolean;
  onClose?: () => void;
}) => {
  const viewRef = React.useRef(null);
  const animationRef = React.useRef<AnimationItem>();
  React.useEffect(() => {
    if (visible) {
      animationRef.current = lottie.loadAnimation({
        container: viewRef.current!,
        renderer: 'svg',
        loop: false,
        animationData: animationjson,
      });
      onClose && animationRef.current.addEventListener('complete', onClose);
      return () => {
        animationRef.current?.destroy();
      };
    } else {
      animationRef.current?.destroy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const {width} = useResponsiveDimensions();
  const size = (200 / 375) * width;

  return (
    <Modal visible={visible} animationType="fade" transparent>
      <View style={styles.container}>
        <View
          ref={viewRef}
          style={{
            width: size,
            height: size,
          }}
        />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CutDown;
