import {SVGProps} from '@/types';
import React from 'react';

const TriangleIcon = ({
  color = '#9FA5AC',
  width = 12,
  height = 12,
}: SVGProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.70711 8.29289C6.31658 8.68342 5.68342 8.68342 5.29289 8.29289L1.70711 4.70711C1.07714 4.07714 1.52331 3 2.41421 3L9.58579 3C10.4767 3 10.9229 4.07714 10.2929 4.70711L6.70711 8.29289Z"
        fill={color}
      />
    </svg>
  );
};

export default TriangleIcon;
