export default {
  label: {
    ok: 'ఓకే',
    confirm: 'కన్ఫర్మ్',
    cancel: 'కాన్సుల్',
    download: 'డౌన్లోడ్',
    submit: 'సబ్మిట్',
    search: 'సెర్చ్',
    recharge: 'రీచార్జ్',
    balance: 'బ్యాలెన్స్',
  },
  tip: {
    success: 'సక్సెసఫుల్య్',
  },
  text: {},
  defaultPage: {
    noMore: 'డేటా లేదు',
  },
  home: {
    scratchOff: 'స్క్రాచ్ ఆఫ్',
    copied:
      'కాపీ సక్సెసఫుల్ గా పూర్తయింది, ఇప్పుడు స్నేహితులకు షేర్ చేసుకోండి ',
    tip: {
      beforDownload: 'రిజిస్టర్ చేయండి లేదా లాగిన్ చేయండి',
    },
  },
  alert: {
    logout: 'లాగ్ అవుట్',
    sureLogout: 'మీరు ఖచ్చితంగా లాగ్ అవుట్ చేయాలనుకుంటున్నారా?',
  },
  warning: {
    unopen: 'ఇప్పటికే ప్రారంభించబడలేదు',
  },
  notification: {
    title: 'నోటిఫికేషన్',
    announcements: 'అంనౌన్సమెంట్స్',
    systemMessage: 'సిస్టం సందేశాలు',
  },
  vip: {
    currentLevel: 'కరెంటు లెవెల్',
    collected: 'సేకరించాలి',
    finished: 'పూర్తయింది',
    received: 'పొందబడింది',
    recharge: 'రీచార్జ్',
    move: 'లెవెల్ చేరడానికి మరింత అవకాశం ఉంది',
    title: 'విఐపి',
    table: {
      level: 'విఐపి లెవెల్',
      reward: 'రివార్డ్',
      bouns: 'బోనస్',
      spin: 'స్పిన్',
    },
    success: 'విజయవంతంగా పొందబడింది',
  },
  referral: {
    copy: 'కాపీ',
    invite: 'ఇన్వైట్',
    copied: 'విజయవంతంగా కాపీ చేయబడింది',
  },
  luckyspin: {
    add: 'ఆడ్',
    spin: 'స్పిన్',
    won: 'వాన్',
    winner: 'బిగ్ విన్నర్స్',
    mySpin: 'మై స్పిన్',
    free: 'ఫ్రీ',
    myfree: 'నా ఫ్రీ',
    morefree: 'మోర్ ఫ్రీ',
    vip: 'వీఐపీ',
    usefreefirst: 'ముందుగా ఫ్రీ సమయాన్ని ఉపయోగించుకోండి',
  },
  'error-pages': {
    refresh: {
      label: {
        title: 'ఏదో భయంకరమైన తప్పు జరిగింది!',
        'sub-title': 'దయచేసి మీ పేజీని రిఫ్రెష్ చేయండి',
        'try-again': 'మళ్లీ ప్రయత్నించండి',
      },
    },
    'no-data': {
      label: {
        title: 'సమాచారం లేదు',
      },
    },
    'not-found': {
      label: {
        title: 'ప్రస్తుత నెట్వర్క్ సిగ్నల్ చాలా తక్కువగా ఉంది',
        'sub-title': 'దయచేసి మీ నెట్వర్క్‌ను తనిఖీ చేయండి',
      },
    },
    'no-result': {
      label: {
        title: 'నో సెర్చ్ రెసుల్త్!',
      },
    },
  },
  share: {
    copy: 'కాపీ',
    invite: 'ఇన్వైట్',
    'copy-success': 'విజయవంతంగా కాపీ చేయబడింది',
    copied: 'విజయవంతంగా కాపీ చేయబడింది',
  },
  casino: {
    big: 'బిగ్',
    win: 'విన్',
    detail: {
      title: 'అదృష్ట క్రీడాకారులకు అభినందనలు',
    },
    winPrize: 'విన్ ప్రైజ్',
    playnow: 'ప్లే నౌ',
  },
  login: {
    label: {
      login: 'లాగిన్',
      skip: 'స్కిప్',
      sign: 'సైన్ అప్',
      next: 'తరువాత',
      'select-region': 'ప్రాంతం ఎంచుకోండి',
      'otp-login': 'OTP లాగిన్',
      'password-login': 'పాస్‌వర్డ్ లాగిన్',
      'get-otp': 'OTP పొందండి',
      'set-password': 'పాస్‌వర్డ్‌ని సెట్ చేయండి',
    },
    tip: {
      'no-phone': 'దయచేసి ఫోన్ నంబర్‌ని నమోదు చేయండి',
      'no-password': 'దయచేసి పాస్‌వర్డ్‌ని నమోదు చేయండి',
      'no-otp': 'దయచేసి OTPని నమోదు చేయండి',
      phone: 'ఫోన్ నంబర్‌ను నమోదు చేయండి',
      password: 'పాస్‌వర్డ్‌ని నమోదు చేయండి(6-18)',
      passwordRule:
        'పొడవు తప్పనిసరిగా 8~16 ఉండాలి మరియు సంఖ్యలు మరియు అక్షరాలు రెండింటినీ కలిగి ఉండాలి',
      'confirm-password': 'కన్ఫర్మ్ పాస్వర్డ్',
      forgot: 'మీ పాస్వర్డ్ మర్చిపోయారా?',
      'new-account': 'అకౌంట్ కావాలాం?',
      'sing-in': 'సైన్ అప్',
      otp: 'OTPని నమోదు చేయండి',
      'referral-code': 'రిఫరల్ కోడ్',
      confrim18: 'నా వయస్సు 18 అని నేను ధృవీకరిస్తున్నాను',
      'allow-notify':
        'ఈ ఫోన్ నంబర్ ద్వారా ముఖ్యమైన విజేత సమాచారాన్ని మీకు తెలియజేయడానికి మమ్మల్ని అనుమతించండి.',
      'has-account': 'ఇప్పటికే యూసర్ ఉన్నారా?',
      'sign-in': 'లాగిన్',
      page18: 'నా వయస్సు 18 అని నేను ధృవీకరిస్తున్నాను',
      notify:
        'ఈ మొబైల్ ద్వారా ముఖ్యమైన విజేత సమాచారాన్ని మీకు తెలియజేయడానికి మమ్మల్ని అనుమతించండి',
      'pwd-dif': 'రెండు పాస్‌వర్డ్‌లు అస్థిరంగా ఉన్నాయి',
      modified: 'పాస్వర్డ్ విజయవంతంగా మారాయి . దయచేసి మళ్లీ లాగిన్ చేయండి',
      'game-not-active':
        'క్షమించండి, గేమ్ నిర్వహణలో ఉంది. దయచేసి తరువాత మళ్లీ ప్రయత్నించండి',
    },
  },
  paidSuccess: {
    label: {
      title: 'పేమెంట్',
      subTitle: 'విజయవంతంగా చెల్లించబడింది',
      viewOrder: 'వ్యూ ఆర్డర్',
      backHome: 'బ్యాక్ హోమ్',
    },
    tip: {
      msg: 'మీ టిక్కెట్లు విజయవంతంగా కొనుగోలు చేయబడ్డాయి.దయచేసి డ్రా సమయాన్ని గమనించండి మరియు ఫలితాలను వెంటనే తనిఖీ చేయండి.',
    },
  },
  splash: {
    tip: {
      checkingResources: 'రిసోర్సెస్ తనిఖీ చేస్తోంది...',
      checkingVersion: 'చెకింగ్ వెర్షన్...',
      downloading: 'రిసోర్సెస్ డౌన్లోడ్ చేస్తోంది...',
      resolve: 'రిసోర్సెస్ రీసోల్వింగ్ (ఈ ప్రక్రియ నెట్‌వర్క్‌ను వినియోగించదు)',
      alertTitle: 'అప్డేట్ అందుబాటులో ఉంది.',
      alertContent:
        'యాప్ అప్లై చేస్తోంది అప్డేట్ చేయడానికి దానితరువాత  పునఃప్రారంభించబడుతుంది.',
      resolv:
        'రిసోర్సెస్ పరిష్కరిస్తోంది(ఈ ప్రక్రియ నెట్వర్క్‌ను వినియోగించదు)',
      restart: 'పునఃప్రారంభించు',
    },
  },
  recharge: {
    tip: {
      easyPaymentError:
        'సేవను పొందడంలో విఫలమైంది,దయచేసి ఇతర పేమెంట్ పద్ధతిని ఎంచుకోండి.',
    },
  },
  'recharge-page': {
    currentMethod: 'ప్రస్తుత పద్దతి',
    label: {
      records: 'రికార్డులు',
      min: 'కనిష్ఠ',
      max: 'గరిష్ఠ',
      channel: 'రిచార్జ్ ఛానల్',
      enter: 'రిచార్జ్ మొత్తాన్ని నమోదు చేయండి',
    },
    tip: {
      payMethodTip: 'ప్రస్తుత పద్దతి విఫలమైతే దయచేసి ఇతర పద్దతికి మార్చండి.',
      'money-error': 'దయచేసి సరైన మొత్తం నమోదు చేయండి',
      'paymethod-error': 'దయచేసి చెల్లింపు పద్ధతిని ఎంచుకోండి',
    },
  },
  datePicker: {
    label: {
      select: 'డేటా సెలెక్ట్ చేసుకోండి',
    },
  },
  rebate: {
    tabs: {
      all: 'అన్ని',
      finish: 'పూర్తయింది',
      collect: 'టు బీ కలెక్టెడ్',
      received: 'రెసీవ్డ్',
    },
    title: 'రీబేట్',
    filter: 'ఫిల్టర్',
    undone: 'పూర్తి కాలేదు',
    unclaimed: 'క్లైమ్ చేసుకోలేదు',
    completed: 'పూర్తి చేయబడింది',
    unfinished: 'పూర్తయినది కాదు',
    receive: 'రిసీవ్',
    received: 'రెసీవ్డ్',
    today: 'ఈ రోజు',
    yesterday: 'నిన్న',
    betting: 'అంచనా బెటింగ్:',
    rebate: 'అంచనా రీబేట్:',
    congratulations: 'అభినందనలు!',
    'get-bonus': 'బోనస్ను పొందండి',
    rule: {
      betting: 'బెటింగ్ బోనస్:',
      'betting-detail':
        '{{name}} యూజర్కు బెట్టింగ్ మొత్తం ప్రకారం రిబేట్ ఇస్తారు .మీరు ఎక్కువ బెట్ చేస్తే, ఎక్కువ పొందవచ్చు',
      activity: 'ఆక్టివిటీ వివరణ:',
      'activity-detail':
        '{{name}} సూపర్ బోనస్ రీబేట్ ఆక్టివిటీ మీకోసం ఓపెన్ చేసి ఉంది. ప్రతి ఓక బెట్ పే మీరు బోనస్ పొందవచ్చు. మీరు గెలిచిన ఓడిపోయిన రిపీట్ బోనస్ అనేది ప్రతిరోజు క్లైమ్ చేసుకోవచ్చు.',
      actrule: 'ఆక్టివిటీ నిబంధనలు:',
      'actrule-detail':
        'ప్రతిరోజు ప్రతిఒక యూసర్ వాల్ రీబేట్ బెటింగ్ బోనస్ ఆ దె రోజూ నైట్ 00:30 తర్వాత రిపీట్ బోనస్ అనేది క్లైమ్ చేసుకోవచ్చు',
      precaution: 'ముందుజాగ్రత్తలు:',
      'precaution-detail-1':
        '-అర్హత కలిగిన {{name}} సభ్యులు మాత్రమే కార్యకలాపాలలో పాల్గొనగలరు, రిబేట్ బోనస్‌ను క్లెయిమ్ చేయడానికి ప్రతి సభ్యుడు సింగం అకౌంట్లో వివరాలను పూర్తి చేయాలి.- చట్టవిరుద్ధమైన కార్యకలాపాలకు పాల్పడే ఏ యూసర్ అయినా ఖచ్చితంగా నిషేధించబడతారు.',
      'precaution-detail-2':
        '-సమె ఫోన్ నంబర్, బ్యాంక్ అకౌంట్ నంబర్. ఇమెయిల్ చిరునామా మరియు IP చిరునామా అనేవీ ముల్తిప్లె అకౌంట్స్ ఉపయోగించరాదు',
      'precaution-detail-3':
        '-ముంబర్స్ అయినా చట్టవిరుద్ధమైన/మోసపూరిత కార్యకలాపాలకు పాల్పడితే వాలా సింగం అకౌంట్ ఫ్రీజ్ చైయడం లేదా చెల్లింపులను రద్దు చేసే హక్కు {{name}}కి ఉంది .(చివరి బోనస్ నిర్ణయం సింగందే).',
    },
  },
  wallet: {
    title: 'నా వాలెట్',
    total: 'టోటల్ వాలెట్',
    recharge: 'రీచార్జ్',
    main: 'మెయిన్ వాల్లెట్',
    third: '3ర్డ్ పార్టీ వాలెట్',
    transfer: 'మెయిన్ వాల్లెట్ ట్రాన్స్ఫర్',
    translation: 'ట్రాన్స్లేషన్:{{సెకండ్}}లు',
    transComplete: 'కన్వర్షన్ కంప్లీటెడ్',
    transferNav: {
      recharge: 'రీచార్జ్',
      withdraw: 'విత్ డ్రా',
      transfer: 'ట్రాన్స్ఫర్',
    },
  },
  promotion: {
    title: 'ప్రమోషన్లు',
    go: 'గో',
    playNow: 'ప్లే నౌ',
    view: 'వ్యూ',
    detail: 'యాక్టివిటీ డీటెయిల్స్',
    join: 'జాయిన్',
    checkin: 'చెక్ ఇన్',
    checkinDescription:
      'చాలా రివార్డ్‌లను పొందడానికి ప్రతిరోజూ చెక్ ఇన్ చేయండి',
  },
  satta: {
    title: {
      betting: 'బెట్టింగ్...',
      rule: 'నియమం',
      history: 'రెసుల్ట్ హిస్టరీ',
      order: 'మై ఆర్డర్',
      panel: 'ప్యానెల్: ',
      open: 'ఓపెన్',
      close: 'క్లోజ్',
    },
    betting: {
      ank: 'అంక్',
      jodi: 'జోడి',
      sp: 'SP',
      dp: 'DP',
      tp: 'TP',
      half: 'హాఫ్ సంగం',
      full: 'ఫుల్ సంగం',
      exwinning: 'ఆశించబడుతున్న విజయం:',
      open: 'ఓపెన్',
      close: 'క్లోజ్',
      paynow: 'పే నౌ',
      add: 'ఆడ్',
      mybids: 'మై బిడ్స్',
      bids: 'బిడ్స్',
      tip: {
        enter: {
          amount: 'ఎంటర్ బయ్ అమౌంట్',
          open: 'ఎంటర్ ఓపెన్ డిజిట్‌',
          opens: 'ఎంటర్ ఓపెన్ డిజిట్స్',
          close: 'ఎంటర్ క్లోజ్ డిజిట్‌',
          closes: 'ఎంటర్ క్లోజ్ డిజిట్స్',
          jodi: 'ఎంటర్ జోడి డిజిట్స్',
          openpanna: 'ఎంటర్ ఓపెన్ పన్నా',
          closepanna: 'ఎంటర్ క్లోజ్ పన్నా',
        },
        require: {
          amount: 'ప్లీజ్ ఎంటర్ బయ్ అమౌంట్',
          open: 'ప్లీజ్ ఎంటర్ ఓపెన్ డిజిట్‌',
          opens: 'ప్లీజ్ ఎంటర్ ఓపెన్ డిజిట్స్',
          close: 'ప్లీజ్ ఎంటర్ క్లోజ్ డిజిట్‌',
          closes: 'ప్లీజ్ ఎంటర్ క్లోజ్ డిజిట్స్ ',
          jodi: 'ప్లీజ్ ఎంటర్ జోడి డిజిట్స్',
          openpanna: 'ప్లీజ్ Eఎంటర్ ఓపెన్ పన్నా',
          closepanna: 'ప్లీజ్ ఎంటర్ క్లోజ్ పన్నా',
        },
        check: 'ప్లీజ్ ఎంటర్ కంప్లీటెడ్ డిజిట్స్',
        minAmount: 'ది మినిమం పే అమౌంట్ {{అమౌంట్}}',
      },
      digitTitle: {
        ank: 'సింగిల్ డిజిట్:',
        jodi: 'జోడి డిజిట్స్:',
        sp: 'సింగిల్ పట్టి:',
        dp: 'డబుల్ పట్టి:',
        tp: 'ట్రిపుల్ పట్టి:',
        half: 'హాఫ్ సంగం:',
        full: 'ఫుల్ సంగం:',
      },
      describe: {
        ank: 'క్లిక్ ఓపెన్ ఓర క్లోజ్ క్లిక్ చేయండి మరియు ఇన్‌పుట్ బాక్స్‌లో మీకు కావలసిన అంకెను నమోదు చేయండి, ఉదాహరణకు 1',
        jodi: 'ఇన్‌పుట్ బాక్స్‌లో మీరు బీటా చేయాలనుకుంటున్న రెండు అంకెల సంఖ్యను నమోదు చేయండి, ఉదాహరణకు 12',
        sp: 'క్లిక్ ఓపెన్ ఓర క్లోజ్ క్లిక్ చేసి, ఇన్‌పుట్ బాక్స్‌లో మీరు బీటా చేయాలనుకుంటున్న 123 వంటి మూడు నంబర్‌లను నమోదు చేయండి',
        dp: 'క్లిక్ ఓపెన్ ఓర క్లోజ్ క్లిక్ చేయండి మరియు ఇన్‌పుట్ బాక్స్‌లో మీరు పందెం వేయాలనుకుంటున్న మూడు నంబర్‌లను నమోదు చేయండి, ఉదాహరణకు 112 వంటి రెండు ఒకే సంఖ్యలు ఉండాలి',
        tp: 'క్లిక్ ఓపెన్ ఓర క్లోజ్ క్లిక్ చేయండి మరియు 111 వంటి మూడు ఒకే సంఖ్యలను నమోదు చేయండి',
        half: 'ఓపెన్ ఓరా క్లోజ్ క్లిక్ చేయండి మరియు ఇన్‌పుట్ బాక్స్‌లో 123 మరియు 1,1 మరియు 123 వంటి మూడు లేదా ఒక సంఖ్యను నమోదు చేయండి',
        full: 'రెండు ఇన్‌పుట్ బాక్స్‌లలో 123 మరియు 123 వంటి మూడు అంకెలను నమోదు చేయండి',
      },
    },
  },
};
