import React from 'react';
import {getRule} from './digit-service';
import Spin from '@/components/basic/spin';
import theme from '@/style';

const DigitRule = ({gameName = ''}: {gameName?: string}) => {
  const [rule, setRule] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getRuleByName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRuleByName = async () => {
    try {
      setLoading(true);
      const str = `<script>
      document.addEventListener("DOMContentLoaded", () => {
        document.body?.style.setProperty('max-width', 'unset', 'important');
        document.querySelector('.banner')?.style.setProperty('display', 'none', 'important');
      });
    </script>`;
      const res = await getRule(gameName);
      if (res) {
        const i = res.content.search('</body>');
        if (i > -1) {
          setRule(res.content.slice(0, i) + str + res.content.slice(i));
        } else {
          setRule(res.content + str);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const webStyle = {
    borderWidth: 0,
    flex: 1,
    display: 'flex',
  };

  return (
    <Spin loading={loading} style={[theme.fill.fill, theme.padding.btmxxl]}>
      <iframe
        style={webStyle}
        height={'100%'}
        width={'100%'}
        src={`data:text/html;charset=utf-8,${encodeURIComponent(rule)}`}
      />
    </Spin>
  );
};

export default React.memo(DigitRule);
