import theme from '@/style';
import React from 'react';
import {View} from 'react-native';
import BetsButton from './bets-btn';
import DigitBall from './digit-ball';
import NumberInput, {NumberInputRef} from './number-input';
import NumberStepper from './number-stepper';
import {BetsItemProps} from './bets-single-item';
import {useTranslation} from 'react-i18next';

export interface BetsDoubleLevelItemProps {
  digits?: string[];
  guessNums?: string[];
  extend?: number;
}

const BetsDoubleLevelItem = (
  props: BetsItemProps & BetsDoubleLevelItemProps,
) => {
  const {digits = [], guessNums = [], onAdd = () => {}, extend = 0} = props;
  const inputRefList = React.useRef<React.RefObject<NumberInputRef>[]>([
    React.createRef(),
    React.createRef(),
  ]);
  const startElement = React.useRef<View>(null);
  const [values, setValues] = React.useState<string[]>([]);
  const [rate, setRate] = React.useState<string>('');
  const [status, setStatus] = React.useState(false);
  React.useEffect(() => {
    if (guessNums) {
      if (guessNums.filter(i => i).length === 2) {
        setValues(guessNums);
      }
    }
  }, [guessNums, extend]);

  const onTextChange = (i: number, v: string) => {
    if (i < 1 && v) {
      const nextInput = inputRefList.current[i + 1]?.current;
      if (nextInput) {
        nextInput.inputRef.current?.focus();
      }
    }
    values[i] = v;
    setValues([...values]);
  };

  const showRate = React.useMemo(() => {
    const hasInput = values.filter(i => i).length === 2;
    return hasInput || status;
  }, [values, status]);

  React.useEffect(() => {
    if (showRate) {
      setRate('3');
    }
  }, [showRate]);

  React.useEffect(() => {
    if (+rate <= 0 && !status) {
      setValues([]);
    }
  }, [rate, status]);

  const {i18n} = useTranslation();

  return (
    <View style={[theme.margin.topl, theme.flex.row, theme.flex.between]}>
      <View style={[theme.flex.row, {gap: theme.paddingSize.s}]}>
        <View
          style={[theme.flex.row, {gap: theme.paddingSize.xxs}]}
          ref={startElement}
          collapsable={false}>
          {digits.map((item, _i) => (
            <DigitBall
              digit={item}
              color={item}
              key={_i}
              fontSize={16}
              ballSize={32}
            />
          ))}
        </View>
        {digits.map((item, index) => (
          <NumberInput
            ref={inputRefList.current[index]}
            value={values[index]}
            onChange={v => onTextChange(index, v)}
            key={index}
          />
        ))}
      </View>
      <View style={[theme.flex.row, {gap: theme.paddingSize.l}]}>
        {showRate && (
          <NumberStepper
            onFocusStateChange={setStatus}
            rate={rate}
            onChangeRate={e => setRate(e)}
            onStepChange={e => setRate(`${e}`)}
          />
        )}
        <BetsButton
          onPress={() => {
            onAdd(
              digits.join(''),
              values.join(''),
              Number(rate),
              startElement.current,
            );
            setTimeout(() => {
              setStatus(false);
              setValues([]);
            });
          }}
          disabled={!showRate}
          title={i18n.t('game-page.label.add').toUpperCase()}
          style={[
            theme.padding.lrl,
            theme.borderRadius.xs,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              height: 32,
            },
          ]}
        />
      </View>
    </View>
  );
};

export default React.memo(BetsDoubleLevelItem);
