/* eslint-disable react-native/no-inline-styles */
import theme from '@/style';
import React from 'react';
import {View, Image} from 'react-native';
import Ball from '../component/ball';
import DiceItem from '../component/dice-item';
import {DICES} from '../constant';

interface betsInfoType {
  type?: string;
  code?: string;
  digit?: number;
  digitType?: string;
}

const BetsListItem = ({digit = 0, digitType, type}: betsInfoType) => {
  const Ele = React.useMemo(() => {
    switch (type) {
      case 'sum':
        return (
          <Ball
            ballSize={28}
            isNum={digit > 0}
            num={digit}
            fontSize={14}
            type={digitType}
          />
        );
      case 'double':
        return (
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            <DiceItem diceSize={28} diceNum={digit} />
            <DiceItem diceSize={28} diceNum={digit} />
          </View>
        );
      case 'single':
        return <DiceItem diceSize={28} diceNum={digit} />;
      case 'leopard':
        return (
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            {digitType === 'any' ? (
              <Image source={DICES.any} style={{width: 30, height: 30}} />
            ) : (
              <DiceItem diceSize={30} diceNum={digit} />
            )}
            {digitType === 'any' ? (
              <Image source={DICES.any} style={{width: 30, height: 30}} />
            ) : (
              <DiceItem diceSize={30} diceNum={digit} />
            )}
            {digitType === 'any' ? (
              <Image source={DICES.any} style={{width: 30, height: 30}} />
            ) : (
              <DiceItem diceSize={30} diceNum={digit} />
            )}
          </View>
        );
    }
  }, [digit, digitType, type]);
  return <View>{Ele}</View>;
};

export default BetsListItem;
