import React from 'react';
import {View} from 'react-native';
import LazyImage from '@/components/basic/image';
import Text from '@/components/basic/text';
import theme from '@/style';
import LotteryTimeCutdown, {
  LotteryTimeCutdownProps,
} from '@/components/business/games/lottery-time-cutdown';
// import Marquee from '@/components/basic/marquee/marquee';
export interface GameInfoProps {
  gameLogo?: string;
  lotteryName?: string;
  issueNum?: string;
  remainingTime?: number;
  timeVersion?: number;
  isQuick?: boolean;
  cycle?: number;
  isLive?: boolean;
}

const GameInfo = ({
  gameLogo = '',
  lotteryName = '',
  issueNum = '',
  remainingTime,
  timeVersion,
  ...props
}: GameInfoProps & LotteryTimeCutdownProps) => {
  return (
    <View style={[theme.flex.row, theme.flex.centerByCol, theme.flex.between]}>
      <View style={[theme.flex.row, theme.flex.centerByCol, theme.flex.flex1]}>
        <LazyImage
          imageUrl={gameLogo}
          width={44}
          height={44}
          occupancy="transparent"
        />
        <View style={[theme.margin.lefts, theme.flex.flex1]}>
          <View style={[theme.flex.row, theme.flex.flex1]}>
            <Text
              color={theme.basicColor.dark}
              fontFamily="fontInterBold"
              size="medium">
              {lotteryName}
            </Text>
            {/* {isQuick && (
              <Text
                color={theme.basicColor.dark}
                fontFamily="fontInterBold"
                size="medium">
                - {cycle}min
              </Text>
            )} */}
          </View>
          <Text style={[theme.margin.topxxs]}>Draw No.{issueNum}</Text>
        </View>
      </View>
      <LotteryTimeCutdown
        remainingTime={remainingTime}
        timeVersion={timeVersion}
        {...props}
      />
    </View>
  );
};

export default GameInfo;
