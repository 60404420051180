/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {View, StyleSheet} from 'react-native';
// import Ball from './ball';
// import DiceItem from './dice-item';
import theme from '@/style';
import PrevResultItem from './prev-result-item';
const DicePrevResult = ({
  oddOrEven,
  bigOrSmall,
  total,
  result = [],
}: {
  result?: string[];
  total?: number;
  bigOrSmall?: number;
  oddOrEven?: number;
}) => {
  return (
    <View style={[theme.flex.row, theme.margin.tops, {columnGap: 2}]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByRow,
          styles.container,
          theme.overflow.hidden,
        ]}>
        {result.map((item, _i) => (
          <PrevResultItem key={_i} type={'DICE'} result={Number(item)} />
        ))}
      </View>
      <View
        style={[
          theme.flex.centerByCol,
          styles.container,
          theme.overflow.hidden,
          {width: 40, height: 40},
        ]}>
        <PrevResultItem type={'SUM'} result={total} />
      </View>
      <View
        style={[
          theme.flex.row,
          theme.padding.lrs,
          theme.overflow.hidden,
          styles.container,
          {width: 68, height: 40},
        ]}>
        {bigOrSmall && bigOrSmall > 0 ? (
          <PrevResultItem
            type={'TYPE'}
            result={bigOrSmall === 1 ? 'big' : 'small'}
          />
        ) : null}
        {oddOrEven && oddOrEven > 0 ? (
          <PrevResultItem
            type={'TYPE'}
            result={oddOrEven === 2 ? 'even' : 'odd'}
          />
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.backgroundColor.diceResultBg,
    borderRadius: 8,
    columnGap: 4,
    width: 96,
    height: 40,
  },
});

export default React.memo(DicePrevResult);
