import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import LinearGradient from '@/components/basic/linear-gradient';
import React from 'react';
import Text from '@/components/basic/text';
import {StyleSheet} from 'react-native';
import theme from '@/style';

export interface BetsTabItemProps {
  active?: boolean;
  label?: string;
  onTabChange?: () => void;
}

const BetsTabItem = ({
  label = '',
  active = false,
  onTabChange = () => {},
}: BetsTabItemProps) => {
  const colors = React.useMemo(() => {
    return active
      ? [theme.basicColor.primary, theme.basicColor.primary]
      : [theme.backgroundColor.grey, '#EAF3FD'];
  }, [active]);

  const borderTopColor = React.useMemo(() => {
    return active ? theme.basicColor.primary : 'rgba(255, 255, 255, 0.50)';
  }, [active]);

  return (
    <NativeTouchableOpacity
      onPress={onTabChange}
      style={[styles.itemContainer, theme.flex.flex1, theme.overflow.hidden]}>
      <LinearGradient
        colors={colors}
        style={[styles.item, theme.flex.center, {borderTopColor}]}>
        <Text
          color={active ? theme.basicColor.white : theme.fontColor.second}
          fontFamily={active ? 'fontInterBold' : 'fontInter'}
          size="medium">
          {label}
        </Text>
      </LinearGradient>
    </NativeTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  item: {
    height: 40,
    width: '100%',
    borderTopWidth: 1,
  },
});

export default BetsTabItem;
