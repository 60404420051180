import {SVGProps} from '@/types';
import React from 'react';

const CloseCircle = ({
  color = '#9FA5AC',
  width = 16,
  height = 16,
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="8" fill={color} />
    <path
      d="M10.6663 5.33398L5.33301 10.6673"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33301 5.33398L10.6663 10.6673"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloseCircle;
