import {StyleProp, View, ViewStyle} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';

const Tag = ({
  label,
  style,
  backgroundColor,
}: {
  label?: string;
  style?: StyleProp<ViewStyle>;
  backgroundColor?: string;
}) => {
  return (
    <View
      style={[
        theme.padding.xxs,
        theme.borderRadius.xs,
        {backgroundColor},
        style,
      ]}>
      <Text color={theme.basicColor.white} fontFamily="fontInterBold">
        {label}
      </Text>
    </View>
  );
};

export default Tag;
