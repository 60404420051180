import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import LazyImageBackground from '@/components/basic/image/lazy-image-background';
import {DIGIT} from '@/common-pages/bets-detail/components/constant';
import React from 'react';
import theme from '@/style';
import {useTranslation} from 'react-i18next';
import Won from '../won/won';

export interface DigitResultProps {
  wonCode?: string;
  codeLists?: {
    indexCode: string;
    pickAmount: number;
    pickCount: number;
    wonCode: string;
    winAmount?: number;
  }[];
}

const DigitResult = ({wonCode = '***', codeLists = []}: DigitResultProps) => {
  const {i18n} = useTranslation();

  const winList = React.useMemo(() => {
    return codeLists.filter(item => item.winAmount && item.winAmount > 0);
  }, [codeLists]);

  return (
    <View style={[]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
          styles.container,
          theme.margin.tops,
        ]}>
        <View style={[theme.flex.flex1]}>
          <Text fontFamily="fontInterBold">
            {i18n.t('bets-detail.label.drawRes')}：
          </Text>
        </View>
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            {gap: theme.paddingSize.xs},
          ]}>
          {['A', 'B', 'C'].map((item, _i) => (
            <LazyImageBackground
              key={_i}
              occupancy="transparent"
              style={[theme.flex.center]}
              width={32}
              height={32}
              imageUrl={DIGIT[item]}>
              <Text size="large" fontFamily="fontInterBold">
                {item}
              </Text>
            </LazyImageBackground>
          ))}
          <Text fontFamily="fontInterBold" size="medium">
            =
          </Text>
          {['A', 'B', 'C'].map((item, _i) => (
            <LazyImageBackground
              key={_i}
              occupancy="transparent"
              style={[theme.flex.center]}
              width={32}
              height={32}
              imageUrl={DIGIT[item]}>
              <Text size="large" fontFamily="fontInterBold">
                {wonCode[_i]}
              </Text>
            </LazyImageBackground>
          ))}
        </View>
      </View>
      {winList.length > 0 && (
        <View style={[theme.margin.tops, {gap: theme.paddingSize.s}]}>
          {winList.map((item, _i) => {
            const indexArr = item.indexCode.split('=');
            const codes = indexArr[1];
            const codeArr = codes.split('');
            const arr = [] as string[];
            const abcArr = indexArr[0]
              .split('')
              .map(p => ['A', 'B', 'C'].findIndex(pie => pie === p));
            abcArr.map((pie, i) => (arr[pie] = codeArr[i]));
            return (
              <Won
                key={_i}
                awardAmount={item.winAmount!}
                result={
                  <View style={[theme.flex.row]}>
                    <Text fontFamily="fontInterBold" size="medium">
                      {indexArr[0]}
                    </Text>
                    <Text fontFamily="fontInterBold" size="medium">
                      =
                    </Text>
                    <View style={[theme.flex.row]}>
                      {arr.map((pie, _ii) => (
                        <Text
                          key={_ii}
                          fontFamily="fontInterBold"
                          size="medium"
                          color={wonCode[_ii] === pie ? '#F15802' : '#48505D'}>
                          {pie}
                        </Text>
                      ))}
                    </View>
                    <View style={styles.tag}>
                      <Text
                        fontFamily="fontInterBold"
                        size="small"
                        color={theme.basicColor.white}>
                        x{item.pickCount}
                      </Text>
                    </View>
                  </View>
                }
                isBet={false}
              />
            );
          })}
        </View>
      )}
      <View
        // eslint-disable-next-line react-native/no-inline-styles
        style={[theme.flex.row, {flexWrap: 'wrap', gap: 8}, theme.margin.tops]}>
        {codeLists.map((item, _i) => (
          <View style={[styles.item]} key={_i}>
            <Text fontFamily="fontInterBold">{item.indexCode}</Text>
            <View style={styles.tag}>
              <Text
                fontFamily="fontInterBold"
                size="small"
                color={theme.basicColor.white}>
                x{item.pickCount}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F4F4F4',
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  item: {
    paddingHorizontal: 16,
    backgroundColor: '#F6F7FB',
    height: 36,
    borderRadius: 18,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tag: {
    backgroundColor: '#F15802',
    borderRadius: 4,
    paddingHorizontal: 4,
    marginLeft: 8,
    paddingVertical: 2,
  },
});

export default DigitResult;
