import theme from '@/style';
import {SVGProps} from '@/types';
import React from 'react';

const LotterySvg = ({color, width, height}: SVGProps) => (
  <svg
    width={width || theme.iconSize.l}
    height={height || theme.iconSize.l}
    viewBox="0 0 24 24"
    fill={color || theme.basicColor.primary}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9998 1.5249C6.21514 1.5249 1.52539 6.21445 1.52539 11.9993C1.52539 17.7842 6.21514 22.4735 11.9998 22.4735C17.7844 22.4735 22.4742 17.7842 22.4742 11.9993C22.4742 6.21445 17.7844 1.5249 11.9998 1.5249ZM9.79827 16.2847C6.15078 16.2847 3.19142 13.3264 3.19142 9.67972C3.19142 6.03269 6.14819 3.07457 9.79827 3.07457C13.4459 3.07457 16.4051 6.03269 16.4051 9.67972C16.4026 13.3264 13.4459 16.2847 9.79827 16.2847ZM10.3242 5.53545C9.57116 5.53545 8.92976 5.73555 8.39995 6.13575C7.86929 6.53504 7.54668 7.06346 7.43183 7.71639C7.33143 8.28309 7.48124 8.73981 7.88126 9.08656L8.15131 9.28995C7.96151 9.37076 7.78179 9.47346 7.61582 9.59598C7.03507 10.0121 6.68453 10.5605 6.56417 11.2411C6.43274 11.9898 6.57935 12.5925 7.00399 13.0491C7.42953 13.5059 8.05496 13.7347 8.88029 13.7354C9.70979 13.7354 10.4174 13.5083 11.003 13.0491C11.5886 12.5923 11.948 11.9897 12.0811 11.2411C12.2006 10.5622 12.0451 10.0145 11.615 9.60306C11.4832 9.47898 11.3362 9.37214 11.1775 9.28511C11.2955 9.2263 11.4097 9.15998 11.5193 9.08656C12.0404 8.73983 12.3511 8.28311 12.4515 7.71639C12.5662 7.06104 12.4292 6.53416 12.0404 6.13575C11.6484 5.7362 11.0763 5.5361 10.3242 5.53545ZM10.4652 11.2365C10.3982 11.6119 10.2429 11.9109 9.99671 12.1333C9.75049 12.3533 9.45407 12.4656 9.10274 12.4656C8.7514 12.4656 8.49315 12.3555 8.32822 12.1333C8.16329 11.9133 8.11315 11.6144 8.1801 11.2365C8.24705 10.8538 8.40478 10.5478 8.64864 10.3205C8.89233 10.0934 9.1911 9.98088 9.54014 9.98088C9.89153 9.98088 10.1474 10.0934 10.3122 10.3205C10.4819 10.5478 10.532 10.8514 10.4652 11.2365ZM10.9146 7.82162C10.862 8.12518 10.7281 8.36443 10.5201 8.54134C10.3098 8.71818 10.054 8.80684 9.75049 8.80684C9.44693 8.80684 9.2223 8.71818 9.07401 8.54375C8.92589 8.36443 8.88029 8.12299 8.93303 7.81921C8.988 7.50834 9.12196 7.26178 9.3322 7.08027C9.5425 6.89853 9.80075 6.80521 10.1043 6.80521C10.4077 6.80521 10.6325 6.89611 10.7783 7.08027C10.9218 7.26443 10.9695 7.51053 10.9146 7.82162Z" />
  </svg>
);

export default LotterySvg;
