import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {Overlay} from '@rneui/themed';
import theme from '@/style';
import React from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';
import LazyImage from '@/components/basic/image';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import Text from '@/components/basic/text';
import {useTranslation} from 'react-i18next';
import {CloseIcon} from '@/common-pages/proxy/svg.variable';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';
import {SportItem} from '@/pages/home/home.type';
import {SafeAny} from '@/types';
import Button from '@/components/basic/button';
import PlayIcon from './play';
import globalStore from '@/services/global.state';

const COLORS = [
  ['#5CB008', '#006E4D'],
  ['#3DB9FF', '#0049B5'],
];

const SportModal = <P extends SafeAny>(props: P) => {
  const {popVisible, setPopVisible, list, getSportURL} = props as SafeAny;
  const {i18n} = useTranslation();
  const {calcActualSize} = useScreenSize();
  const styles = StyleSheet.create({
    ls4: {
      letterSpacing: calcActualSize(-0.4),
    },
    cap: {
      textTransform: 'capitalize',
    },
    gap4: {
      gap: calcActualSize(4),
    },
    gap12: {
      gap: calcActualSize(12),
    },
    height36: {
      height: calcActualSize(36),
    },
    height192: {
      height: calcActualSize(192),
    },
    width275: {
      width: calcActualSize(275),
    },
    width311: {
      width: calcActualSize(311),
    },
    container: {
      backgroundColor: theme.basicColor.white,
      width: calcActualSize(351),
      padding: calcActualSize(20),
      borderRadius: calcActualSize(16),
    },
    containerHeight: {
      height: calcActualSize(450),
    },
    card: {
      width: calcActualSize(149),
      borderRadius: calcActualSize(8),
      paddingLeft: calcActualSize(11),
      paddingBottom: calcActualSize(11),
      paddingRight: calcActualSize(10.5),
    },
    btn: {
      backgroundColor: theme.basicColor.white,
      paddingVertical: calcActualSize(globalStore.isWeb ? 6 : 0),
      paddingHorizontal: calcActualSize(12),
      height: calcActualSize(29),
    },
    btnTextTop: {
      top: globalStore.isAndroid ? calcActualSize(-1.5) : calcActualSize(-0.5),
    },
  });

  return (
    <Overlay
      isVisible={popVisible}
      onBackdropPress={() => setPopVisible(false)}
      overlayStyle={[
        theme.flex.flex,
        theme.flex.col,
        list.length === 1 && theme.flex.center,
        styles.container,
        styles.gap12,
      ]}>
      <View
        style={[
          theme.flex.flex,
          theme.flex.row,
          styles.gap12,
          styles.height36,
        ]}>
        <View
          style={[
            theme.flex.flex,
            theme.flex.col,
            styles.gap4,
            styles.width275,
          ]}>
          <Text
            calc
            fontWeight="700"
            color={theme.basicColor.dark}
            fontSize={14}>
            {i18n.t('sports.sports')}
          </Text>
          <Text calc color={'#5F6975'} fontWeight="500" fontSize={12}>
            {i18n.t('sports.selectTip')}
          </Text>
        </View>
        <NativeTouchableOpacity
          onPress={() => {
            setPopVisible(false);
          }}>
          <CloseIcon width={24} height={24} />
        </NativeTouchableOpacity>
      </View>
      <ScrollView style={list.length > 4 ? [styles.containerHeight] : []}>
        <View
          style={[
            theme.flex.flex,
            theme.flex.row,
            theme.flex.wrap,
            styles.gap12,
            styles.width311,
          ]}>
          {list.map((sport: SportItem, index: number) => {
            return (
              <NativeTouchableOpacity
                key={index}
                onPress={() => getSportURL(sport.moduleType)}>
                <LinearGradient
                  start={{x: 0, y: 0}}
                  end={{x: 0, y: 1}}
                  style={[
                    theme.flex.flex,
                    theme.flex.col,
                    theme.flex.centerByCol,
                    styles.height192,
                    styles.card,
                  ]}
                  colors={
                    sport.styleType
                      ? sport.styleType.split(',')
                      : COLORS[index % 2]
                  }>
                  <LazyImage
                    imageUrl={sport.bgImgUrl}
                    width={calcActualSize(128)}
                    height={calcActualSize(128)}
                    occupancy="transparent"
                  />
                  <View style={[theme.flex.flex, theme.flex.col, styles.gap4]}>
                    <View
                      style={[
                        theme.flex.flex,
                        theme.flex.row,
                        theme.flex.centerByCol,
                        styles.gap4,
                      ]}>
                      <LazyImage
                        imageUrl={sport.defaultImgUrl}
                        width={calcActualSize(16)}
                        height={calcActualSize(16)}
                        resizeMode="center"
                        occupancy="transparent"
                      />
                      <Text
                        style={[styles.ls4]}
                        numberOfLines={1}
                        calc
                        fontFamily="fontDin"
                        fontSize={16}
                        fontWeight="900"
                        color={theme.basicColor.white}>
                        {sport.moduleType.split('_')[0].toUpperCase() +
                          ' ' +
                          i18n.t('sports.sports')}
                      </Text>
                    </View>
                    <View
                      style={[
                        theme.flex.flex,
                        theme.flex.row,
                        theme.flex.centerByRow,
                      ]}>
                      <Button
                        buttonStyle={[
                          theme.flex.flex,
                          theme.flex.row,
                          styles.gap4,
                          styles.btn,
                        ]}
                        width={calcActualSize(111)}
                        onPress={() => getSportURL(sport.moduleType)}>
                        <Text
                          primary
                          calc
                          fontWeight="700"
                          fontSize={14}
                          numberOfLines={1}
                          blod
                          style={[
                            theme.position.rel,
                            styles.ls4,
                            styles.btnTextTop,
                          ]}>
                          {i18n.t('sports.play')}
                        </Text>
                        <PlayIcon
                          width={calcActualSize(14)}
                          height={calcActualSize(14)}
                        />
                      </Button>
                    </View>
                  </View>
                </LinearGradient>
              </NativeTouchableOpacity>
            );
          })}
        </View>
      </ScrollView>
    </Overlay>
  );
};

export default SportModal;
