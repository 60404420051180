import React from 'react';
import {View, Image, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import {useTranslation} from 'react-i18next';
import SattaPanel from './components/satta-panel';
import LazyImage from '@/components/basic/image';
import {calcResultNum} from './satta-service';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';

export interface SattaHeaderProps {
  name: string;
  issueNum: string | number;
  open?: string;
  close?: string;
  backImage?: string;
  openResultNum?: string;
  closeResultNum?: string;
  showRule?: () => void;
}

const SattaHeader = ({
  name = '',
  issueNum = '',
  close = '',
  open = '',
  backImage,
  openResultNum,
  closeResultNum,
  showRule,
}: SattaHeaderProps) => {
  const {i18n} = useTranslation();

  const panelResult = React.useMemo(() => {
    const openNum = calcResultNum(openResultNum);
    const closeNum = calcResultNum(closeResultNum);
    return `${
      openResultNum && openResultNum.length ? openResultNum : '***'
    }-${openNum}${closeNum}-${
      closeResultNum && closeResultNum.length ? closeResultNum : '***'
    }`;
  }, [openResultNum, closeResultNum]);

  return (
    <View
      style={[theme.padding.lrxxl, theme.padding.topxxl, theme.padding.btmxs]}>
      {backImage && (
        <View style={[theme.position.abs, styles.container]}>
          <LazyImage
            resizeMode="cover"
            imageUrl={backImage}
            width={'100%'}
            height={'100%'}
            occupancy="transparent"
          />
        </View>
      )}
      <View style={[theme.flex.row, theme.flex.between]}>
        <Text
          size="large"
          fontFamily="fontDinBold"
          color={theme.basicColor.white}>
          {name.toUpperCase()}
        </Text>
        <Text style={[{color: theme.basicColor.white}]}>NO.{issueNum}</Text>
      </View>

      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.margin.tbl,
          theme.flex.between,
        ]}>
        <SattaPanel pannel={panelResult} />
        <NativeTouchableOpacity onPress={showRule} style={[theme.flex.row]}>
          <Image
            style={[theme.icon.l, theme.margin.rights]}
            source={require('@components/assets/imgs/game/satta/rule.webp')}
          />
          <View>
            <Text
              size="medium"
              fontFamily="fontInterBold"
              style={[{color: theme.basicColor.white}]}>
              Rule
            </Text>
          </View>
        </NativeTouchableOpacity>
      </View>

      <View
        style={[
          theme.flex.row,
          theme.flex.between,
          theme.padding.tbl,
          styles.bottom,
        ]}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Image
            source={require('@assets/icons/home/matka-open.webp')}
            style={[styles.icon]}
          />
          <Text fontSize={12} secAccent>
            {i18n.t('home.matka.open')}
          </Text>
          <Text main style={[theme.margin.leftxxs]}>
            {open}
          </Text>
        </View>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Image
            source={require('@assets/icons/home/matka-close.webp')}
            style={[styles.icon]}
          />
          <Text fontSize={12} secAccent>
            {i18n.t('home.matka.close')}
          </Text>
          <Text main style={[theme.margin.leftxxs]}>
            {close}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  icon: {
    width: 14,
    height: 14,
  },
  bottom: {
    borderTopWidth: 1,
    borderTopColor: '#FFFFFF80',
  },
});

export default SattaHeader;
