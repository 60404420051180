import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import theme from '@/style';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import RightMenusService from './right-menus-service';
import RightMenusTop from './right-menus-top';

export interface RightMenusProps {
  hasTop?: boolean;
  onTop?: () => void;
}

const RightMenus: React.FC<RightMenusProps> = ({hasTop, onTop}) => {
  const {calcActualSize} = useScreenSize();
  const rightMenusStyle = StyleSheet.create({
    main: {
      right: calcActualSize(22),
      bottom: calcActualSize(24),
    },
  });
  return (
    <View style={[theme.position.abs, rightMenusStyle.main, theme.flex.col]}>
      <RightMenusService />
      {hasTop ? (
        <View style={[theme.margin.topl]}>
          <RightMenusTop onClick={onTop} />
        </View>
      ) : null}
    </View>
  );
};

export default RightMenus;
