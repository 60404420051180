import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {ColorGetAllResponse} from './color-service';
import {View} from 'react-native';

const ODDS = [
  '🔴 Red: 2x (Bet amount x 2)',
  '🟢 Green: 2x (Bet amount x 2)',
  '🟣 Violet: 4.5x (Bet amount x 4.5)',
];

interface RuleProps {
  tabsInfo: ColorGetAllResponse[];
}

const ColorRule = ({tabsInfo = []}: RuleProps) => {
  return (
    <>
      <Text
        style={[theme.margin.btml]}
        fontFamily="fontInterBold"
        size="medium">
        🌈 Color Prediction Game Rules 🌈
      </Text>
      <View>
        {tabsInfo.map((item, i) => (
          <Text key={i}>
            {tabsInfo.length > 1 ? 'Game type:' : ''} {item.cycle} minute
            {item.cycle !== 1 ? 's' : ''} Bet close: {item.minRemainingSecond}{' '}
            second{item.minRemainingSecond !== 1 ? 's' : ''} before
          </Text>
        ))}
      </View>
      <Text style={[theme.margin.tbl]} fontFamily="fontInterBold" size="medium">
        🔮 Winning Odds
      </Text>
      {ODDS.map((item, _i) => (
        <Text key={_i}>{item}</Text>
      ))}
      <Text>
        *If the result is 🟣 Violet, and it's the same as 🔴 Red or 🟢 Green,
        such as 0 or 5, players who bet on 🔴 Red/🟢 Green will receive 1.5x
        odds.
      </Text>
      <Text style={[theme.margin.tbl]} fontFamily="fontInterBold" size="medium">
        🔮 Guess Number
      </Text>
      <Text> Winning Odds - Any number: 9x (Bet amount x 9)</Text>
      <Text style={[theme.margin.tbl]} fontFamily="fontInterBold" size="medium">
        🔮 Charges:
      </Text>
      <Text>
        The platform will charge a 2% service fee for each bet. If you choose to
        bet 100 rupees, after deducting the 2% service fee, your final betting
        amount is 98 rupees.
      </Text>
      <Text>
        Example: If you bet 100 rupees and your odds are 2x, you will receive 98
        x 2 = 196 rupees.
      </Text>
    </>
  );
};

export default ColorRule;
