import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {View, Image} from 'react-native';
import ResultPanel from '../components/result-panel/result-panel';
import {useTranslation} from 'react-i18next';
import {getMatkaLastOrder} from '../result-service';
import {MatkaOrder} from '../result.type';
import globalStore from '@/services/global.state';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {downIcon} from '@/common-pages/rebate/rebate.variables';
import dayjs from 'dayjs';
import {goTo, toPriceStr} from '@/utils';

const MatkaDraw = () => {
  const {t} = useTranslation();
  const [order, setOrder] = React.useState<MatkaOrder>();

  const getOrder = async () => {
    try {
      const res = await getMatkaLastOrder();
      setOrder(res);
    } finally {
    }
  };

  React.useEffect(() => {
    if (globalStore.token) {
      getOrder();
    }
  }, []);

  const matkaType = [
    '',
    `${t('satta.betting.ank')} ${t('satta.betting.open')}`,
    `${t('satta.betting.ank')} ${t('satta.betting.close')}`,
    t('satta.betting.jodi'),
    `${t('satta.betting.sp')} ${t('satta.betting.open')}`,
    `${t('satta.betting.sp')} ${t('satta.betting.close')}`,
    `${t('satta.betting.dp')} ${t('satta.betting.open')}`,
    `${t('satta.betting.dp')} ${t('satta.betting.close')}`,
    `${t('satta.betting.tp')} ${t('satta.betting.open')}`,
    `${t('satta.betting.tp')} ${t('satta.betting.close')}`,
    `${t('satta.betting.half')} ${t('satta.betting.open')}`,
    `${t('satta.betting.half')} ${t('satta.betting.close')}`,
    t('satta.betting.full'),
  ];

  return order?.orderNo ? (
    <ResultPanel
      betInfo={order?.lotteryName}
      onMore={() => goTo('Bets', {tag: 'Satta Matka'})}
      betTime={dayjs(order?.orderTime).format('DD-MM-YYYY hh:mm A')}>
      <View style={[theme.padding.lrl, theme.padding.tops]}>
        <Text textAlign="center">{t('game-page.label.myBets')}</Text>
        <View style={[{gap: theme.paddingSize.xxs / 2}, theme.margin.topxxs]}>
          <View
            style={[
              theme.padding.s,
              theme.flex.center,
              theme.borderRadius.m,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                backgroundColor: '#EFF1F7',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              },
            ]}>
            <Text fontFamily="fontInterBold" size="medium">
              {order?.lotteryName}
            </Text>
          </View>
          {order?.userDigits?.slice(0, 3).map((item, _i) => (
            <View
              style={[
                theme.flex.row,
                theme.flex.centerByCol,
                theme.flex.between,
                theme.borderRadius.m,
                theme.padding.s,
                // eslint-disable-next-line react-native/no-inline-styles
                {backgroundColor: '#EFF1F7'},
              ]}>
              <View style={[theme.flex.row, theme.flex.centerByCol]}>
                <Text
                  size="medium"
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={[{fontWeight: '500', marginRight: 8}]}>
                  {matkaType[item.gameType]}
                </Text>
                <Text main fontFamily="fontInterBold" size="medium">
                  {item.digits}
                </Text>
              </View>
              <View style={[theme.flex.row, theme.flex.centerByCol]}>
                <Text>Won </Text>
                <Text size="medium" fontFamily="fontInterBold">
                  {toPriceStr(0)}
                </Text>
              </View>
            </View>
          ))}
        </View>
        {order?.userDigits && order.userDigits?.length > 3 && (
          <NativeTouchableOpacity
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.flex.between,
              theme.padding.tbs,
            ]}>
            <Text>View all</Text>
            <View
              style={[
                theme.margin.leftxxs,
                {
                  transform: [
                    {
                      rotate: '-90deg',
                    },
                  ],
                },
              ]}>
              <Image source={downIcon} style={[theme.icon.xs]} />
            </View>
          </NativeTouchableOpacity>
        )}
      </View>
    </ResultPanel>
  ) : null;
};

export default MatkaDraw;
