import {ScrollView, View} from 'react-native';
import BetsHeader from '@components/business/games/bets/bets-header';
import React from 'react';
import Dustbin from '@/components/svgs/basic/dustbin';
import BetsItem from './components/bets-item';
import theme from '@/style';
import NoData from '@/components/basic/error-pages/no-data';
import {betsListItem} from './digit-service';
import {useTranslation} from 'react-i18next';

export interface BetsDigitProps {
  digitList?: betsListItem[];
  onClose?: () => void;
  onDel?: () => void;
  onDigitClose?: (id: number) => void;
}

const BetsDigit = ({
  digitList = [],
  onClose,
  onDigitClose = () => {},
}: BetsDigitProps) => {
  const {i18n} = useTranslation();
  return (
    <View>
      <BetsHeader
        title={i18n.t('game-page.label.myNum')}
        closeIcon={<Dustbin />}
        onClose={onClose}
      />
      <View
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {height: 400},
          theme.background.white,
        ]}>
        {digitList.length === 0 ? (
          <NoData label={i18n.t('game-page.label.allEmpty')} />
        ) : (
          <ScrollView
            contentContainerStyle={[
              theme.flex.row,
              theme.padding.lrl,
              theme.padding.tops,
              // eslint-disable-next-line react-native/no-inline-styles
              {flexWrap: 'wrap', gap: 12},
              theme.background.white,
            ]}>
            {digitList.map((item, index) => (
              <BetsItem
                onClose={() => onDigitClose(index)}
                type={item.type}
                digit={item.value}
                rate={item.count}
                key={index}
              />
            ))}
          </ScrollView>
        )}
      </View>
    </View>
  );
};

export default React.memo(BetsDigit);
