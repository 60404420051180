import theme from '@/style';
import React from 'react';
import {View} from 'react-native';
const iconSize = 10;
export default () => (
  <View style={[{width: iconSize, height: iconSize}]}>
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.45895 1.97855C4.65421 1.78329 4.97079 1.78329 5.16605 1.97855L8.02145 4.83395C8.21671 5.02921 8.21671 5.34579 8.02145 5.54105L3.70895 9.85355C3.61518 9.94732 3.488 10 3.3554 10H0.5C0.223858 10 0 9.77614 0 9.5V6.6446C0 6.512 0.0526784 6.38482 0.146447 6.29105L4.45895 1.97855ZM5.16605 3.79105C4.97079 3.59578 4.65421 3.59578 4.45895 3.79105L1.39644 6.85355C1.30267 6.94732 1.25 7.0745 1.25 7.2071V8.25C1.25 8.52615 1.47385 8.75 1.75 8.75H2.7929C2.9255 8.75 3.05268 8.69732 3.14645 8.60356L6.20895 5.54105C6.40422 5.34579 6.40421 5.02921 6.20895 4.83395L5.16605 3.79105ZM6.14359 0.336608C6.34156 0.149641 6.65274 0.155463 6.84357 0.349705L9.65268 3.20898C9.84499 3.40473 9.8436 3.7189 9.64956 3.91294L9.29106 4.27145C9.09579 4.46671 8.77921 4.46671 8.58395 4.27145L5.7388 1.4263C5.53958 1.22708 5.54422 0.902682 5.74904 0.709235L6.14359 0.336608Z"
        fill={theme.basicColor.primary}
      />
    </svg>
  </View>
);
