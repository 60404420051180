import LazyImage from '@/components/basic/image';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import {whiteRightIcon} from '@/pages/me/me.variable';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {toPriceStr} from '@/utils';
import React from 'react';
import {View, StyleSheet, Image} from 'react-native';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '../hooks/size.hooks';

const TransferBalance = ({
  balance = 0,
  onGotoRecords,
}: {
  balance: number;
  onGotoRecords?: () => void;
}) => {
  const {i18n} = useTranslation();

  const {screenWidth, screenHeight, designWidth} = useScreenSize();
  const rightIconSize = (14 * screenWidth) / designWidth;
  const itemWidth = (72 * screenWidth) / designWidth;
  const itemHeight = (40 * screenWidth) / designWidth;
  const size = React.useMemo(() => {
    return {
      screenWidth,
      screenHeight,
      designWidth,
      rightIconSize,
      itemWidth,
      itemHeight,
    };
  }, [
    screenWidth,
    screenHeight,
    designWidth,
    rightIconSize,
    itemWidth,
    itemHeight,
  ]);

  return (
    <View style={[theme.margin.topl, styles.container]}>
      <View style={styles.cardBg}>
        <Image
          resizeMode="cover"
          source={require('@assets/imgs/recharge-header-bg.webp')}
          style={[styles.bgBox]}
        />
      </View>
      <View style={[theme.flex.row]}>
        <View style={[theme.flex.flex1]}>
          <Text color={theme.basicColor.white} style={[styles.opacity]}>
            {i18n.t('transfer-page.label.balance')}
          </Text>
          <View style={[theme.flex.row, theme.flex.alignEnd]}>
            <Text
              fontFamily="fontInter"
              blod
              fontSize={24}
              style={[theme.font.white]}>
              {toPriceStr(balance, {
                thousands: true,
                spacing: true,
                currency: globalStore.currency,
              })}
            </Text>
          </View>
        </View>
        <NativeTouchableOpacity
          activeOpacity={0.8}
          style={[theme.flex.row, theme.flex.centerByCol]}
          onPress={onGotoRecords}>
          <View style={[theme.flex.alignEnd, theme.margin.rights]}>
            <Text color={theme.basicColor.white}>
              {i18n.t('label.transfer')}
            </Text>
            <Text color={theme.basicColor.white}>
              {i18n.t('recharge-page.label.records')}
            </Text>
          </View>
          <LazyImage
            occupancy={'transparent'}
            imageUrl={whiteRightIcon}
            width={size.rightIconSize}
            height={size.rightIconSize}
          />
        </NativeTouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardBg: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  container: {
    position: 'relative',
    borderRadius: 8,
    paddingHorizontal: 20,
    paddingVertical: 22,
    overflow: 'hidden',
  },
  bottomContainer: {
    borderRadius: 8,
    padding: 8,
    overflow: 'hidden',
  },
  opacity: {
    opacity: 0.7,
  },
  bgBox: {
    width: '100%',
    height: '100%',
  },
  balanceContainer: {
    paddingHorizontal: 8,
    // paddingTop: 20,
    alignItems: 'center',
  },
  methodContainer: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    // paddingHorizontal: 20,
  },
});

export default TransferBalance;
