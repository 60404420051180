import {BasicObject} from '@/types';

const DICES = {
  1: require('@components/assets/imgs/game/dice/1-red.webp'),
  '1-disabled': require('@components/assets/imgs/game/dice/1-red-disabled.webp'),
  2: require('@components/assets/imgs/game/dice/2-black.webp'),
  '2-disabled': require('@components/assets/imgs/game/dice/2-black-disabled.webp'),
  3: require('@components/assets/imgs/game/dice/3-black.webp'),
  '3-disabled': require('@components/assets/imgs/game/dice/3-black-disabled.webp'),
  4: require('@components/assets/imgs/game/dice/4-red.webp'),
  '4-disabled': require('@components/assets/imgs/game/dice/4-red-disabled.webp'),
  5: require('@components/assets/imgs/game/dice/5-black.webp'),
  '5-disabled': require('@components/assets/imgs/game/dice/5-black-disabled.webp'),
  6: require('@components/assets/imgs/game/dice/6-black.webp'),
  '6-disabled': require('@components/assets/imgs/game/dice/6-black-disabled.webp'),
  ball: require('@components/assets/imgs/game/dice/ball.webp'),
  redBall: require('@components/assets/imgs/game/dice/red.webp'),
  'redBall-disabled': require('@components/assets/imgs/game/dice/red-disabled.webp'),
  greenBall: require('@components/assets/imgs/game/dice/green.webp'),
  'greenBall-disabled': require('@components/assets/imgs/game/dice/green-disabled.webp'),
  small: require('@components/assets/imgs/game/dice/small.webp'),
  'small-disabled': require('@components/assets/imgs/game/dice/small-disabled.webp'),
  big: require('@components/assets/imgs/game/dice/big.webp'),
  'big-disabled': require('@components/assets/imgs/game/dice/big-disabled.webp'),
  even: require('@components/assets/imgs/game/dice/even.webp'),
  'even-disabled': require('@components/assets/imgs/game/dice/even-disabled.webp'),
  odd: require('@components/assets/imgs/game/dice/odd.webp'),
  'odd-disabled': require('@components/assets/imgs/game/dice/odd-disabled.webp'),
  any: require('@components/assets/imgs/game/dice/dice-question.webp'),
} as BasicObject;

const COLOR_MAP = {
  1: '#E20000',
  0: '#02921B',
  2: '#0090E2',
} as BasicObject;

const RESULT_MAP = {
  1: 'B',
  0: 'T',
  2: 'S',
} as BasicObject;

export const SUF_NUM = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
];

const SUF_TYPE = ['big', 'small', 'odd', 'even'];

const SUM_POINTS = SUF_NUM.slice(3).map(item => `sum_${item}`);

const SINGLE = SUF_NUM.slice(1, 7);

export {DICES, SUF_TYPE, SUM_POINTS, SINGLE, COLOR_MAP, RESULT_MAP};

export const sumMap = {
  sum_big: 'big',
  sum_small: 'small',
  sum_odd: 'odd',
  sum_even: 'even',
} as BasicObject;

export const sumSuffixMap: Record<string, number> = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
  ten: 10,
  eleven: 11,
  twelve: 12,
  thirteen: 13,
  fourteen: 14,
  fifteen: 15,
  sixteen: 16,
  seventeen: 17,
  eighteen: 18,
};
