import {
  View,
  Dimensions,
  StyleSheet,
  LayoutChangeEvent,
  // BackHandler,
  RefreshControl,
  Animated,
} from 'react-native';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {LazyImageBackground} from '@basicComponents/image';
import theme from '@style';
import {
  designToDp,
  goCS,
  goTo,
  navigate,
  toAgentApply,
  toPriceStr,
  useResponsiveDimensions,
  inApp,
  getVersionName,
} from '@utils';
import Button from '@basicComponents/button';
import Text from '@basicComponents/text';
import {
  createVipBadge,
  getVipRender,
  maxVipLevel,
  useVipProgressList,
} from '@businessComponents/vip';
import {
  MeListItem,
  // commissionIcon,
  customerServiceIcon,
  languagesIcon,
  moneyIcon,
  notificationsIcon,
  passwordIcon,
  resultHistoryIcon,
  agencyIcon,
  transactionsIcon,
  updateIcon,
  commissionIcon,
} from '@businessComponents/list-item';
import Tag from '@basicComponents/tag';
import {postVipInfo} from './me.service';
import {
  IUserInfo,
  MessageCountInfo,
  postUserInfo,
  postVipConfig,
} from '@services/global.service';
import {useConfirm, ToastType} from '@basicComponents/modal';
import {useLanguageModal} from '@businessComponents/language';
import {useBottomTabBarHeight} from '@react-navigation/bottom-tabs';
import {useFocusEffect} from '@react-navigation/native';
import globalStore from '@/services/global.state';
import {toLogin, topBackground} from './me.variable';
import MeHeader from './me-header';
import MeAmount from './me-amount';
import MeUser from './me-user';
import {FadeInView} from '@basicComponents/animations';
import {NoMoreData} from '@/components/basic/default-page';
import Spin from '@/components/basic/spin';
import {useTranslation} from 'react-i18next';
import MeRowMenu from './me-row-menu';
import {checkUpdate} from '@/utils/update';

const {
  flex,
  overflow,
  padding,
  paddingSize,
  font,
  margin,
  borderRadius,
  background,
  fontSize,
} = theme;

const topClipHeight = 44;

interface VipInfo {
  level: number;
  diff: number; // 差多少值结束
  nextValue: number; // 该等级结束值
}

/** TODO 单个文件过大,需要拆解 */
const Me = () => {
  const {i18n} = useTranslation();
  const {width: systemWidth} = useResponsiveDimensions();
  const [login, setLogin] = useState<boolean>(false);
  const [vip, setVip] = useState<VipInfo>({
    level: 0,
    diff: 200,
    nextValue: 200,
  });
  const scrollAnim = useRef(new Animated.Value(0)).current;
  const currentVipOption = useMemo(() => getVipRender(vip.level), [vip.level]);
  const nextVipBadge = createVipBadge(vip.level + 1);
  const topBlockWidth = systemWidth - 4 * paddingSize.m;
  const [renderProgress] = useVipProgressList([
    {
      currentBadge: currentVipOption.badge,
      nextBadge: nextVipBadge,
      current: vip.nextValue - vip.diff,
      total: vip.nextValue,
      hasCurrentText: true,
    },
  ]);
  const [user, setUser] = useState<IUserInfo>();
  const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm();
  const {renderModal: renderLanguageModal, show: languageShow} =
    useLanguageModal();
  const [showNoMenu] = useState<boolean>(false);
  const bottomTabbarHeight = useBottomTabBarHeight();

  const [userAreaY, setUserAreaY] = useState<number>(0);
  const firstFocus = useRef(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [messageCountInfo, setMessageCountInfo] = useState<MessageCountInfo>({
    messageTotalCount: 0,
    sysMessageCount: 0,
    sysUserMessageCount: 0,
  });
  const notYetWarning = {
    type: ToastType.warning,
    message: i18n.t('warning.unopen'),
  };
  const refresh = useCallback(
    async (token: string | null, showloading = true) => {
      showloading && setPageLoading(true);
      try {
        if (!token) {
          setLogin(false);
          postVipConfig().then(conf => {
            const currentConf = conf.find(c => c.level === 1);
            setVip({
              level: 0,
              diff: currentConf?.recharge || 200,
              nextValue: currentConf?.recharge || 200,
            });
          });
        } else {
          setLogin(true);
          const results = await Promise.allSettled([
            postUserInfo(),
            postVipInfo(),
          ]);
          const [userInfo, vipInfo] = results;
          if (userInfo.status === 'fulfilled') {
            setUser(userInfo.value);
            globalStore.userInfo = userInfo.value;
          }
          if (vipInfo.status === 'fulfilled') {
            const {value: list} = vipInfo;
            for (let i = 0; i < list.length; i++) {
              if (list[i].diff > 0) {
                const current = list[i];
                setVip({
                  level: current.level,
                  diff: current.diff,
                  nextValue: current.amount,
                });
                break;
              }
            }
          }
        }
      } finally {
        setPageLoading(false);
        setRefreshing(false);
      }
    },
    [],
  );
  const onFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setLogin(!!token);
      refresh(token, firstFocus.current);
      firstFocus.current = false;
      if (token) {
        globalStore.amountCheckOut.next({});
        globalStore.refreshNotification.next();
      } else {
        setMessageCountInfo({
          messageTotalCount: 0,
          sysMessageCount: 0,
          sysUserMessageCount: 0,
        });
      }
    });
    const msgSub = globalStore.notificationSubject.subscribe(_countInfo => {
      setMessageCountInfo(
        _countInfo || {
          messageTotalCount: 0,
          sysMessageCount: 0,
          sysUserMessageCount: 0,
        },
      );
    });
    return () => {
      sub.unsubscribe();
      msgSub.unsubscribe();
    };
  }, [refresh]);
  useFocusEffect(onFocusEffect);
  const handleMoneyLayout = (e: LayoutChangeEvent) => {
    setUserAreaY(e.nativeEvent.layout.y);
  };

  const handleRefresh = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    refresh(globalStore.token);
  };

  const toVip = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Vip');
  };

  const toRecharge = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转recharge
    goTo('Recharge');
  };

  const toWithdraw = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转withdraw
    goTo('Withdraw');
    // postMessage('router:/pages/withdrawal/withDraw');
  };

  const toTransfer = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Transfer');
    // 跳转transfer
  };

  const toResults = () => {
    goTo('Result');
    // postMessage('tabbar:/pages/results/results');
  };

  const toCommission = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转commission
    goTo('Invitation');
  };

  const toTransactions = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Transactions');
    // goTo('WebView', {
    //   path: '/transactions',
    //   header: true,
    // });
  };

  const toMyBets = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Bets');
    // goTo('Scratch', {
    //   path: 'my-scratch-list/play/11111',
    // });
    // 跳转mybets
    // goTo('WebView', {
    //   path: '/pages/order/myOrder?title=1',
    //   header: true,
    //   isReactH5: '0',
    // });
  };

  const toNotify = () => {
    if (!login) {
      goTo('Login');
    } else {
      goTo('Notification');
    }
  };

  const toLanguage = () => {
    languageShow();
  };

  const doLogout = () => {
    // logout
    confirmShow(i18n.t('alert.logout'), i18n.t('alert.sureLogout'), () => {
      globalStore.token = null;
      globalStore.userInfo = null;
      toLogin();
    });
  };

  const toSetPassword = () => {
    if (!login) {
      toLogin();
      return;
    }
    navigate('SetPassword');
  };

  const toAgency = () => {
    if (!login) {
      toLogin();
      return;
    }
    if (user?.isAgent === 1) {
      goTo('ProxyHome');
    } else {
      toAgentApply();
    }
  };

  const handleUser = () => {
    if (!login) {
      toLogin();
      return;
    }
    if (showNoMenu) {
      globalStore.globalTotal.next(notYetWarning);
    }
  };
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const ME_TOP_LIST = [
    {
      icon: resultHistoryIcon,
      title: i18n.t('me.bottom.resultHistory'),
      onPress: toResults,
    },
    {
      icon: agencyIcon,
      title: i18n.t('me.bottom.proxy'),
      rightContent: (
        <View style={[theme.flex.center, theme.padding.lrxs, styles.new]}>
          <Text fontSize={8} style={[theme.font.white]} calc>
            {i18n.t('me.new')}
          </Text>
        </View>
      ),
      onPress: toAgency,
    },
    {
      icon: moneyIcon,
      title: i18n.t('me.bottom.myBets'),
      onPress: toMyBets,
    },
    {
      icon: transactionsIcon,
      title: i18n.t('me.bottom.myTransactions'),
      onPress: toTransactions,
    },
    {
      icon: commissionIcon,
      title: i18n.t('me.bottom.commission'),
      onPress: toCommission,
    },
  ];

  const ME_BOTTOM_LIST = [
    {
      icon: notificationsIcon,
      title: i18n.t('me.bottom.notify'),
      rightContent:
        messageCountInfo && messageCountInfo.messageTotalCount ? (
          <Tag content={messageCountInfo.messageTotalCount} />
        ) : null,
      onPress: toNotify,
    },
    {
      icon: passwordIcon,
      title: i18n.t('me.bottom.password'),
      onPress: toSetPassword,
    },
    {
      icon: languagesIcon,
      title: i18n.t('me.bottom.lang'),
      onPress: toLanguage,
    },
    {
      icon: customerServiceIcon,
      title: i18n.t('me.bottom.customer'),
      onPress: goCS,
    },
    {
      hide: !inApp,
      icon: updateIcon,
      title: i18n.t('me.bottom.update'),
      rightContent: (
        <Text style={[font.secAccent, font.fs]} calc>
          {getVersionName()}
        </Text>
      ),
      onPress: () => {
        checkUpdate(true);
      },
    },
  ];

  return (
    <FadeInView>
      {/* TODO 这里的滚动方案需要优化,以及文件过大需要拆分 */}
      <Spin loading={pageLoading}>
        <Animated.ScrollView
          style={{height: Dimensions.get('window').height - bottomTabbarHeight}}
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {y: scrollAnim},
                },
              },
            ],
            {useNativeDriver: true},
          )}
          scrollEventThrottle={1}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                setRefreshing(true);
                refresh(globalStore.token, false);
              }}
            />
          }>
          <View
            style={[
              flex.col,
              flex.end,
              {height: (systemWidth / 375) * (372 - topClipHeight)},
            ]}>
            <LazyImageBackground
              occupancy={'transparent'}
              imageUrl={topBackground}
              width={systemWidth}
              height={(systemWidth / 375) * 372}
              style={[
                padding.lrm,
                styles.topBg,
                overflow.hidden,
                padding.btmm,
              ]}>
              <View style={[padding.m]}>
                {/* 用户块 */}
                <MeUser
                  login={login}
                  user={user}
                  currentVipOption={currentVipOption}
                  onUser={handleUser}
                  showNoMenu={showNoMenu}
                />
                {/* 钱包区域 */}
                <MeAmount
                  login={login}
                  onLayout={handleMoneyLayout}
                  topBlockWidth={topBlockWidth}
                  user={user}
                  onRecharge={toRecharge}
                  onWithdraw={toWithdraw}
                  onRefresh={handleRefresh}
                />
                {/* VIP区域，直接使用当前的TouchableOpacity组件会导致安卓的vip图标被遮挡，故直接使用原生的 */}
                <NativeTouchableOpacity onPress={toVip}>
                  {currentVipOption.backgroundFn(
                    <View style={[margin.lrm]}>
                      <View
                        style={[
                          flex.row,
                          flex.alignEnd,
                          {marginTop: -paddingSize.l},
                          margin.leftm,
                        ]}>
                        <View style={[margin.rightxs]}>
                          {currentVipOption.sign('small')}
                        </View>
                        {/* 小进度条 */}
                        <View
                          style={[
                            margin.btmxxs,
                            theme.flex.flex1,
                            theme.flex.row,
                            theme.flex.alignEnd,
                          ]}>
                          {renderProgress}
                        </View>

                        <View />
                      </View>
                      {vip.level >= maxVipLevel ? (
                        <View />
                      ) : (
                        <View
                          style={[
                            flex.row,
                            flex.between,
                            flex.centerByCol,
                            padding.lrm,
                            padding.tbxxs,
                          ]}>
                          <View style={[flex.row]}>
                            <Text
                              numberOfLines={2}
                              calc
                              style={[font.main, styles.opacity]}
                              fontSize={fontSize.xs}>
                              {i18n.t('me.vip.recharge')}{' '}
                              <Text blod calc>
                                {toPriceStr(vip.nextValue, {fixed: 0})}
                              </Text>{' '}
                              {i18n.t('me.vip.move')} VIP{vip.level + 1}
                            </Text>
                          </View>
                          <Button
                            type="primary"
                            size="xsmall"
                            titleBold
                            title={'Deposit'}
                            onPress={toVip}
                          />
                        </View>
                      )}
                    </View>,
                    topBlockWidth,
                  )}
                </NativeTouchableOpacity>
              </View>
            </LazyImageBackground>
          </View>
          {/* 下半区域 */}
          <MeRowMenu
            onRecharge={toRecharge}
            onTransfer={toTransfer}
            onWithdraw={toWithdraw}
          />
          <View style={[padding.lrl, padding.btmxxl, background.lightGrey]}>
            {/* 列表区域 */}
            <View
              style={[
                background.white,
                padding.lrm,
                padding.tbl,
                borderRadius.m,
                overflow.hidden,
                margin.btml,
              ]}>
              {ME_TOP_LIST.map((item, index) => (
                <MeListItem
                  key={`${index}`}
                  icon={item.icon}
                  title={item.title}
                  rightContent={item.rightContent || null}
                  onPress={item.onPress}
                />
              ))}
            </View>
            <View
              style={[
                background.white,
                padding.lrm,
                padding.tbl,
                borderRadius.m,
                overflow.hidden,
                margin.btml,
              ]}>
              {ME_BOTTOM_LIST.map((item, index) =>
                item.hide ? null : (
                  <MeListItem
                    key={`${index}`}
                    icon={item.icon}
                    title={item.title}
                    rightContent={item.rightContent || null}
                    onPress={item.onPress}
                  />
                ),
              )}
            </View>
            {login && (
              <NativeTouchableOpacity onPress={doLogout}>
                <View
                  style={[
                    background.white,
                    padding.lrm,
                    padding.tbl,
                    borderRadius.m,
                    overflow.hidden,
                    margin.btmxxl,
                  ]}>
                  <Text
                    style={[font.second, font.fm, font.bold, font.center]}
                    calc>
                    {i18n.t('me.bottom.logout')}
                  </Text>
                </View>
              </NativeTouchableOpacity>
            )}
            <NoMoreData text="" />
          </View>
        </Animated.ScrollView>
        <MeHeader
          user={user}
          userAreaY={userAreaY}
          login={login}
          scrollAnim={scrollAnim}
          onUser={handleUser}
          showNoMenu={showNoMenu}
        />
      </Spin>
      {renderConfirmModal}
      {renderLanguageModal}
    </FadeInView>
  );
};

const styles = StyleSheet.create({
  topBg: {
    /** 此处由于裁剪高度是根据宽度按比例换算的，所以这边也需要按照比例换算paddingTop */
    paddingTop: designToDp(topClipHeight) + paddingSize.m,
  },
  opacity: {
    opacity: 0.5,
  },
  lightOpacity: {
    opacity: 0.8,
  },
  bottomNav: {
    height: designToDp(80),
  },
  bottomNavItem: {
    width: designToDp(104),
  },
  new: {
    backgroundColor: '#FF4D4D',
    borderRadius: 20,
    paddingVertical: 2,
  },
});

export default Me;
