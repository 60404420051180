import theme from '@/style';
import {SVGProps} from '@/types';
import React from 'react';

const LiveSvg = ({color, width, height}: SVGProps) => (
  <svg
    width={width || theme.iconSize.l}
    height={height || theme.iconSize.l}
    viewBox="0 0 24 24"
    fill={color || theme.basicColor.primary}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M17.0575 5.76758C19.4621 5.76758 21.3936 7.64782 21.3936 9.98813V10.7882C21.9849 11.8284 22.2806 12.6685 22.2806 13.2886C22.2806 15.5488 20.4081 17.3691 18.1021 17.3691H16.0128C13.7068 17.3691 11.8344 15.5488 11.8344 13.2886C11.8344 12.6485 12.13 11.8284 12.7213 10.7882V9.98813C12.7213 7.64782 14.6529 5.76758 17.0575 5.76758Z" />
    <path d="M14.199 15.9688H19.7177C21.2354 15.9688 22.4771 17.2289 22.4771 18.7691V19.8693H11.4396V18.7691C11.4396 17.2289 12.6813 15.9687 14.199 15.9688Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9383 16.1461C12.2304 15.3878 11.7982 14.3856 11.7982 13.2884C11.7982 12.6283 12.0982 11.7882 12.6983 10.728V9.98794C12.6983 7.54763 14.7586 5.56738 17.2989 5.56738C19.8393 5.56738 21.8995 7.54763 21.8995 9.98794V10.728C22.4996 11.7882 22.7997 12.6283 22.7997 13.2884C22.7997 14.4223 22.338 15.4546 21.5877 16.2213C22.4365 16.7506 23 17.6925 23 18.7691V19.8692C23 19.9892 22.92 20.0692 22.8 20.0692H11.5985C11.4784 20.0692 11.3984 19.9892 11.3984 19.8692V18.7691C11.3984 17.6395 12.0188 16.6581 12.9383 16.1461Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 6.48004C1 4.96797 2.22578 3.74219 3.73786 3.74219H14.6893C15.2983 3.74219 15.8609 3.94105 16.3157 4.27738C13.5223 4.7296 11.3295 7.07517 11.3295 9.98798V10.3765C10.7955 11.3767 10.4294 12.3613 10.4294 13.2884C10.4294 14.2365 10.6717 15.1279 11.0942 15.9087C10.518 16.5741 10.1394 17.4164 10.0501 18.3441H3.73785C2.22578 18.3441 1 17.1183 1 15.6062V6.48004ZM17.2714 5.56751C17.3723 5.85293 17.4271 6.16008 17.4271 6.48004V15.6062C17.4271 17.1183 16.2014 18.3441 14.6893 18.3441H11.4283C11.4946 17.8745 11.669 17.4403 11.9258 17.0674C12.1872 16.6877 12.5339 16.3715 12.9385 16.1462C12.6248 15.8101 12.3652 15.426 12.1733 15.0071C11.9324 14.4808 11.7984 13.8993 11.7984 13.2884C11.7984 12.6283 12.0984 11.7882 12.6985 10.7281V9.98798C12.6985 7.55656 14.7438 5.58185 17.2714 5.56751ZM10.1264 11.0431L5.56329 13.781L5.56329 8.30525L10.1264 11.0431Z"
    />
    <path
      d="M18.4372 14.1685V16.0687H18.9299V18.129C18.4175 18.529 17.7671 18.7691 17.0575 18.7691C16.3479 18.7691 15.6975 18.529 15.1851 18.129V16.0687H15.6778V14.1685H18.4372Z"
      fill="white"
    />
    <path d="M17.0572 18.9689C16.3279 18.9689 15.6184 18.7289 15.0665 18.2888C15.0271 18.2488 14.9877 18.1888 14.9877 18.1288V16.0685C14.9877 15.9485 15.0665 15.8685 15.1848 15.8685H15.4804V14.1683C15.4804 14.0483 15.5593 13.9683 15.6775 13.9683H18.4369C18.5552 13.9683 18.634 14.0483 18.634 14.1683V15.8685H18.9296C19.0479 15.8685 19.1267 15.9485 19.1267 16.0685V18.1288C19.1267 18.1888 19.107 18.2488 19.0479 18.2888C18.496 18.7289 17.7865 18.9689 17.0572 18.9689ZM15.3819 18.0288C15.8549 18.3888 16.4462 18.5689 17.0572 18.5689C17.6682 18.5689 18.2595 18.3688 18.7325 18.0288V16.2686H18.4369C18.3186 16.2686 18.2398 16.1885 18.2398 16.0685V14.3683H15.8746V16.0685C15.8746 16.1885 15.7958 16.2686 15.6775 16.2686H15.3819V18.0288Z" />
    <path
      d="M18.3382 8.56787C18.8309 9.20795 19.5405 9.66801 20.3683 9.82803C20.4668 10.1481 20.5062 10.4881 20.5062 10.8282V11.4682C20.5062 13.4085 18.9689 14.9687 17.057 14.9687C15.1452 14.9687 13.6078 13.4085 13.6078 11.4682V10.8282C13.6078 10.4881 13.6472 10.1681 13.7458 9.86804C14.002 9.90804 14.2385 9.90804 14.4947 9.90804C15.9533 9.90804 17.2935 9.40798 18.3382 8.56787Z"
      fill="white"
    />
    <path d="M17.0576 15.1686C15.0472 15.1686 13.4113 13.5083 13.4113 11.4681V10.828C13.4113 10.488 13.4507 10.1279 13.5492 9.80786C13.5689 9.70785 13.6675 9.64784 13.766 9.66784C14.0026 9.68785 14.2588 9.70785 14.4953 9.70785C15.8553 9.70785 17.1758 9.24779 18.2205 8.40768C18.2599 8.36768 18.319 8.36768 18.3584 8.36768C18.4176 8.36768 18.457 8.40768 18.4964 8.44769C18.9694 9.04776 19.6396 9.48782 20.4083 9.64784C20.4871 9.66784 20.5462 9.70785 20.5659 9.78786C20.6645 10.1279 20.7236 10.488 20.7236 10.848V11.4881C20.7039 13.5083 19.068 15.1686 17.0576 15.1686ZM13.8843 10.0879C13.8252 10.3279 13.8055 10.588 13.8055 10.848V11.4881C13.8055 13.2883 15.264 14.7685 17.0576 14.7685C18.8512 14.7685 20.3097 13.2883 20.3097 11.4681V10.828C20.3097 10.548 20.2703 10.2679 20.2112 10.0079C19.4622 9.82787 18.8118 9.42781 18.2993 8.84774C17.2153 9.66784 15.875 10.1079 14.4953 10.1079C14.2982 10.1079 14.0814 10.1079 13.8843 10.0879Z" />
    <path d="M15.16 11.7274C15.16 11.8173 15.1775 11.9063 15.2113 11.9894C15.2452 12.0724 15.2949 12.1479 15.3575 12.2114C15.4202 12.275 15.4945 12.3254 15.5764 12.3598C15.6582 12.3942 15.7459 12.4119 15.8345 12.4119C15.9231 12.4119 16.0108 12.3942 16.0926 12.3598C16.1744 12.3254 16.2488 12.275 16.3114 12.2114C16.3741 12.1479 16.4237 12.0724 16.4576 11.9894C16.4915 11.9063 16.5089 11.8173 16.5089 11.7274C16.5089 11.6376 16.4915 11.5486 16.4576 11.4655C16.4237 11.3825 16.3741 11.307 16.3114 11.2435C16.2488 11.1799 16.1744 11.1295 16.0926 11.0951C16.0108 11.0607 15.9231 11.043 15.8345 11.043C15.7459 11.043 15.6582 11.0607 15.5764 11.0951C15.4945 11.1295 15.4202 11.1799 15.3575 11.2435C15.2949 11.307 15.2452 11.3825 15.2113 11.4655C15.1775 11.5486 15.16 11.6376 15.16 11.7274Z" />
    <path d="M17.8585 11.7274C17.8585 11.8173 17.8759 11.9063 17.9098 11.9894C17.9437 12.0724 17.9934 12.1479 18.056 12.2114C18.1186 12.275 18.193 12.3254 18.2748 12.3598C18.3567 12.3942 18.4444 12.4119 18.533 12.4119C18.6215 12.4119 18.7093 12.3942 18.7911 12.3598C18.8729 12.3254 18.9473 12.275 19.0099 12.2114C19.0725 12.1479 19.1222 12.0724 19.1561 11.9894C19.19 11.9063 19.2074 11.8173 19.2074 11.7274C19.2074 11.6376 19.19 11.5486 19.1561 11.4655C19.1222 11.3825 19.0725 11.307 19.0099 11.2435C18.9473 11.1799 18.8729 11.1295 18.7911 11.0951C18.7093 11.0607 18.6215 11.043 18.533 11.043C18.4444 11.043 18.3567 11.0607 18.2748 11.0951C18.193 11.1295 18.1186 11.1799 18.056 11.2435C17.9934 11.307 17.9437 11.3825 17.9098 11.4655C17.8759 11.5486 17.8585 11.6376 17.8585 11.7274Z" />
  </svg>
);

export default LiveSvg;
