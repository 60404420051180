import {BasicObject} from '@/types';

export const COLOR_MAP = {
  1: '#FFDA00',
  2: '#38A8E0',
  3: '#393C3C',
  4: '#EF7D00',
  5: '#00D58D',
  6: '#4B58C9',
  7: '#6B6A69',
  8: '#FF306E',
  9: '#E30613',
  10: '#24CD1A',
  11: '#38a8e0',
  12: '#ef7d00',
  13: '#24ce1a',
  14: '#e30613',
} as BasicObject;

export const CAR_MAP = {
  1: require('@components/assets/imgs/result/racecar/racecar-1.webp'),
  2: require('@components/assets/imgs/result/racecar/racecar-2.webp'),
  3: require('@components/assets/imgs/result/racecar/racecar-3.webp'),
  4: require('@components/assets/imgs/result/racecar/racecar-4.webp'),
  5: require('@components/assets/imgs/result/racecar/racecar-5.webp'),
  6: require('@components/assets/imgs/result/racecar/racecar-6.webp'),
  7: require('@components/assets/imgs/result/racecar/racecar-7.webp'),
  8: require('@components/assets/imgs/result/racecar/racecar-8.webp'),
  9: require('@components/assets/imgs/result/racecar/racecar-9.webp'),
  10: require('@components/assets/imgs/result/racecar/racecar-10.webp'),
} as BasicObject;
