import React from 'react';
import WinnerItem from './winner-item';
import NoData from '@/components/basic/error-pages/no-data';
import Page from '@/components/business/games/page';
import theme from '@/style';
import Spin from '@/components/basic/spin';
import {getWinners, WinnerResponse} from '../../satta-service';

const pageSize = 10;

const Winner = () => {
  const [list, setList] = React.useState<WinnerResponse[]>([]);
  const [total, setTotal] = React.useState(0);
  const totalList = React.useRef<WinnerResponse[]>([]);
  const [page, setPage] = React.useState(1);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try {
      const res = await getWinners();
      if (res) {
        totalList.current = res;
        setList(res.slice(0, 10));
        setTotal(res.length);
      }
    } finally {
      setFirstLoad(false);
      setLoading(false);
    }
  };

  const getListByPage = (pageNumber: number = 1) => {
    if (totalList.current.length > 10) {
      const current = (pageNumber - 1) * 10;
      const next = pageNumber * 10;
      setList(totalList.current.slice(current, next));
      setPage(pageNumber);
    }
  };

  return (
    <>
      <Spin
        loading={firstLoad || loading}
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {minHeight: 300},
          theme.flex.flex1,
          theme.background.lightGrey,
          theme.padding.topl,
        ]}>
        {!firstLoad && !loading && list && list.length === 0 ? (
          <NoData />
        ) : null}
        {list.map((item, _i) => (
          <WinnerItem
            key={_i}
            page={page}
            index={_i}
            bonus={item.bonus}
            userName={item.userName}
            userPhone={item.userPhone}
            userAvatar={item.userAvatar}
          />
        ))}
        {total > 0 && (
          <Page
            total={total}
            pageSize={pageSize}
            onPageChange={getListByPage}
          />
        )}
      </Spin>
    </>
  );
};

export default Winner;
