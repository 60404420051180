import Tabs, {TabsOptionItem} from '@/components/basic/tab';
import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import {goBack} from '@/utils';
import React, {useEffect, useState} from 'react';
import {FlatList, ListRenderItem, RefreshControl, View} from 'react-native';
import Text from '@basicComponents/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import LazyImage from '@/components/basic/image';
import {downIcon, filterIcon} from './rebate.variables';
import {useInnerStyle} from './rebate.hooks';
import NoData from '@/components/basic/error-pages/no-data';
import {NoMoreData} from '@/components/basic/default-page';
import RebateCard from './rebate-card';
import {usePaging} from '../hooks/paging.hooks';
import {RecordItem, getRecord} from './rebate.service';
import globalStore from '@/services/global.state';

import {useRebateRules} from './rebate-rules.hooks';
import RuleIcon from './svg/ruleIcon';
import DatePicker from '@/components/basic/date-picker/date-picker';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';

const Rebate = () => {
  const {i18n} = useTranslation();
  const [value, setValue] = useState<string>('3');
  const {filterStyle, rebateStyle} = useInnerStyle();
  const [openFilter, setOpenFilter] = useState(false);
  const [date, setDate] = useState<Date>(null!);

  const [refreshing, setRefreshing] = useState(false);
  const rebateOptions: TabsOptionItem[] = [
    {
      label: i18n.t('rebate.tabs.all'),
      value: '3',
    },
    {
      label: i18n.t('rebate.tabs.finish'),
      value: '0',
    },
    {
      label: i18n.t('rebate.tabs.collect'),
      value: '1',
    },
    {
      label: i18n.t('rebate.tabs.received'),
      value: '2',
    },
  ];
  // const [dateVisible, setDateVisible] = useState(false);
  // const [date, setDate] = useState<Date | undefined>();
  const {renderModal, show} = useRebateRules();

  const {resultList, isEnd, init, refreshNextPage} = usePaging(pageNo => {
    return getRecord({
      startDate: date
        ? dayjs(date).startOf('month').format('YYYYMMDD')
        : undefined,
      endDate: date ? dayjs(date).endOf('month').format('YYYYMMDD') : undefined,
      receiveStatus: value !== '3' ? +value : undefined,
      pageNo,
    });
  });

  useEffect(() => {
    setDate(null!);
    globalStore.globalLoading.next(true);
    init().finally(() => globalStore.globalLoading.next(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    globalStore.globalLoading.next(true);
    init().finally(() => globalStore.globalLoading.next(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleRefresh = () => {
    setRefreshing(true);
    init().finally(() => setRefreshing(false));
  };

  const renderItem: ListRenderItem<RecordItem> = ({item, index}) => {
    return (
      <View style={[theme.margin.btms]} key={index}>
        <RebateCard onRefresh={handleRefresh} data={item} />
      </View>
    );
  };

  return (
    <View
      style={[
        theme.flex.col,
        rebateStyle.container,
        theme.background.lightGrey,
      ]}>
      <DetailNavTitle
        title={i18n.t('rebate.title')}
        onBack={goBack}
        hideServer
        hideAmount
        rightNode={
          <NativeTouchableOpacity onPress={show}>
            <RuleIcon />
          </NativeTouchableOpacity>
        }
      />
      <View
        style={[
          theme.flex.row,
          theme.background.white,
          theme.flex.centerByCol,
        ]}>
        <Tabs
          tabOptions={rebateOptions}
          value={value}
          onChange={setValue}
          style={[theme.flex.flex1]}
        />
        <DatePicker
          open={openFilter}
          setOpen={setOpenFilter}
          type="month"
          value={date}
          onValueChange={setDate}
          titleRender={
            <View
              style={[
                theme.flex.row,
                filterStyle.wrap,
                theme.padding.lrs,
                theme.flex.centerByCol,
                theme.borderRadius.xs,
                theme.margin.rightl,
              ]}>
              <LazyImage
                occupancy="#0000"
                imageUrl={filterIcon}
                width={14}
                height={14}
              />
              <Text
                second
                fontSize={theme.fontSize.m}
                style={[theme.margin.lrxxs]}>
                {date ? dayjs(date).format('MM/YY') : i18n.t('rebate.filter')}
              </Text>
              <LazyImage
                occupancy="#0000"
                imageUrl={downIcon}
                width={theme.iconSize.xs}
                height={theme.iconSize.xs}
              />
            </View>
          }
        />
      </View>
      <View style={[theme.flex.row, theme.padding.l, theme.flex.flex1]}>
        <FlatList
          data={resultList}
          renderItem={renderItem}
          onEndReached={refreshNextPage}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
          }
          ListEmptyComponent={
            <View style={[theme.padding.xxl]}>
              <NoData />
            </View>
          }
          ListFooterComponent={
            resultList.length && isEnd ? <NoMoreData /> : undefined
          }
        />
      </View>
      {renderModal}
    </View>
  );
};

export default Rebate;
