import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';

export interface ResultItemProps {
  index?: number;
  issueNum?: string;
  digit?: string;
  drawTime?: string;
}

const ResultItem = ({
  issueNum = '',
  index = 0,
  drawTime = '',
  digit = '',
}: ResultItemProps) => {
  const backgroundColor = React.useMemo(() => {
    return {
      backgroundColor: index % 2 === 0 ? theme.basicColor.white : '#F8F9FF',
    };
  }, [index]);

  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.centerByCol,
        styles.itemContainer,
        theme.margin.lrl,
        backgroundColor,
      ]}>
      <View style={[]}>
        <Text>NO.{issueNum}</Text>
      </View>
      <View style={[theme.flex.flex1, theme.flex.center]}>
        <View style={[styles.drawResult, theme.overflow.hidden]}>
          <Text fontFamily="fontInterBold">{digit}</Text>
          <View style={[styles.circle, styles.left]} />
          <View style={[styles.circle, styles.right]} />
        </View>
      </View>
      <View>
        <Text>{drawTime}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    height: 48,
    paddingHorizontal: 12,
  },
  drawResult: {
    backgroundColor: '#EDE2FF',
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  circle: {
    position: 'absolute',
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: '#fff',
    top: 9,
  },
  left: {
    left: -3,
  },
  right: {
    right: -3,
  },
});

export default ResultItem;
