import React from 'react';
import ResultPanel from '../components/result-panel/result-panel';
import {View} from 'react-native';
import globalStore from '@/services/global.state';
import Text from '@/components/basic/text';
import Ball from '@/common-pages/color/components/ball';
import theme from '@/style';
import {useTranslation} from 'react-i18next';
import {ALL_BALLS} from '@/common-pages/color/constant';
import dayjs from 'dayjs';
import {formatDate, goTo} from '@/utils';
import {getColor} from '@/common-pages/bets/bets.service';
import {BasicObject} from '@/types';
const ColorDraw = () => {
  const [colorOrder, setColorOrder] = React.useState<BasicObject>();
  const {t} = useTranslation();

  const getResult = async () => {
    try {
      const res = await getColor({
        pageNo: 1,
        pageSize: 1,
        orderStatus: '4',
        yearMonth: formatDate(new Date(), 'yyyyMM'),
      });
      if (res.content) {
        setColorOrder(res.content[0]);
      }
    } finally {
    }
  };

  React.useEffect(() => {
    if (globalStore.token) {
      getResult();
    }
  }, []);

  return colorOrder?.issueNo ? (
    <ResultPanel
      winAmount={colorOrder.awardAmount}
      betInfo={
        <>
          <Text size="medium" fontFamily="fontInterBold" main>
            {colorOrder.cycle} Minute {'\n'}
          </Text>
          <Text size="small" fontFamily="fontInterBold" main>
            {colorOrder.issueNo}
          </Text>
        </>
      }
      onMore={() => goTo('Bets', {tag: 'Color'})}
      betTime={dayjs(colorOrder.createTime).format('DD-MM-YYYY hh:mm A')}>
      <View style={[theme.flex.row, theme.margin.tops]}>
        <View style={[theme.flex.flex1, theme.flex.center]}>
          <Text>Result</Text>
          <View style={[theme.padding.s]}>
            <Ball
              digit={colorOrder!.digitResult}
              ballSize={20}
              color={colorOrder.colorResult}
              fontSize={10}
              onlyColor={false}
            />
          </View>
        </View>
        <View
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            width: 1,
            backgroundColor: '#D8E0EA',
          }}
        />
        <View style={[theme.flex.flex1, theme.flex.center]}>
          <Text>{t('game-page.label.myBets')}</Text>
          <View
            style={[
              theme.padding.s,
              theme.flex.row,
              theme.flex.wrap,
              theme.flex.centerByCol,
              {gap: theme.paddingSize.xxs},
            ]}>
            {colorOrder.subsetList.map((item: BasicObject, _i: number) => (
              <Ball
                key={_i}
                isSmall={item.selectType === 2}
                digit={item.selectValue}
                ballSize={20}
                fontColor={item.selectType === 2 ? '#fff' : '#000'}
                color={
                  item.selectType === 2
                    ? item.selectValue
                    : ALL_BALLS.find(pie => pie.digit === item.selectValue)
                        ?.color
                }
                fontSize={10}
                onlyColor={item.selectType === 2}
              />
            ))}
          </View>
        </View>
      </View>
    </ResultPanel>
  ) : (
    <View />
  );
};

export default ColorDraw;
