import React, {useReducer, useState, Reducer, useCallback, useRef} from 'react';
import {
  Image,
  Pressable,
  ScrollView,
  Share,
  StyleSheet,
  View,
} from 'react-native';
import Text from '@basicComponents/text';
import i18n from '@i18n';
import theme from '@style';
import {goTo} from '@utils';
import {getShareInfo} from './referral.service';
import Clipboard from '@react-native-clipboard/clipboard';
import {ToastType, useToast} from '@basicComponents/modal';
import AsyncStorage from '@react-native-async-storage/async-storage';
import globalStore from '@/services/global.state';
import {SafeAny} from '@/types';
import Skeleton from '@basicComponents/skeleton';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {FadeInView} from '@/components/basic/animations';

const {flex, padding, position, font, background, paddingSize} = theme;

const Referral = () => {
  const {screenWidth, screenHeight} = useScreenSize();
  const innerStyles = StyleSheet.create({
    bottomView: {
      bottom: 35,
      left: 0,
      width: '100%',
    },
    largeBorder: {
      borderRadius: 34,
      borderWidth: 2,
      borderColor: '#B557FF',
    },
    button: {
      borderRadius: 30,
      paddingHorizontal: 24,
    },
    buttomButton: {
      width: screenWidth - 4 * paddingSize.l,
      height: 48,
      borderRadius: 24,
    },
    buttomButtonText: {
      fontSize: 18,
    },
    image: {
      width: screenWidth,
    },
    skeleton: {
      zIndex: -2,
    },
  });
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [imageHeights, setImageHeights] = useReducer<
    Reducer<number[], {index?: number; value: number | number[]}>
  >((state, {index, value}) => {
    if (index != null) {
      if (typeof value !== 'number') {
        return state;
      }
      const _state = [...state];
      _state[index] = value;
      return _state;
    }
    if (typeof value === 'number') {
      return state;
    }
    return value;
  }, []);
  const [code, setCode] = useState<string>('');
  const {renderModal, show} = useToast();
  const [login, setLogin] = useState<boolean>(false);
  const [invateText, setInviteText] = useState('');
  const [loading, setLoading] = useState(true);
  const throttleRef = useRef(false);

  const copy = (content: string) => {
    if (!login) {
      goTo('Login');
      return;
    }
    Clipboard.setString(content);
    show({
      type: ToastType.success,
      message: i18n.t('share.copy-success'),
    });
  };

  const onShare = async (message: string) => {
    if (throttleRef.current) {
      return;
    }
    throttleRef.current = true;
    setTimeout(() => {
      throttleRef.current = false;
    }, 300);
    try {
      const result = await Share.share({
        message,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      globalStore.globalTotal.next({
        type: 'warning',
        message: (error as SafeAny)?.message || error,
      });
    }
  };

  const copyShareLink = () => {
    if (!code) {
      goTo('Login');
      return;
    }
    if (globalStore.isAndroid) {
      onShare(`${invateText}?code=${code}`);
    } else {
      copy(`${invateText}?code=${code}`);
    }
  };

  const handleLoadEnd = () => {
    setLoading(false);
  };

  const refresh = useCallback(() => {
    AsyncStorage.getItem('token').then(value => {
      setLogin(!!value);
    });
    getShareInfo().then(data => {
      const _imageUrls = data.backgroundPicture
        ? data.backgroundPicture.split(',')
        : [];
      setImageUrls(_imageUrls);
      setCode(data.inviteCode);
      setInviteText(data.inviteRegisterLinkUrl);
      const _imageHeights = Array(_imageUrls.length)
        .fill(0)
        .map(() => 0);
      setImageHeights({value: _imageHeights});
      _imageUrls.map((url, index) => {
        Image.getSize(url, (width, height) => {
          setImageHeights({
            index,
            value: (screenWidth / width) * height,
          });
        });
      });
    });
  }, [screenWidth]);
  React.useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FadeInView
      style={[
        position.rel,
        theme.fill.fill,
        {
          maxHeight: screenHeight,
        },
      ]}>
      <ScrollView style={[theme.fill.fill]} stickyHeaderIndices={[0]}>
        <View style={[padding.lrxxl, padding.tbs, background.primary]}>
          <View
            style={[
              flex.row,
              flex.between,
              innerStyles.largeBorder,
              padding.rights,
              padding.tbxxs,
              padding.leftxxl,
              flex.centerByCol,
            ]}>
            <View
              style={[
                theme.flex.flex1,
                theme.flex.row,
                theme.flex.centerByCol,
                theme.flex.around,
              ]}>
              {[0, 1, 2, 3, 4, 5].map(v => (
                <Text
                  key={v}
                  blod
                  fontSize={theme.fontSize.xl}
                  color={theme.basicColor.white}>
                  {code?.[v] || '-'}
                </Text>
              ))}
            </View>
            <Pressable
              style={[padding.tbs, innerStyles.button, theme.background.second]}
              onPress={() => copy(code)}>
              <Text style={[font.white, font.fxl, font.bold]}>
                {i18n.t('referral.copy')}
              </Text>
            </Pressable>
          </View>
        </View>
        <Skeleton loading={!imageUrls || !imageUrls.length || loading}>
          <View style={[theme.flex.col, theme.fill.fillW]}>
            {imageUrls.map(
              (url, i) =>
                url && (
                  <Image
                    key={i}
                    source={{uri: url}}
                    onLoadEnd={handleLoadEnd}
                    style={[innerStyles.image, {height: imageHeights[i]}]}
                    resizeMode="cover"
                  />
                ),
            )}
          </View>
        </Skeleton>
      </ScrollView>
      <View
        style={[position.abs, innerStyles.bottomView, padding.lrl, flex.row]}>
        <View style={[padding.lrl]}>
          <Pressable
            onPress={() => copyShareLink()}
            style={[
              innerStyles.buttomButton,
              flex.center,
              theme.background.primary,
            ]}>
            <Text style={[innerStyles.buttomButtonText, font.bold, font.white]}>
              {i18n.t('referral.invite')}
            </Text>
          </Pressable>
        </View>
      </View>
      {renderModal}
    </FadeInView>
  );
};

export default Referral;
