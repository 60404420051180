import {View, StyleSheet, Animated} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import React, {useMemo, useRef} from 'react';
import Text from '@/components/basic/text';
import theme from '@/style';
import {debounce} from '@/utils';
import {packageId} from '@/config';
import LazyImage from '@/components/basic/image';
const triangle = require('@assets/icons/triangle.webp');

const FILTER_MENU = [
  {
    name: 'Kerala',
    hideIds: [5, 101],
  },
  {
    name: '3 Digit',
    hideIds: [5, 101],
  },
  {
    name: 'Quick 3D',
    hideIds: [5, 101],
  },
  {
    name: 'Color',
  },
  {
    name: 'Quick Race',
  },
  {
    name: 'Dice',
  },
  {
    name: 'Satta Matka',
    hideIds: [5, 101],
  },
  {
    name: 'Scratch off',
    hideIds: [8],
  },
  {
    name: 'Casino',
  },
  {
    name: 'Live',
  },
  {
    name: 'Sports',
    hideIds: [101],
  },
  {
    name: 'State Lottery',
  },
  {
    name: 'Quick State Lottery',
  },
];

const BetsFilter = (props: {
  value?: string;
  onChange?: (v: string) => void;
  onExpend?: () => void;
  expend?: boolean;
  onAnimateEnd?: () => void;
}) => {
  const {
    value = 'Kerala',
    onChange = () => {},
    expend = true,
    onExpend,
    onAnimateEnd,
  } = props;
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const filterList = useMemo(() => {
    return FILTER_MENU.filter(v => {
      if (v.hideIds) {
        return v.hideIds.indexOf(packageId) === -1;
      }
      return true;
    });
  }, []);

  const changeName = debounce((name: string) => {
    onChange(name);
  });

  const openClose = (status = false) => {
    Animated.timing(fadeAnim, {
      toValue: status ? 1 : 0,
      duration: 150,
      useNativeDriver: false,
    }).start(() => onAnimateEnd?.());
  };

  React.useEffect(() => {
    openClose(expend);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expend]);

  return (
    <Animated.View
      style={[
        theme.overflow.hidden,
        {
          maxHeight: fadeAnim.interpolate({
            inputRange: [0, 1],
            outputRange: [48, 500],
          }),
        },
      ]}>
      <View
        style={[theme.padding.lrl, theme.padding.tbs, theme.background.white]}>
        <View style={styles.container}>
          {filterList.map((item, index) => (
            <NativeTouchableOpacity
              style={[styles.item, value === item.name && styles.itemSelected]}
              key={index}
              onPress={() => changeName(item.name)}>
              <Text
                color={
                  value === item.name
                    ? theme.basicColor.primary
                    : theme.basicColor.dark
                }>
                {item.name}
              </Text>
            </NativeTouchableOpacity>
          ))}
          <NativeTouchableOpacity
            style={[theme.position.abs, styles.triangle]}
            onPress={onExpend}>
            <Animated.View
              style={[
                {
                  transform: [
                    {
                      rotate: fadeAnim.interpolate({
                        inputRange: [0, 1],
                        outputRange: ['0deg', '-180deg'],
                      }),
                    },
                  ],
                },
              ]}>
              <LazyImage
                imageUrl={triangle}
                width={14}
                height={14}
                occupancy="transparent"
              />
            </Animated.View>
          </NativeTouchableOpacity>
        </View>
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
  },
  item: {
    borderWidth: 1,
    backgroundColor: '#F6F7FB',
    borderColor: 'transparent',
    borderRadius: 30,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  itemSelected: {
    borderColor: theme.basicColor.primary,
    ...theme.background.white,
  },
  triangle: {
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'flex-end',
    right: 0,
    top: 4,
  },
});

export default BetsFilter;
