declare const window: {
  Android: any;
} & Window;

export const inApp = window?.navigator?.userAgent?.indexOf('Pandora') > -1;

export function getAdjustAttributionFromAPP() {
  const res = window.Android.getAdjustAttribution();
  if (res) {
    try {
      return JSON.parse(res);
    } catch (error) {
      return null;
    }
  }
  return res;
}

export function openURLInAPP(url: string) {
  try {
    window.Android.openURL(url);
  } catch (error) {
    console.log(error);
  }
}

export function getVersion() {
  const res =
    navigator.userAgent.match(/versionCode\/(\d+)/) ||
    navigator.userAgent.match(/AppVersion\/(\d+)/);
  if (res) {
    return res[1];
  }
  return '999';
}

export function getVersionName() {
  const res = navigator.userAgent.match(/versionName\/(.*)\s/);
  if (res) {
    return res[1];
  }
  return getVersion().split('').join('.');
}
