import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import {useTranslation} from 'react-i18next';
import theme from '@/style';
import {SafeAny} from '@/types';
import globalStore from '@/services/global.state';
import CutDown from './cut-down';
import Video from '@/components/basic/video';
import BackgroundTimer from '@components/utils/timer';
export interface TimeRemainingProps {
  title?: string;
  remainingTime?: number;
  tip?: string;
  includeHour?: boolean;
  hasCutdown?: boolean;
  onTimeEnd?: () => void;
  onDisabled?: () => void;
  isSame?: boolean;
  stopBetSec?: number;
  onTimeChange?: (v: number) => void;
  timeVersion?: number;
}

const TimeRemaining = (props: TimeRemainingProps) => {
  const {i18n} = useTranslation();
  const timer = React.useRef<SafeAny>(null);
  const timeCurrent = React.useRef(0);
  const [showCutdown, setShowCutdown] = React.useState(false);
  const [cutdownPaused, setCutdownPaused] = React.useState(true);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [nowTime, setNowTime] = React.useState(-1);
  const lockToast = React.useRef(false);
  const lockShow = React.useRef(false);
  const timeRef = React.useRef(-1);
  const {
    title = i18n.t('game-page.label.remaining'),
    tip,
    remainingTime = -1,
    includeHour = false,
    stopBetSec,
    onTimeEnd,
    onDisabled,
    onTimeChange,
    isSame = true,
    hasCutdown = true,
    timeVersion = 0,
  } = props;

  const timeStr = React.useMemo(() => {
    if (nowTime > 0) {
      const days = Math.floor(nowTime / 3600 / 24);
      const hour = Math.floor(nowTime / 3600);
      const minutes = Math.floor(nowTime / 60) % 60;
      const seconds = nowTime % 60;
      return {
        days,
        hour: (hour + '').padStart(2, '0').split(''),
        minutes: (minutes + '').padStart(2, '0').split(''),
        seconds: (seconds + '').padStart(2, '0').split(''),
      };
    }
    return {
      days: 0,
      hour: ['-', '-'],
      minutes: ['-', '-'],
      seconds: ['-', '-'],
    };
  }, [nowTime]);

  React.useEffect(() => {
    timer.current = BackgroundTimer.setInterval(() => {
      timeCurrent.current += 1;
      setCurrentTime(timeCurrent.current);
    }, 1000);
    return () => timer.current && BackgroundTimer.clearInterval(timer.current);
  }, []);

  React.useEffect(() => {
    if (timeRef.current > 0) {
      timeRef.current -= 1;
      setNowTime(timeRef.current);
      onTimeChange?.(timeRef.current);
      if (timeRef.current === 3 && !lockShow.current) {
        // 倒计时3秒
        lockShow.current = true;
        if (isSame) {
          setCutdownPaused(false);
          setShowCutdown(true);
        }
      }
      if (timeRef.current === 0) {
        onTimeEnd?.();
      }
      if (stopBetSec && timeRef.current <= stopBetSec) {
        if (!lockToast.current) {
          lockToast.current = true;
          if (isSame) {
            globalStore.globalWaringTotal(
              i18n.t('game-page.tip.unable', {
                time: stopBetSec,
              }),
            );
          }
          onDisabled?.();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  React.useEffect(() => {
    if (timeVersion && timeVersion > 0 && remainingTime && remainingTime > 0) {
      timeRef.current = remainingTime + 1;
    }
    return () => {
      lockToast.current = false;
      lockShow.current = false;
    };
  }, [remainingTime, timeVersion]);

  return (
    <>
      <View style={[theme.flex.alignEnd]}>
        <Text fontWeight="500">{title}</Text>
        <View
          style={[
            theme.flex.row,
            theme.margin.topxxs,
            {
              gap: includeHour
                ? theme.paddingSize.xxs
                : theme.paddingSize.xxs / 2,
            },
          ]}>
          {includeHour && (
            <View style={[theme.flex.row, {gap: theme.paddingSize.xxs / 2}]}>
              {timeStr.hour.map((item, _i) => (
                <View style={[styles.item]} key={_i}>
                  <Text
                    color={theme.basicColor.white}
                    fontSize={20}
                    fontFamily="fontDinBold">
                    {item}
                  </Text>
                </View>
              ))}
              <Text
                color={theme.basicColor.dark}
                fontSize={20}
                fontFamily="fontDinBold">
                :
              </Text>
            </View>
          )}
          {timeStr.minutes.map((item, _i) => (
            <View style={styles.item} key={_i}>
              <Text
                color={theme.basicColor.white}
                fontSize={20}
                fontFamily="fontDinBold">
                {item}
              </Text>
            </View>
          ))}
          {includeHour ? (
            <Text
              color={theme.basicColor.dark}
              fontSize={20}
              fontFamily="fontDinBold">
              :
            </Text>
          ) : (
            <View style={[styles.item, styles.itemThin]}>
              <Text
                color={theme.basicColor.white}
                fontSize={20}
                fontFamily="fontDinBold">
                :
              </Text>
            </View>
          )}

          {timeStr.seconds.map((item, index) => (
            <View style={styles.item} key={index}>
              <Text
                color={theme.basicColor.white}
                fontSize={20}
                fontFamily="fontDinBold">
                {item}
              </Text>
            </View>
          ))}
        </View>
        <Text fontFamily="fontInterBold">{tip || '-'}</Text>
      </View>
      {hasCutdown && (
        <Video
          onEnd={() => {
            setCutdownPaused(true);
            // videoRef.current?.seek(0);
          }}
          audioOnly
          source={require('@components/assets/music/cut-down.mp3')}
          src={'/assets/mp3/cut-down.mp3'}
          paused={cutdownPaused}
        />
      )}

      {hasCutdown && (
        <CutDown
          visible={showCutdown}
          onClose={() => {
            setShowCutdown(false);
          }}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  item: {
    width: 19,
    height: 32,
    backgroundColor: theme.basicColor.dark,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemThin: {
    width: 'auto',
    paddingHorizontal: 4,
  },
});

export default React.memo(TimeRemaining);
