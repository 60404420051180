import theme from '@/style';
import {View} from 'react-native';
import BetsHeader, {BetsHeaderProps} from './bets-header';
import React from 'react';
import BetsSingleItem from './bets-single-item';
import {SafeAny} from '@/types';
import BetsDoubleLevelItem from './bets-double-item';
import BetsTripleItem from './bets-triple-item';

const BetsDigitLevelItem = ({
  level = 1,
  winPrize,
  payment,
  onAdd = () => {},
  handleBox = () => {},
}: {
  level?: number;
  onAdd?: (
    type: string,
    value: string,
    count: number,
    startElement?: View | null,
  ) => void;
  handleBox?: (
    value: string,
    count: number,
    startElement?: View | null,
  ) => void;
} & BetsHeaderProps) => {
  const [levelOne, setLevelOne] = React.useState<string[]>([]);
  const [levelTwo, setLevelTwo] = React.useState<string[][]>([[]]);
  const [levelThree, setLevelThree] = React.useState<string[][]>([[]]);
  const [code, setCode] = React.useState(0);
  const [doubleCode, setDoubleCode] = React.useState(0);
  const [threeCode, setThreeCode] = React.useState(0);
  const data = React.useMemo<SafeAny[]>(() => {
    if (level === 1) {
      return ['A', 'B', 'C'];
    }
    if (level === 2) {
      return [
        ['A', 'B'],
        ['A', 'C'],
        ['B', 'C'],
      ];
    }
    if (level === 3) {
      return [['A', 'B', 'C']];
    }
    return [];
  }, [level]);

  const onGuess = () => {
    if (level === 1) {
      const nums = new Array(3)
        .fill('')
        .map(() => `${Math.floor(Math.random() * 10)}`);
      setLevelOne(nums);
    } else if (level === 2) {
      const nums = new Array(3).fill(new Array(2).fill('')).map(item => {
        return item.map(() => `${Math.floor(Math.random() * 10)}`);
      });
      setLevelTwo(nums);
    } else if (level === 3) {
      const nums = new Array(1).fill(new Array(3).fill('')).map(item => {
        return item.map(() => `${Math.floor(Math.random() * 10)}`);
      });
      setLevelThree(nums);
    }
  };

  React.useEffect(() => {
    setCode(code + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelOne]);

  React.useEffect(() => {
    setDoubleCode(doubleCode + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelTwo]);

  React.useEffect(() => {
    setThreeCode(threeCode + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelThree]);

  return (
    <View
      style={[theme.borderRadius.xs, theme.padding.l, theme.background.white]}>
      <BetsHeader
        winPrize={winPrize}
        payment={payment}
        level={level}
        onGuess={onGuess}
      />
      <View>
        {data.map((item, _i) =>
          level === 1 ? (
            <BetsSingleItem
              onAdd={onAdd}
              guessNum={levelOne[_i]}
              key={_i}
              color={item}
              digit={item}
              extend={code}
            />
          ) : level === 2 ? (
            <BetsDoubleLevelItem
              onAdd={onAdd}
              guessNums={levelTwo[_i]}
              key={_i}
              extend={doubleCode}
              digits={item}
            />
          ) : (
            <BetsTripleItem
              onAdd={onAdd}
              onBox={handleBox}
              guessNums={levelThree[_i]}
              digits={item}
              extend={threeCode}
              key={_i}
            />
          ),
        )}
      </View>
    </View>
  );
};

export default React.memo(BetsDigitLevelItem);
