import globalStore from '@/services/global.state';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import NoData from '@/components/basic/error-pages/no-data';
import theme from '@/style';
import Spin from '@/components/basic/spin';
import OrderCard from '@/components/business/order/order-card';
import PageNumber from '@/components/business/games/page';
import DrawResult from '../component/draw-result';
import TicketList from './ticket-list';
import {
  getOrders,
  LotteryOrder as LotteryOrderType,
} from '../mix-lottery-service';
import {goTo} from '@/utils';
import dayjs from 'dayjs';
const pageSize = 3;

const LotteryOrder = ({
  lotteryID,
  refreshCode,
  isQuick,
}: {
  lotteryID?: number;
  refreshCode?: number;
  isQuick?: boolean;
}) => {
  const [list, setList] = React.useState<LotteryOrderType[]>([]);
  const [total, setTotal] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [initPage, setInitPage] = React.useState(1);
  const {i18n} = useTranslation();

  const getList = async (id: number, pageNo: number = 1) => {
    try {
      if (!firstLoad) {
        setLoading(true);
      }
      const res = await getOrders(id, isQuick, pageNo, pageSize);
      if (res) {
        setList(res.records || []);
        setTotal(res.totalCount);
      }
    } finally {
      setFirstLoad(false);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!globalStore.token) {
      goTo('Login');
      setFirstLoad(false);
      return;
    }
    if (lotteryID) {
      getList(lotteryID).then();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryID]);

  React.useEffect(() => {
    if (refreshCode && lotteryID) {
      if (!globalStore.token) {
        return;
      }
      getList(lotteryID).then();
      setInitPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCode]);

  const onCopy = (id: string) => {
    Clipboard.setString(id);
    globalStore.globalSucessTotal(i18n.t('share.copy-success'));
  };

  const getStatus = (info: LotteryOrderType) => {
    if (isQuick) {
      return info.wonCode.every(v => v.indexOf('-') === -1) ? 4 : 2;
    } else {
      return info.wonCode.every(v => v.indexOf('-') === -1)
        ? info.ticketsLists.every(v => v.ticketStatus === 2)
          ? 4
          : 3
        : 2;
    }
  };

  return (
    <Spin
      loading={firstLoad || loading}
      style={[
        // eslint-disable-next-line react-native/no-inline-styles
        {minHeight: 300},
        theme.flex.flex1,
        total <= 3 && theme.padding.btml,
      ]}>
      {!firstLoad && !loading && list && list.length === 0 ? <NoData /> : null}
      {list.map((item, _i) => (
        <View key={_i}>
          <OrderCard
            hideDraw={false}
            id={item.orderGroup}
            hasWin={item.totalPrize > 0}
            status={getStatus(item)}
            betsTime={dayjs(item.createTime * 1000).format('DD-MM-YYYY HH:mm')}
            drawTime={dayjs(item.drawSec * 1000).format('DD-MM-YYYY hh:mm A')}
            payment={item.totalAmount}
            gameName={`${item.lotteryName} No.${item.roundNo}`}
            awardAmount={item.totalPrize}
            result={
              <DrawResult
                wonCode={item.wonCode}
                name={`${item.lotteryName} No.${item.roundNo}`}
              />
            }
            onCopy={() => onCopy(item.orderGroup)}
          />
          <TicketList
            ticketList={item.ticketsLists}
            isOpen={getStatus(item) !== 2}
          />
        </View>
      ))}
      {total > pageSize ? (
        <PageNumber
          total={total}
          page={initPage}
          pageSize={pageSize}
          onPageChange={p => {
            setInitPage(p);
            getList(lotteryID!, p).then();
          }}
        />
      ) : null}
    </Spin>
  );
};

export default LotteryOrder;
