import LinearGradient from '@/components/basic/linear-gradient';
import Text from '@/components/basic/text';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {goTo} from '@/utils';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Image, StyleSheet, Pressable, View} from 'react-native';
import {getGameURL, getSportGames} from '../home.service';
import {SportItem} from '../home.type';
import SportModal from '@/components/business/sport-modal';
import {toSports} from '@/utils';
import {useNavigation} from '@react-navigation/native';
import {debounce} from '@/utils';
import {ToastType} from '@basicComponents/modal';

type SportsModalProps = {
  popVisible: boolean;
  setPopVisible: React.Dispatch<React.SetStateAction<boolean>>;
  list: SportItem[];
  getSportURL: Function;
};

const style = StyleSheet.create({
  tabItem: {
    height: 72,
  },
  tabItemActive: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: theme.basicColor.primary,
    paddingTop: 2,
  },
  tabItemBgActive: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    paddingTop: 4,
  },
});

const HomeTab = ({
  value,
  setValue,
}: {
  value: 0 | 1 | 2 | 3 | 4;
  setValue: (v: 0 | 1 | 2 | 3 | 4) => void;
}) => {
  const {getState} = useNavigation();
  const {index: stateIndex, routes} = getState();
  const currentRouteName = routes[stateIndex].name;
  const {i18n} = useTranslation();
  const [sports, setSports] = React.useState<SportItem[]>([]);
  const [popVisible, setPopVisible] = React.useState(false);
  const notYetWarning = {
    type: ToastType.warning,
    message: i18n.t('warning.unopen'),
  };

  const getSports = () =>
    getSportGames()
      .then(res => {
        if (!res || !res.length) {
          globalStore.globalTotal.next(notYetWarning);
          return;
        }
        if (res.length > 1) {
          setSports(res);
          setPopVisible(true);
        } else {
          getSportURL(res[0].moduleType);
        }
      })
      .catch(e => console.error('error', e));
  const getSportURL = debounce((gametype: string) => {
    if (gametype === 'betby_sport') {
      goTo('BetBy');
      setPopVisible(false);
      return;
    }
    getGameURL(gametype)
      .then((url: string) => {
        setPopVisible(false);
        toSports(gametype.split('_')[0].toUpperCase() + ' Sports', url);
      })
      .catch(e => console.error('error', e));
  });
  React.useEffect(() => {
    if (currentRouteName !== 'Home') {
      setPopVisible(false);
    }
  }, [currentRouteName, setPopVisible]);
  React.useEffect(() => {
    const sub = globalStore.openSports.subscribe(msg => {
      if (msg === 'open') {
        getSports();
      }
    });
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabList = React.useMemo(
    () => [
      {
        img: require('@assets/imgs/home/lottery.webp'),
        title: i18n.t('home.tab.lottery'),
        onPress: () => {
          setValue(0);
        },
      },
      // {
      //   img: require('@assets/imgs/home/scratch-off.webp'),
      //   title: i18n.t('home.tab.scratchOff'),
      //   onPress: () => {
      //     setValue(1);
      //   },
      // },
      {
        img: require('@assets/imgs/home/live.webp'),
        title: i18n.t('home.tab.live'),
        onPress: () => {
          setValue(1);
        },
      },
      {
        img: require('@assets/imgs/home/casino.webp'),
        title: i18n.t('home.tab.casino'),
        onPress: () => {
          setValue(2);
        },
      },
      {
        img: require('@assets/imgs/home/sports.webp'),
        title: i18n.t('home.tab.sports'),
        onPress: () => {
          if (globalStore.token) {
            getSports();
          } else {
            goTo('Login');
          }
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <LinearGradient
      colors={['#fff', '#f4f5f8']}
      style={[
        theme.background.white,
        theme.padding.lrl,
        theme.padding.tops,
        theme.flex.row,
        theme.flex.between,
      ]}>
      {tabList.map((v, i) => {
        return (
          <Pressable
            style={[
              theme.position.rel,
              theme.overflow.hidden,
              style.tabItem,
              value === i && [style.tabItemActive],
            ]}
            key={v.title}
            onPress={v.onPress}>
            <LinearGradient
              colors={
                value === i
                  ? ['rgb(136,212,140)', '#f4f5f8']
                  : ['#0000', '#0000']
              }
              style={[theme.flex.flex1, value === i && style.tabItemBgActive]}>
              <View style={[theme.padding.lrxs, theme.flex.centerByCol]}>
                <Image style={[theme.image.s]} source={v.img} />
                <Text fontFamily="fontInterBold" style={{fontSize: 10}}>
                  {v.title}
                </Text>
              </View>
            </LinearGradient>
          </Pressable>
        );
      })}
      <SportModal<SportsModalProps>
        popVisible={popVisible}
        setPopVisible={setPopVisible}
        list={sports}
        getSportURL={getSportURL}
      />
    </LinearGradient>
  );
};

export default HomeTab;
