import {View} from 'react-native';
import {SUM_POINTS, SUF_TYPE} from '../../constant';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import Ball from '../../component/ball';
import DiceContainer from '../../component/dice-container';
import BetsTabHeader from '../../component/bets-tab-header';
import {DiceConfigInfo} from '../../dice-service';

const GAP = 8;
const SumPoints = ({
  diceConf = {
    ruleLinkUrl: '',
    videoLinkUrl: '',
    cycle: 0,
    id: 0,
    minRemainingSecond: 0,
    optionOdds: undefined,
  },
  onSelect = () => {},
  disabled = false,
}: {
  diceConf?: DiceConfigInfo;
  disabled?: boolean;
  onSelect?: (code: string, value: string | number) => void;
}) => {
  return (
    <>
      <BetsTabHeader />
      <View
        style={[
          theme.padding.lrl,
          theme.flex.row,
          theme.flex.wrap,
          {gap: GAP},
        ]}>
        {SUF_TYPE.map((item, _i) => (
          <DiceContainer
            disabled={disabled}
            key={_i}
            onPress={() => onSelect(`sum_${item}`, item)}>
            <Ball
              fontSize={24}
              disabled={disabled}
              ballSize={40}
              isNum={false}
              type={item}
            />
            <Text style={[theme.margin.topxxs]}>
              {diceConf.optionOdds ? diceConf.optionOdds[`sum_${item}`] : '0'}X
            </Text>
          </DiceContainer>
        ))}
        {SUM_POINTS.map((item, _i) => (
          <DiceContainer
            disabled={disabled}
            key={_i}
            onPress={() => onSelect(item, _i + 3)}>
            <Ball
              fontSize={24}
              disabled={disabled}
              ballSize={40}
              isNum
              num={_i + 3}
            />
            <Text style={[theme.margin.topxxs]}>
              {diceConf.optionOdds ? diceConf.optionOdds[item] : '0'}X
            </Text>
          </DiceContainer>
        ))}
      </View>
    </>
  );
};

export default React.memo(SumPoints);
