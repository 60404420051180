import {SVGProps} from '@/types';
import React from 'react';

const ColorResultBg = ({color = '#B098D8', width, height}: SVGProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_13693_93834)">
      <rect x="0.5" width={width} height={height} rx="8" fill={color} />
    </g>
    <defs>
      <filter
        id="filter0_i_13693_93834"
        x="0.5"
        y="0"
        width="198"
        height="44"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_13693_93834"
        />
      </filter>
    </defs>
  </svg>
);

export default ColorResultBg;
