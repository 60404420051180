import React from 'react';
import {termsConditions, privacyPolicy} from './config';
const close = require('@components/assets/icons/close.webp');
import './index.css';
const Privacy = ({fix = false}: {fix?: boolean}) => {
  const [openTerms, setOpenTerms] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const openTermsHandler = () => {
    setOpenTerms(true);
  };
  const closeTermsHandler = () => {
    setOpenTerms(false);
  };
  const openPrivacyHandler = () => {
    setOpenPrivacy(true);
  };
  const closePrivacyHandler = () => {
    setOpenPrivacy(false);
  };
  return (
    <>
      <div className="privacy">
        By clicking this button you confirm that you have read and agree to the{' '}
        <span onClick={openTermsHandler} className="privacy-link">
          Terms and Conditions
        </span>{' '}
        and{' '}
        <span onClick={openPrivacyHandler} className="privacy-link">
          privacy policy
        </span>{' '}
        of the company and confirm that you are legal age
      </div>
      {openTerms && (
        <div className={`jump-wrap terms-conditions ${fix ? 'fix-wrap' : ''}`}>
          <img
            src={close}
            alt="close"
            onClick={closeTermsHandler}
            className="close-icon"
          />
          <h2 className="privacy-title">Terms Conditions</h2>
          <main className="terms-conditions-main">
            {termsConditions.map((item, index) => (
              <section key={item.title}>
                <h4>{`${index + 1}. ${item.title}`}</h4>
                {!!item.content && (
                  <p className="terms-conditions-content">{item.content}</p>
                )}
                {!!item.table && (
                  <div className="terms-conditions-table">
                    {item.table.map((tItem, tIndex) => (
                      <div
                        className="terms-conditions-table-row terms-conditions-table-wrap"
                        key={tIndex}>
                        <div className="row-title">{tItem.tTitle}</div>
                        <div className="row-s-title">{tItem.tSTitle}</div>
                        <div className="row-content">{tItem.tContent}</div>
                      </div>
                    ))}
                  </div>
                )}
                {!!item.list && (
                  <>
                    {item.list.map((lItem, lIndex) => (
                      <div className="terms-conditions-list" key={lIndex}>
                        <span className="terms-conditions-list-order">
                          {lIndex + 1}
                        </span>
                        {`. ${lItem}`}
                      </div>
                    ))}
                  </>
                )}
                {!!item.complex && (
                  <>
                    {item.complex.map((cItem, cIndex) => (
                      <div className="terms-conditions-list" key={cIndex}>
                        {cItem.type === 'p' && (
                          <p className="terms-conditions-content">
                            {cItem.content}
                          </p>
                        )}
                        {cItem.type === 'l' && (
                          <div className="terms-conditions-list-indent">
                            {(cItem.content as string[]).map((cl, clIndex) => (
                              <div key={clIndex}>
                                <span className="terms-conditions-list-order">
                                  {clIndex + 1}
                                </span>
                                {`. ${cl}`}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </section>
            ))}
          </main>
        </div>
      )}
      {openPrivacy && (
        <div className={`jump-wrap terms-conditions ${fix ? 'fix-wrap' : ''}`}>
          <img
            src={close}
            alt="close"
            onClick={closePrivacyHandler}
            className="close-icon"
          />
          <h2 className="privacy-title">Privacy Policy</h2>
          <main className="terms-conditions-main">
            {privacyPolicy.map(item => (
              <section key={item.title}>
                <h4>{item.title}</h4>
                {!!item.content && (
                  <p className="terms-conditions-content">{item.content}</p>
                )}
                {!!item.list && (
                  <>
                    {item.list.map((lItem, lIndex) => (
                      <div className="terms-conditions-list" key={lIndex}>
                        <span className="terms-conditions-list-order">
                          {lIndex + 1}
                        </span>
                        {`. ${lItem}`}
                      </div>
                    ))}
                  </>
                )}
              </section>
            ))}
          </main>
        </div>
      )}
    </>
  );
};

export default Privacy;
