import {ImageRequireSource} from 'react-native';

const DICE_GAME_ICON: Record<number, ImageRequireSource> = {
  1: require('./dice/dice-1.webp'),
  3: require('./dice/dice-3.webp'),
  5: require('./dice/dice-5.webp'),
  15: require('./dice/dice-15.webp'),
};

const COLOR_GAME_ICON: Record<number, ImageRequireSource> = {
  1: require('./color/color-1.webp'),
  2: require('./color/color-2.webp'),
  3: require('./color/color-3.webp'),
  5: require('./color/color-5.webp'),
};

const KERALA_GAME_ICON: Record<string, ImageRequireSource> = {
  AKSHAYA: require('./kerala/akshaya.webp'),
  'STHREE-SAKTHI': require('./kerala/sthree.webp'),
  KARUNYA: require('./kerala/karunya.webp'),
  NIRMAL: require('./kerala/nirmal.webp'),
  'KARUNYA PLUS': require('./kerala/karunya-plus.webp'),
  'FIFTY-FIFTY': require('./kerala/fifty.webp'),
  'WIN-WIN': require('./kerala/win.webp'),
  'ONLINE-ONLY': require('./kerala/only.webp'),
};

const SATTA_GAME_ICON: Record<string, ImageRequireSource> = {
  Kalyan: require('./satta/kalyan.webp'),
  'Kalyan Night': require('./satta/kalyan-night.webp'),
  'Milan Night': require('./satta/milan-night.webp'),
  'Main Bazar': require('./satta/main-bazar.webp'),
  'Mumbai Main': require('./satta/mumbai-main.webp'),
  'Bengal Bazar': require('./satta/bengal-bazar.webp'),
  Ratnagiri: require('./satta/ratnagiri.webp'),
  'Kalyan Morning': require('./satta/kalyan-morning.webp'),
  'Milan Morning': require('./satta/milan-morning.webp'),
  'Milan Day': require('./satta/milan-day.webp'),
  'Delhi Darbar': require('./satta/delhi-darbar.webp'),
  'MP Bazar': require('./satta/mp-bazar.webp'),
  Kuber: require('./satta/kuber.webp'),
};

const SATTA_GAME_BG: Record<string, string> = {
  Kalyan: 'https://download.bhaulotterys.com/satta/bg/Kalyan.webp',
  'Kalyan Night':
    'https://download.bhaulotterys.com/satta/bg/Kalyan_Night.webp',
  'Milan Night': 'https://download.bhaulotterys.com/satta/bg/Milan_Night.webp',
  'Main Bazar': 'https://download.bhaulotterys.com/satta/bg/Main_Bazar.webp',
  'Mumbai Main': 'https://download.bhaulotterys.com/satta/bg/Mumbai_Main.webp',
  'Bengal Bazar':
    'https://download.bhaulotterys.com/satta/bg/Bengal_Bazar.webp',
  Ratnagiri: 'https://download.bhaulotterys.com/satta/bg/Ratnagiri.webp',
  'Kalyan Morning':
    'https://download.bhaulotterys.com/satta/bg/Kalyan_Morning.webp',
  'Milan Morning':
    'https://download.bhaulotterys.com/satta/bg/Milan_Morning.webp',
  'Milan Day': 'https://download.bhaulotterys.com/satta/bg/Milan_Day.webp',
  'Delhi Darbar':
    'https://download.bhaulotterys.com/satta/bg/Delhi_Darbar.webp',
  'MP Bazar': 'https://download.bhaulotterys.com/satta/bg/MP_Bazar.webp',
  Kuber: 'https://download.bhaulotterys.com/satta/bg/Kuber.webp',
};
/** todo */
const SATTA_GAME_ICON_WEB: Record<string, string> = {
  Kalyan:
    'https://download.bhaulotterys.com/manager/8673a2b0e4824cfaae968449c359ad95.webp',
  'Kalyan Night': '',
  'Milan Night':
    'https://download.bhaulotterys.com/manager/40e9a2cca9b64264b22be1a515a5ebc6.webp',
  'Main Bazar': '',
  'Mumbai Main': '',
  'Bengal Bazar': '',
  Ratnagiri: '',
  'Kalyan Morning': '',
  'Milan Morning': '',
  'Milan Day':
    'https://download.bhaulotterys.com/manager/36f032bfe7f64007b5c0b05c5856944a.webp',
  'Delhi Darbar': '',
  'MP Bazar':
    'https://download.bhaulotterys.com/manager/1e7cda58091b49fcb6bf84bc6c38a461.webp',
  Kuber: '',
};

const DIGIT_GAME_ICON: Record<string, ImageRequireSource> = {
  'Dear Lottery': require('./digit/dear.webp'),
  'Kerala Lottery': require('./digit/kerala.webp'),
  Skywin: require('./digit/sky-win.webp'),
  Lucwin: require('./digit/luc.webp'),
  GoldenJackpot: require('./digit/golden.webp'),
  KuberLottery: require('./digit/kuber.webp'),
  Chennailotto: require('./digit/chennai.webp'),
  bhutan: require('./digit/bhutan.webp'),
  quick: require('./digit/quick.webp'),
  Manipur: require('./digit/manipur.webp'),
  Mizoram: require('./digit/mizoram.webp'),
  Andaman: require('./digit/andaman.webp'),
  Assam: require('./digit/assam.webp'),
  'Arunachal Pradesh': require('./digit/arunachal-pradesh.webp'),
  Meghalaya: require('./digit/meghalaya.webp'),
  Sikkim: require('./digit/bhutan.webp'),
};

const DIGIT_IMAGES: Record<string, string> = {
  GoldenJackpot:
    'https://download.bhaulotterys.com/manager/9b06ae85d0b345d9bbea73c158c9d483.webp',
  'Dear Lottery':
    'https://download.bhaulotterys.com/manager/0625b63f98a14fc29c2b572327a80ff7.webp',
  'Kerala Lottery':
    'https://download.bhaulotterys.com/manager/067a9c910a5c44d38250f3d42345fb0d.webp',
  Lucwin:
    'https://download.bhaulotterys.com/manager/aca5560096ad4aafbccf2771f3c50e6c.webp',
  KuberLottery:
    'https://download.bhaulotterys.com/manager/2cf8344c4fd44f7a8572804a423da766.webp',
  Chennailotto:
    'https://download.bhaulotterys.com/manager/031b6971325844628f93354605ee2fcb.webp',
  Skywin:
    'https://download.bhaulotterys.com/manager/0c47deede4c04406a5e8a494ceabc1af.webp',
  Manipur:
    'https://download.bhaulotterys.com/manager/3132253358614f4ea421fef0ab613572.webp',
  Mizoram:
    'https://download.bhaulotterys.com/manager/542c296090eb408193f63af16906556b.webp',
  Andaman:
    'https://download.bhaulotterys.com/manager/a8ce3415f15f4f8a8b45f7e70e306571.webp',
  Assam:
    'https://download.bhaulotterys.com/manager/6b8ea9b2acbf457ab9ccbf650fa1d771.webp',
  'Arunachal Pradesh':
    'https://download.bhaulotterys.com/manager/ca09d2066ddf481ab853c0b62c795464.webp',
  Meghalaya:
    'https://download.bhaulotterys.com/manager/938dd63ec2e2467498d542d2ce3ddcab.webp',
  Sikkim:
    'https://download.bhaulotterys.com/manager/002a45d7735b423a84425054658bd3e2.webp',
};

const KERALA_IMAGES: Record<string, string> = {
  AKSHAYA:
    'https://download.bhaulotterys.com/manager/92e6aa89acc04a4e99c9d3f4395079f2.webp',
  'STHREE-SAKTHI':
    'https://download.bhaulotterys.com/manager/c3bc2a3505b64806ab630e2631434f08.webp',
  KARUNYA:
    'https://download.bhaulotterys.com/manager/de74fa1b3f424d23979d6f51f8afdf74.webp',
  NIRMAL:
    'https://download.bhaulotterys.com/manager/b23fd8ba499b43709cccaf4af51939b7.webp',
  'KARUNYA PLUS':
    'https://download.bhaulotterys.com/manager/a44c5bbdbd9b4c5a9696839eb07d612d.webp',
  'FIFTY-FIFTY':
    'https://download.bhaulotterys.com/manager/6c6d4ce2e79f437da2a1d2f72d2ff5f1.webp',
  'WIN-WIN':
    'https://download.bhaulotterys.com/manager/c44264ca536849b1beb4f078d19f0d84.webp',
  'ONLINE-ONLY':
    'https://download.bhaulotterys.com/manager/c3e0a9729b104b98a3065dfbddfe7806.webp',
};

const KERALA_IMAGES_LOGO: Record<string, string> = {
  AKSHAYA: 'https://download.bhaulotterys.com/kerala/order/akshaya.webp',
  'STHREE-SAKTHI': 'https://download.bhaulotterys.com/kerala/order/sthree.webp',
  KARUNYA: 'https://download.bhaulotterys.com/kerala/order/karunya.webp',
  NIRMAL: 'https://download.bhaulotterys.com/kerala/order/nirmal.webp',
  'KARUNYA PLUS':
    'https://download.bhaulotterys.com/kerala/order/karunya_plus.webp',
  'FIFTY-FIFTY': 'https://download.bhaulotterys.com/kerala/order/fifty.webp',
  'WIN-WIN': 'https://download.bhaulotterys.com/kerala/order/win.webp',
  'ONLINE-ONLY': 'https://download.bhaulotterys.com/kerala/order/online.webp',
};

export {
  DICE_GAME_ICON,
  COLOR_GAME_ICON,
  KERALA_GAME_ICON,
  SATTA_GAME_ICON,
  SATTA_GAME_BG,
  SATTA_GAME_ICON_WEB,
  DIGIT_GAME_ICON,
  KERALA_IMAGES,
  DIGIT_IMAGES,
  KERALA_IMAGES_LOGO,
};
