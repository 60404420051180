import theme from '@/style';
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import {BasicObject} from '@/types';
import LinearGradient from '@/components/basic/linear-gradient';
export interface ResultItemProps {
  index?: number;
  issueNum?: string;
  digit?: string;
  color?: string;
}

const COLOR_MAP = {
  r: '#D80000',
  g: '#00B912',
  // 'g,v': ['#00B912', '#8800DB'],
  'g,v': '#8800DB',
  // 'r,v': ['#D80000', '#8800DB'],
  'r,v': '#8800DB',
} as BasicObject;

const ResultItem = ({
  index = 0,
  issueNum,
  digit,
  color = 'r',
}: ResultItemProps) => {
  const backgroundColor = React.useMemo(() => {
    return {
      backgroundColor: index % 2 === 0 ? theme.basicColor.white : '#F8F9FF',
    };
  }, [index]);

  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.centerByCol,
        styles.itemContainer,
        theme.margin.lrl,
        backgroundColor,
      ]}>
      <View style={[theme.flex.flex1]}>
        <Text size="medium">{issueNum}</Text>
      </View>
      <View style={{width: theme.iconSize.m * 4}}>
        <Text size="medium" textAlign="center" color={COLOR_MAP[color]}>
          {digit}
        </Text>
      </View>
      <View style={[theme.flex.flex1, theme.flex.alignEnd]}>
        <LinearGradient
          colors={
            Array.isArray(COLOR_MAP[color])
              ? COLOR_MAP[color]
              : [COLOR_MAP[color], COLOR_MAP[color]]
          }
          style={[styles.circle]}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    height: 48,
    paddingHorizontal: 12,
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: 8,
  },
});

export default ResultItem;
