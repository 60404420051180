import React from 'react';
import WinnerItem from './winner-item';
import theme from '@/style';
import NoData from '@/components/basic/error-pages/no-data';
import Page from '@/components/business/games/page';
import {WinnerResponse, getWinners} from '../../color-service';
import Spin from '@/components/basic/spin';

const pageSize = 10;

const Winner = ({}: {configId?: number}) => {
  const [list, setList] = React.useState<WinnerResponse[]>([]);
  const totalList = React.useRef<WinnerResponse[]>([]);
  const [total, setTotal] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try {
      const res = await getWinners();
      if (res) {
        totalList.current = res;
        setList(res.slice(0, 10));
        setTotal(res.length);
      }
    } finally {
      setFirstLoad(false);
      setLoading(false);
    }
  };

  const getListByPage = (pageNumber: number = 1) => {
    if (totalList.current.length > 10) {
      const current = (pageNumber - 1) * 10;
      const next = pageNumber * 10;
      setList(totalList.current.slice(current, next));
      setPage(pageNumber);
    }
  };

  return (
    <Spin loading={firstLoad || loading} style={[theme.flex.flex1]}>
      {!firstLoad && !loading && list && list.length === 0 ? <NoData /> : null}
      {list.map((item, index) => (
        <WinnerItem key={index} page={page} index={index} {...item} />
      ))}
      {total > 0 ? (
        <Page
          total={total}
          pageSize={pageSize}
          onPageChange={p => {
            getListByPage(p);
          }}
        />
      ) : null}
    </Spin>
  );
};

export default React.memo(Winner);
