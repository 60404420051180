import i18n from '@/i18n';

export const positionIcon = require('@assets/icons/wallet/position.webp');
export const rechargeIcon = require('@components/assets/icons/pig.webp');
export const transferIcon = require('@assets/icons/wallet/transfer.webp');
export const transformIcon = require('@assets/icons/wallet/transform.webp');
export const walletIcon = require('@assets/icons/wallet/wallet.webp');
export const withdrawIcon = require('@assets/icons/wallet/withdraw.webp');
export const walletBackground = require('@assets/imgs/wallet-bg.webp');
export const notYetWarning: {type: 'warning' | 'success'; message: string} = {
  type: 'warning',
  message: i18n.t('warning.unopen'),
};
