import React from 'react';
import {View} from 'react-native';
import Text from '@basicComponents/text';
import theme from '@/style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {goTo} from '@/utils';
import {vipIcon} from '../luckyspin.style';
import LazyImage from '@/components/basic/image';
import {useTranslation} from 'react-i18next';

export interface BottomInfoProps {
  login?: boolean;
  freeCount: number;
  onClose?: () => void;
}

const BottomInfo: React.FC<BottomInfoProps> = ({freeCount, onClose, login}) => {
  const {i18n} = useTranslation();
  const toVip = () => {
    if (!login) {
      onClose?.();
      goTo('Login');
      return;
    }
    goTo('Vip');
    onClose?.();
  };
  return (
    <View
      style={[
        theme.flex.row,
        theme.fill.fillW,
        theme.flex.between,
        theme.padding.lrl,
        theme.padding.topxxs,
      ]}>
      <View style={[theme.flex.row]}>
        <Text fontSize={theme.fontSize.s} color={'#FFED4D'}>
          {i18n.t('luckyspin.myfree')}
        </Text>
        <Text
          fontSize={theme.fontSize.s}
          blod
          color={'#FFED4D'}
          style={[theme.margin.leftxxs]}>
          {freeCount}
        </Text>
      </View>
      <View style={[theme.flex.row]}>
        <Text fontSize={theme.fontSize.s} color={'#FFED4D'}>
          {i18n.t('luckyspin.morefree')}
        </Text>
        <NativeTouchableOpacity onPress={toVip} style={[theme.margin.leftxxs]}>
          <LazyImage
            occupancy="#0000"
            imageUrl={vipIcon}
            width={34}
            height={18}
          />
        </NativeTouchableOpacity>
      </View>
    </View>
  );
};

export default BottomInfo;
