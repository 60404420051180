import React from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import {useResponsiveDimensions} from '@/utils';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Won from '../won/won';
import {LEVELS} from '@/common-pages/bets-detail/components/constant';
import LazyImage from '@/components/basic/image/lazy-image';
export interface KeralaResultProps {
  list: {
    isWin: number;
    lotteryPrice: number;
    wonCode: string;
    level?: string;
    wonAmount?: number;
  }[];
}

const KeralaResult = ({list = []}: KeralaResultProps) => {
  const [showMore, setShowMore] = React.useState(false);

  const targetList = React.useMemo(() => {
    if (list.length > 8 && showMore) {
      return list;
    }
    return list.slice(0, 8);
  }, [showMore, list]);

  const winList = React.useMemo(() => {
    return list.filter(item => item.isWin > 0);
  }, [list]);

  const {width} = useResponsiveDimensions();

  return (
    <View style={[theme.margin.tops]}>
      <View
        style={[
          {gap: theme.paddingSize.s},
          winList.length > 0 && theme.margin.btms,
        ]}>
        {winList.map((item, _i) => (
          <Won
            key={_i}
            awardAmount={item.wonAmount ?? 0}
            result={
              <View style={[theme.flex.row, theme.flex.centerByCol]}>
                {item.level && (
                  <LazyImage
                    occupancy="transparent"
                    width={32}
                    height={32}
                    imageUrl={LEVELS[item.level]}
                  />
                )}
                <Text
                  color={theme.basicColor.primary}
                  fontFamily="fontInterBold"
                  size="medium">
                  {item.wonCode}
                </Text>
              </View>
            }
            isBet={false}
          />
        ))}
      </View>
      <View
        // eslint-disable-next-line react-native/no-inline-styles
        style={[{gap: theme.paddingSize.s, flexWrap: 'wrap'}, theme.flex.row]}>
        {targetList.map((item, _i) => {
          const winColor = item.isWin ? '#F15802' : '#31373D';
          return _i === 8 && !showMore ? (
            <NativeTouchableOpacity
              onPress={() => setShowMore(true)}
              key={_i}
              style={[
                {width: (width - 48 - 16) / 3},
                styles.item,
                styles.normal,
              ]}>
              <Text fontFamily="fontInterBold">View All</Text>
            </NativeTouchableOpacity>
          ) : (
            <View
              key={_i}
              style={[
                {width: (width - 48 - 16) / 3},
                styles.item,
                item.isWin > 0 && styles.winItem,
              ]}>
              <Text color={winColor} fontFamily="fontInterBold">
                {item.wonCode}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    height: 36,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F6F7FB',
  },
  winItem: {
    borderWidth: 1,
    borderColor: '#F15802',
    backgroundColor: '#fff',
  },
  normal: {
    borderWidth: 1,
    borderColor: '#9FA5AC',
    backgroundColor: '#fff',
  },
});

export default KeralaResult;
