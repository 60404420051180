import LazyImage from '@/components/basic/image';
import React, {FC, useEffect} from 'react';

import {
  boxReachedIcon,
  boxReceivedIcon,
  boxUnReachIcon,
} from '@/common-pages/vip/vip.variable';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated';

export interface VipReceivingBoxProps {
  statusReached: number;
  rewardReceivingStatus: number;
}

const VipReceivingBox: FC<VipReceivingBoxProps> = ({
  statusReached,
  rewardReceivingStatus,
}) => {
  const rotate = useSharedValue(0);
  const style = useAnimatedStyle(
    () => ({
      transform: [
        {
          rotate:
            statusReached === 1 && rewardReceivingStatus !== 0
              ? `${rotate.value}deg`
              : '0deg',
        },
      ],
    }),
    [rotate, statusReached, rewardReceivingStatus],
  );

  useEffect(() => {
    if (statusReached === 1 && rewardReceivingStatus !== 0) {
      rotate.value = withRepeat(
        withSequence(
          withTiming(10, {duration: 100}),
          withTiming(-10, {duration: 200}),
          withTiming(0, {duration: 100}),
          withTiming(0, {duration: 1600}),
        ),
        -1,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusReached, rewardReceivingStatus]);

  return (
    <Animated.View style={style}>
      <LazyImage
        occupancy="#0000"
        imageUrl={
          statusReached === 1
            ? rewardReceivingStatus === 0
              ? boxReceivedIcon
              : boxReachedIcon
            : boxUnReachIcon
        }
        width={40}
        height={40}
      />
    </Animated.View>
  );
};

export default VipReceivingBox;
