import {View, Image} from 'react-native';
import Ball from './ball';
import React from 'react';
import theme from '@/style';
import DiceItem from './dice-item';
import {DICES} from '../constant';

export interface BetsEleProps {
  type?: string;
  digit?: number;
  digitType?: string;
}

const BetsEle = ({
  type = 'sum',
  digit = 3,
  digitType = 'even',
}: BetsEleProps) => {
  const Ele = React.useMemo(() => {
    switch (type) {
      case 'sum':
        return (
          <Ball
            ballSize={64}
            isNum={digit > 0}
            num={digit}
            fontSize={32}
            type={digitType}
          />
        );
      case 'double':
        return (
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            <DiceItem diceSize={30} diceNum={digit} />
            <DiceItem diceSize={30} diceNum={digit} />
          </View>
        );
      case 'single':
        return <DiceItem diceSize={64} diceNum={digit} />;
      case 'leopard':
        return (
          <View style={[theme.flex.centerByCol]}>
            {digitType === 'any' ? (
              <Image
                source={DICES.any}
                style={{
                  width: theme.paddingSize.m * 3,
                  height: theme.paddingSize.m * 3,
                }}
              />
            ) : (
              <DiceItem diceSize={30} diceNum={digit} />
            )}
            <View style={[theme.flex.row, theme.flex.centerByCol]}>
              {digitType === 'any' ? (
                <Image
                  source={DICES.any}
                  style={{
                    width: theme.paddingSize.m * 3,
                    height: theme.paddingSize.m * 3,
                  }}
                />
              ) : (
                <DiceItem diceSize={30} diceNum={digit} />
              )}
              {digitType === 'any' ? (
                <Image
                  source={DICES.any}
                  style={{
                    width: theme.paddingSize.m * 3,
                    height: theme.paddingSize.m * 3,
                  }}
                />
              ) : (
                <DiceItem diceSize={30} diceNum={digit} />
              )}
            </View>
          </View>
        );
    }
  }, [digit, digitType, type]);

  return <View>{Ele}</View>;
};

export default BetsEle;
