import theme from '@/style';
import {View} from 'react-native';
import DigitBall, {DigitBallProps} from './digit-ball';
import NumberInput from './number-input';
import React from 'react';
import BetsButton from './bets-btn';
import NumberStepper from './number-stepper';
import {useTranslation} from 'react-i18next';

export interface BetsItemProps {
  guessNum?: string;
  extend?: number;
  onAdd?: (
    type: string,
    value: string,
    count: number,
    startElement?: View | null,
  ) => void;
}

const BetsSingleItem = (props: BetsItemProps & DigitBallProps) => {
  const {
    digit = '*',
    color = 'A',
    onAdd = () => {},
    guessNum = '',
    extend = 0,
  } = props;
  const [value, setValue] = React.useState('');
  const [rate, setRate] = React.useState<string>('');
  const [status, setStatus] = React.useState(false);

  const startElement = React.useRef<View>(null);

  React.useEffect(() => {
    setValue(guessNum);
  }, [extend, guessNum]);

  React.useEffect(() => {
    if (value) {
      setRate('3');
    }
  }, [value]);

  React.useEffect(() => {
    if (+rate <= 0 && !status) {
      setValue('');
    }
  }, [rate, status]);

  const {i18n} = useTranslation();

  return (
    <View style={[theme.margin.topl, theme.flex.row, theme.flex.between]}>
      <View style={[theme.flex.row, {gap: theme.paddingSize.s}]}>
        <View ref={startElement} collapsable={false}>
          <DigitBall digit={digit} color={color} fontSize={16} ballSize={32} />
        </View>
        <NumberInput value={value} onChange={setValue} inputStatus={status} />
      </View>
      <View style={[theme.flex.row, {gap: theme.paddingSize.l}]}>
        {(!!value || status) && (
          <NumberStepper
            onFocusStateChange={setStatus}
            rate={rate}
            onChangeRate={e => setRate(e)}
            onStepChange={e => setRate(`${e}`)}
          />
        )}
        <BetsButton
          onPress={() => {
            onAdd(color, value, Number(rate), startElement.current);
            setTimeout(() => {
              setStatus(false);
              setValue('');
            });
          }}
          disabled={!value}
          title={i18n.t('game-page.label.add').toUpperCase()}
          style={[
            theme.padding.lrl,
            theme.borderRadius.xs,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              height: 32,
            },
          ]}
        />
      </View>
    </View>
  );
};

export default React.memo(BetsSingleItem);
