import Spin from '@/components/basic/spin';
import theme from '@/style';
import React from 'react';
import {FlatList} from 'react-native';
import {getMatkaResultList} from '../result-service';
import {MatkaResultItem, MatkaTypeItem} from '../result.type';
import ListItem from './list-item';
import useInfiniteScroll from '@/common-pages/hooks/load-more.hooks';
import MatkaDraw from './draw';

const MatkaResult = ({
  matkaType = [],
  isActive,
  lotteryType,
}: {
  matkaType?: MatkaTypeItem[];
  isActive?: boolean;
  lotteryType?: string;
}) => {
  const [loading, setLoading] = React.useState(true);
  const [list, setList] = React.useState<MatkaResultItem[]>([]);
  const {onEndReachedCalledDuringMomentum} = useInfiniteScroll('result-matka');
  const currentPage = React.useRef(1);
  const hasMores = React.useRef(true);

  const getList = async (page = 1, loadMore = false) => {
    try {
      if (!loadMore) {
        setLoading(true);
      }
      const searchId = matkaType.find(item => item.lotteryName === lotteryType);
      const res = await getMatkaResultList(page, searchId?.matkaId || 0);
      if (res && res.length < 5) {
        hasMores.current = false;
      }
      setList(loadMore ? list?.concat(res) : res);
    } finally {
      setLoading(false);
      onEndReachedCalledDuringMomentum.current = true;
    }
  };

  React.useEffect(() => {
    if (isActive) {
      currentPage.current = 1;
      hasMores.current = true;
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryType, isActive]);

  const onLoadMore = async () => {
    if (hasMores.current && !onEndReachedCalledDuringMomentum.current) {
      currentPage.current += 1;
      await getList(currentPage.current, true);
    }
  };

  return (
    <Spin loading={loading} style={[theme.flex.flex1]}>
      <FlatList
        ListHeaderComponent={!loading && isActive ? <MatkaDraw /> : null}
        id="result-matka"
        scrollEventThrottle={16}
        onEndReachedThreshold={0.1}
        onMomentumScrollBegin={() => {
          onEndReachedCalledDuringMomentum.current = false;
        }}
        onEndReached={onLoadMore}
        contentContainerStyle={[theme.padding.l, {gap: theme.paddingSize.l}]}
        keyExtractor={item => item.issueNo}
        data={list}
        renderItem={({item}) => <ListItem {...item} />}
      />
    </Spin>
  );
};

export default MatkaResult;
