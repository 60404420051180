/* eslint-disable prettier/prettier */
export default {
  label: {
    ok: 'ശരി',
    confirm: 'കൺഫോം',
    cancel: 'റദ്ദാക്കുക',
    download: 'ഡൗൺലോഡ്',
    submit: 'സമർപ്പിക്കുക',
    search: 'തിരയുക',
    recharge: 'റീചാർജ്',
    balance: 'ബാലൻസ്',
  },
  tip: {
    success: 'വിജയകരമായി പൂർത്തികരിച്ചു',
  },
  text: {},
  defaultPage: {
    noMore: 'കൂടുതൽ ഡാറ്റ ഇല്ല',
  },
  home: {
    scratchOff: 'സ്ക്രാച്ച് ഓഫ്',
    copied: 'പകർത്തുക',
    tip: {
      beforDownload: 'ദയവായി രജിസ്റ്റർ ചെയ്യുക അല്ലെങ്കിൽ ലോഗിൻ ചെയ്യുക',
    },
  },
  alert: {
    logout: 'ലോഗ് ഔട്ട്',
    sureLogout: 'നിങ്ങൾക് ലോഗ് ഔട്ട് ചെയ്യണമെന്ന് തീർച്ചയാണോ?',
  },
  warning: {
    unopen: 'ഇതുവരെ പ്രവർത്തനക്ഷമമാക്കിയിട്ടില്ല',
  },
  notification: {
    title: 'തലക്കെട്ട്',
    announcements: 'പ്രഖ്യാപനങ്ങൾ',
    systemMessage: 'സിസ്റ്റം സന്ദേശങ്ങൾ',
  },
  vip: {
    currentLevel: 'ഇപ്പോഴത്തെ അവസ്ഥ',
    collected: 'ശേഖരിയ്ക്കുക',
    finished: 'പൂർത്തിയാക്കുക',
    received: 'ലഭിച്ചു',
    recharge: 'റീചാർജ്',
    move: 'കൂടുതൽ ലെവലിൽ എത്താൻ',
    title: 'വിഐപി',
    table: {
      level: 'വിഐപി ലെവൽ',
      reward: 'പ്രതിഫലം',
      bouns: 'ബോണസ്',
      spin: 'സ്പിൻ',
    },
    success: 'വിജയകരമായി',
  },
  referral: {
    copy: 'പകർത്തുക',
    invite: 'ക്ഷണിക്കുക',
    copied: 'വിജയകരമായി',
  },
  luckyspin: {
    add: 'ആഡ് ചെയ്യുക',
    spin: 'സ്പിൻ',
    won: 'ജയിച്ചു',
    winner: 'വലിയ വിജയികൾ',
    mySpin: 'എൻ്റെ സ്പിൻ',
    free: 'സൗജന്യം',
    myfree: 'എന്റെ സൗജന്യ സ്പിൻ',
    morefree: 'കൂടുതൽ സൗജന്യം',
    vip: 'വിഐപി',
    usefreefirst: 'ദയവായി ഒഴിവു സമയം ആദ്യം ഉപയോഗിക്കുക',
  },
  'error-pages': {
    refresh: {
      label: {
        title: 'പിശക്!',
        'sub-title': 'ദയവായി നിങ്ങളുടെ പേജ് പുതുക്കുക',
        'try-again': 'വീണ്ടും ശ്രമിക്കുക',
      },
    },
    'no-data': {
      label: {
        title: 'ഡാറ്റ ലഭ്യമല്ല',
      },
    },
    'not-found': {
      label: {
        title: 'നിലവിലെ നെറ്റ്‌വർക്ക് സിഗ്നൽ മോശമാണ്',
        'sub-title': 'ദയവായി നിങ്ങളുടെ നെറ്റ്‌വർക്ക് പരിശോധിക്കുക',
      },
    },
    'no-result': {
      label: {
        title: 'തിരയൽ ഫലമില്ല!',
      },
    },
  },
  share: {
    copy: 'പകർത്തുക',
    invite: 'ക്ഷണിക്കുക',
    'copy-success': 'വിജയകരമായി പകർത്തി',
    copied: 'വിജയകരമായി പകർത്തിയിരിക്കുന്നു',
  },
  casino: {
    big: 'വലുത്‌',
    win: 'വിജയിക്കുക',
    detail: {
      title: 'വിജയികൾക്ക്  അഭിനന്ദനങ്ങൾ',
    },
    winPrize: 'വിജയിച്ച സമ്മാനം',
    playnow: 'ഇപ്പോൾ കളിക്കുക',
  },
  login: {
    label: {
      login: 'ലോഗിൻ',
      skip: 'ഒഴിവാക്കുക',
      sign: 'സൈൻ അപ്പ് ചെയ്യുക',
      next: 'അടുത്തത്',
      'select-region': 'പ്രദേശം തിരഞ്ഞെടുക്കുക',
      'otp-login': 'OTP ലോഗിൻ',
      'password-login': 'പാസ്‌വേഡ് ലോഗിൻ',
      'get-otp': 'OTP നേടുക',
      'set-password': 'പാസ്‌വേഡ് സജ്ജമാക്കുക',
    },
    tip: {
      'no-phone': 'ദയവായി ഫോൺ നമ്പർ നൽകുക',
      'no-password': 'ദയവായി പാസ്‌വേഡ് നൽകുക',
      'no-otp': 'OTP നൽകുക',
      phone: 'ഫോൺ നമ്പർ നൽകുക',
      password: 'പാസ്‌വേഡ് നൽകുക(6-18)',
      passwordRule: '8 മുതൽ 16 വരെ അക്കങ്ങളും അക്ഷരങ്ങളും അടങ്ങിയിരിക്കണം',
      'confirm-password': 'കൺഫോം പാസ്‍വേഡ്',
      forgot: 'ഫോർഗെറ്റ് പാസ്‌വേഡ് ?',
      'new-account': 'പുതിയ അക്കൗണ്ട്?',
      'sing-in': 'സൈൻ ചെയ്യുക',
      otp: 'OTP നൽകുക',
      'referral-code': 'റെഫറൽ കോഡ്',
      confrim18: 'ഞാൻ 18+ ആണെന്ന് സ്ഥിരീകരിക്കുന്നു',
      'allow-notify':
        'ഈ ഫോൺ നമ്പറിലൂടെ പ്രധാനപ്പെട്ട വിജയകരമായ വിവരങ്ങൾ നിങ്ങളെ അറിയിക്കാൻ ഞങ്ങളെ അനുവദിക്കുക.',
      'has-account': 'മുൻ ഉഭഭോക്താവാണ്?',
      'sign-in': 'ലോഗിൻ',
      page18: 'ഞാൻ 18+ ആണെന്ന് സ്ഥിരീകരിക്കുന്നു',
      'pwd-dif': 'പാസ്‌വേഡുകൾ പൊരുത്തമില്ലാത്തതാണ്',
      notify:
        'ഈ മൊബൈൽ നമ്പറിലൂടെ പ്രധാനപ്പെട്ട വിജയകരമായ വിവരങ്ങൾ നിങ്ങളെ അറിയിക്കാൻ ഞങ്ങളെ അനുവദിക്കുക.',
      modified: 'പാസ്‌വേഡ് വിജയകരമായി പൂർത്തികരിച്ചിരിക്കുന്നു . ദയവായി വീണ്ടും ലോഗിൻ ചെയ്യുക',
      'game-not-active':
        'ക്ഷമിക്കണം, ഗെയിം പരിപാലനത്തിലാണ്‌. ദയവായി വീണ്ടും ശ്രമിക്കുക',
    },
  },
  paidSuccess: {
    label: {
      title: 'പേയ്മെന്റ്റ്',
      subTitle: 'വിജയകരമായി',
      viewOrder: 'ഓർഡർ കാണുക',
      backHome: 'തിരികെ ഹോം പേജിലേക്',
    },
    tip: {
      msg: 'നിങ്ങൾ ടിക്കറ്റ് വിജയകരമായി വാങ്ങി. നറുക്കെടുപ്പ് സമയം ശ്രദ്ധിക്കുകയും ഫലങ്ങൾ ഉടനടി പരിശോധിക്കുകയും ചെയ്യുക',
    },
  },
  splash: {
    tip: {
      checkingResources: 'റിസോഴ്സ് പരിശോധിയ്ക്കുന്നു ...',
      checkingVersion: 'വേർഷൻ പരിശോധിയ്ക്കുന്നു...',
      downloading: 'റിസോഴ്സ് പരിശോധിയ്ക്കുന്നു...',
      resolve:
        'റിസോഴ്സ് പരിഹരിക്കുന്നു (ഈ പ്രക്രിയ നെറ്റ്‌വർക്ക് ഉപയോഗിക്കുന്നില്ല)',
      alertTitle: 'അപ്ഡേറ്റ് ലഭ്യമാണ്',
      restart: 'പുനരാരംഭിക്കുക',
      alertContent:
        'ഒരു അപ്ഡേറ്റ് ലഭ്യമാണ്. അപ്ഡേറ്റ്നു വേണ്ടിആപ്പ് റീസ്‌റ്റാർട്ട് ചെയ്യുന്നു...',
    },
  },
  recharge: {
    tip: {
      easyPaymentError:
        'സേവനം നേടുന്നതിൽ പരാജയപ്പെട്ടു, ദയവായി മറ്റ് പേയ്‌മെന്റ് രീതികളിലേയ്ക് മാറ്റുക',
    },
  },
  'recharge-page': {
    currentMethod: 'നിലവിലെ രീതി',
    label: {
      records: 'രേഖകൾ',
      min: 'ഏറ്റവും കുറഞ്ഞത്',
      max: 'പരമാവധി',
      channel: 'റീചാർജ് ചാനൽ',
      enter: 'തുക നൽകുക',
    },
    tip: {
      payMethodTip:
        'നിലവിലെ രീതി പരാജയപ്പെട്ടാൽ ദയവായി മറ്റൊരു രീതിയിലേക്ക് മാറുക.',
      'money-error': 'ശരിയായ തുക നൽകുക',
      'paymethod-error': 'പേയ്മെന്റ്-പിശക്',
    },
  },
  datePicker: {
    label: {
      select: 'തീയതി തിരഞ്ഞെടുക്കുക',
    },
  },
  rebate: {
    tabs: {
      all: 'എല്ലാം',
      finish: 'പൂർത്തിയാക്കുക',
      collect: 'ശേഖരിക്കുക',
      received: 'ലഭിച്ചു',
    },
    title: 'റിബേറ്റ്',
    filter: 'ഫിൽട്ടർ',
    undone: 'പഴയപടിയാക്കുക',
    unclaimed: 'അവകാശപ്പെടാത്തത്',
    completed: 'പൂർത്തിയാക്കി',
    unfinished: 'പൂർത്തിയാകാത്തത്',
    receive: 'സ്വീകരിക്കുക',
    received: 'ലഭിച്ചു',
    today: 'ഇന്ന്',
    yesterday: 'ഇന്നലെ',
    betting: 'കണക്കാക്കിയ ബെറ്റിങ്：',
    rebate: 'കണക്കാക്കിയ റിബേറ്റ്：',
    congratulations: 'അഭിനന്ദനങ്ങൾ！',
    'get-bonus': 'ബോണസ് നേടുക',
    rule: {
      betting: 'ബെറ്റിങ് ബോണസ്:',
      'betting-detail':
        '{{name}} ബെറ്റിങ് തുക അനുസരിച്ച് യൂസേഴ്‌സിന് ഇളവ് നൽകും.ബെറ്റിങ് എത്രയോ അത്രയും നിങ്ങൾക്ക് ലഭിക്കും.',
      activity: 'പ്രവർത്തന വിവരണം:',
      'പ്രവർത്തനം-വിശദാംശം':
        '{{name}} ബോണസ് റിബേറ്റ് പ്രവർത്തനം തുറന്നിരിക്കുന്നു. ഓരോ ബെറ്റിങ്ങിലും 1 റിബേറ്റ് ബോണസ് ലഭിക്കും. നിങ്ങൾ ജയിച്ചാലും തോറ്റാലും , ബോണസ് ലഭിക്കും.',
      actrule: 'പ്രവർത്തന നിയമങ്ങൾ:',
      'actrule-detail':
        'വാതുവെപ്പ് റിബേറ്റ് ബോണസ് ഡെയ്‌ലിബോണസ് ക്ലെയിം സമയം അടുത്ത ദിവസം 00:30 ന് ശേഷമായിരിക്കും.',
      precaution: 'Precautions:',
      'precaution-detail-1':
        '-യോഗ്യതയുള്ളവർ മാത്രം {{name}} അംഗങ്ങൾക്ക് പ്രവർത്തനങ്ങളിൽ പങ്കെടുക്കാം റിബേറ്റ് ബോണസ് ക്ലെയിം ചെയ്യുന്നതിന് ഓരോ അംഗവും സിങ്കം അക്കൗണ്ടിലെ അക്കൗണ്ട് വിശദാംശങ്ങൾ പൂർത്തിയാക്കണം.- നിയമവിരുദ്ധ പ്രവർത്തനങ്ങളിൽ ഏർപ്പെട്ടിരിക്കുന്ന ഏതൊരു ഉപയോക്താവും കർശനമായി നിരോധിച്ചിരിക്കുന്നു',
      'precaution-detail-2':
        '-സെയിം നമ്പർ , ബാങ്ക് അക്കൗണ്ട് നമ്പർ. ഇമെയിൽ വിലാസവും ഐപി വിലാസവും ഒന്നിലധികം അക്കൗണ്ടുകൾക്കായി ഉപയോഗിക്കാൻ കഴിയില്ല',
      'precaution-detail-3':
        '-{{name}}ബോണസ് പ്രയോജനപ്പെടുത്തി വ്യക്തികളോ ഗ്രൂപ്പുകളോ നടത്തുന്ന നിയമവിരുദ്ധ/വഞ്ചനാപരമായ പ്രവർത്തനങ്ങൾ കണ്ടെത്തിയാൽ അക്കൗണ്ടുകൾ മരവിപ്പിക്കാനോ പേയ്‌മെന്റുകൾ റദ്ദാക്കാനോ അവകാശമുണ്ട്., (അന്തിമ ബോണസ് തീരുമാനം സിങ്കത്തിന്റ്റ്താണ്）',
    },
  },
  wallet: {
    title: 'എൻ്റെ വാലറ്റ്',
    total: 'ആകെ വാലറ്റ്',
    recharge: 'റീചാർജ്',
    main: 'പ്രധാന വാലറ്റ്',
    third: 'മൂന്നാം കക്ഷി വാലറ്റ്',
    transfer: 'പ്രധാന വാലറ്റ് കൈമാറ്റം',
    translation: 'വിവർത്തനം：{{second}}s',
    transComplete: 'പരിവർത്തനം പൂർത്തിയായി',
    transferNav: {
      recharge: 'റീചാർജ്',
      withdraw: 'പിൻവലിക്കുക',
      transfer: 'കൈമാറ്റം',
    },
  },
  promotion: {
    title: 'പ്രമോഷനുകൾ',
    go: 'പോകൂ',
    playNow: 'ഇപ്പോൾ കളിക്കുക',
    view: 'കാണുക',
    detail: 'പ്രവർത്തന വിശദാംശങ്ങൾ',
    join: 'ചേരുക',
    checkin: 'ചെക്ക് - ഇൻ ചെയ്യുക',
    checkinDescription: 'ധാരാളം റിവാർഡുകൾ ലഭിക്കാൻ ദിവസവും ചെക്ക് ഇൻ ചെയ്യുക',
  },
  satta: {
    title: {
      betting: 'ബെറ്റിങ്',
      rule: 'നിയമം',
      history: 'റിസൾട്ട് ഹിസ്റ്റോറി',
      order: 'എൻ്റെ ഓർഡർ',
      panel: 'പാനൽ:',
      open: 'തുറക്കുക',
      close: 'അടയ്ക്കുക',
    },
    betting: {
      ank: 'അങ്ക്',
      jodi: 'ജോഡി',
      sp: 'SP',
      dp: 'DP',
      tp: 'TP',
      half: 'പകുതി സംഗമം',
      full: 'പൂർണ്ണ സംഗമം',
      exwinning: 'പ്രതീക്ഷിച്ച വിജയം:',
      open: 'തുറക്കുക',
      close: 'അടയ്ക്കുക',
      paynow: 'പണം അടയ്ക്കുക',
      add: 'ചേർക്കുക',
      mybids: 'എൻ്റെ ബെറ്റ് ',
      bids: 'ബെറ്റുകൾ',
      tip: {
        enter: {
          amount: 'തുക ആഡ് ചെയ്യുക',
          open: 'ഓപ്പൺ ഡിജിറ്റ് നൽകുക',
          opens: 'അക്കം തുറക്കുന്നു',
          close: 'അടുത്ത അക്കം',
          closes: 'അക്കം അടയ്ക്കുന്നു',
          jodi: 'ജോഡി ഡിജിറ്റ്  നൽകുക',
          openpanna: 'പന്ന തുറക്കുക',
          closepanna: 'പന്ന അടയ്ക്കുക',
        },
        require: {
          amount: 'തുക അടയ്ക്കുക',
          open: 'ഡിജിറ്റ് തുറക്കുക',
          opens: 'ഓപ്പൺ  ഡിജിറ്റ് നൽകുക',
          close: ' ക്ലോസ് ഡിജിറ്റ് നൽകുക',
          closes: 'ഡിജിറ്റ് ക്ലോസ് ചെയ്യുക',
          jodi: 'ജോഡി ഡിജിറ്റ് ആഡ് ചെയ്യുക',
          openpanna: 'പന്ന  തുറക്കുക ആഡ് ചെയ്യുക',
          closepanna: 'പന്ന അടയ്ക്കുക ആഡ് ചെയ്യുക',
        },
        check: 'പൂർത്തിയാക്കിയ അക്കങ്ങൾ നൽകുക',
        minAmount: 'ഏറ്റവും കുറഞ്ഞ ശമ്പള തുക{{തുക}}',
      },
      digitTitle: {
        ank: 'സിംഗിൾ ഡിജിറ്റ്:',
        jodi: 'ജോഡി ഡിജിറ്റ്:',
        sp: 'സിംഗിൾ പത്തി:',
        dp: 'ഡബിൾ പത്തി:',
        tp: 'ട്രിപ്പിൾ പത്തി :',
        half: 'HS:',
        full: 'FS:',
      },
      describe: {
        ank: 'തുറക്കുക അല്ലെങ്കിൽ അടയ്ക്കുക ക്ലിക്കുചെയ്യുക, ഇൻപുട്ട് ബോക്സിൽ നിങ്ങൾക്ക് ആവശ്യമുള്ള ഒരു അക്കം നൽകുക, ഉദാഹരണത്തിന് 1',
        jodi: 'ഇൻപുട്ട് ബോക്സിൽ നിങ്ങൾ വാതുവെയ്ക്കാൻ ആഗ്രഹിക്കുന്ന രണ്ട് അക്ക നമ്പർ നൽകുക, ഉദാഹരണത്തിന് 12',
        sp: 'തുറക്കുക അല്ലെങ്കിൽ അടയ്ക്കുക ക്ലിക്കുചെയ്യുക, തുടർന്ന് ഇൻപുട്ട് ബോക്സിൽ 123 പോലെയുള്ള മൂന്ന് നമ്പറുകൾ നൽകുക.',
        dp: 'തുറക്കുക അല്ലെങ്കിൽ അടയ്ക്കുക ക്ലിക്കുചെയ്യുക, ഇൻപുട്ട് ബോക്സിൽ നിങ്ങൾ വാതുവെക്കാൻ ആഗ്രഹിക്കുന്ന മൂന്ന് നമ്പറുകൾ നൽകുക, 112 പോലുള്ള രണ്ട് സമാന നമ്പറുകൾ ഉണ്ടായിരിക്കണം',
        tp: 'തുറക്കുക അല്ലെങ്കിൽ അടയ്ക്കുക ക്ലിക്കുചെയ്യുക, 111 പോലെയുള്ള മൂന്ന് സമാന നമ്പറുകൾ നൽകുക',
        half: 'തുറക്കുക അല്ലെങ്കിൽ അടയ്ക്കുക ക്ലിക്കുചെയ്യുക, ഇൻപുട്ട് ബോക്സിൽ 123, 1,1, 123 എന്നിങ്ങനെ മൂന്നോ ഒന്നോ നമ്പർ നൽകുക',
        full: 'രണ്ട് ഇൻപുട്ട് ബോക്സുകളിൽ 123, 123 എന്നിങ്ങനെ മൂന്ന് അക്കങ്ങൾ നൽകുക',
      },
    },
  },
};
