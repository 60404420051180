import theme from '@/style';
import React from 'react';
import {View} from 'react-native';
import {SINGLE} from '../../constant';
import DiceContainer from '../../component/dice-container';
import DiceItem from '../../component/dice-item';
import Text from '@/components/basic/text';
import BetsTabHeader from '../../component/bets-tab-header';
import {DiceConfigInfo} from '../../dice-service';
import {useTranslation} from 'react-i18next';

const GAP = 8;
const prefix = 'double_';
const DoubleDice = ({
  diceConf = {
    ruleLinkUrl: '',
    videoLinkUrl: '',
    cycle: 0,
    id: 0,
    minRemainingSecond: 0,
    optionOdds: undefined,
  },
  onSelect = () => {},
  onTip = () => {},
  disabled = false,
}: {
  diceConf?: DiceConfigInfo;
  onSelect?: (code: string, value: string | number) => void;
  onTip?: () => void;
  disabled?: boolean;
}) => {
  const {i18n} = useTranslation();
  return (
    <>
      <BetsTabHeader tip={i18n.t('game-page.tip.double-odd')} onTip={onTip} />
      <View
        style={[
          theme.padding.lrl,
          theme.flex.row,
          theme.flex.wrap,
          {gap: GAP},
        ]}>
        {SINGLE.map((item, _i) => (
          <DiceContainer
            disabled={disabled}
            itemNumber={3}
            key={_i}
            onPress={() => onSelect(`${prefix}${item}`, _i + 1)}>
            <View style={[theme.flex.row, theme.flex.centerByCol]}>
              <DiceItem disabled={disabled} diceSize={36} diceNum={_i + 1} />
              <DiceItem disabled={disabled} diceSize={36} diceNum={_i + 1} />
            </View>
            <Text style={[theme.margin.topxxs]}>
              {' '}
              {diceConf.optionOdds
                ? diceConf.optionOdds[`${prefix}${item}`]
                : '0'}
              X
            </Text>
          </DiceContainer>
        ))}
      </View>
    </>
  );
};

export default DoubleDice;
