import React from 'react';
import TabHeader from '@/components/business/games/tab-header';

const DiceTab = ({
  orderNum = 0,
  onOrderActive = () => {},
  index,
  setIndex,
}: {
  configId?: number;
  orderNum?: number;
  refreshCode?: number;
  onOrderActive?: (active: boolean) => void;
  index?: number;
  setIndex?: (v: number) => void;
}) => {
  const tablist = [
    {
      title: 'result',
      key: 'result',
    },
    {
      title: 'winners',
      key: 'winners',
    },
    {
      title: 'analyze',
      key: 'analyze',
    },
    {
      title: 'order',
      key: 'order',
    },
  ];

  React.useEffect(() => {
    onOrderActive(index === 3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  return (
    <TabHeader
      routes={tablist}
      onTabPress={setIndex}
      index={index || 0}
      orderKey="order"
      orderNum={orderNum}
    />
  );
};

export default React.memo(DiceTab);
