import NoticeBar from '@/components/business/games/notice';
import NoticeSvg from '@/components/svgs/basic/notice';
import theme from '@/style';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet} from 'react-native';

const NoticeMessage = () => {
  const {i18n} = useTranslation();
  return (
    <View style={[styles.container, theme.flex.row, theme.flex.centerByCol]}>
      {/*  */}
      <View style={[theme.fill.fillH, theme.flex.centerByRow, theme.margin.l]}>
        <NoticeBar text={i18n.t('game-page.notice.available')} />
      </View>
      <View
        style={[
          theme.position.abs,
          // eslint-disable-next-line react-native/no-inline-styles
          {backgroundColor: '#FFE5A3'},
          theme.padding.lrxs,
        ]}>
        <NoticeSvg />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 40,
    backgroundColor: '#FFE5A3',
  },
});

export default NoticeMessage;
