import {View, Image, StyleSheet} from 'react-native';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import Text from '@/components/basic/text';
import React from 'react';
import {downIcon} from '@/common-pages/rebate/rebate.variables';
import theme from '@/style';
import {useTranslation} from 'react-i18next';

const MoreBtn = ({onPress}: {onPress?: () => void}) => {
  const {i18n} = useTranslation();
  return (
    <View style={[theme.background.white, theme.padding.l]}>
      <NativeTouchableOpacity
        onPress={() => onPress && onPress()}
        style={[
          theme.flex.row,
          theme.flex.center,
          theme.background.white,
          theme.borderRadius.m,
          theme.padding.l,
          styles.btn,
        ]}>
        <Text fontFamily="fontInterBold">
          {i18n.t('game-page.label.more').toUpperCase()}
        </Text>
        <Image style={styles.iconSize} source={downIcon} />
      </NativeTouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  iconSize: {
    width: 12,
    height: 12,
    marginLeft: 10,
  },
  btn: {
    elevation: 2,
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowColor: '#000',
    shadowRadius: 10,
  },
});

export default MoreBtn;
