import {downIcon} from '@/common-pages/rebate/rebate.variables';
import i18n from '@/i18n';
import theme from '@/style';
import {View, Image, StyleSheet} from 'react-native';
import Price from '../price';
import React from 'react';
import Text from '@/components/basic/text';

const OrderListItemStatus = ({
  showMore = false,
  hasDetail = false,
  status,
  awardAmount = 0,
}: {
  showMore?: boolean;
  hasDetail?: boolean;
  status?: number;
  awardAmount?: number;
}) => {
  const arrowStyle = React.useMemo(() => {
    return {
      transform: [
        {
          rotate: showMore ? '0deg' : '-90deg',
        },
      ],
    };
  }, [showMore]);
  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.centerByCol,
        theme.flex.flex1,
        theme.flex.between,
      ]}>
      <View style={[theme.flex.flex1]}>
        <View style={[theme.flex.flex1]}>
          <Text numberOfLines={1} textAlign="right">
            {i18n.t(
              status === 0
                ? 'bets-detail.label.noWin'
                : status === 1
                ? 'bets-detail.label.won'
                : status === 2
                ? 'bets-detail.label.noDraw'
                : 'bets-detail.label.settling',
            )}
          </Text>
        </View>

        {status !== 2 && status !== 3 ? (
          <View style={[theme.flex.alignEnd]}>
            <Price
              price={awardAmount}
              textProps={{
                color:
                  status === 1 && awardAmount > 0
                    ? theme.backgroundColor.orange
                    : theme.basicColor.dark,
                size: 'medium',
                textAlign: 'right',
              }}
            />
          </View>
        ) : (
          <Text size="medium" textAlign="right">
            ...
          </Text>
        )}
      </View>
      {hasDetail && (
        <View style={[arrowStyle, theme.margin.leftxxs]}>
          <Image style={styles.iconSize} source={downIcon} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  iconSize: {
    width: 14,
    height: 14,
  },
});

export default React.memo(OrderListItemStatus);
