import theme from '@/style';
import {SVGProps} from '@/types';
import React from 'react';

const SportsSvg = ({color, width, height}: SVGProps) => (
  <svg
    width={width || theme.iconSize.l}
    height={height || theme.iconSize.l}
    viewBox="0 0 24 24"
    fill={color || theme.basicColor.primary}
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.24987 15.87C9.24737 16.3575 10.3424 16.6275 11.4749 16.6575L13.3724 14.4825C13.3949 13.2 13.0949 11.9325 12.5099 10.8L8.75237 9.92251C7.97237 10.71 7.36486 11.655 6.97486 12.6975L8.24236 15.8775L8.24987 15.87ZM8.05486 16.485L6.78736 17.5425C8.15986 18.7875 9.97487 19.545 11.9774 19.545C12.3599 19.545 12.7349 19.515 13.1024 19.4625L11.4749 17.295C10.2749 17.2725 9.11236 16.9875 8.05486 16.485ZM4.27486 12.315H6.44236C6.87736 11.2125 7.53737 10.215 8.38487 9.38251V7.04251C7.63487 6.86251 6.85487 6.79501 6.08237 6.84001C4.94237 8.18251 4.25987 9.92251 4.25987 11.82C4.25987 11.985 4.25986 12.1425 4.27486 12.3075V12.315ZM17.1599 14.34C17.8274 13.6575 18.3674 12.855 18.7424 11.9775L17.7149 8.48251C16.8449 8.04002 15.8999 7.77001 14.9174 7.68001L13.1774 10.6725C13.7024 11.745 13.9874 12.915 14.0174 14.1075L17.1524 14.3475L17.1599 14.34ZM17.5349 14.88V17.1825C18.7574 15.915 19.5449 14.235 19.6724 12.3675L19.3049 12.3C18.8849 13.26 18.2774 14.1375 17.5274 14.8725L17.5349 14.88ZM17.9774 6.98251C16.8449 5.58001 15.2324 4.58251 13.3874 4.24501C13.1249 4.55251 12.8924 4.87501 12.6824 5.21251L14.7899 7.02751C15.8324 7.09501 16.8374 7.35751 17.7674 7.79251L17.9774 6.98251ZM12.0449 5.02501C12.2249 4.71751 12.4199 4.42501 12.6374 4.14751C12.4199 4.13251 12.2024 4.11751 11.9774 4.11751C9.94487 4.11751 8.09236 4.90501 6.71236 6.19501C7.31986 6.20251 7.91987 6.27751 8.51237 6.42001C8.53487 6.39752 8.56487 6.38251 8.59487 6.37501L12.0449 5.03251V5.02501ZM11.9849 20.8275C7.01236 20.8275 2.98486 16.8 2.98486 11.8275C2.98486 6.85501 7.01236 2.82751 11.9849 2.82751C16.9574 2.82751 20.9849 6.85501 20.9849 11.8275C20.9849 16.8 16.9574 20.8275 11.9849 20.8275Z"
      fill={color || '#48505D'}
    />
  </svg>
);

export default SportsSvg;
