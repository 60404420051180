import {BasicObject, SafeAny} from '@types';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {View} from 'react-native';
import React, {useState, useCallback} from 'react';
import theme from '@style';
import config from '@utils/env.config';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
// import WebViewInner, {WebViewInnerRef} from './webview-inner';
import {useFocusEffect} from '@react-navigation/native';
import {useWebView} from '../hooks/webview.hooks';
import Spin from '@/components/basic/spin';

type GameType = 'kerala' | 'digit' | 'color' | 'dice' | 'matka';

const amountTypeMap: Record<GameType, string> = {
  kerala: 'Kerala',
  digit: '3Digit',
  color: 'Color',
  dice: 'Dice',
  matka: 'Matka',
};

const GameWebView = (props: SafeAny) => {
  const {i18n} = useTranslation();
  const gameMap: Record<
    GameType,
    {
      title: string;
      path: string;
      uni?: boolean;
    }
  > = {
    kerala: {
      title: i18n.t('home.kerala.title'),
      path: '/kerala',
    },
    digit: {
      title: i18n.t('home.digit.title'),
      path: '/digit',
    },
    color: {
      title: i18n.t('home.color.title'),
      path: '/color',
    },
    dice: {
      title: i18n.t('home.dice.title'),
      path: '/dice',
    },
    matka: {
      title: i18n.t('home.matka.title'),
      path: '/satta',
    },
  };
  const {route} = props;
  const type: GameType = route.params.type;
  const params = route.params.params;
  const gameOption = gameMap[type || 'color'];
  const [title, setTitle] = useState<string>(gameOption.title);
  const urlPrefix = `${config.reactH5Url}${gameOption.path}${
    params ? `?${params}` : ''
  }`;
  const [pageLoading, setPageLoading] = React.useState(true);
  // const ref = useRef<WebViewInnerRef>(null);
  const handleMessage = (data: string | BasicObject) => {
    if (data.startsWith?.('title:')) {
      // 表示更改标题
      setTitle(data.substring('title:'.length));
      return;
    }
    if (data === 'pageLoading:hide') {
      setPageLoading(false);
      return;
    }
    if (data === 'update:amount') {
      if (globalStore.token) {
        globalStore.updateAmount.next(
          amountTypeMap[type] ? {gameType: amountTypeMap[type]} : {},
        );
      }
      return;
    }
  };
  const handleUpdateAmount = useCallback(() => {
    if (globalStore.token) {
      globalStore.updateAmount.next(
        amountTypeMap[type] ? {gameType: amountTypeMap[type]} : {},
      );
    }
  }, [type]);
  useFocusEffect(handleUpdateAmount);

  const {render, goBack} = useWebView({
    urlPrefix,
    onMessage: handleMessage,
    onLoadEnd: type => {
      if (type === 'failed') {
        setPageLoading(false);
      }
    },
  });

  const handleGoBack = () => {
    if (globalStore.token) {
      globalStore.updateAmount.next(
        amountTypeMap[type] ? {gameType: amountTypeMap[type]} : {},
      );
    }
    goBack();
  };

  return (
    <View style={[theme.fill.fill, theme.flex.col]}>
      <DetailNavTitle
        title={title}
        hideServer={true}
        onBack={() => handleGoBack()}
      />
      <Spin
        loading={pageLoading}
        style={[
          theme.flex.flex1,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            position: 'relative',
            zIndex: 0,
            backgroundColor: theme.basicColor.transparent,
          },
        ]}>
        {render}
      </Spin>
      {/* <WebViewInner
        title={title}
        ref={ref}
        urlPrefix={urlPrefix}
        onMessage={handleMessage}
      /> */}
    </View>
  );
};

export default GameWebView;
