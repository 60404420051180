import {PageParams, PageResponse} from '@/types';
import {http} from '@utils';

export interface NotificationItem {
  /** 创建时间 */
  createTime: string;
  id: number;
  /** 是否已读 0未读 1已读 */
  ifRead: number;
  /** 图标 */
  messageIcon: string;
  /** 标题 */
  messageTitle: string;
  /** 发送时间 */
  sendTime: number;
  status: number;
  messageContent: string;
}
/**
 * 消息类型 1 系统消息 2 用户消息
 */
export type MessageType = 1 | 2;

export function postUserMessageList(pageNo: number) {
  return http.post<PageParams, PageResponse<NotificationItem>>(
    'app/userMessage/getList',
    {
      pageNo,
    },
  );
}

export function postSystemMessageList(pageNo: number) {
  return http.post<PageParams, PageResponse<NotificationItem>>(
    'app/sysMessage/getList',
    {
      pageNo,
    },
  );
}
