/* eslint-disable react-native/no-inline-styles */
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

const Header = () => {
  const {i18n} = useTranslation();
  const {calcActualSize} = useScreenSize();
  return (
    <View style={[theme.flex.row, styles.container]}>
      <View style={[theme.flex.flex1, theme.flex.row]}>
        <View style={[{width: calcActualSize(64)}]}>
          <Text>{i18n.t('bets-detail.label.issue')}</Text>
        </View>
        <View style={[theme.flex.flex1, theme.flex.center]}>
          <Text>{i18n.t('bets-detail.label.result')}</Text>
        </View>
      </View>
      <View style={[theme.flex.flex1, theme.flex.row, theme.flex.between]}>
        <View style={{width: 26}}>
          <Text textAlign="center">{i18n.t('bets-detail.label.sum')}</Text>
        </View>
        <Text>{i18n.t('bets-detail.label.value')}</Text>
        <View style={{width: 48}}>
          <Text textAlign="right">{i18n.t('bets-detail.label.number')}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#E9ECF5',
    paddingHorizontal: 12,
    paddingVertical: 8,
    columnGap: 12,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginHorizontal: 12,
  },
});

export default Header;
