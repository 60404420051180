import AsyncStorage from '@react-native-async-storage/async-storage';

const GAME_KEYS = {
  color: 'COLOR_WIN',
  dice: 'DICE_WIN',
  digit: 'DIGIT_WIN',
  mixLottery: 'MIX_LOTTERY_WIN',
};

const checkIsShown = async (
  game: 'color' | 'dice' | 'digit' | 'mixLottery',
  issNo: string,
) => {
  const key = GAME_KEYS[game];
  const res = await AsyncStorage.getItem(key);
  if (res) {
    const toJson = JSON.parse(res) as string[];
    return Boolean(toJson.find(item => item === issNo));
  }
  return false;
};

const saveIssueNum = async (
  game: 'color' | 'dice' | 'digit' | 'mixLottery',
  issNo: string,
) => {
  const key = GAME_KEYS[game];
  const res = (await AsyncStorage.getItem(key)) || '[]';
  const toJson = JSON.parse(res);
  toJson.push(issNo);
  await AsyncStorage.setItem(key, JSON.stringify(toJson));
};

const clearAllWin = async () => {
  try {
    await AsyncStorage.multiRemove(Object.values(GAME_KEYS));
  } catch (e) {
    console.error(e);
  }
};

export {checkIsShown, saveIssueNum, clearAllWin};
