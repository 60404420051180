export default {
  v: '5.6.3',
  fr: 25,
  ip: 0,
  op: 40,
  w: 156,
  h: 156,
  nm: '合成 1',
  ddd: 0,
  assets: [
    {
      id: 'image_0',
      w: 120,
      h: 93,
      u: '/assets/lottie/home/free-lottery/',
      p: '___.png',
      e: 0,
    },
    {
      id: 'image_1',
      w: 80,
      h: 104,
      u: '/assets/lottie/home/free-lottery/',
      p: '____0_.png',
      e: 0,
    },
    {
      id: 'image_2',
      w: 96,
      h: 116,
      u: '/assets/lottie/home/free-lottery/',
      p: '____1_.png',
      e: 0,
    },
    {
      id: 'image_3',
      w: 97,
      h: 65,
      u: '/assets/lottie/home/free-lottery/',
      p: '____2.png',
      e: 0,
    },
    {
      id: 'image_4',
      w: 156,
      h: 156,
      u: '/assets/lottie/home/free-lottery/',
      p: 'Mask_group.png',
      e: 0,
    },
    {
      id: 'image_5',
      w: 156,
      h: 156,
      u: '/assets/lottie/home/free-lottery/',
      p: '__.png',
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: '箱身外.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [77.375, 85.125, 0], ix: 2},
        a: {a: 0, k: [60, 46.5, 0], ix: 1},
        s: {a: 0, k: [102.083, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: '红色彩券.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              t: 20,
              s: [100],
            },
            {t: 25, s: [0]},
          ],
          ix: 11,
        },
        r: {a: 0, k: 0, ix: 10},
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              t: 10,
              s: [58.25, 66.75, 0],
              to: [0, -2.083, 0],
              ti: [0, 2.083, 0],
            },
            {t: 25, s: [58.25, 54.25, 0]},
          ],
          ix: 2,
        },
        a: {a: 0, k: [40, 52, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: '红色彩券.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [58.25, 66.75, 0], ix: 2},
        a: {a: 0, k: [40, 52, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: '紫色彩券.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              t: 10,
              s: [97],
            },
            {t: 15, s: [0]},
          ],
          ix: 11,
        },
        r: {a: 0, k: 0, ix: 10},
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              t: 0,
              s: [99.25, 67.25, 0],
              to: [0, -2.167, 0],
              ti: [0, 2.167, 0],
            },
            {t: 15, s: [99.25, 54.25, 0]},
          ],
          ix: 2,
        },
        a: {a: 0, k: [48, 58, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: '紫色彩券.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [99.25, 67.25, 0], ix: 2},
        a: {a: 0, k: [48, 58, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: '箱身内.png',
      cl: 'png',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [78.125, 80.5, 0], ix: 2},
        a: {a: 0, k: [48.5, 32.5, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: 'Mask group.png',
      cl: 'png',
      refId: 'image_4',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [78, 78, 0], ix: 2},
        a: {a: 0, k: [78, 78, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: '底色.png',
      cl: 'png',
      refId: 'image_5',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [78, 78, 0], ix: 2},
        a: {a: 0, k: [78, 78, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
