import {SVGProps} from '@/types';
import React from 'react';
import theme from '@/style';

const NoticeSvg = ({
  color = '#31373D',
  width = theme.iconSize.l,
  height = theme.iconSize.l,
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.1 16.9V9.9C7.1 7.1938 9.2938 5 12 5C14.7062 5 16.9 7.1938 16.9 9.9V16.9M5 16.9H19"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 19.0004C12.9665 19.0004 13.75 18.2169 13.75 17.2504V16.9004H10.25V17.2504C10.25 18.2169 11.0335 19.0004 12 19.0004Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NoticeSvg;
