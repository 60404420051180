import {View} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {BasicObject} from '@/types';

export const COLORS = {
  A: '#D57300',
  B: '#0087D4',
  C: '#D50000',
  D: '#67A519',
  UNUSED: '#5F6975',
} as BasicObject;

export interface DigitBallProps {
  digit: string;
  type: 'A' | 'B' | 'C' | 'D' | 'UNUSED';
  size?: number;
  disabled?: boolean;
  bg?: string;
}

const DigitBall = (props: DigitBallProps) => {
  const {digit, type, size = 20, disabled = false, bg} = props;

  return (
    <View
      style={[
        theme.flex.center,
        {
          width: size,
          height: size,
          borderRadius: size / 2,
          backgroundColor: bg || COLORS[type],
        },
        // eslint-disable-next-line react-native/no-inline-styles
        disabled && {opacity: 0.3},
      ]}>
      <Text
        color={theme.basicColor.white}
        size="medium"
        fontFamily="fontInterBold">
        {digit}
      </Text>
    </View>
  );
};

export default DigitBall;
