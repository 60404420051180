import React from 'react';
import {CarListItem} from '../../home.type';
import {useTranslation} from 'react-i18next';
import {toGame} from '@/common-pages/game-navigate';
import HomeLotteryCarTemplate from './lottery-car-template';
import {formatDate} from '@/utils';

const getTimer = (time: number, cycle: number) => {
  const date = formatDate(time, 'yyyy-MM-dd');
  if (date === 'Invalid Date') {
    return 0;
  }
  return (
    cycle * 60 -
    Math.round(
      (Math.abs(time - new Date(date).getTime()) % (cycle * 60000)) / 1000,
    )
  );
};

interface CarProps {
  list: CarListItem[];
  serverTime?: number;
  currentTime?: number;
}
const HomeLotteryCar = ({list = [], currentTime}: CarProps) => {
  const i18n = useTranslation();

  const targetList = React.useMemo(() => {
    if (list && list.length > 0) {
      return list.map(item => {
        const cycle = +item.gameUrl.split('cycle=')[1].split('&')[0];
        const remain = getTimer(item.currentTimestamp, cycle);
        return {
          ...item,
          cycle,
          remain,
        };
      });
    }
    return [];
  }, [list]);

  return (
    <HomeLotteryCarTemplate
      list={targetList}
      currentTime={currentTime}
      titleColor="rgba(201, 228, 209, 0.50)"
      title={i18n.t('home.car.title')}
      onPress={item => {
        if (item) {
          (item as any).name = i18n.t('home.car.title');
          // @ts-ignore
          toGame(item);
        }
      }}
    />
  );
};

export default React.memo(HomeLotteryCar);
