import theme from '@/style';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import SattaPanel from '@/common-pages/satta/components/satta-panel';
import {useTranslation} from 'react-i18next';
import Won from '../won/won';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';

function getResultNum(
  openResultNum?: string | null,
  closeResultNum?: string | null,
) {
  let openNum, closeNum;
  if (openResultNum) {
    openNum = openResultNum.split('').reduce((s, y) => (s + +y) % 10, 0);
  } else {
    openNum = '*';
  }
  if (closeResultNum) {
    closeNum = closeResultNum.split('').reduce((s, y) => (s + +y) % 10, 0);
  } else {
    closeNum = '*';
  }
  return {openNum, closeNum};
}

interface SattaOrderDigitItem {
  digits: string;
  gameType: number;
  points: number;
  digitsWonAmount?: number;
  isWin?: number;
}

export interface MatkaProps {
  openNum: string;
  closeNum: string;
  userDigits: SattaOrderDigitItem[];
}

const Matka = ({userDigits = [], openNum = '', closeNum = ''}: MatkaProps) => {
  const {t} = useTranslation();
  const [showMore, setShowMore] = React.useState(false);
  const matkaType = [
    '',
    `${t('satta.betting.ank')} ${t('satta.betting.open')}`,
    `${t('satta.betting.ank')} ${t('satta.betting.close')}`,
    t('satta.betting.jodi'),
    `${t('satta.betting.sp')} ${t('satta.betting.open')}`,
    `${t('satta.betting.sp')} ${t('satta.betting.close')}`,
    `${t('satta.betting.dp')} ${t('satta.betting.open')}`,
    `${t('satta.betting.dp')} ${t('satta.betting.close')}`,
    `${t('satta.betting.tp')} ${t('satta.betting.open')}`,
    `${t('satta.betting.tp')} ${t('satta.betting.close')}`,
    `${t('satta.betting.half')} ${t('satta.betting.open')}`,
    `${t('satta.betting.half')} ${t('satta.betting.close')}`,
    t('satta.betting.full'),
  ];

  const pannelDigits = (open: string, close: string) => {
    return `${open || '***'}-${getResultNum(open, close).openNum}${
      getResultNum(open, close).closeNum
    }-${close || '***'}`;
  };

  const targetList = React.useMemo(() => {
    if (userDigits.length > 7 && showMore) {
      return userDigits;
    }
    return userDigits.slice(0, 7);
  }, [userDigits, showMore]);

  const winList = React.useMemo(() => {
    return userDigits.filter(
      item => item.digitsWonAmount && item.digitsWonAmount > 0,
    );
  }, [userDigits]);

  return (
    <View style={[theme.margin.tops]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
          styles.drawContainer,
        ]}>
        <View style={[theme.flex.flex1]}>
          <Text size="medium" fontFamily="fontInterBold">
            Drawn Results：
          </Text>
        </View>
        <SattaPanel hideLabel pannel={pannelDigits(openNum, closeNum)} />
      </View>
      {winList.length > 0 && (
        <View>
          {winList.map((item, _i) => {
            return (
              <Won
                key={_i}
                awardAmount={item.digitsWonAmount!}
                bet={item.points}
                result={
                  <Text
                    color={'#F15802'}
                    fontFamily="fontInterBold"
                    size="medium">
                    {matkaType[item.gameType]}({item.digits})
                  </Text>
                }
              />
            );
          })}
        </View>
      )}
      <View
        style={[theme.flex.row, theme.margin.tops, {gap: theme.paddingSize.s}]}>
        {targetList.map((item, _i) => {
          const isWin = item.digitsWonAmount && item.digitsWonAmount > 0;
          const showBtn = _i === 7 && !showMore;
          return showBtn ? (
            <NativeTouchableOpacity
              key={_i}
              style={[styles.item, styles.viewAll]}
              onPress={() => setShowMore(true)}>
              <Text fontFamily="fontInterBold">View All</Text>
            </NativeTouchableOpacity>
          ) : (
            <View key={_i} style={[styles.item, isWin ? styles.itemCheck : {}]}>
              <Text
                color={isWin ? '#F15802' : '#48505D'}
                fontFamily="fontInterBold">
                {matkaType[item.gameType]}({item.digits})
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  drawContainer: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: '#F4F4F4',
  },
  item: {
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
    borderRadius: 18,
    backgroundColor: '#F6F7FB',
  },
  itemCheck: {
    borderWidth: 1,
    borderColor: '#F15802',
    backgroundColor: '#fff',
  },
  viewAll: {
    borderWidth: 1,
    borderColor: '#9FA5AC',
    backgroundColor: '#fff',
  },
});

export default Matka;
