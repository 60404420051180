import {BasicObject} from '@/types';
import {http} from '@/utils';

export interface ColorGetAllResponse {
  bulletinBoard: string;
  createTime: number;
  cycle: number;
  digitAwardRate: number;
  feeRate: number;
  greenAwardRate: number;
  greenSpecialAwardRate: number;
  id: number;
  minRemainingSecond: number;
  redAwardRate: number;
  redSpecialAwardRate: number;
  ruleLinkUrl: string;
  updateTime: number;
  updateUserId: number;
  violetAwardRate: number;
}

export interface ColorCreateParams {
  /** 彩票期号 */
  issueNo: string;
  /** 价格基数 */
  basePrice: number;
  /** 购买数量 */
  num: number;
  /** 订单总金额 */
  amount: number;
  /** 类型，1-数字 2-颜色*/
  selectType: number;
  /** 购买的彩票值
   * 数字，提交参数为： 0,1,2, ...9
   * 颜色,提交参数为： r, g, v
   * r--red
   * g--green
   * v--violet
   */
  selectValue: string | number;
}

export interface ColorGetNearestLotteryParams {
  /** 配置id */
  configId: number;
}

export interface ColorGetNearestLotteryResponse {
  bulletinBoard: string;
  configId: number;
  countdownTime: number;
  currentTime: number;
  cycle: number;
  dataStatus: number;
  id: number;
  issueNo: string;
  minRemainingSecond: number;
  openTime: number;
  rank: number;
  status: number;
}

export interface GetLatestParams {
  configId: number;
  pageNo: number;
  pageSize: number;
}

export interface ColorGetLatestResponse {
  content: ColorGetLatestContentResponse[];
  totalPages: number;
  totalSize: number;
}

export interface ColorGetLatestContentResponse {
  colorResult: string;
  configId: number;
  createTime: number;
  cycle: number;
  digitResult: string;
  id: number;
  issueNo: string;
  openStatus: number;
  openTime: number;
  updateTime: number;
}

export interface ColorGetOrderListParams extends GetLatestParams {}

export interface ColorGetBigOrderListParams extends GetLatestParams {
  type: number;
}

export interface ColorGetResultResponse {
  colorResult: string;
  configId: number;
  createTime: number;
  cycle: number;
  digitResult: string;
  id: number;
  issueNo: string;
  openStatus: number;
  openTime: number;
  updateTime: number;
}

export interface NumberListItem {
  selectType: number;
  selectValue: string;
}

export interface ShareObj {
  gameName: string;
  gameInfo: shareGameInfo;
}

interface shareGameInfo {
  shareAward: number;
  shareGameDto: BasicObject;
}

export interface ColorGetOrderListResponse {
  content: ColorListItem[];
  totalPages: number;
  totalSize: number;
}

export interface ColorGetOrderListContent {
  amount: number;
  awardAmount: number;
  awardStatus: number;
  basePrice: number;
  colorResult: string;
  configId: number;
  createDate: number;
  createTime: number;
  cycle: number;
  deliveryAmount: number;
  digitResult: string;
  feeAmount: number;
  groupOrderNo: string;
  id: number;
  isPublic: number;
  issueNo: string;
  num: number;
  openTime: number;
  orderNo: string;
  resultId: number;
  selectType: number;
  selectValue: string;
  updateTime: number;
  userId: number;
}

export interface AllLittleOrder {
  amount: number;
  awardAmount: number;
  awardStatus: number;
  basePrice: number;
  configId: number;
  createDate: number;
  createTime: number;
  cycle: number;
  groupOrderNo: string;
  id: number;
  issueNo: string;
  num: number;
  orderNo: string;
  resultId: number;
  selectType: number;
  selectValue: string;
  updateTime: number;
  userId: number;
}

export interface ColorGetBigOrderListContent {
  allLittleOrders: AllLittleOrder[];
  awardLittleOrders: [];
  colorResult: string;
  digitResult: string;
  issueNo: string;
  selectValueList: [];
  userId: number;
}

export interface ColorGetBigOrderListResponse {
  content: ColorGetBigOrderListContent[];
  totalPages: number;
  totalSize: number;
}

export interface ColorListItem {
  issueNo: string;
  digitResult: string;
  colorResult: string;
  openStatus: number;
  groupOrderNo: string;
  gameName: string;
  deliveryAmount: number;
  feeAmount: number;
  openTime: number;
  createTime: number;
  shareAward: number;
  awardStatus: number;
  totalAmount: number;
  selectType: number;
  awardAmount: number;
  subsetList?: SubsetListItem[];
}

export interface SubsetListItem {
  // payment
  amount: number;
  deliveryAmount: number;
  feeAmount: number;
  // Betting time timestamp
  createTime: number;
  awardAmount: number;
  // 1 win, other nowin
  awardStatus: number;
  selectType: number;
  selectValue: string;
}

export interface OrderParams {
  orderStatus: string | '0' | '1' | '2' | '3';
  pageNo: number;
  pageSize: number;
  yearMonth?: string;
}

export interface BetsInfo {
  digit: string;
  color: string;
  onlyColor: boolean;
}

/**
 * 获取红绿灯全局配置信息
 * @returns
 */
export const getAll = (cycle?: string) =>
  http.get<{cycle?: string}, ColorGetAllResponse[]>(
    'app/redGreen/config/getAll',
    {
      params: {
        cycle,
      },
    },
  );

/**
 * 购买红绿灯彩票
 * @param body
 * @returns
 */
export const create = (body: ColorCreateParams) =>
  http.post<null, boolean>('app/redGreen/order/newCreate', body);

/**
 * 获取即将开奖的彩票信息
 * @returns
 */
export const getNearestLottery = (params: ColorGetNearestLotteryParams) =>
  http.get<null, ColorGetNearestLotteryResponse>(
    'app/redGreen/lottery/getNearestLottery',
    {
      params,
    },
  );

/**
 * 获取最近100条开奖结果
 * @returns
 */
export const getLatest = (params: GetLatestParams) =>
  http.post<null, ColorGetLatestResponse>(
    'app/redGreen/openResult/getLatest',
    params,
  );

/**
 * 获取当前期号彩票的购买数字列表
 * @param issueNo 红绿灯彩票期号
 * @returns
 */
export const getNumberList = (issueNo: string) =>
  http.get<null, NumberListItem[]>('app/redGreen/order/getNumberList', {
    params: {
      issueNo,
    },
  });

/**
 * 获取订单列表
 * @returns
 */
export const getOrderList = (params: ColorGetOrderListParams) =>
  http.post<null, ColorGetOrderListResponse>(
    'app/redGreen/order/getOrderList',
    params,
  );

export const getOrderListColor = (params: OrderParams) => {
  return http.post<OrderParams, ColorGetOrderListResponse>(
    'app/redGreen/order/orderList',
    params,
  );
};

/**
 * 获取红绿灯大订单列表
 * @param body
 * @returns
 */
export const getBigOrderList = (body: ColorGetBigOrderListParams) =>
  http.post<null, ColorGetBigOrderListResponse>(
    'app/redGreen/order/getBigOrderList',
    body,
  );

/**
 * 获取某期彩票的开奖结果
 * @param issueNo 红绿灯彩票期号
 * @returns
 */
export const getResult = (issueNo: string) =>
  http.post<null, ColorGetResultResponse[]>(
    'app/redGreen/openResult/getResult',
    issueNo,
  );

export interface GetOrderByIssueNoResponse {
  awardAmount: number;
  colorResult: string;
  cycle: number;
  digitResult: string;
  issueNo: string;
  openStatus: number;
}

/**
 * 获取用户当前期是否中奖
 * @param issueNo
 * @returns
 */
export const getOrderByIssueNo = (issueNo: string) =>
  http.get<null, GetOrderByIssueNoResponse>(
    'app/redGreen/order/getAwardAmountByIssueNo',
    {
      params: {
        issueNo,
      },
    },
  );

export interface WinnerResponse {
  bonus: number;
  userName: string;
  userAvatar: string;
  userPhone: string;
}

export const getWinners = () =>
  http.post<null, WinnerResponse[]>('app/redGreen/lottery/marquee', {group: 1});
