import theme from '@style';
import React, {useMemo} from 'react';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {BasicObject} from '@types';
import globalStore from '@/services/global.state';
import {StyleSheet, View} from 'react-native';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {TopIcon} from '../svg.variable';
import Text from '@/components/basic/text';

export interface RightMenusTopProps {
  onClick?: () => void;
}

const RightMenusTop: React.FC<RightMenusTopProps> = ({onClick}) => {
  const {calcActualSize} = useScreenSize();
  const wrapSize = useMemo(() => calcActualSize(52), [calcActualSize]);
  const innerSize = useMemo(() => calcActualSize(46), [calcActualSize]);
  const rightMenuStyle = StyleSheet.create({
    outer: {
      width: wrapSize,
      height: wrapSize,
      borderRadius: wrapSize / 2,
    },
    inner: {
      width: innerSize,
      height: innerSize,
      borderRadius: innerSize / 2,
    },
  });

  const iconShadow = useMemo(() => {
    let shadow = {} as BasicObject;
    if (globalStore.isWeb) {
      shadow = {
        ...shadow,
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
      };
    } else {
      shadow = {
        ...shadow,
        shadowColor: 'rgba(0, 0, 0, 0.25)',
        shadowOffset: {width: 0, height: 4},
        shadowOpacity: 1,
        shadowRadius: 4,
        elevation: 12,
      };
    }
    return shadow;
  }, []);

  return (
    <NativeTouchableOpacity
      style={[
        theme.background.primary,
        rightMenuStyle.outer,
        theme.flex.center,
        iconShadow,
      ]}
      onPress={onClick}>
      <View
        style={[
          rightMenuStyle.inner,
          theme.flex.col,
          theme.flex.center,
          theme.background.white,
        ]}>
        <TopIcon
          width={calcActualSize(theme.iconSize.s)}
          height={calcActualSize(theme.iconSize.s)}
          stroke={theme.basicColor.primary}
        />
        <Text second fontFamily="fontInterBold">
          TOP
        </Text>
      </View>
    </NativeTouchableOpacity>
  );
};

export default RightMenusTop;
