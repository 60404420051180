// import { IMatkaResultVo } from "@/common-pages/satta/satta-service";
import Spin from '@/components/basic/spin';
import theme from '@/style';
import React from 'react';
import Text from '@/components/basic/text';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet} from 'react-native';
import Page from '@/components/business/games/page';
import HistoryItem from './history-item';
import {LotteryResultHistoryItem, getLottoResult} from '../mix-lottery-service';
const pageSize = 10;

const History = ({
  lotteryID,
  refreshCode = 0,
  isQuick = false,
}: {
  lotteryID?: number;
  refreshCode?: number;
  isQuick?: boolean;
}) => {
  const [total, setTotal] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [list, setList] = React.useState<LotteryResultHistoryItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [initPage, setInitPage] = React.useState(1);
  const {i18n} = useTranslation();

  const getHistory = async (id: number, page: number = 1) => {
    try {
      if (!firstLoad) {
        setLoading(true);
      }
      const res = await getLottoResult(id, isQuick, page, pageSize);
      setList(res.rounds || []);
      setTotal(res.totalCount);
    } finally {
      setFirstLoad(false);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (lotteryID) {
      getHistory(lotteryID).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryID]);

  React.useEffect(() => {
    if (refreshCode && lotteryID) {
      getHistory(lotteryID).then();
      setInitPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCode]);

  return (
    <Spin
      loading={firstLoad || loading}
      // eslint-disable-next-line react-native/no-inline-styles
      style={[theme.flex.flex1, {minHeight: 400}]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.between,
          theme.flex.centerByCol,
          theme.padding.lrl,
          theme.padding.tbs,
          styles.header,
          theme.margin.lrl,
          theme.margin.topl,
        ]}>
        <Text fontWeight="500">
          {i18n.t('game-page.label.issue').toUpperCase()}
        </Text>
        <Text fontWeight="500">
          {i18n.t('game-page.label.numbers').toUpperCase()}
        </Text>
      </View>
      <View style={[theme.padding.lrl]}>
        {list.map((item, _i) => (
          <HistoryItem
            index={_i}
            key={_i}
            drawTime={item.drawTime}
            results={item.results}
            roundNo={item.roundNo}
          />
        ))}
      </View>
      {list.length > 0 ? (
        <Page
          page={initPage}
          total={total}
          pageSize={pageSize}
          onPageChange={p => {
            if (lotteryID) {
              setInitPage(p);
              getHistory(lotteryID, p).then();
            }
          }}
        />
      ) : null}
    </Spin>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#E9ECF5',
  },
});

export default History;
