import React from 'react';
import {View, StyleSheet} from 'react-native';
import theme from '@/style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import OrderListItemStatus from '@/components/business/order/order-list-item-status';
import Price from '@/components/business/price';
import DigitBall from '../component/digit-ball';
import {COLORS, MAIN_KEY, RESULT_INDEX} from '../constant';
import FeeDetail from './fee-detail';
import {LotteryOrderTicket, checkType} from '../mix-lottery-service';
import Tag from '../component/tag';

const DEFAULT = ['A', 'B', 'C', 'D'];
const SIDE_TYPE = ['Odd', 'Even', 'Big', 'Small'];
const F_P_C_TYPE = ['Fish', 'Prawn', 'Crab'];

const ListItem = ({
  index = 0,
  betNo = '',
  amount,
  fee,
  prize = 0,
  modeID,
  odds,
  betItem,
  tabName,
  modeName,
  isOpen,
}: {index?: number; isOpen?: boolean} & LotteryOrderTicket) => {
  const [showMore, setShowMore] = React.useState(false);
  // const {i18n} = useTranslation();

  const backgroundColor = React.useMemo(() => {
    return {
      backgroundColor: index % 2 === 0 ? '#FFF' : '#F8F9FF',
    };
  }, [index]);

  const tagInfo = React.useMemo(() => {
    if (modeID === 1) {
      if (betItem) {
        const TYPE_INDEX = (betItem - 1) % 4;
        const INDEX = Math.trunc((betItem - 1) / 4);
        return {
          type: DEFAULT[INDEX],
          label: SIDE_TYPE[TYPE_INDEX],
        };
      }
    }
    if (modeID === 9) {
      const TYPE_INDEX = (betItem - 1) % 3;
      return {
        type: 'D',
        label: F_P_C_TYPE[TYPE_INDEX],
      };
    }
    return {};
  }, [modeID, betItem]);

  const numbers = React.useMemo(() => {
    if (tagInfo.label) {
      if (modeID === 1) {
        return `${tagInfo.type}-${tagInfo.label}: ${MAIN_KEY[tagInfo.label]}`;
      }
      if (modeID === 9) {
        return `${tagInfo.label}: ${MAIN_KEY[tagInfo.label]}`;
      }
    }
    return '';
  }, [modeID, tagInfo]);

  const newBet = React.useMemo(() => {
    if (checkType(modeID).isx1) {
      return betNo.split('').slice(-1);
    }
    if (checkType(modeID).isx2) {
      return betNo.split('').slice(-2);
    }
    if (checkType(modeID).isx3) {
      return betNo.split('').slice(-3);
    }
    return betNo.split('');
  }, [modeID, betNo]);

  return (
    <View style={[backgroundColor]}>
      <NativeTouchableOpacity
        onPress={() => setShowMore(!showMore)}
        style={[
          theme.flex.row,
          theme.flex.between,
          theme.padding.l,
          theme.flex.centerByCol,
          {gap: theme.paddingSize.s},
        ]}>
        {modeID === 9 || modeID === 1 ? (
          <View
            style={[
              theme.flex.row,
              {gap: theme.paddingSize.xxs},
              styles.number,
            ]}>
            {tagInfo.type && (
              <Tag
                label={tagInfo.label}
                backgroundColor={COLORS[tagInfo.type]}
              />
            )}
          </View>
        ) : (
          <View
            style={[
              theme.flex.row,
              {gap: theme.paddingSize.xxs},
              styles.number,
            ]}>
            {newBet.map((item, i) => (
              <DigitBall
                key={i}
                digit={item}
                isPrimary={checkType(modeID).isX}
                type={RESULT_INDEX.slice(-newBet.length)[i]}
              />
            ))}
          </View>
        )}
        <View style={styles.time}>
          <Price
            price={amount + fee}
            textProps={{size: 'medium', textAlign: 'center'}}
          />
        </View>
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            theme.flex.end,
            theme.flex.flex1,
          ]}>
          <OrderListItemStatus
            hasDetail
            showMore={showMore}
            status={!isOpen ? 2 : prize > 0 ? 1 : 0}
            awardAmount={prize}
          />
        </View>
      </NativeTouchableOpacity>
      {showMore && (
        <FeeDetail
          fee={fee}
          delivery={amount}
          odds={odds}
          numberType={tagInfo.type}
          numbers={numbers}
          type={`${modeName}-${tabName}`}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  itemAction: {
    height: 48,
  },
  iconSize: {
    width: 14,
    height: 14,
  },
  ballSize: {
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
  },
  number: {
    flex: 1,
  },
  time: {
    flex: 1,
    alignItems: 'center',
  },
});

export default ListItem;
