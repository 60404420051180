export const pofileImgWidth = 88;
export const RectangleImgWidth = 48;

export const pofileMagin = {
  marginTop: 24,
  marginBottom: 24,
};
export const pofilePadding = {
  paddingLeft: 33.5,
  paddingRight: 33.5,
};

export const padding20 = {
  paddingLeft: 20,
  paddingRight: 20,
};
export const w24 = 24;
export const margintb = {
  marginTop: 16,
  marginBottom: 16,
};

export const fontSize18 = {
  fontSize: 18,
};
export const height32 = {
  height: 32,
};
export const height48 = {
  height: 48,
};
export const w295 = {
  width: 295,
};
export const w248 = {
  width: 248,
};
export const pos = {
  top: 0,
  right: 0,
  bottom: 0,
};
export const borderRadius50 = {
  borderRadius: 50,
};
const levelColor = '#fff';

export const levelStyle = {
  fontSize: 11.75,
  color: levelColor,
  marginLeft: 5,
};
export const h24 = 24;
// 头像之后可能会用组件里面的头像图片
export const emptyHeaderImg = require('@components/assets/icons/header.webp');
export const defaultHeaderImg = require('@components/assets/icons/default-header.webp');
export const RectangleImg = require('@components/assets/pofile/rectangle.png');
export const edit = require('@components/assets/pofile/edit.webp');
export const lock = require('@components/assets/pofile/lock.webp');
export const idImg = require('@components/assets/pofile/idImg.webp');
export const smartphone = require('@components/assets/pofile/smartphone.webp');
export const rightIcon = require('@components/assets/pofile/rightIcon.webp');
export const pic = require('@components/assets/pofile/pic.webp');
export const del = require('@components/assets/pofile/del.webp');
export const delIcon = require('@components/assets/pofile/delIcon.webp');
export const profileImg = require('@components/assets/pofile/profile-img.jpg');
