/* eslint-disable react-native/no-inline-styles */
import LinearGradient from '@/components/basic/linear-gradient';
import {TimeRemaining} from '@/components/business/games';
import theme from '@/style';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import Svg, {Line} from 'react-native-svg';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import DigitBall from './components/digit-ball';
import ColorResultBg from '@/components/svgs/basic/color-result-bg';
import {useTranslation} from 'react-i18next';

export interface TimeResultProps {
  showRule?: () => void;
  pickName?: string;
  pickNum?: string;
  drawResult?: string;
  tip?: string;
  remainingTime?: number;
  pickGameType?: string;
  onTimeEnd?: () => void;
  onDisabled?: () => void;
  isSameRoute?: boolean;
  stopBetSec?: number;
  timeVersion?: number;
}

const TimeResult = ({
  showRule,
  pickName,
  pickNum,
  drawResult,
  tip,
  remainingTime,
  pickGameType = 'normal',
  onTimeEnd,
  onDisabled,
  isSameRoute = true,
  timeVersion = 0,
  stopBetSec,
}: TimeResultProps) => {
  const [bgWidth, setBgWidth] = React.useState(0);
  const {i18n} = useTranslation();
  const drawResultArr = React.useMemo(() => {
    if (drawResult) {
      return drawResult.split('');
    }
    return ['*', '*', '*'];
  }, [drawResult]);

  React.useEffect(() => {
    if (remainingTime === 0) {
      onTimeEnd && onTimeEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  return (
    <LinearGradient colors={theme.backgroundColor.remainingLinearGradient}>
      <View
        style={[
          theme.flex.row,
          theme.padding.lrl,
          theme.padding.tbl,
          theme.flex.centerByCol,
        ]}>
        <View>
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.flex.between,
            ]}>
            <View>
              <Text fontFamily="fontInterBold">{pickName || '-'}</Text>
              <Text fontFamily="fontInterBold">{pickNum || '-'}</Text>
            </View>
            <NativeTouchableOpacity
              onPress={() => showRule?.()}
              style={[
                theme.borderRadius.xs,
                styles.plyBtn,
                theme.flex.center,
                theme.padding.tbxxs,
                theme.padding.lrs,
                theme.margin.tops,
                theme.margin.leftxs,
              ]}>
              <Text style={[{fontWeight: '500'}]} fontFamily="fontInter">
                {' '}
                {i18n.t('game-page.label.howToPlay')}
              </Text>
            </NativeTouchableOpacity>
          </View>
          <View
            style={[
              theme.flex.row,
              theme.margin.tops,
              theme.borderRadius.xs,
              theme.flex.around,
              theme.padding.tbxxs,
              theme.padding.lrl,
              theme.overflow.hidden,
              {position: 'relative'},
            ]}>
            <View
              onLayout={e => setBgWidth(e.nativeEvent.layout.width)}
              style={[
                {position: 'absolute', left: 0, right: 0, top: 0, bottom: 0},
              ]}>
              <ColorResultBg
                color={theme.backgroundColor.diceResultBg}
                height={40}
                width={bgWidth}
              />
            </View>

            {['A', 'B', 'C'].map((item, index) => (
              <DigitBall
                key={index}
                ballSize={32}
                color={item}
                fontSize={16}
                digit={drawResultArr[index]}
              />
            ))}
          </View>
        </View>
        <View style={[theme.flex.flex1, theme.flex.centerByCol]}>
          <Svg height={72} width={1}>
            <Line
              x1="0"
              y1="0"
              x2="0"
              y2="72"
              stroke={theme.backgroundColor.colorDashed}
              strokeWidth="2"
              strokeDasharray={'5,5'}
            />
          </Svg>
        </View>
        <TimeRemaining
          includeHour={pickGameType === 'normal'}
          remainingTime={remainingTime}
          tip={tip}
          onDisabled={onDisabled}
          onTimeEnd={onTimeEnd}
          hasCutdown={pickGameType !== 'normal'}
          stopBetSec={stopBetSec}
          isSame={isSameRoute}
          timeVersion={timeVersion}
        />
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  plyBtn: {
    backgroundColor: theme.backgroundColor.ruleBtn,
  },
});

export default React.memo(TimeResult);
