interface CallbackInfo {
  callback: () => void;
  interval: boolean;
  timeout: number;
}

class BackgroundTimer {
  private uniqueId: number;
  private callbacks: {[id: number]: CallbackInfo};
  private backgroundTimer?: NodeJS.Timeout;

  constructor() {
    this.uniqueId = 0;
    this.callbacks = {};
  }

  runBackgroundTimer(callback: () => void, delay: number): void {
    this.backgroundClockMethod(callback, delay);
  }

  backgroundClockMethod(callback: () => void, delay: number) {
    this.backgroundTimer = setTimeout(() => {
      callback();
      this.backgroundClockMethod(callback, delay);
    }, delay);
  }

  stopBackgroundTimer() {
    if (this.backgroundTimer) {
      clearTimeout(this.backgroundTimer);
    }
  }

  setTimeout(callback: () => void, timeout: number) {
    this.uniqueId += 1;
    const timeoutId = this.uniqueId;
    this.callbacks[timeoutId] = {
      callback,
      interval: false,
      timeout,
    };
    setTimeout(() => {
      this.handleTimeout(timeoutId);
    }, timeout);
    return timeoutId;
  }

  clearTimeout(timeoutId: number) {
    if (this.callbacks[timeoutId]) {
      delete this.callbacks[timeoutId];
    }
  }

  setInterval(callback: () => void, timeout: number) {
    this.uniqueId += 1;
    const intervalId = this.uniqueId;
    this.callbacks[intervalId] = {
      callback,
      interval: true,
      timeout,
    };
    setTimeout(() => {
      this.handleInterval(intervalId);
    }, timeout);
    return intervalId;
  }

  clearInterval(intervalId: number) {
    if (this.callbacks[intervalId]) {
      delete this.callbacks[intervalId];
    }
  }

  private handleTimeout(timeoutId: number) {
    if (this.callbacks[timeoutId]) {
      const {callback} = this.callbacks[timeoutId];
      delete this.callbacks[timeoutId];
      callback();
    }
  }

  private handleInterval(intervalId: number) {
    if (this.callbacks[intervalId]) {
      const {callback, timeout} = this.callbacks[intervalId];
      callback();
      setTimeout(() => {
        this.handleInterval(intervalId);
      }, timeout);
    }
  }
}

export default new BackgroundTimer();
