import theme from '@/style';
import React from 'react';
import {View} from 'react-native';
import BetsButton from './bets-btn';
import NumberStepper from './number-stepper';
import NumberInput, {NumberInputRef} from './number-input';
import DigitBall from './digit-ball';
import {useTranslation} from 'react-i18next';

export interface BetsTripleItemProps {
  digits?: string[];
  guessNums?: string[];
  onAdd?: (
    type: string,
    value: string,
    count: number,
    startElement?: View | null,
  ) => void;
  onBox?: (value: string, count: number, startElement?: View | null) => void;
  extend?: number;
}

const BetsTripleItem = (props: BetsTripleItemProps) => {
  const {
    digits = [],
    guessNums = [],
    onAdd = () => {},
    onBox = () => {},
    extend = 0,
  } = props;
  const [values, setValues] = React.useState<string[]>([]);
  const [rate, setRate] = React.useState<string>('');
  const [status, setStatus] = React.useState(false);
  const startElement = React.useRef<View>(null);
  const inputRefList = React.useRef<React.RefObject<NumberInputRef>[]>([
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  React.useEffect(() => {
    if (guessNums) {
      if (guessNums.filter(i => i).length === 3) {
        setValues(guessNums);
      }
    }
  }, [extend, guessNums]);

  const onTextChange = (i: number, v: string) => {
    if (i < 2 && v) {
      const nextInput = inputRefList.current[i + 1]?.current;
      if (nextInput) {
        nextInput.inputRef.current?.focus();
      }
    }
    values[i] = v;
    setValues([...values]);
  };

  const showRate = React.useMemo(() => {
    const hasInput = values.filter(i => i).length === 3;
    return hasInput || status;
  }, [values, status]);

  React.useEffect(() => {
    if (showRate) {
      setRate('3');
    }
  }, [showRate]);

  React.useEffect(() => {
    if (+rate <= 0 && !status) {
      setValues([]);
    }
  }, [rate, status]);

  const {i18n} = useTranslation();
  return (
    <View style={[theme.margin.topl]}>
      <View
        style={[theme.flex.row, theme.flex.between, theme.flex.centerByCol]}>
        <View
          style={[theme.flex.row, {gap: theme.paddingSize.xxs}]}
          ref={startElement}
          collapsable={false}>
          {digits.map((item, _i) => (
            <DigitBall digit={item} color={item} key={_i} fontSize={16} />
          ))}
        </View>
        <View style={[theme.flex.row, {gap: theme.paddingSize.s}]}>
          {digits.map((item, _i) => (
            <NumberInput
              ref={inputRefList.current[_i]}
              key={_i}
              value={values[_i]}
              onChange={v => onTextChange(_i, v)}
            />
          ))}
        </View>
      </View>
      <View style={[theme.flex.row, theme.flex.between, theme.margin.topl]}>
        <View>
          {showRate && (
            <NumberStepper
              onFocusStateChange={setStatus}
              rate={rate}
              onChangeRate={e => setRate(e)}
              onStepChange={e => setRate(`${e}`)}
            />
          )}
        </View>
        <View style={[theme.flex.row, {gap: theme.paddingSize.s}]}>
          <BetsButton
            disabled={!showRate}
            title={i18n.t('game-page.label.box').toUpperCase()}
            onPress={() => {
              onBox(values.join(''), Number(rate), startElement.current);
              setTimeout(() => {
                setStatus(false);
                setValues([]);
              });
            }}
            style={[
              theme.padding.lrl,
              theme.borderRadius.xs,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                height: 32,
              },
            ]}
          />
          <BetsButton
            onPress={() => {
              onAdd(
                digits.join(''),
                values.join(''),
                Number(rate),
                startElement.current,
              );
              setTimeout(() => {
                setStatus(false);
                setValues([]);
              });
            }}
            disabled={!showRate}
            title={i18n.t('game-page.label.add').toUpperCase()}
            style={[
              theme.padding.lrl,
              theme.borderRadius.xs,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                height: 32,
              },
            ]}
          />
        </View>
      </View>
    </View>
  );
};

export default BetsTripleItem;
