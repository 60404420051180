import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {useResponsiveDimensions} from '@/utils';

export interface CardsProps {
  closed?: boolean;
  title?: string;
  tip?: string;
  digit?: string;
}

const Cards = ({title, tip, closed, digit}: CardsProps) => {
  const bottomBg = React.useMemo(() => {
    return closed ? '#C9CED3' : theme.basicColor.primary;
  }, [closed]);

  const borderColor = React.useMemo(() => {
    return closed ? '#C9CED3' : theme.basicColor.primary;
  }, [closed]);

  const {width} = useResponsiveDimensions();

  const itemWidth = (width - 24 - 16 * 3) / 3;

  return (
    <View
      style={[
        styles.container,
        theme.overflow.hidden,
        {borderColor, width: itemWidth},
      ]}>
      <View style={[theme.flex.center, styles.top]}>
        <Text
          size="medium"
          textAlign="center"
          style={[
            // eslint-disable-next-line react-native/no-inline-styles
            {
              color: closed ? '#C9CED3' : '#000',
              fontWeight: '500',
            },
          ]}>
          {title}
        </Text>
        {!!tip && (
          <Text color={closed ? '#C9CED3' : theme.fontColor.accent}>{tip}</Text>
        )}
      </View>
      <View
        style={[
          theme.padding.lrs,
          theme.padding.tbxxs,
          {backgroundColor: bottomBg},
        ]}>
        <Text
          color={theme.basicColor.white}
          textAlign="center"
          size="medium"
          fontFamily="fontInterBold">
          {closed ? '-' : digit}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 8,
  },
  top: {
    height: 56,
    paddingHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Cards;
