import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';

const HEADER = ['Digit', 'Amount', 'Type', 'Delete'];

const ListHeader = () => {
  return (
    <View
      style={[
        theme.flex.row,
        theme.padding.lrs,
        theme.padding.tbl,
        styles.header,
      ]}>
      {HEADER.map((item, _i) => (
        <View key={item} style={[theme.flex.flex1, theme.flex.center]}>
          <Text>{item}</Text>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: '#E9ECF5',
  },
});

export default ListHeader;
