export const boxReachedIcon = require('@assets/icons/vip/box-reached.webp');
export const boxReceivedIcon = require('@assets/icons/vip/box-received.webp');
export const boxUnReachIcon = require('@assets/icons/vip/box-unreach.webp');
export const luckyspinIcon = require('@assets/icons/vip/luckyspin.webp');
export const moneyIcon = require('@assets/icons/vip/money.webp');

export const currentPointIcon = require('@assets/icons/vip/current.webp');
export const firstPointIcon = require('@assets/icons/vip/first.webp');
export const nextPointIcon = require('@assets/icons/vip/next.webp');
export const unreachPointIcon = require('@assets/icons/vip/unreach.webp');
export const reachPointIcon = require('@assets/icons/vip/reached.webp');
