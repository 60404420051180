import React from 'react';
import ReactDOM from 'react-dom';
import {Modal, StyleSheet, View} from 'react-native';

type ModalOptions = {};
export function showCenterModal(
  modal: React.ReactElement,
  options: ModalOptions,
): number {
  return modalManager.open(modal, options);
}

export function closeModal(id: number) {
  modalManager.close(id);
}

const styles = StyleSheet.create({
  modal: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

class ModalManager {
  modals: {[k: number]: HTMLDivElement};
  constructor() {
    this.modals = {};
  }

  open(component: React.ReactElement, props = {}) {
    const modalId = new Date().getTime();
    const div = document.createElement('div');
    div.id = `modal-${modalId}`;
    document.body.appendChild(div);

    const modal = (
      <Modal
        id="modal"
        animationType="fade"
        transparent={true}
        visible={true}
        onRequestClose={() => this.close(modalId)}>
        <View style={styles.modal}>
          {React.cloneElement(component, {
            ...props,
            closeModal: () => this.close(modalId),
          })}
        </View>
      </Modal>
    );
    ReactDOM.render(modal, div);
    this.modals[modalId] = div;

    return modalId; // 返回 modalId 以便手动关闭
  }

  close(modalId: number) {
    const div = this.modals[modalId];
    if (div) {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
      delete this.modals[modalId];
    }
  }
}

const modalManager = new ModalManager();
export default modalManager;
