import {SVGProps} from '@/types';
import React from 'react';

const Dustbin = ({color = '#9FA5AC', width, height}: SVGProps) => (
  <svg
    width={width || 16}
    height={height || 16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 3.80078H2.55556H15"
      stroke={color}
      strokeWidth="1.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4446 3.8V13.6C13.4446 13.9713 13.2807 14.3274 12.9889 14.5899C12.6972 14.8525 12.3016 15 11.889 15H4.11122C3.69866 15 3.303 14.8525 3.01128 14.5899C2.71955 14.3274 2.55566 13.9713 2.55566 13.6V3.8M4.889 3.8V2.4C4.889 2.0287 5.05289 1.6726 5.34461 1.41005C5.63633 1.1475 6.03199 1 6.44455 1H9.55566C9.96822 1 10.3639 1.1475 10.6556 1.41005C10.9473 1.6726 11.1112 2.0287 11.1112 2.4V3.8"
      stroke={color}
      strokeWidth="1.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.44434 7.30078V11.5008"
      stroke={color}
      strokeWidth="1.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.55566 7.30078V11.5008"
      stroke={color}
      strokeWidth="1.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Dustbin;
