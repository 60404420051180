import NoData from '@/components/basic/error-pages/no-data';
import Spin from '@/components/basic/spin';
import PageNumber from '@/components/business/games/page';
import OrderCard from '@/components/business/order/order-card';
import theme from '@/style';
import dayjs from 'dayjs';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import globalStore from '@/services/global.state';
import {getDiceOrderList, OrderResContentItem} from '../../dice-service';
import OrderBetsList from './order-bets-list';
import DrawResult from './draw-result';
import {goTo} from '@/utils';
const pageSize = 3;

const OrderList = ({
  isActive = false,
  refreshCode = 0,
}: {
  isActive?: boolean;
  refreshCode?: number;
}) => {
  const [total, setTotal] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [list, setList] = React.useState<OrderResContentItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const {i18n} = useTranslation();
  const [initPage, setInitPage] = React.useState(1);
  const getList = async (page: number = 1) => {
    try {
      setLoading(true);
      const res = await getDiceOrderList({
        orderStatus: '3',
        pageNo: page,
        pageSize,
      });
      if (res) {
        setTotal(res.totalSize);
        setList(res.content || []);
      }
    } finally {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  React.useEffect(() => {
    if (isActive) {
      if (!globalStore.token) {
        setFirstLoad(false);
        goTo('Login');
        return;
      }
      getList().then();
    }
  }, [isActive]);

  React.useEffect(() => {
    if (refreshCode && isActive) {
      if (!globalStore.token) {
        return;
      }
      getList().then();
      setInitPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCode]);

  const onCopy = (id: string) => {
    Clipboard.setString(id);
    globalStore.globalSucessTotal(i18n.t('share.copy-success'));
  };

  return (
    <Spin loading={firstLoad || loading} style={[theme.flex.flex1]}>
      {!firstLoad && !loading && list && list.length === 0 ? <NoData /> : null}
      {list.map((item, _i) => (
        <View key={_i}>
          <OrderCard
            id={item.issNo}
            gameName={item.gameName}
            hideDraw={false}
            payment={item.totalAmount}
            awardAmount={item.totalAwardAmount}
            drawTime={dayjs(item.openTime).format('DD-MM hh:mm A')}
            betsTime={dayjs(item.createTime).format('DD-MM-YYYY hh:mm:ss')}
            onCopy={() => onCopy(item.issNo)}
            result={
              <DrawResult
                bigOrSmall={item.bigOrSmall}
                result={item.result}
                oddOrEven={item.oddOrEven}
                status={
                  item.openStatus === 1 ? 2 : item.totalAwardAmount > 0 ? 1 : 0
                }
                totalCount={item.totalCount}
              />
            }
            // eslint-disable-next-line react-native/no-inline-styles
            drawContainerStyle={{
              backgroundColor: '#FFFDED',
            }}
            status={
              item.openStatus === 1 ? 2 : item.totalAwardAmount > 0 ? 1 : 0
            }
          />
          <OrderBetsList
            betsTime={dayjs(item.createTime).format('DD-MM-YYYY hh:mm:ss')}
            typeList={item.typeList}
            status={
              item.openStatus === 1 ? 2 : item.totalAwardAmount > 0 ? 1 : 0
            }
          />
        </View>
      ))}
      {total > 3 ? (
        <PageNumber
          page={initPage}
          total={total}
          pageSize={pageSize}
          onPageChange={p => {
            setInitPage(p);
            getList(p).then();
          }}
        />
      ) : null}
    </Spin>
  );
};

export default React.memo(OrderList);
