export default {
  v: '5.6.3',
  fr: 25,
  ip: 0,
  op: 40,
  w: 156,
  h: 156,
  nm: '合成 1',
  ddd: 0,
  assets: [
    {
      id: 'image_0',
      w: 156,
      h: 156,
      u: '/assets/lottie/home/spin/',
      p: '__.png',
      e: 0,
    },
    {
      id: 'image_1',
      w: 156,
      h: 156,
      u: '/assets/lottie/home/spin/',
      p: '___0.png',
      e: 0,
    },
    {
      id: 'image_2',
      w: 156,
      h: 156,
      u: '/assets/lottie/home/spin/',
      p: '___1.png',
      e: 0,
    },
    {
      id: 'image_3',
      w: 156,
      h: 156,
      u: '/assets/lottie/home/spin/',
      p: '___2.png',
      e: 0,
    },
    {
      id: 'image_4',
      w: 156,
      h: 156,
      u: '/assets/lottie/home/spin/',
      p: '___3.png',
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: '指针.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [78, 78, 0], ix: 2},
        a: {a: 0, k: [78, 78, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: '转盘.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {
          a: 1,
          k: [
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              t: 0,
              s: [0],
            },
            {t: 40, s: [360]},
          ],
          ix: 10,
        },
        p: {a: 0, k: [78, 78, 0], ix: 2},
        a: {a: 0, k: [78, 78, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: '底座.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [78, 78, 0], ix: 2},
        a: {a: 0, k: [78, 78, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: '投影.png',
      cl: 'png',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [78, 78, 0], ix: 2},
        a: {a: 0, k: [78, 78, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: '底色.png',
      cl: 'png',
      refId: 'image_4',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [78, 78, 0], ix: 2},
        a: {a: 0, k: [78, 78, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
