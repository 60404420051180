import theme from '@/style';
import {toPriceStr} from '@/utils';
import React from 'react';
import {View} from 'react-native';
import Text from '@/components/basic/text';
import {useTranslation} from 'react-i18next';

export interface WonProps {
  bet?: number;
  awardAmount: number;
  result: React.ReactElement | null;
  isBet?: boolean;
}

const Won = ({bet, awardAmount = 0, result, isBet = true}: WonProps) => {
  const {i18n} = useTranslation();
  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.between,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          height: 36,
          borderRadius: 18,
          backgroundColor: theme.backgroundColor.betsBgLight,
        },
        theme.padding.lrxxl,
      ]}>
      <View style={[theme.flex.row, theme.flex.centerByCol]}>
        {result}
        {isBet && (
          <Text
            fontFamily="fontInterBold"
            size="medium"
            style={[theme.margin.leftm]}>
            {i18n.t('game-page.label.bet')} {toPriceStr(bet)}
          </Text>
        )}
      </View>
      <View style={[theme.flex.row, theme.flex.centerByCol]}>
        <Text accent fontFamily="fontInterBold">
          {i18n.t('bets-detail.label.won')}{' '}
        </Text>
        <Text
          fontFamily="fontInterBold"
          size="large"
          color={theme.basicColor.primary}>
          {toPriceStr(awardAmount, {fixed: 2})}
        </Text>
      </View>
    </View>
  );
};

export default Won;
