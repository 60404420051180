import Adjust from '@adjustcom/adjust-web-sdk';
import {getAdjustAttributionFromAPP, inApp} from './interface';

const ADJUST_TOKEN = 'm2ftc11t9d6o';

export function setupAdjust() {
  Adjust.initSdk({
    appToken: ADJUST_TOKEN,
    environment: 'production',
    logLevel: 'verbose',
    attributionCallback() {
      // Chrome {"adid": "32bb2eeab99aff90bb3d99c1dfea5c43","tracker_token": "1crjsj0g","tracker_name": "Organic","network": "Organic","state": "installed"}
      // Chrome {"adid": "eb809ae0f9903c6718023899e3bb4a7a", "tracker_token": "1crjsj0g","tracker_name": "Organic","network": "Organic","state": "installed"}
      // Safari {"adid": "a7a346bdc2c1d98b87fb0c3f4e103968", "tracker_token": "1crjsj0g","tracker_name": "Organic","network": "Organic","state": "installed"}
    },
  });
}

export function trackEvent(event: Adjust.EventParams) {
  Adjust.trackEvent(event);
}

function check(attr: Adjust.Attribution): boolean {
  return attr.tracker_name !== 'Organic';
}

export function getAdjustAttribution() {
  try {
    if (inApp) {
      return getAdjustAttributionFromAPP();
    }
    return Adjust.getAttribution();
  } catch (error) {
    return null;
  }
}

export function getInstallFromAd() {
  const attr = getAdjustAttributionFromAPP() as Adjust.Attribution;
  return check(attr);
}

//https://app.adjust.com/1cw2ypj2?campaign=%7B%7Bcampaign.name%7D%7D%20(%7B%7Bcampaign.id%7D%7D)&adgroup=%7B%7Badset.name%7D%7D%20(%7B%7Badset.id%7D%7D)&creative=%7B%7Bad.name%7D%7D%20(%7B%7Bad.id%7D%7D)&fbclid=IwZXh0bgNhZW0CMTAAAR39Yd6E1w37VOzX0BCwCCsIpBGeoIx0f6v74dmRmzIj45UqHBew1Ne2SqU_aem_ATSDU1ohTivKg2R5UTEUCfNBoI7j3L2lQjFQRPwv1lIc_f92z4sYpAO0erX4LJ0sD7LN_jwleQNMICAj7917tI5r
export function generateAPKURL({
  redirect,
  host = 'https://app.adjust.com/',
}: {
  redirect: string;
  host?: string;
}) {
  const attr = getAdjustAttribution() as Adjust.Attribution;
  if (!attr || attr.tracker_name === 'Organic') {
    return null;
  }
  let trackerInfo = getCookie('__trackerInfo__');
  if (!trackerInfo) {
    return null;
  }
  const tracker_token = JSON.parse(trackerInfo).a;
  // build a tracker URL
  let params: {[key: string]: string} = {
    campaign: attr.campaign || '',
    adgroup: attr.adgroup || '',
    creative: attr.creative || '',
    redirect,
  };

  const fbclid = getCookie('_fbc');
  if (fbclid) {
    const real = fbclid.split('.').pop();
    if (real) {
      params.fbclid = real;
    }
  }

  const fbpid = getFbPid();
  if (fbpid) {
    params.fbpid = fbpid;
  }
  let newURL =
    host +
    tracker_token +
    '?' +
    Object.keys(params)
      .map(key => key + '=' + encodeURIComponent(params[key]))
      .join('&');
  return newURL;
}
// build a new URL with parameters p0-p6, fbclid and fbpaid
function buildURL(
  p0: string,
  p1: string,
  p2: string,
  p3: string,
  p4: string,
  p5: string,
  p6: string,
  fbclid: string,
  fbpid: string,
  host: string,
  redirect: string,
) {
  if (!p0) {
    return null;
  }
  const tracker_token = p0;
  let campaign = '';
  if (p1 || p2) {
    campaign = p1 + '(' + p2 + ')';
  } else {
    campaign = '';
  }
  let adgroup = '';
  if (p3 || p4) {
    adgroup = p3 + '(' + p4 + ')';
  } else {
    adgroup = '';
  }
  let creative = '';
  if (p5 || p6) {
    creative = p5 + '(' + p6 + ')';
  } else {
    creative = '';
  }

  if (!fbclid) {
    fbclid = '';
  }

  if (!fbpid) {
    fbpid = '';
  }
  // build a tracker URL
  let params: {[key: string]: string} = {
    campaign,
    adgroup,
    creative,
    fbclid,
    fbpid,
    redirect,
  };
  let newURL =
    host +
    tracker_token +
    '?' +
    Object.keys(params)
      .map(key => key + '=' + encodeURIComponent(params[key]))
      .join('&');
  return newURL;
}

//get fbpid from Cookie written by Facebook Pixel
function getFbPid() {
  let fbPid = document.cookie.match(/(^|;) ?_fbp=([^;]*)(;|$)/);
  if (fbPid) {
    return fbPid[2];
  } else {
    return null;
  }
}

function getCookie(key: string) {
  let value = document.cookie.match(new RegExp(`(^|;) ?${key}=([^;]*)(;|$)`));
  if (value) {
    return value[2];
  } else {
    return null;
  }
}

export function generatorURL({
  host = 'https://app.adjust.com/',
  redirect,
}: {
  host?: string;
  redirect: string;
}) {
  // get query string, and parse it with URLSearchParams
  const urlParams = new URLSearchParams(window.location.search);
  //p0 = urlParams.get("p0") and fallback to "default"
  const p0 = urlParams.get('p0');
  if (!p0) {
    return redirect;
  }
  const p1 = urlParams.get('p1') || '';
  const p2 = urlParams.get('p2') || '';
  const p3 = urlParams.get('p3') || '';
  const p4 = urlParams.get('p4') || '';
  const p5 = urlParams.get('p5') || '';
  const p6 = urlParams.get('p6') || '';
  const fbClickId = urlParams.get('fbclid') || '';
  const fbPid = getFbPid() || '';
  const url = buildURL(
    p0,
    p1,
    p2,
    p3,
    p4,
    p5,
    p6,
    fbClickId,
    fbPid,
    host,
    redirect,
  );
  return url;
}
