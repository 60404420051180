import {http} from '@/utils';

export interface KeralaGroupLevel {
  drawLevel: string;
  lotteryResultList: {
    drawAmount: number;
    drawLevel: string;
    lotteryCode: string;
  }[];
}

export interface KeralaGroupLevelListInfo {
  groupLevelList: KeralaGroupLevel[];
  liveNumbers: string[];
  mainLive: string;
  // 用这个来输出结果
  keralaOrderRes?: KeralaOrderResult;
}

export interface KeralaOrderResult {
  betTime: number;
  wonTotalAmount: number;
  // 0 未中奖 1 中奖
  isWin: number;
  issNo: string;
  orderCodes: KeralaOrderCode[];
  orderId: string;
  drawTime: string;
  drawDate: number;
}

export interface KeralaOrderCode {
  level: string;
  wonAmount: number;
  wonCode: string;
  isWin?: number;
  lotteryPrice: number;
}

export function getGroupLevelList(issueNo: string) {
  return http.post<{issueNo: string}, KeralaGroupLevelListInfo>(
    'app/lottery/result/groupLevelList',
    {
      issueNo,
    },
  );
}
