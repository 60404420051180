import React from 'react';
import PrizeTab from '../component/prize-tab';
import {NORMAL_NUM} from '../constant';
import BetsDigitItem from './bets-digit-item';
import BetsActions from './bets-actions';
import {
  CartListItem,
  LotteryMode,
  LotteryModeData,
  MixBetOpt,
  ModeDataItem,
  checkType,
  roundTicketHisType,
} from '../mix-lottery-service';
import LotteryTip from '../component/lottery-tip';
import {useTranslation} from 'react-i18next';
import {BasicObject, SafeAny} from '@/types';
import globalStore from '@/services/global.state';
import {padZero} from '@/utils';
import {useConfirm} from '@/components/basic/modal';
import {View} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import parabolaService from '@/components/basic/parabola/parabola.service';

export interface LotteryBetsProps {
  lotteryDetail: LotteryModeData;
  selectPrize: number;
  onChangePrize?: (id: number) => void;
  cartList: CartListItem[];
  lotteryID: number;
  onCartChange?: (list: CartListItem[]) => void;
  hasSelected?: roundTicketHisType[];
  disabledAll?: boolean;
  endElement?: View | null;
}

const LotteryBets = ({
  lotteryDetail,
  selectPrize = 1,
  onChangePrize,
  cartList = [],
  onCartChange,
  hasSelected = [],
  lotteryID,
  disabledAll,
  endElement,
}: LotteryBetsProps) => {
  const {t} = useTranslation();
  const [options, setOptions] = React.useState<MixBetOpt[]>([]);
  const {maxTicketCount, modeID} = React.useMemo(() => {
    return {
      modeID: lotteryDetail?.modeID,
      maxTicketCount: lotteryDetail?.maxTicketCount,
    };
  }, [lotteryDetail]);
  const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm(
    t('label.del'),
  );

  const {renderModal: renderRepeatModal, show: confirmShowRepeat} = useConfirm(
    t('label.confirm'),
  );
  // const [listC, setListC] = React.useState<CartListItem[]>([]);
  const [listCShadow, setListCShadow] = React.useState<CartListItem[]>([]);

  React.useEffect(() => {
    if (lotteryID && listCShadow.length) {
      setListCShadow([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryID]);
  React.useEffect(() => {
    if (!lotteryDetail) {
      return;
    }
    const isTS = modeID === LotteryMode['Two Side'];
    const isFPC = modeID === LotteryMode.FishPrawnCrab;
    const list = isTS
      ? ['Odd', 'Even', 'Big', 'Small']
      : isFPC
      ? ['Fish', 'Prawn', 'Crab']
      : NORMAL_NUM;
    setOptions(
      list.map((v, i) => ({
        label: isTS
          ? t(`mix-lotto.label.${v}`)
          : isFPC
          ? t(`mix-lotto.label.${v}`)
          : v,
        value: v,
        min:
          isTS || isFPC
            ? (lotteryDetail.itemData as ModeDataItem[])[i].min
            : (lotteryDetail.itemData as ModeDataItem).min,
        max:
          isTS || isFPC
            ? (lotteryDetail.itemData as ModeDataItem[])[i].max
            : (lotteryDetail.itemData as ModeDataItem).max,
        odds:
          isTS || isFPC
            ? (lotteryDetail.itemData as ModeDataItem[])[i].odds
            : (lotteryDetail.itemData as ModeDataItem).odds,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryDetail]);

  const {typeMap, titleList} = React.useMemo(() => {
    let typeList = [];
    switch (modeID) {
      case LotteryMode['1 Digit']:
        typeList = ['D'];
        break;
      case LotteryMode.FishPrawnCrab:
        typeList = ['D'];
        break;
      case LotteryMode['2D']:
      case LotteryMode['2X']:
        typeList = ['C', 'D'];
        setListCShadow([]);
        break;
      case LotteryMode['3D']:
      case LotteryMode['3X']:
        typeList = ['B', 'C', 'D'];
        setListCShadow([]);
        break;
      default:
        typeList = ['A', 'B', 'C', 'D'];
        setListCShadow([]);
        break;
    }
    return {
      typeMap: checkType(modeID),
      titleList: typeList as ('A' | 'B' | 'C' | 'D')[],
    };
  }, [modeID]);

  const isDigit = React.useMemo(() => {
    if (typeMap.isBSOD || typeMap.isFPC) {
      return false;
    }
    return true;
  }, [typeMap]);

  const onAddToCart = (
    type: string,
    itemValue: MixBetOpt,
    isShadow: boolean = false,
    v?: View | null,
  ) => {
    let newList = isShadow ? [...listCShadow] : [...cartList];
    const cartHasGoods = newList.findIndex(
      item =>
        item.modeID === modeID &&
        item?.value === itemValue.value &&
        item.selectPrize === selectPrize &&
        item.type === type,
    );
    if (cartHasGoods >= 0) {
      newList.splice(cartHasGoods, 1);
    } else {
      if (!isShadow) {
        parabolaService.start({
          startWith: v!,
          endWith: endElement!,
        });
      }
      newList.unshift({
        type,
        ...itemValue,
        selectPrize,
        modeID,
        amount: 30,
      });
    }
    isShadow ? setListCShadow(newList) : onCartChange?.(newList);
  };

  const getAllCombinations = (data: SafeAny[][], moveRepeat = false) => {
    const res = data.reduce(
      (acc, arr) => {
        return acc.flatMap(combination => {
          return arr.map(item => [...combination, item]);
        });
      },
      [[]],
    );
    if (moveRepeat) {
      const seen = {} as BasicObject; // 用于记录已经出现过的数组
      const sortedUniqueArrays = [] as SafeAny[][]; // 存储排序并去重后的数组

      res.forEach(innerArr => {
        const sortedArr = innerArr.slice().sort(); // 对每个内部数组进行排序
        const key = sortedArr.join(','); // 将排序后的数组转换为字符串作为键
        if (!seen[key]) {
          // 如果当前数组未曾出现过
          seen[key] = true; // 记录当前数组已经出现
          sortedUniqueArrays.push(sortedArr); // 将当前数组添加到结果数组中
        }
      });
      return sortedUniqueArrays;
    }
    return res;
  };

  const combinationsList = React.useMemo<string[][]>(() => {
    if (listCShadow && listCShadow.length > 0 && titleList) {
      let total = 0;
      let totalList = [] as string[][];
      titleList.map(item => {
        const current = listCShadow
          .filter(pie => pie.type === item)
          .map(pie => pie.value);
        if (current.length > 0) {
          total += 1;
          totalList.push(current);
        }
      });
      const res = getAllCombinations(totalList, checkType(modeID).isX);
      return total >= titleList.length ? res : [];
    }
    return [];
  }, [listCShadow, titleList, modeID]);

  const renderTip = (hasNums: CartListItem[], hasBuy: roundTicketHisType[]) => {
    return (
      <View style={[theme.margin.tbl]}>
        <Text size="medium" fontWeight="500">
          {t('mix-lotto.tip.numberRepeated')}
          {t('mix-lotto.tip.cantAddToCart')}:
        </Text>
        <View
          style={[
            theme.flex.row,
            theme.flex.wrap,
            theme.margin.topxxs,
            {gap: theme.paddingSize.xxs},
          ]}>
          {hasNums.map((item, _i) => (
            <View
              key={_i}
              style={[
                theme.flex.row,
                theme.borderRadius.xs,
                theme.padding.lrs,
                theme.padding.tbxxs,
                // eslint-disable-next-line react-native/no-inline-styles
                {backgroundColor: 'rgb(240,240,246)'},
              ]}>
              <Text size="small" accent fontWeight="500">
                {item.value}
              </Text>
            </View>
          ))}
          {hasBuy.map((item, _i) => (
            <View
              key={_i}
              style={[
                theme.flex.row,
                theme.borderRadius.xs,
                theme.padding.lrs,
                theme.padding.tbxxs,
                // eslint-disable-next-line react-native/no-inline-styles
                {backgroundColor: 'rgb(240,240,246)'},
              ]}>
              <Text size="small" accent fontWeight="500">
                {delZero(item)}
              </Text>
            </View>
          ))}
        </View>
      </View>
    );
  };

  const delZero = (info: roundTicketHisType) => {
    const betNo = checkType(info.modeID).isX
      ? info.betNo
          .split('')
          .sort((a: string, b: string) => +a - +b)
          .join('')
      : info.betNo;
    if (checkType(info.modeID).isx4) {
      return betNo.slice(-4);
    }
    if (checkType(info.modeID).isx3) {
      return betNo.slice(-3);
    }
    if (checkType(info.modeID).isx2) {
      return betNo.slice(-2);
    }
    if (checkType(info.modeID).isx1) {
      return betNo.slice(-1);
    }
  };

  const onDigitAddToCart = (v?: View | null) => {
    if (maxTicketCount && combinationsList.length > maxTicketCount) {
      globalStore.globalWaringTotal(
        t('mix-lotto.tip.betMax', {num: maxTicketCount}),
      );
      return;
    }
    let targetList = combinationsList.map(item => {
      return Object.assign({}, options[0], {
        label: item.join(''),
        value: item.join(''),
        selectPrize,
        modeID,
        amount: 30,
        type: titleList.join(''),
      });
    });
    const hasNums = cartList.filter(
      item =>
        item.modeID === modeID &&
        item.type === titleList.join('') &&
        item.selectPrize === selectPrize &&
        checkExist(item.value),
    );
    const hasBuy = hasSelected.filter(
      item =>
        item.modeID === modeID &&
        item.tabID === selectPrize &&
        checkExistOrder(item.betNo),
    );

    if (hasNums.length > 0 || hasBuy.length > 0) {
      const hasNumsV = checkType(modeID).isX
        ? hasNums.map(item =>
            item.value
              .split('')
              .sort((a: string, b: string) => +a - +b)
              .join(''),
          )
        : hasNums.map(item => item.value);
      const hasBuyV = hasBuy.map(item => delZero(item));
      const exist = targetList
        .filter(item =>
          checkType(modeID).isX
            ? !hasNumsV.includes(
                item.value
                  .split('')
                  .sort((a: string, b: string) => +a - +b)
                  .join(''),
              )
            : !hasNumsV.includes(item.value),
        )
        .filter(item =>
          checkType(modeID).isX
            ? !hasBuyV.includes(
                item.value
                  .split('')
                  .sort((a: string, b: string) => +a - +b)
                  .join(''),
              )
            : !hasBuyV.includes(item.value),
        );
      confirmShowRepeat(t('label.tip'), renderTip(hasNums, hasBuy), () => {
        onCartChange?.(exist.concat(cartList));
        setListCShadow([]);
      });
      return;
    }
    parabolaService.start({
      startWith: v!,
      endWith: endElement!,
    });
    onCartChange?.(targetList.concat(cartList));
    setListCShadow([]);
  };

  const checkExist = (v: string) => {
    const res = checkType(modeID).isX
      ? combinationsList.find(
          item =>
            item.sort((a: string, b: string) => +a - +b).join('') ===
            v
              .split('')
              .sort((a: string, b: string) => +a - +b)
              .join(''),
        )
      : combinationsList.find(item => item.join('') === v);
    return Boolean(res);
  };

  const checkExistOrder = (v: string) => {
    const res = checkType(modeID).isX
      ? combinationsList.find(
          item =>
            padZero(
              item.sort((a: string, b: string) => +a - +b).join(''),
              4,
            ) ===
            v
              .split('')
              .sort((a: string, b: string) => +a - +b)
              .join(''),
        )
      : combinationsList.find(item => padZero(item.join(''), 4) === v);
    return Boolean(res);
  };

  React.useEffect(() => {
    setListCShadow([]);
  }, [selectPrize]);

  return (
    <>
      {lotteryDetail && lotteryDetail.tabs.length > 1 && (
        <PrizeTab
          tabs={lotteryDetail.tabs}
          selectPrize={selectPrize}
          onChange={onChangePrize}
        />
      )}
      <LotteryTip mode={modeID} />
      {titleList.map((item, _i) => (
        <BetsDigitItem
          disabledAll={disabledAll}
          currentPrize={selectPrize}
          cartList={cartList}
          hasSelected={hasSelected}
          cartListShadow={listCShadow}
          isSingle={modeID === LotteryMode['1 Digit']}
          key={_i}
          index={_i}
          type={item}
          mode={modeID}
          options={options}
          isDigit={isDigit}
          onBets={(v, view) => {
            onAddToCart(
              item,
              v,
              modeID !== LotteryMode['1 Digit'] &&
                modeID !== LotteryMode['Two Side'] &&
                modeID !== LotteryMode.FishPrawnCrab,
              view,
            );
          }}
        />
      ))}
      {modeID !== LotteryMode['1 Digit'] &&
        modeID !== LotteryMode['Two Side'] &&
        modeID !== LotteryMode.FishPrawnCrab && (
          <BetsActions
            counts={combinationsList.length}
            onDel={() => {
              if (combinationsList.length > 1) {
                confirmShow(t('label.del'), t('game-page.tip.tip-clear'), () =>
                  setListCShadow([]),
                );
              } else {
                setListCShadow([]);
              }
            }}
            onAdd={onDigitAddToCart}
          />
        )}
      {renderConfirmModal}
      {renderRepeatModal}
    </>
  );
};

export default React.memo(LotteryBets);
