import {View, ImageBackground} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import Ball from './ball';
import {useTranslation} from 'react-i18next';

export interface DrawResultProps {
  issueNum?: string;
  digit?: string;
  color?: string;
  onlyColor: boolean;
}

const DrawResult = ({
  issueNum = '',
  digit = '',
  color = 'g',
  onlyColor = false,
}: DrawResultProps) => {
  const {i18n} = useTranslation();
  return (
    <View style={[theme.flex.row]}>
      <View style={[theme.flex.flex1]}>
        <Text fontFamily="fontInterBold" size="medium">
          {issueNum}
        </Text>
        <Text fontFamily="fontInterBold" size="medium">
          {i18n.t('game-page.label.drawResult')}:
        </Text>
      </View>
      <ImageBackground
        resizeMode="stretch"
        style={[theme.flex.flex1, theme.flex.center, theme.padding.tbxxs]}
        source={require('@components/assets/imgs/game/color/result-bg.webp')}>
        <Ball
          digit={digit}
          color={color}
          onlyColor={onlyColor}
          ballSize={32}
          fontSize={14}
          fontColor={theme.basicColor.dark}
        />
      </ImageBackground>
    </View>
  );
};

export default DrawResult;
