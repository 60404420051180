import React from 'react';
import {View} from 'react-native';
import ProgressBar, {
  ProgressBarProps,
} from '@components/business/games/progress';
import {Times} from '@/components/business/games';
import {TabItem} from '../mix-lottery-service';
import theme from '@/style';

export interface QuickLotteryProps {
  lotteryID: number;
  tabs?: TabItem[];
  onTimeChange?: (id?: number) => void;
}

const QuickLottery = ({
  lotteryID,
  tabs = [],
  onTimeChange,
  value,
  total,
}: QuickLotteryProps & ProgressBarProps) => {
  const minutes = React.useMemo(() => {
    return tabs.map(item => {
      return {
        cycle: item.tabName,
        id: item.lotteryID,
      };
    });
  }, [tabs]);

  return (
    <>
      {minutes.length > 1 ? (
        <Times
          style={[
            // eslint-disable-next-line react-native/no-inline-styles
            {
              minHeight: 44,
              marginBottom: 0,
            },
          ]}
          checkColors={['#B7AFAF', '#DACECE']}
          itemStyle={[
            theme.flex.row,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              height: 44,
              gap: theme.paddingSize.xxs,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          ]}
          selectInnerStyle={[
            // eslint-disable-next-line react-native/no-inline-styles
            {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          ]}
          minutes={minutes}
          configId={lotteryID}
          onTimePress={onTimeChange}
        />
      ) : (
        <View />
      )}

      <ProgressBar value={value} total={total} />
    </>
  );
};

export default QuickLottery;
