import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {COLORS} from '../constant';

export interface BetsLabelProps {
  type: 'A' | 'B' | 'C' | 'D';
}

const BetsLabel = ({type}: BetsLabelProps) => {
  return (
    <View style={[theme.flex.center, theme.padding.rightl]}>
      <Text color={COLORS[type]} size="large" fontFamily="fontInterBold">
        {type}
      </Text>
      <View style={[styles.dot, {backgroundColor: COLORS[type]}]} />
    </View>
  );
};

const styles = StyleSheet.create({
  dot: {
    width: 16,
    height: 16,
    borderRadius: 8,
    marginTop: 8,
  },
});

export default BetsLabel;
