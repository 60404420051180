import TabHeader from '@/components/business/games/tab-header';
import React from 'react';

const LotteryTab = ({
  index,
  setIndex,
  orderNum,
}: {
  index?: number;
  setIndex?: (v: number) => void;
  configId?: number;
  refreshCode?: number;
  orderNum?: number;
}) => {
  const [tablist] = React.useState([
    {
      title: 'result',
      key: 'result',
    },
    {
      title: 'analyze',
      key: 'analyze',
    },
    {
      title: 'order',
      key: 'order',
    },
  ]);

  return (
    <TabHeader
      routes={tablist}
      onTabPress={setIndex}
      index={index || 0}
      orderNum={orderNum}
      orderKey="order"
    />
  );
};

export default LotteryTab;
