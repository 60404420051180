import {View} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import Price from '@/components/business/price';
import React from 'react';
import {COLORS} from '../constant';

export interface FeeDetailProps {
  fee?: number;
  odds?: number;
  type?: string;
  numbers?: string;
  delivery?: number;
  numberType?: string;
}

const FeeDetail = ({
  type,
  numbers,
  fee,
  delivery,
  odds,
  numberType,
}: FeeDetailProps) => {
  return (
    <View
      style={[
        theme.padding.lrl,
        theme.padding.tbs,
        {gap: theme.paddingSize.l},
      ]}>
      <View style={[theme.flex.row, theme.flex.between]}>
        <Text size="medium" fontWeight="500" accent>
          Type
        </Text>
        <Text size="medium" fontWeight="500">
          {type}
        </Text>
      </View>
      {numbers ? (
        <View style={[theme.flex.row, theme.flex.between]}>
          <Text size="medium" fontWeight="500" accent>
            Numbers
          </Text>
          {numberType && (
            <Text
              size="medium"
              fontFamily="fontInterBold"
              color={COLORS[numberType]}>
              {numbers}
            </Text>
          )}
        </View>
      ) : null}
      <View style={[theme.flex.row, theme.flex.between]}>
        <Text size="medium" fontWeight="500" accent>
          Odds
        </Text>
        <Text size="medium" fontWeight="500">
          @{odds}
        </Text>
      </View>
      <View style={[theme.flex.row, theme.flex.between]}>
        <Text size="medium" fontWeight="500" accent>
          Delivery
        </Text>
        <Price price={delivery} textProps={{size: 'medium'}} />
      </View>
      <View style={[theme.flex.row, theme.flex.between]}>
        <Text size="medium" fontWeight="500" accent>
          Fee
        </Text>
        <Price price={fee} textProps={{size: 'medium'}} />
      </View>
    </View>
  );
};

export default FeeDetail;
