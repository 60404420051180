import {View} from 'react-native';
import ResultTabItem from './result-tab-item';
import React from 'react';
import theme from '@/style';

export interface ResultTabProps {
  tabs: {
    key: string;
    label: string;
  }[];
  onSelectKey?: (key: string) => void;
  selectKey?: string;
}

const ResultTab = ({tabs = [], onSelectKey, selectKey}: ResultTabProps) => {
  return (
    <View
      style={[
        theme.flex.row,
        theme.padding.l,
        {gap: theme.paddingSize.s},
        theme.background.lightGrey,
      ]}>
      {tabs.map((item, _i) => (
        <ResultTabItem
          key={_i}
          onSelectKey={() => onSelectKey?.(item.key)}
          isSelect={selectKey === item.key}
          label={item.label}
        />
      ))}
    </View>
  );
};

export default ResultTab;
