import LazyImage from '@/components/basic/image';
import React from 'react';
import {View} from 'react-native';
import {useInnerStyle} from './wallet.hooks';
import theme from '@/style';
import Text from '@basicComponents/text';
import {toPriceStr} from '@/utils';
import {positionIcon} from './wallet.variable';
import {WalletAmountListItem} from './wallet.service';

interface WalletListProps {
  walletList: WalletAmountListItem[];
}

const WalletList: React.FC<WalletListProps> = ({walletList}) => {
  const {
    listStyle,
    size: {walletIconSize, walletLrGap},
  } = useInnerStyle();
  return (
    <View style={[theme.flex.row, theme.flex.wrap, theme.margin.topl]}>
      {walletList.map((item, index) => (
        <View
          key={index}
          style={[
            listStyle.wallet,
            theme.position.rel,
            theme.flex.col,
            theme.flex.center,
            item.balance > 0
              ? theme.background.primary
              : theme.background.white,
            // eslint-disable-next-line react-native/no-inline-styles
            {marginLeft: index % 3 === 0 ? 0 : walletLrGap},
            theme.borderRadius.m,
            theme.margin.btms,
          ]}>
          <View
            style={[theme.position.abs, theme.fill.fill, theme.flex.center]}>
            <LazyImage
              width={walletIconSize}
              height={walletIconSize}
              occupancy="#0000"
              imageUrl={item.otherUrl}
            />
          </View>
          <Text
            fontSize={theme.fontSize.m}
            blod
            color={
              item.balance > 0 ? theme.basicColor.white : theme.fontColor.main
            }
            style={[theme.margin.btmxxs]}>
            {toPriceStr(item.balance, {
              fixed: 2,
              spacing: true,
            })}
          </Text>
          <Text
            style={[theme.margin.btmxxs]}
            fontSize={theme.fontSize.s}
            color={
              item.balance > 0 ? theme.basicColor.white : theme.fontColor.accent
            }>
            {item.name}
          </Text>
          {item.balance > 0 && (
            <LazyImage
              occupancy="#0000"
              imageUrl={positionIcon}
              width={14}
              height={14}
            />
          )}
        </View>
      ))}
    </View>
  );
};

export default WalletList;
