import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {BasicObject} from '@/types';
import DigitBall from '@/components/business/games/lottery-color-ball';
import dayjs from 'dayjs';

export const INDEX_TO_O = {
  1: '1st',
  2: '2nd',
  3: '3rd',
} as BasicObject;

export const RESULT_INDEX = ['UNUSED', 'UNUSED', 'A', 'B', 'C', 'D'] as [
  'UNUSED',
  'UNUSED',
  'A',
  'B',
  'C',
  'D',
];

export interface StateLotteryResultProps {
  name: string;
  num: string;
  wonCode: string[];
  isQuick?: boolean;
  drawTime?: number;
}

const StateLotteryResult = ({
  name,
  wonCode = [],
  isQuick,
  num,
  drawTime,
}: StateLotteryResultProps) => {
  const {i18n} = useTranslation();

  return (
    <View
      style={[
        theme.padding.l,
        theme.background.white,
        theme.margin.btml,
        theme.flex.row,
        theme.flex.centerByCol,
      ]}>
      <View style={[theme.flex.flex1]}>
        {isQuick ? (
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.flex.wrap,
              {gap: theme.paddingSize.xxs},
            ]}>
            <Text accent size="medium" fontWeight="500">
              {dayjs(drawTime).format('DD-MM-YYYY')}
            </Text>
            <Text
              size="medium"
              fontFamily="fontInterBold"
              color={theme.basicColor.dark}>
              {dayjs(drawTime).format('hh:mm A')}
            </Text>
          </View>
        ) : (
          <Text
            size="medium"
            fontFamily="fontInterBold"
            color={theme.basicColor.dark}>
            {name} No.{num}
          </Text>
        )}
        <Text size="medium" fontFamily="fontInterBold">
          {i18n.t('bets-detail.label.drawRes')}:
        </Text>
      </View>
      <View style={[{gap: theme.paddingSize.xxs}]}>
        {wonCode.map((item, i) => (
          <View style={[theme.flex.row, theme.flex.between]} key={i}>
            <Text fontFamily="fontInterBold">{INDEX_TO_O[i + 1]}-prize：</Text>
            <View
              style={[
                theme.flex.row,
                theme.margin.leftxxs,
                {gap: theme.paddingSize.xxs},
              ]}>
              {item.split('').map((pie, _i) => (
                <DigitBall
                  disabled={
                    RESULT_INDEX.slice(-item.split('').length)[_i] === 'UNUSED'
                  }
                  digit={pie}
                  type={RESULT_INDEX.slice(-item.split('').length)[_i]}
                  key={_i}
                />
              ))}
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default StateLotteryResult;
