import {View} from 'react-native';

import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';
import React from 'react';
import theme from '@/style';
import Text from '@/components/basic/text';
import ResultHeader from '@/common-pages/pick-digit/digit-tab/digit-result/result-header';
import {useTranslation} from 'react-i18next';

export interface TableHederProps {
  isQuick?: boolean;
  drawTime?: string;
}

const TableHeder = ({drawTime}: TableHederProps) => {
  const {t} = useTranslation();
  return (
    <View
      style={[
        theme.margin.topl,
        theme.overflow.hidden,
        {
          borderTopLeftRadius: theme.paddingSize.s,
          borderTopRightRadius: theme.paddingSize.s,
        },
      ]}>
      <LinearGradient
        colors={['#FFFFFF', '#FFFFFF00']}
        style={[theme.padding.l, theme.flex.row, theme.flex.centerByCol]}>
        <Text
          accent
          style={[
            theme.margin.rightl,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              fontWeight: '500',
            },
          ]}>
          {t('bets-page.label.drawTime')}：
        </Text>
        <Text size="medium" fontFamily="fontInterBold">
          {drawTime}
        </Text>
      </LinearGradient>
      <ResultHeader />
    </View>
  );
};

export default TableHeder;
