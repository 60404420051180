import theme from '@/style';
import React from 'react';
import {View, ImageBackground, Image, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import {BasicObject} from '@/types';
const levels = {
  lucky: require('@components/assets/imgs/result/top-card/top-lucky.webp'),
  cons: require('@components/assets/imgs/result/top-card/top-cons.webp'),
  1: require('@components/assets/imgs/result/top-card/top-1.webp'),
  2: require('@components/assets/imgs/result/top-card/top-2.webp'),
  3: require('@components/assets/imgs/result/top-card/top-3.webp'),
  4: require('@components/assets/imgs/result/top-card/top-4.webp'),
  5: require('@components/assets/imgs/result/top-card/top-5.webp'),
  6: require('@components/assets/imgs/result/top-card/top-6.webp'),
  7: require('@components/assets/imgs/result/top-card/top-7.webp'),
  8: require('@components/assets/imgs/result/top-card/top-8.webp'),
  9: require('@components/assets/imgs/result/top-card/top-9.webp'),
  10: require('@components/assets/imgs/result/top-card/top-10.webp'),
  11: require('@components/assets/imgs/result/top-card/top-11.webp'),
} as BasicObject;

const LevelCard = ({
  bonus,
  top,
  children,
}: {
  bonus?: number;
  top: number | 'cons' | 'lucky';
  children?: React.ReactNode;
}) => {
  const color = React.useMemo(() => {
    switch (top) {
      case 1:
      case 'cons':
        return '#F15802';
      case 2:
        return '#4E4ED9';
      case 3:
        return '#982620';
      default:
        return '#000';
    }
  }, [top]);

  return (
    <View
      style={[
        theme.margin.lrl,
        theme.background.white,
        theme.borderRadius.m,
        theme.overflow.hidden,
        theme.margin.btml,
      ]}>
      <ImageBackground
        resizeMode="stretch"
        source={levels[top]}
        style={[theme.flex.row, theme.flex.end, theme.padding.l]}>
        <View
          style={[
            theme.padding.lrl,
            theme.padding.tbs,
            theme.flex.row,
            theme.flex.centerByCol,
            styles.itemContainer,
            {gap: theme.paddingSize.s},
          ]}>
          <Text accent fontFamily="fontInterBold" size="medium">
            BONUS
          </Text>
          <Text size="large" color={color} fontFamily="fontInterBold">
            RS {bonus}/-
          </Text>
          <Image
            style={[theme.icon.s]}
            source={require('@components/assets/imgs/result/top-card/banknotes.webp')}
          />
        </View>
      </ImageBackground>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    backgroundColor: '#FFFFFF80',
    borderColor: '#FFFFFF',
    borderWidth: 1,
    borderRadius: 20,
  },
});

export default LevelCard;
