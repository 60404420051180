import theme from '@/style';

export const dateImg = require('@components/assets/icons/filter/date.webp');
export const sanImg = require('@components/assets/icons/filter/san.webp');
export const filterImg = require('@components/assets/icons/filter/filter.webp');

export const filterColor = {
  sortRed: theme.basicColor.primary,
  sortGrey: '#9F9F9F',
};

export const down = require('@components/assets/icons/filter/down.webp');
export const up = require('@components/assets/icons/filter/up.webp');
