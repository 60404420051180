import theme from '@/style';
import {SVGProps} from '@/types';
import React from 'react';

const ColorBetsBg = ({width = 0, height = 0}: SVGProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_5567_17882)">
      <rect
        x="1.5"
        width={width! - 2}
        height={height! - 2}
        rx="8"
        fill="url(#paint0_linear_5567_17882)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5567_17882"
        x="0.5"
        y="0"
        width={width}
        height={height}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5567_17882"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5567_17882"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_5567_17882"
        x1="173"
        y1="0"
        x2="173"
        y2="65"
        gradientUnits="userSpaceOnUse">
        <stop stopColor={theme.backgroundColor.colorBetsListBg} />
        <stop
          offset="1"
          stopColor={theme.backgroundColor.colorBetsListBgLight}
        />
      </linearGradient>
    </defs>
  </svg>
);

export default ColorBetsBg;
