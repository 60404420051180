import en_US from './en_US';
import zh_CN from './zh_CN';
import hi_IN from './hi_IN';
import ml_IN from './ml_IN';
import ta_IN from './ta_IN';
import te_IN from './te_IN';
export default {
  en_US,
  zh_CN,
  hi_IN,
  ml_IN,
  ta_IN,
  te_IN,
};
