import {View} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import {useTranslation} from 'react-i18next';

export interface TableHeaderProps {
  headers: string[];
}

const TableHeader = ({headers = []}: TableHeaderProps) => {
  const {i18n} = useTranslation();
  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.centerByCol,
        theme.flex.between,
        theme.padding.lrl,
        theme.padding.tbs,
        theme.margin.lrl,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          backgroundColor: '#E9ECF5',
        },
      ]}>
      {headers.map((str, _i) => (
        <Text key={_i} color={theme.fontColor.second}>
          {i18n.t(`game-page.label.${str}`).toUpperCase()}
        </Text>
      ))}
    </View>
  );
};

export default TableHeader;
