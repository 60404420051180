import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import {downloadApk, stopBeforeDownload} from '@/utils';
import {inApp} from '@/utils/interface';
import React from 'react';
import {ImageBackground, Image} from 'react-native';

const Download: React.FC = () => {
  const [show, setShow] = React.useState(true);
  const {calcActualSize} = useScreenSize();
  const toDownload = () => {
    if (stopBeforeDownload()) {
      return;
    }
    downloadApk();
  };
  const bannerHeight = calcActualSize(56);
  const iconSize = calcActualSize(theme.iconSize.l);
  const margin = calcActualSize(theme.paddingSize.s);
  if (inApp) {
    return null;
  }
  return show ? (
    <ImageBackground
      resizeMode="cover"
      source={require('@assets/imgs/download.webp')}
      style={[
        theme.fill.fillW,
        theme.flex.row,
        theme.flex.centerByCol,
        {
          height: bannerHeight,
        },
      ]}>
      <NativeTouchableOpacity
        onPress={toDownload}
        style={[theme.flex.flex1, theme.fill.fillH]}
      />
      <NativeTouchableOpacity onPress={() => setShow(false)}>
        <Image
          style={[
            {
              height: iconSize,
              width: iconSize,
              marginHorizontal: margin,
            },
          ]}
          source={require('@assets/icons/close-white.webp')}
        />
      </NativeTouchableOpacity>
    </ImageBackground>
  ) : (
    <></>
  );
};

export default Download;
