import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import {Svg, Rect} from 'react-native-svg';
import React from 'react';

export interface BetsQuantityProps {
  onStep?: (step: number) => void;
  stepValue?: number;
  stepDisabled?: boolean;
  rates?: number[];
}

const BetsQuantity = ({
  stepDisabled = false,
  stepValue = 1,
  onStep = () => {},
  rates = [1, 3, 9, 27, 81, 243, 729],
}: BetsQuantityProps) => {
  return (
    <View style={styles.container}>
      <View
        style={[theme.flex.row, theme.flex.centerByCol, theme.flex.between]}>
        <Text size="medium" fontFamily="fontInterBold">
          Multiplier:
        </Text>
        <View style={[theme.flex.row]}>
          <NativeTouchableOpacity
            onPress={() => onStep(stepValue - 1)}
            disabled={stepValue === 1}
            style={[styles.step, stepDisabled && styles.disabled]}>
            <Svg width={12} height={12}>
              <Rect x="1" y="5" width="10" height="2" rx="1" fill="#5F6975" />
            </Svg>
          </NativeTouchableOpacity>
          <View style={styles.numberContainer}>
            {/* <TextInput
              value={'1'}
              style={styles.input}
            /> */}
            <Text size="medium" fontFamily="fontInterBold">
              {stepValue}
            </Text>
          </View>
          <NativeTouchableOpacity
            onPress={() => onStep(stepValue + 1)}
            disabled={stepValue === 99999}
            style={[styles.step, stepDisabled && styles.disabled]}>
            <Svg width={12} height={12}>
              <Rect x="1" y="5" width="10" height="2" rx="1" fill="#5F6975" />
              <Rect
                x="5"
                y="11"
                width="10"
                height="2"
                rx="1"
                transform="rotate(-90 5 11)"
                fill="#5F6975"
              />
            </Svg>
          </NativeTouchableOpacity>
        </View>
      </View>
      <View
        style={[
          theme.flex.row,
          theme.margin.topl,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            gap: 4,
          },
        ]}>
        {rates.map((item, index) => {
          const isActive = item === stepValue;
          return (
            <NativeTouchableOpacity
              onPress={() => onStep(item)}
              key={index}
              style={[
                theme.flex.center,
                theme.flex.flex1,
                styles.itemContainer,
                // eslint-disable-next-line react-native/no-inline-styles
                {
                  borderColor: isActive
                    ? theme.basicColor.primary
                    : 'rgba(255,255,255, 0.5)',
                },
              ]}>
              <Text
                color={
                  isActive ? theme.basicColor.primary : theme.basicColor.dark
                }
                fontFamily="fontInterBold">
                x{item}
              </Text>
            </NativeTouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 12,
    backgroundColor: '#F7F7F7',
    padding: 12,
    marginBottom: 16,
  },
  numberContainer: {
    backgroundColor: theme.backgroundColor.lightGrey,
    borderRadius: 4,
    borderWidth: 1,
    width: 116,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 4,
    borderColor: theme.backgroundColor.grey,
  },
  step: {
    paddingHorizontal: 9,
    backgroundColor: '#EAEBF4',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    padding: 0,
    flex: 1,
    textAlign: 'center',
    fontFamily: 'fontInterBold',
    fontWeight: 'bold',
    fontSize: 14,
  },
  disabled: {
    opacity: 0.3,
  },
  itemContainer: {
    backgroundColor: theme.backgroundColor.rateItem,
    height: 28,
    borderRadius: 4,
    borderWidth: 1,
  },
});

export default BetsQuantity;
