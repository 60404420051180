import theme from '@/style';
import React from 'react';
import {View, ScrollView, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import {COLOR_MAP, RESULT_MAP} from '../../constant';
import LinearGradient from '@/components/basic/linear-gradient';
import {DiceResultItem} from '../../dice-service';

const groupConsecutiveDuplicates = (arr: DiceResultItem[] = []) => {
  let result = [];
  let currentGroup = [] as DiceResultItem[];

  for (let i = 0; i < arr.length; i++) {
    if (i === 0 || arr[i].bigOrSmall === arr[i - 1].bigOrSmall) {
      currentGroup.push(arr[i]);
    } else {
      result.push([...currentGroup]);
      currentGroup = [arr[i]];
    }
  }

  if (currentGroup.length > 0) {
    result.push([...currentGroup]);
  }

  return result;
};

const AnalyzeGrid = ({list = []}: {list?: DiceResultItem[]}) => {
  const targetList = React.useMemo(() => {
    if (list && list.length > 0) {
      return groupConsecutiveDuplicates(list);
    }
    return [];
  }, [list]);

  const maxGrid = React.useMemo(() => {
    if (targetList.length > 0) {
      const lenArr = targetList.map(item => item.length);
      return Math.max(...lenArr);
    }
    return 6;
  }, [targetList]);
  return (
    <View style={styles.container}>
      <ScrollView horizontal contentContainerStyle={[]}>
        {targetList.map((item, _i) => {
          return (
            <View key={_i}>
              {item
                ?.concat(
                  new Array(maxGrid - (item.length || 0)).fill({
                    bigOrSmall: -1,
                  }),
                )
                .map((pie, _j) => (
                  <GridItem bigOrSmall={pie.bigOrSmall} key={_j} />
                ))}
            </View>
          );
        })}
      </ScrollView>
      <LinearGradient
        colors={['rgba(255, 255, 255, 0)', '#fff']}
        start={{
          x: 0,
          y: 0,
        }}
        end={{
          x: 1,
          y: 0,
        }}
        style={[styles.gradient]}
      />
    </View>
  );
};

interface GridItemProps {
  bigOrSmall?: number;
}
const GridItem = ({bigOrSmall = 0}: GridItemProps) => {
  return (
    <View style={[styles.item, theme.flex.center]}>
      {bigOrSmall >= 0 && (
        <View
          style={[
            styles.ball,
            theme.flex.center,
            {backgroundColor: COLOR_MAP[bigOrSmall]},
          ]}>
          <Text color={theme.basicColor.white}>{RESULT_MAP[bigOrSmall]}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderTopColor: theme.backgroundColor.grey,
    borderLeftWidth: 1,
    marginTop: 12,
    borderLeftColor: theme.backgroundColor.grey,
  },
  item: {
    width: 26,
    height: 26,
    borderBottomWidth: 1,
    borderBottomColor: theme.backgroundColor.grey,
    borderRightWidth: 1,
    borderRightColor: theme.backgroundColor.grey,
  },
  ball: {
    width: 22,
    height: 22,
    borderRadius: 11,
  },
  gradient: {
    width: 80,
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
  },
});

export default React.memo(AnalyzeGrid);
