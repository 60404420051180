import React from 'react';
import {View} from 'react-native';
import DigitQuickSelect from './digit-quick-select';
import {PickGamesContent} from './digit-service';
import TimeResult, {TimeResultProps} from './time-result';

export interface QuickDigitProps {
  list?: PickGamesContent[];
  activeIndex?: number;
  showRule?: () => void;
  remainingTime?: number;
  onChangeTime?: (i: number) => void;
  activeInfo?: PickGamesContent;
}

const QuickDigit = ({
  activeIndex = 0,
  activeInfo,
  showRule = () => {},
  remainingTime = 0,
  list = [],
  onChangeTime = () => {},
  ...props
}: QuickDigitProps & TimeResultProps) => {
  return (
    <View>
      {list.length > 1 ? (
        <DigitQuickSelect
          list={list}
          activeIndex={activeIndex}
          onChangeTime={onChangeTime}
        />
      ) : (
        <View />
      )}
      <TimeResult
        drawResult={activeInfo?.lastResult?.drawResult || '***'}
        pickNum={activeInfo?.lastResult?.pickNo || '-'}
        pickName={'Quick 3D'}
        remainingTime={remainingTime}
        tip={activeInfo?.currentPickNo || '-'}
        showRule={showRule}
        pickGameType={'quick'}
        {...props}
      />
    </View>
  );
};

export default React.memo(QuickDigit);
