import {View} from 'react-native';
import AnalyzeNum from './analyze-num';
import theme from '@/style';
import React from 'react';
import AnalyzeGrid from './analyze-grid';
import AnalyzeRound from './analyze-round';
import {DiceResultItem, getDiceResult} from '../../dice-service';
import Spin from '@/components/basic/spin';

const Analyze = ({
  configId = 0,
  isActive = false,
  refreshCode = 0,
}: {
  configId?: number;
  isActive?: boolean;
  refreshCode?: number;
}) => {
  const [nums, setNums] = React.useState<DiceResultItem[]>();
  const [loading, setLoading] = React.useState(false);
  const [firstLoad, setFirstLoad] = React.useState(true);

  React.useEffect(() => {
    if (configId && isActive) {
      getInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configId, isActive]);

  React.useEffect(() => {
    if (refreshCode && isActive) {
      getInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCode]);

  const getInfo = async () => {
    try {
      setLoading(true);
      const res = await getDiceResult(configId);
      if (res) {
        setNums(res.content);
      }
    } finally {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  return (
    <Spin loading={firstLoad || loading} style={[theme.flex.flex1]}>
      <View
        style={[
          theme.padding.l,
          theme.background.white,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            borderBottomColor: theme.backgroundColor.grey,
            borderBottomWidth: 1,
          },
        ]}>
        <AnalyzeNum list={nums} />
        {nums && nums.length > 0 && <AnalyzeGrid list={nums} />}
      </View>
      {nums && nums.length > 0 && <AnalyzeRound configId={configId} />}
    </Spin>
  );
};

export default React.memo(Analyze);
