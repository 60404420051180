import React from 'react';
import ResultPanel from '../components/result-panel/result-panel';
import {
  getStateOrders,
  getQuickStateOrders,
} from '@/common-pages/bets/bets.service';
import {
  LotteryOrder,
  LotteryOrderTicket,
  checkType,
} from '@/common-pages/mix-lottery/mix-lottery-service';
import {formatDate, goTo} from '@/utils';
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import {useTranslation} from 'react-i18next';
import globalStore from '@/services/global.state';
import dayjs from 'dayjs';
import Tag from '@/common-pages/mix-lottery/component/tag';
import {RESULT_INDEX, COLORS} from '@/common-pages/mix-lottery/constant';
import {SafeAny, BasicObject} from '@/types';
import DigitBall from '@/common-pages/mix-lottery/component/digit-ball';

const DEFAULT = ['A', 'B', 'C', 'D'];
const SIDE_TYPE = ['Odd', 'Even', 'Big', 'Small'];
const F_P_C_TYPE = ['Fish', 'Prawn', 'Crab'];

const StateLotteryDraw = ({isQuick = true}: {isQuick?: boolean}) => {
  const [result, setResult] = React.useState<LotteryOrder>();
  const {t} = useTranslation();
  const getOrder = async () => {
    const params = {
      pageNo: 1,
      pageSize: 1,
      orderStatus: '0',
      yearMonth: formatDate(new Date(), 'yyyyMM'),
    };
    try {
      const res = isQuick
        ? await getQuickStateOrders(params)
        : await getStateOrders(params);
      if (res.records) {
        setResult(res.records[0]);
      }
    } finally {
    }
  };

  React.useEffect(() => {
    if (globalStore.token) {
      getOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mergeArrayByField = (
    arr: SafeAny[],
    field1: string,
    field2: string,
  ) => {
    const merged = {} as BasicObject;
    for (const obj of arr) {
      const key = obj[field1] + '-' + obj[field2]; // 使用字段值组合作为键
      if (!merged[key]) {
        merged[key] = [];
      }
      merged[key].push(obj);
    }
    return merged;
  };

  const targetData = React.useMemo(() => {
    // const res = ticketsLists.filter(item => item.prize === 0);
    if (result?.ticketsLists) {
      return (
        mergeArrayByField(result.ticketsLists, 'modeName', 'tabName') || {}
      );
    }
    return {};
  }, [result]);

  const tagInfo = (id: number, betItem: number) => {
    if (id === 1) {
      if (betItem) {
        const TYPE_INDEX = (betItem - 1) % 4;
        const INDEX = Math.trunc((betItem - 1) / 4);
        return {
          type: DEFAULT[INDEX],
          label: SIDE_TYPE[TYPE_INDEX],
        };
      }
    }
    if (id === 9) {
      const TYPE_INDEX = (betItem - 1) % 3;
      return {
        type: 'D',
        label: F_P_C_TYPE[TYPE_INDEX],
      };
    }
    return {};
  };

  const getDigitData = (modeID: number, betNo: string) => {
    if (checkType(modeID).isx4) {
      const indexs = RESULT_INDEX.slice(-4);
      return betNo
        .slice(-4)
        .split('')
        .map((item, _i) => {
          return {
            number: item,
            bg: COLORS[indexs[_i]],
          };
        });
    }
    if (checkType(modeID).isx3) {
      const indexs = RESULT_INDEX.slice(-3);
      return betNo
        .slice(-3)
        .split('')
        .map((item, _i) => {
          return {
            number: item,
            bg: COLORS[indexs[_i]],
          };
        });
    }
    if (checkType(modeID).isx2) {
      const indexs = RESULT_INDEX.slice(-2);
      return betNo
        .slice(-2)
        .split('')
        .map((item, _i) => {
          return {
            number: item,
            bg: COLORS[indexs[_i]],
          };
        });
    }
    if (checkType(modeID).isx1) {
      return betNo
        .slice(-1)
        .split('')
        .map((item, _i) => {
          return {
            number: item,
            bg: theme.basicColor.primary,
          };
        });
    }
    return [];
  };

  const renderItem = (lotteryInfo: LotteryOrderTicket, index: number) => {
    if (lotteryInfo.modeID === 9 || lotteryInfo.modeID === 1) {
      const info = tagInfo(lotteryInfo.modeID, lotteryInfo.betItem);
      return (
        <View
          key={index}
          style={[
            theme.margin.topxxs,
            theme.flex.row,
            {gap: theme.paddingSize.xxs},
          ]}>
          {info.type && (
            <Tag label={info.label} backgroundColor={COLORS[info.type]} />
          )}
        </View>
      );
    }
    const list = getDigitData(lotteryInfo.modeID, lotteryInfo.betNo);
    return (
      <View
        key={index}
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          {gap: theme.paddingSize.xxs},
        ]}>
        {list.map((item, _i) => (
          <DigitBall
            isPrimary={checkType(lotteryInfo.modeID).isX}
            key={_i}
            digit={item.number}
            bg={item.bg}
            type={'A'}
          />
        ))}
      </View>
    );
  };

  return result?.orderGroup ? (
    <ResultPanel
      onMore={() => {
        goTo('Bets', {
          tag: isQuick ? 'Quick State Lottery' : 'State Lottery',
        });
      }}
      betInfo={
        <View>
          <Text numberOfLines={1} fontFamily="fontInterBold" main>
            {result.lotteryName} {'\n'}
          </Text>
          <Text numberOfLines={1} size="small" fontFamily="fontInterBold" main>
            {result.roundNo}
          </Text>
        </View>
      }
      betTime={dayjs(result.createTime * 1000).format('DD-MM-YYYY hh:mm A')}
      winAmount={result.totalPrize}>
      <View style={[theme.padding.lrl, theme.padding.tops]}>
        <Text textAlign="center">{t('game-page.label.myBets')}</Text>
        <View style={[theme.margin.topxs]}>
          {Object.keys(targetData)
            .slice(0, 3)
            .map((item, _i) => {
              const valuesData = targetData[item].slice(0, 6);
              return (
                <View key={_i} style={[styles.item]}>
                  <Text fontFamily="fontInterBold">{item}:</Text>
                  <View
                    style={[
                      theme.flex.row,
                      {gap: theme.paddingSize.s},
                      theme.flex.wrap,
                    ]}>
                    {valuesData.map((pie: LotteryOrderTicket, _ii: number) =>
                      renderItem(pie, _ii),
                    )}
                  </View>
                </View>
              );
            })}
        </View>
      </View>
    </ResultPanel>
  ) : null;
};

const styles = StyleSheet.create({
  drawContainer: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: '#F4F4F4',
  },
  item: {
    marginTop: 2,
    backgroundColor: '#F6F7FB',
    paddingHorizontal: 12,
    borderRadius: 8,
    paddingVertical: 4,
  },
  over: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    paddingTop: 80,
  },
});

export default StateLotteryDraw;
