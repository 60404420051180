import React from 'react';
import {BannerListItem} from '../home.type';
import theme from '@/style';
import Carousel from 'react-native-reanimated-carousel';
import {View, StyleSheet} from 'react-native';
import LazyImage from '@basicComponents/image';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {goToUrl} from '@/common-pages/game-navigate';
import {useResponsiveDimensions} from '@utils';

interface BannerProps {
  bannerList?: BannerListItem[];
}

const HomeBanner = ({bannerList = []}: BannerProps) => {
  const {width: screenWidth} = useResponsiveDimensions();
  const bannerWidth = screenWidth - theme.paddingSize.l * 2;
  const bannerHeight = (bannerWidth * 120) / 351;

  const [currentIndex, setCurrentIndex] = React.useState(0);
  const styles = StyleSheet.create({
    idotBox: {
      bottom: theme.paddingSize.l * 2,
      width: screenWidth - theme.paddingSize.l * 2,
    },
    idot: {
      width: theme.paddingSize.xxs,
      height: theme.paddingSize.xxs,
      borderRadius: theme.paddingSize.xxs / 2,
      marginHorizontal: theme.paddingSize.xxs / 2,
      backgroundColor: theme.backgroundColor.palegrey,
    },
    idotActive: {
      backgroundColor: theme.basicColor.primary,
      width: theme.paddingSize.l,
    },
  });

  return (
    <View style={[theme.position.rel, theme.padding.l]}>
      {bannerList?.length > 0 && (
        <Carousel
          loop
          style={[theme.borderRadius.m, theme.overflow.hidden]}
          width={bannerWidth}
          height={bannerHeight}
          autoPlay
          autoPlayInterval={3000}
          scrollAnimationDuration={1000}
          data={bannerList}
          onProgressChange={e => {
            let index = Math.abs(Math.round(e / bannerWidth));
            if (index >= bannerList?.length) {
              index = 0;
            }
            if (index !== currentIndex) {
              setCurrentIndex(index);
            }
          }}
          renderItem={({item}) => (
            <NativeTouchableOpacity
              style={[theme.fill.fill]}
              onPress={() => {
                item.skipLinks && goToUrl(item.skipLinks, item.title);
              }}>
              <View
                style={[
                  theme.fill.fill,
                  theme.borderRadius.m,
                  theme.overflow.hidden,
                  theme.background.primary,
                ]}>
                <LazyImage
                  occupancy={theme.backgroundColor.palegrey}
                  imageUrl={item.bannerImg}
                  height={bannerHeight}
                  width={bannerWidth}
                />
              </View>
            </NativeTouchableOpacity>
          )}
        />
      )}
      {!bannerList?.length && (
        <View
          style={[
            {
              height: bannerHeight,
              width: bannerWidth,
            },
            theme.borderRadius.m,
            theme.background.palegrey,
          ]}
        />
      )}
      {bannerList?.length > 0 && (
        <View
          style={[
            theme.position.abs,
            theme.flex.row,
            theme.flex.center,
            styles.idotBox,
          ]}>
          {bannerList.map((_v, i) => (
            <View
              key={i}
              style={[styles.idot, currentIndex === i && styles.idotActive]}
            />
          ))}
        </View>
      )}
    </View>
  );
};

export default HomeBanner;
