import React from 'react';
import {View, StyleSheet, ImageBackground} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import {useTranslation} from 'react-i18next';

const SattaPanel = ({
  pannel = '',
  hideLabel = false,
}: {
  pannel: string;
  hideLabel?: boolean;
}) => {
  const strArr = React.useMemo(() => {
    if (pannel) {
      return pannel.split('');
    }
    return [];
  }, [pannel]);

  const {t} = useTranslation();

  return (
    <ImageBackground
      resizeMode="cover"
      source={require('@components/assets/imgs/game/satta/pannel-bg.webp')}
      style={[styles.container]}>
      {!hideLabel && (
        <Text style={[{color: theme.basicColor.white}, theme.margin.rights]}>
          {t('satta.title.panel')}
        </Text>
      )}
      <View
        style={[
          theme.flex.row,
          // eslint-disable-next-line react-native/no-inline-styles
          {gap: 2},
        ]}>
        {strArr.map((item, _i) => {
          if (item === '-') {
            return (
              <Text
                key={_i}
                size="large"
                fontFamily="fontInterBold"
                style={[{color: theme.basicColor.white}]}>
                -
              </Text>
            );
          }
          return (
            <View key={_i} style={[styles.item, theme.flex.center]}>
              <Text
                fontSize={20}
                fontFamily="fontInterBold"
                style={[{color: theme.basicColor.white}]}>
                {item}
              </Text>
            </View>
          );
        })}
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 8,
    overflow: 'hidden',
  },
  item: {
    width: 20,
    borderRadius: 2,
    backgroundColor: '#000',
  },
});

export default React.memo(SattaPanel);
