import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import {goBack, goTo} from '@/utils';
import React from 'react';
import {View, ScrollView, TextInput, StyleSheet} from 'react-native';
import {
  KeralaGroupLevel,
  KeralaOrderResult,
  getGroupLevelList,
} from '../result-detail-service';
import Text from '@/components/basic/text';
import {useRoute} from '@react-navigation/native';
import {BasicObject} from '@/types';
import dayjs from 'dayjs';
import Search from '@/components/svgs/basic/search';
import ResultPanel from '@/common-pages/result/components/result-panel/result-panel';
import {useTranslation} from 'react-i18next';
import Tag from './tag';
import LevelCard from './level-card';
import NoResult from '@/components/basic/error-pages/no-result';
interface KeralaSearchResult {
  code: string;
  winAmount: number;
}

const getTop = (drawLevel: string): number | 'cons' | 'lucky' => {
  if (
    drawLevel.endsWith('th') ||
    drawLevel.endsWith('st') ||
    drawLevel.endsWith('nd') ||
    drawLevel.endsWith('rd')
  ) {
    return +drawLevel.substring(0, drawLevel.length - 2);
  }
  return drawLevel === 'Cons' ? 'cons' : 'lucky';
};

const KeralaDetail = () => {
  const {params = {}} = useRoute() as BasicObject;
  const {issueNo = '', drawDate} = params;
  const [groupLevelList, setGroupLevelList] =
    React.useState<KeralaGroupLevel[]>();
  const [orderResult, setOrderResult] =
    React.useState<KeralaOrderResult | null>();
  const [searchNo, setSearchNo] = React.useState<string>();
  const [searchResult, setSearchResult] =
    React.useState<KeralaSearchResult | null>({
      code: '',
      winAmount: 0,
    });
  const [keywords, setKeywords] = React.useState('');

  const getLevel = async (id = '') => {
    try {
      const res = await getGroupLevelList(id);
      if (res) {
        setOrderResult(res.keralaOrderRes);
        setGroupLevelList(res.groupLevelList);
      }
    } finally {
    }
  };
  const {t} = useTranslation();

  React.useEffect(() => {
    if (issueNo) {
      getLevel(issueNo);
    }
  }, [issueNo]);

  const handleSearch = (value: string) => {
    if (!value || value.trim() === '') {
      setSearchNo(undefined);
      return;
    }
    setSearchNo(value);
    if (!groupLevelList || !groupLevelList.length) {
      setSearchResult({
        code: value,
        winAmount: 0,
      });
      return;
    }
    let item: KeralaGroupLevel | undefined;
    // 首先查询1cons23等奖
    const bigList = groupLevelList.slice(0, 4);
    item = bigList.find(lv =>
      lv.lotteryResultList.find(v => value === v.lotteryCode),
    );
    if (!item) {
      // 然后匹配45678等奖
      const smallList = groupLevelList.slice(4);
      item = smallList.find(lv =>
        lv.lotteryResultList.find(v => value.endsWith(v.lotteryCode)),
      );
    }
    if (!item) {
      if (value.length !== 8) {
        setSearchResult(null);
        return;
      }
      setSearchResult({
        code: value,
        winAmount: 0,
      });
      return;
    }
    setSearchResult({
      code: value,
      winAmount: item.lotteryResultList[0].drawAmount,
    });
  };

  return (
    <View style={[theme.flex.flex1, theme.background.lightGrey]}>
      <DetailNavTitle onBack={goBack} serverRight hideAmount title={'Kerala'} />
      <View style={[theme.padding.l, theme.background.white]}>
        <View style={[theme.flex.row, theme.flex.between, theme.padding.l]}>
          <Text main size="large" fontFamily="fontInterBold">
            {issueNo}
          </Text>
          <Text
            size="medium"
            // eslint-disable-next-line react-native/no-inline-styles
            style={[{fontWeight: '500'}]}>
            {orderResult?.drawDate || drawDate}
          </Text>
        </View>
        <View style={styles.inputContainer}>
          <Search />
          <View style={[theme.flex.flex1]}>
            <TextInput
              maxLength={8}
              value={keywords}
              onChangeText={v => {
                setKeywords(v);
                if (!v) {
                  setSearchNo('');
                }
              }}
              placeholderTextColor={'#9FA5AC'}
              style={styles.input}
              underlineColorAndroid={'transparent'}
              placeholder="Enter Ticket NO."
              returnKeyType="search"
              onSubmitEditing={e => {
                handleSearch(e.nativeEvent.text);
              }}
            />
          </View>
        </View>
      </View>
      <View style={[theme.flex.flex1, theme.flex.basis0]}>
        <ScrollView contentContainerStyle={[theme.padding.topl]}>
          {!searchNo && orderResult && (
            <ResultPanel
              onMore={() => {
                goTo('Bets', {
                  tag: 'Kerala',
                });
              }}
              betTime={dayjs(orderResult.betTime).format('DD-MM-YYYY hh:mm A')}
              winAmount={orderResult.wonTotalAmount}>
              <View style={[theme.padding.lrl, theme.padding.tops]}>
                <Text textAlign="center">{t('game-page.label.myBets')}</Text>
                <View
                  style={[
                    theme.padding.tbl,
                    theme.flex.center,
                    theme.flex.row,
                    {gap: theme.paddingSize.s},
                  ]}>
                  {orderResult.orderCodes?.slice(0, 3).map((order, i) => (
                    <Tag key={i} isWin={!!order.isWin} text={order.wonCode} />
                  ))}
                </View>
              </View>
            </ResultPanel>
          )}
          {!searchNo &&
            groupLevelList?.map((item, _i) => {
              return (
                <LevelCard
                  bonus={item.lotteryResultList?.[0]?.drawAmount}
                  key={_i}
                  top={getTop(item.drawLevel)}>
                  <View
                    style={[
                      theme.padding.l,
                      theme.flex.row,
                      theme.flex.wrap,
                      item.lotteryResultList.length < 3 && theme.flex.center,
                      {
                        columnGap: theme.paddingSize.s,
                        rowGap: theme.paddingSize.l * 2,
                      },
                    ]}>
                    {item.lotteryResultList.map((pie, _ii) => (
                      <Tag text={pie.lotteryCode} key={_ii} />
                    ))}
                  </View>
                </LevelCard>
              );
            })}

          {!!searchNo && searchResult && (
            <ResultPanel hideBet winAmount={searchResult.winAmount || 0}>
              <View style={[theme.padding.lrl, theme.padding.tops]}>
                <Text textAlign="center">{t('game-page.label.myBets')}</Text>
                <View
                  style={[
                    theme.padding.tbl,
                    theme.flex.center,
                    theme.flex.row,
                    {gap: theme.paddingSize.s},
                  ]}>
                  <Tag isWin={!!searchResult.winAmount} text={searchNo} />
                </View>
              </View>
            </ResultPanel>
          )}
          {searchNo && !searchResult && <NoResult />}
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    borderColor: '#DEE1E9',
    borderWidth: 1,
    height: 44,
    flexDirection: 'row',
    paddingHorizontal: 20,
    backgroundColor: '#EFF1F7',
    borderRadius: 40,
    alignItems: 'center',
    columnGap: 12,
  },
  input: {
    fontSize: 14,
    padding: 0,
    outlineWidth: '0',
  },
});

export default KeralaDetail;
