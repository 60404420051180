import LazyImage from '@/components/basic/image';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import React from 'react';
import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native';
import Text from '@/components/basic/text';
import LinearGradient from '@/components/basic/linear-gradient';
import {useTranslation} from 'react-i18next';
const copyIcon = require('@components/assets/icons/bets/copy.webp');

export interface DrawStatusProps {
  colors?: string[];
  textColor?: string;
  label?: string;
  id?: string;
  tip?: string;
  onCopy?: () => void;
  isDetail?: boolean;
  style?: StyleProp<ViewStyle>;
}

const DrawStatus = ({
  colors = [],
  textColor = '',
  label = '',
  id = '',
  isDetail = false,
  tip = '',
  style,
  onCopy = () => {},
}: DrawStatusProps) => {
  const {i18n} = useTranslation();
  return (
    <LinearGradient
      style={[
        // eslint-disable-next-line react-native/no-inline-styles
        {flexDirection: isDetail ? 'row' : 'row-reverse'},
        theme.flex.centerByCol,
        theme.flex.between,
        theme.borderRadius.xl,
        theme.padding.lrl,
        styles.borderRadius,
        theme.padding.tbs,
        style,
      ]}
      colors={colors}
      start={isDetail ? {x: 0, y: 0} : {x: 1, y: 0}}
      end={isDetail ? {x: 1, y: 0} : {x: 0, y: 0}}>
      <View
        style={[
          theme.flex.flex1,
          // eslint-disable-next-line react-native/no-inline-styles
          {alignItems: isDetail ? 'flex-start' : 'flex-end'},
        ]}>
        <Text fontWeight="500" numberOfLines={1} color={textColor}>
          {label}
        </Text>
      </View>
      {isDetail ? (
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Text color={theme.fontColor.accent} style={[theme.margin.rights]}>
            {i18n.t('label.id')}
          </Text>
          <Text color={theme.fontColor.second}>{id}</Text>
          <NativeTouchableOpacity onPress={onCopy} style={[theme.margin.lefts]}>
            <LazyImage
              imageUrl={copyIcon}
              width={14}
              height={14}
              occupancy="transparent"
            />
          </NativeTouchableOpacity>
        </View>
      ) : (
        <Text
          size="medium"
          color={theme.basicColor.dark}
          style={[theme.margin.rightl]}
          fontWeight="500">
          {tip}
        </Text>
      )}
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  borderRadius: {
    borderRadius: 20,
  },
});
export default DrawStatus;
