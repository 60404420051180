import theme from '@style';
import {StyleSheet} from 'react-native';

const tableStyle = StyleSheet.create({
  th: {
    ...theme.padding.lrl,
    ...theme.padding.tbs,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#E9ECF5',
  },
  tbPayment: {
    width: 96,
  },
  diceNumber: {
    width: 60,
  },
  tbGameType: {
    width: 72,
  },
  textRight: {
    textAlign: 'right',
  },
  td: {
    flexDirection: 'row',
    ...theme.background.white,
    alignItems: 'center',
    height: 48,
    paddingHorizontal: 12,
  },
  tdGray: {
    backgroundColor: '#F8F9FF',
  },
});

export default tableStyle;
